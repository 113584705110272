import { gql } from '@apollo/client';
import { adminEmailTemplateFragment } from 'graphql/fragments';

const adminEmailTemplatesQuery = gql`
  query adminEmailTemplatesQuery($isLibrary: Boolean) {
    emailTemplates(isLibrary: $isLibrary) {
      ...adminEmailTemplateFragment
    }
  }
  ${adminEmailTemplateFragment}
`;

export default adminEmailTemplatesQuery;
