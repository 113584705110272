import { Badge } from 'antd';
import { numberToLocaleString } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { find } from 'lodash';

const formattedInputValue = ({ inputField, showDigits, t, isCustomPrice }) => {
  if (typeof inputField.value === 'string')
    return (
      <span className="info-text">
        {[find(inputField.options, { _id: inputField.value })?.name, inputField.unitName].filter(Boolean).join(' ')}
      </span>
    );
  let { value } = inputField;
  let postfix = null;
  if (Number.isFinite(inputField.variable?.value ?? inputField.defaultValue)) {
    const defaultValue = inputField.variable?.value ?? inputField.defaultValue;
    value = isCustomPrice ? defaultValue : inputField.value ?? defaultValue;
    postfix = value !== defaultValue && (
      <span>
        <Badge
          className="ant-badge-background-primary-color"
          count={t('viewer.ShoppingCartView.customInternalValue')}
        />
      </span>
    );
  }

  return [
    [numberToLocaleString(value, t('localeCode'), { isRestrictMaximumDigits: false, showDigits }), inputField.unitName]
      .filter(Boolean)
      .map((e) => <span className="info-text">{e}</span>),
    postfix,
  ]
    .flat()
    .filter(Boolean)
    .map((e) => <>{e} </>);
};

export default formattedInputValue;
