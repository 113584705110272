import apollo from 'graphql/apollo';
import { sendTestEmailWithSMTPQuery } from 'graphql/queries';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

const sendTestEmail = async () =>
  apollo
    .query({
      query: sendTestEmailWithSMTPQuery,
      fetchPolicy: 'network-only',
    })
    .then(toast.successDefault)
    .catch(toastGraphQLError);

export default sendTestEmail;
