import apollo from 'graphql/apollo';
import { updateTenantMutation } from 'graphql/mutations';

const updateTenant = async ({
  _id,
  name,
  nrOfAllowedCompaniesByPricingPlan,
  nrOfAllowedUsersByPricingPlan,
  licenseStarted,
  locale,
  disabled,
}) =>
  apollo.mutate({
    mutation: updateTenantMutation,
    variables: {
      _id,
      modifier: {
        name,
        nrOfAllowedCompaniesByPricingPlan,
        nrOfAllowedUsersByPricingPlan,
        licenseStarted,
        locale,
        disabled,
      },
    },
  });
export default updateTenant;
