import I18nFormik from 'components/common/I18nFormik';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { Checkbox, Form, Input, Radio } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/SaveChangesButton';
import * as Yup from 'yup';
import i18n from 'i18n';
import { adminEmailPreferencesQuery, adminEmailServiceQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { Skeleton, Button, Row, Col, Alert, Tooltip, Collapse, Spin } from 'antd';
import { updateEmailService, updateTenantSettings } from 'graphql/methods';
import { defaults } from 'lodash';
import { useFormikContext } from 'formik';
import sendTestEmail from 'graphql/methods/admin/tenant/sendTestEmail';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import confirmModal from 'utils/confirmModal';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import {
  BulbOutlined,
  CloudServerOutlined,
  InfoCircleOutlined,
  MailOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons';
import Card from 'components/common/Card';
import { FaSignature } from 'react-icons/fa';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import apolloClient from 'graphql/apollo';
import { gql } from '@apollo/client';
import { TableMemo } from 'memo';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { EmailFormikRichEditor } from '../EmailTemplates/EmailTemplates';
import OtherEmailSettings from './OtherEmailSettings';

const { Panel } = Collapse;

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const schema = () => ({
  mailServiceProvider: Yup.string()
    .required()
    .nullable()
    .label(i18n.t('admin.Setting.Mail.inputs.mailServiceProvider')),
  smtpUserName: Yup.string().nullable().label(i18n.t('admin.Setting.Mail.inputs.smtpUserName')),
  smtpPassword: Yup.string().nullable().label(i18n.t('admin.Setting.Mail.inputs.smtpPassword')),
  smtpServer: Yup.string().nullable().label(i18n.t('admin.Setting.Mail.inputs.smtpServer')),
  smtpPort: Yup.string().nullable().label(i18n.t('admin.Setting.Mail.inputs.smtpPort')),
  from: Yup.string().nullable().label(i18n.t('admin.Setting.Mail.inputs.from.label')),
  useTLS: Yup.boolean().nullable(),
  useSSLv3: Yup.boolean().nullable(),
  requireTLS: Yup.boolean().nullable(),
});
const updateEmailSettingSchema = () => Yup.object().shape(schema());
const defaultValues = {
  mailServiceProvider: 'default',
  smtpUserName: '',
  smtpPassword: '',
  smtpServer: '',
  smtpPort: '',
  from: '',
  useTLS: false,
  useSSLv3: false,
  requireTLS: false,
};
const EmailHelperWidget = memo(() => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t('admin.CatalogueConfiguration.EmailHelperWidget.howUseButton')}
      title={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.title')}
      helpText={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.CatalogueConfiguration.EmailHelperWidget.modalInfo.imageUrl')}
    />
  );
}, equal);
const useInitialValues = () => {
  const { data, loading } = useCachedQuery(adminEmailServiceQuery);
  const emailService = grabFirstGQLDataResult(data);

  const initialValues = useMemo(() => defaults({}, emailService, defaultValues), [emailService]);
  return { initialValues, loading, initialLoading: !data && loading };
};

const EmailSettingsFormFields = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const disableSmtpInputs = useMemo(() => values.mailServiceProvider !== 'smtp', [values.mailServiceProvider]);
  return (
    <>
      <FormItem name="mailServiceProvider">
        <Radio.Group name="mailServiceProvider">
          <Radio name="mailServiceProvider" value="default">
            {i18n.t('admin.Setting.Email.inputs.mailServiceProvider.default')}
          </Radio>
          <Radio name="mailServiceProvider" value="smtp">
            {i18n.t('admin.Setting.Email.inputs.mailServiceProvider.smtp')}
          </Radio>
        </Radio.Group>
      </FormItem>
      <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
        <Col xs={24} sm={12}>
          <FormItem name="smtpUserName" label={t('admin.Setting.Email.inputs.smtpUserName')}>
            <Input name="smtpUserName" disabled={disableSmtpInputs} />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem name="smtpPassword" label={t('admin.Setting.Email.inputs.smtpPassword')}>
            <Input name="smtpPassword" type="password" disabled={disableSmtpInputs} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
        <Col xs={24} sm={12}>
          <FormItem name="smtpServer" label={t('admin.Setting.Email.inputs.smtpServer')}>
            <Input name="smtpServer" disabled={disableSmtpInputs} />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem name="smtpPort" label={t('admin.Setting.Email.inputs.smtpPort')}>
            <Input name="smtpPort" disabled={disableSmtpInputs} />
          </FormItem>
        </Col>
      </Row>
      <FormItem
        name="from"
        label={t('admin.Setting.Email.inputs.from.label')}
        tooltip={t('admin.Setting.Email.inputs.from.tooltip')}
      >
        <Input name="from" />
      </FormItem>
      <FormItem name="placeholder" label={t('admin.Setting.Email.inputs.options.label')}>
        <Checkbox name="useTLS" disabled={disableSmtpInputs}>
          {t('admin.Setting.Email.inputs.useTLS')}{' '}
          <Tooltip title={t('admin.Setting.Email.inputs.options.useTLS.tooltip')} autoAdjustOverflow>
            <InfoCircleOutlined />
          </Tooltip>
        </Checkbox>
        <Checkbox name="requireTLS" disabled={disableSmtpInputs}>
          {t('admin.Setting.Email.inputs.requireTLS')}{' '}
          <Tooltip title={t('admin.Setting.Email.inputs.options.requireTLS.tooltip')} autoAdjustOverflow>
            <InfoCircleOutlined />
          </Tooltip>
        </Checkbox>
        <Checkbox name="useSSLv3" disabled={disableSmtpInputs}>
          {t('admin.Setting.Email.inputs.useSSLv3')}{' '}
          <Tooltip title={t('admin.Setting.Email.inputs.options.useSSLv3.tooltip')} autoAdjustOverflow>
            <InfoCircleOutlined />
          </Tooltip>
        </Checkbox>
      </FormItem>
    </>
  );
};

export const SignatureRichEditor = (props) => <EmailFormikRichEditor name="signature" {...props} />;

const SignatureCard = () => {
  const result = useCachedQuery(adminEmailPreferencesQuery);
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      signature:
        grabFirstGQLDataResult(result.data)?.emailPreferences?.signature ?? RichText.getDefaultRichEditorValue(),
    }),
    [result.data],
  );
  const onSubmit = useCallback(async ({ signature }) => {
    await updateTenantSettings({
      settings: {
        emailPreferences: {
          signature,
        },
      },
    });
  }, []);
  return (
    <GraphQLLoadingWrapper {...result} countSkeletons={1}>
      <Card icon={<FaSignature />} title={t('admin.Setting.Email.signatureTitle')}>
        <I18nFormik initialValues={initialValues} onSubmit={onSubmit}>
          <Form layout="vertical">
            <AlertFromFormik />
            <SignatureRichEditor label="" />
            <SaveChangesButton initialValues={initialValues} />
          </Form>
        </I18nFormik>
      </Card>
    </GraphQLLoadingWrapper>
  );
};
const SignatureCardMemo = memo(SignatureCard, equal);
const getAllowedEmailConfigurationsQuery = gql`
  query getAllowedEmailConfigurations {
    getAllowedEmailConfigurations
  }
`;
const getAllowedConfigurations = () =>
  apolloClient
    .query({ query: getAllowedEmailConfigurationsQuery, fetchPolicy: 'network-only' })
    .then((e) => JSON.parse(e.data.getAllowedEmailConfigurations))
    .catch(toastGraphQLError);

const AllowedConfigurations = ({ emailServiceData }) => {
  const { t } = useTranslation();
  if (!emailServiceData) return null;
  return (
    <Card icon={<SearchOutlined />} title={t('admin.Setting.Email.smtpSetting.button')}>
      {(() => {
        if (emailServiceData === 'loading')
          return (
            <>
              <Spin style={{ marginRight: 15 }} />
              {t('admin.Setting.Email.smtpSetting.loading')}
            </>
          );
        const bestOption = emailServiceData[0];
        const columns = (isSuccess) =>
          [
            isSuccess && {
              title: t('common.success').replace('!', ''),
              render: (row) => {
                const style = { width: '100%', height: '100%', margin: -16, position: 'absolute', display: 'flex' };
                return !row.success ? (
                  <Tooltip title={row.error || ''} autoAdjustOverflow>
                    <div style={{ ...style, background: 'red' }}>
                      <InfoCircleOutlined style={{ margin: 'auto' }} />
                    </div>
                  </Tooltip>
                ) : (
                  <div style={{ ...style, background: 'green' }} />
                );
              },
              key: 'success',
            },
            {
              title: t('admin.Setting.Email.inputs.smtpPort'),
              render: (row) => row.smtpPort,
              key: 'port',
            },
            {
              title: t('admin.Setting.Email.inputs.useTLS'),
              render: (row) => t(row.useTLS ? 'common.yes' : 'common.no'),
              key: 'useTLS',
            },
            {
              title: t('admin.Setting.Email.inputs.requireTLS'),
              render: (row) => t(row.requireTLS ? 'common.yes' : 'common.no'),
              key: 'requireTLS',
            },
            {
              title: t('admin.Setting.Email.inputs.useSSLv3'),
              render: (row) => t(row.useSSLv3 ? 'common.yes' : 'common.no'),
              key: 'useSSLv3',
            },
          ].filter(Boolean);
        const successColumns = columns(true);
        const optionColumns = columns();
        return (
          <>
            {bestOption.success && (
              <>
                <Alert
                  showIcon
                  type="success"
                  className="margin-bottom-16"
                  message={t('common.success')}
                  description={t('admin.Setting.Email.smtpSetting.found')}
                />
                <TableMemo rowKey="_id" dataSource={[emailServiceData[0]]} pagination={false} columns={optionColumns} />
              </>
            )}
            {!bestOption.success && (
              <Alert
                showIcon
                type="error"
                className="margin-bottom-16"
                message={t('common.error')}
                description={t('admin.Setting.Email.smtpSetting.notFound')}
              />
            )}
            <Collapse bordered={false} defaultActiveKey={null} expandIconPosition="left">
              <Panel forceRender header={t('admin.Setting.Email.smtpSetting.showDetails')}>
                <TableMemo
                  rowKey="_id"
                  dataSource={emailServiceData.map((e) => ({ ...e, _id: JSON.stringify(e) }))}
                  pagination={false}
                  columns={successColumns}
                />
              </Panel>
            </Collapse>
          </>
        );
      })()}
    </Card>
  );
};

function EmailSettingWrapper() {
  const { t } = useTranslation();
  const [me] = useCurrentUser();
  const { initialValues, initialLoading } = useInitialValues();
  const currentSubmitTypeRef = useRef({ type: undefined }); // save or save and test
  const formikRef = useRef();
  const [emailServiceData, setEmailServiceData] = useState();

  const setBestSMTPSettings = useCallback(async (values) => {
    setEmailServiceData('loading');
    await getAllowedConfigurations()
      .then(async (r) => {
        setEmailServiceData(r);
        if (r[0].success) {
          currentSubmitTypeRef.current.type = 'setBestSMTPSettings';
          await updateEmailService(
            ['requireTLS', 'smtpPort', 'useSSLv3', 'useTLS'].reduce(
              (acc, name) => ({ ...acc, [name]: r[0][name] }),
              values,
            ),
          );
        }
      })
      .catch((e) => (toastGraphQLError(e), setEmailServiceData(null)));
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      if (currentSubmitTypeRef.current.type === 'test') {
        confirmModal({
          cancelText: i18n.t('admin.Setting.Email.saveAndTestEmailModal.cancelText'),
          icon: <MailOutlined />,
          okText: i18n.t('admin.Setting.Email.saveAndTestEmailModal.okText'),
          onOk: async () => {
            await updateEmailService(values);
            await sendTestEmail();
          },
          title: i18n.t('admin.Setting.Email.saveAndTestEmailModal.title', { email: me?.email }),
        });
      } else if (currentSubmitTypeRef.current.type === 'getValid') {
        if (
          !(
            values.mailServiceProvider === 'smtp' &&
            values.smtpServer &&
            values.smtpPassword &&
            values.smtpUserName &&
            values.from
          )
        ) {
          return confirmModal({
            disableCloseButton: true,
            okText: i18n.t('common.ok'),
            okType: 'danger',
            onOk: () => {},
            title: i18n.t('admin.Setting.Email.smtpSetting.validationError'),
          });
        }
        await updateEmailService(values);
        await setBestSMTPSettings(values);
      } else if (values.mailServiceProvider === 'smtp' && currentSubmitTypeRef.current.type === 'save') {
        confirmModal({
          cancelText: i18n.t('common.no'),
          maskClosable: true,
          okText: i18n.t('common.yes'),
          okType: 'danger',
          onOk: async () => {
            await updateEmailService(values).catch(toastGraphQLError);
            setBestSMTPSettings(values).catch(toastGraphQLError);
          },
          onCancel: () => updateEmailService(values),
          title: i18n.t('admin.Setting.Email.setBestSMTPSettingsTitle'),
        });
      } else await updateEmailService(values);
      return undefined;
    },
    [me?.email, setBestSMTPSettings],
  );
  if (initialLoading) return skeletons.map((k) => <Skeleton title loading active key={k} />);
  return (
    <PageContainer
      title={t('admin.Setting.Email.title')}
      right={
        <>
          <Button
            icon={<SearchOutlined />}
            onClick={() => {
              currentSubmitTypeRef.current.type = 'getValid';
              formikRef.current?.handleSubmit();
            }}
          >
            {t('admin.Setting.Email.smtpSetting.button')}
          </Button>{' '}
          <Button
            icon={<SendOutlined />}
            onClick={() => {
              currentSubmitTypeRef.current.type = 'test';
              formikRef.current?.handleSubmit();
            }}
          >
            {t('admin.Setting.Email.saveAndTestEmail')}
          </Button>
        </>
      }
    >
      <Alert
        icon={<BulbOutlined />}
        showIcon
        closable
        className="alert-info"
        message={t('admin.Setting.Email.saveAndTestEmailAlert.message')}
        description={t('admin.Setting.Email.saveAndTestEmailAlert.description')}
        type="info"
      />
      <AllowedConfigurations emailServiceData={emailServiceData} />
      <EmailHelperWidget />
      <Card icon={<CloudServerOutlined />} title={t('admin.Setting.Email.settingTitle')}>
        <I18nFormik
          ref={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={updateEmailSettingSchema}
          enableReinitialize
        >
          <Form layout="vertical">
            <AlertFromFormik />
            <EmailSettingsFormFields currentSubmitTypeRef={currentSubmitTypeRef} />
            <SaveChangesButton
              initialValues={initialValues}
              onClick={() => {
                currentSubmitTypeRef.current.type = 'save';
                return true;
              }}
            />
          </Form>
        </I18nFormik>
      </Card>
      <OtherEmailSettings mailServiceProvider={initialValues.mailServiceProvider} />
      <SignatureCardMemo />
    </PageContainer>
  );
}

export default memo(EmailSettingWrapper, equal);
