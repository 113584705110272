import { ComparisonTypes } from 'constants/shoppingCart';
import { find } from 'lodash';
import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import useComparingData from './useComparingData';
import { applyGlobalToIndependent } from './utils';

const itemComparedProps = [
  'calculationMode',
  'highlightBindingness',
  'minPrice',
  'paymentInterval',
  'pricingFormula',
  'scales',
];
const staticItemComparedProps = ['nameRaw', 'valueRaw'];
const inputFieldsProps = ['defaultValue'];

export const useComparingShoppingCartCategories = ({ shoppingCartId, query, fetchPolicy }) => {
  const { global, independent, loading, error } = useComparingData({
    queries: [query],
    shoppingCartId,
    fetchPolicy,
  });

  const categories = useMemo(() => {
    // region TODO: enable comparison feature
    // if (!shoppingCartId && global) return grabFirstGQLDataResult(global);
    // endregion
    if (global) return grabFirstGQLDataResult(global);
    if (!global || !independent) return null;
    const globalCategories = grabFirstGQLDataResult(global);
    const independentCategories = grabFirstGQLDataResult(independent);
    return independentCategories.map((category) => {
      const globalCategory = find(globalCategories, { _id: category._id });
      const newItems = applyGlobalToIndependent({
        disableComparisonTypes: [ComparisonTypes.NEW],
        independent: category.items,
        global: globalCategory?.items,
        comparedProps: itemComparedProps,
      });
      return {
        ...category,
        items: newItems.map((item) => ({
          ...item,
          inputFields: applyGlobalToIndependent({
            disableComparisonTypes: [ComparisonTypes.NEW],
            independent: item.inputFields,
            global: find(globalCategory?.items, { _id: item._id })?.inputFields,
            comparedProps: inputFieldsProps,
          }),
        })),
        staticItems: applyGlobalToIndependent({
          independent: category.staticItems,
          global: globalCategory?.staticItems,
          comparedProps: staticItemComparedProps,
        }),
      };
    });
  }, [global, independent]);

  return { loading, error, data: categories };
};

export default useComparingShoppingCartCategories;
