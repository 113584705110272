import { gql } from '@apollo/client';

const adminInputFieldFragment = gql`
  fragment adminInputFieldFragment on InputField {
    _id
    allowedRangeMaxValue
    allowedRangeMinValue
    categoryId
    defaultType
    defaultValue
    name
    optionPDFNameTitle
    optionPDFValueTitle
    order
    parentId
    parentType
    type
    unitName
    variable {
      _id
      label
    }
    category {
      _id
      name
    }
    parent {
      ... on Category {
        _id
        name
      }
      ... on CategoryItem {
        _id
        name
      }
    }
    options {
      _id
      order
      value
      valueExtended {
        _id
        _apolloCacheKey
        isComplex
      }
      name
    }
    recursiveFormulaInfo {
      _id
      usedIdsInFormula
    }
  }
`;

export default adminInputFieldFragment;
