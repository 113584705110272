import { find, groupBy, isEqual, pick } from 'lodash';
import { ComparisonTypes } from 'constants/shoppingCart';
import { useMemo } from 'react';

export const applyGlobalToIndependent = ({
  independent: _independent = [],
  global: _global = [],
  disableComparisonTypes = [],
  comparedProps,
  formatDocument = (e) => e,
}) => {
  const ids = [...new Set([..._independent, ..._global].map((e) => e._id))];
  const newData = ids
    .map((_id) => {
      const global = find(_global, { _id });
      const independent = find(_independent, { _id });

      let comparisonType;
      if (
        global &&
        independent &&
        !isEqual(formatDocument(pick(global, comparedProps)), formatDocument(pick(independent, comparedProps)))
      )
        comparisonType = ComparisonTypes.CHANGED;
      else if (!global) comparisonType = ComparisonTypes.DELETED;
      else if (!independent) comparisonType = ComparisonTypes.NEW;
      else comparisonType = ComparisonTypes.NO_CHANGE;
      if (disableComparisonTypes.includes(comparisonType)) return null;
      return {
        comparisonType,
        ...(independent ?? global),
        ...(comparisonType === ComparisonTypes.CHANGED && { global }),
      };
    })
    .filter(Boolean);
  return newData;
};

export const useGroupComparingData = (data) => {
  const groupedStaticItems = useMemo(
    () => groupBy(data, (e) => (ComparisonTypes.NEW === e.comparisonType ? e.comparisonType : ComparisonTypes.CHANGED)),
    [data],
  );
  return [groupedStaticItems[ComparisonTypes.CHANGED] ?? [], groupedStaticItems[ComparisonTypes.NEW] ?? []];
};
