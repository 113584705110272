import React, { useMemo, useRef } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { SubmitButton } from 'formik-antd';
import { debounce, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

const AUTOSAVE_INTERVAL = 1000;

const SaveChangesButton = ({
  style,
  marginTop = 16,
  initialValues,
  withDisable = false,
  autosaveEnabled = false,
  onClick = () => true,
  title,
}) => {
  const isChangedValues = useRef(false);
  const { values, handleSubmit, isSubmitting } = useFormikContext();
  const debounced = useMemo(
    () =>
      debounce(async () => {
        await handleSubmit();
        isChangedValues.current = true;
      }, AUTOSAVE_INTERVAL),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { t } = useTranslation();
  // console.log(values, initialValues);
  // console.log(JSON.stringify(values, null, 2), JSON.stringify(initialValues, null, 2));

  const B = withDisable ? SubmitButton : Button;
  const canBeSaved = !isEqual(values, initialValues);
  React.useEffect(() => {
    if (autosaveEnabled && canBeSaved) {
      debounced();
    }
  }, [autosaveEnabled, canBeSaved, debounced, values]);
  const handleSubmitWithConfirmation = (e) => {
    Promise.resolve(onClick(values, e)).then((res) => {
      if (!res) return;
      handleSubmit(e);
    });
  };
  if (autosaveEnabled && canBeSaved) return <div className="autosave-changes-button">{t('common.saving')}</div>;
  if (autosaveEnabled)
    return isChangedValues.current ? <div className="autosave-changes-button">{t('common.saved')}</div> : null;
  return (
    canBeSaved && (
      <B
        style={{ marginTop, ...style }}
        onClick={handleSubmitWithConfirmation}
        disabled={isSubmitting}
        type="primary"
        className="save-changes-button"
      >
        {title ?? t('admin.textsTabSubmitButton')}
      </B>
    )
  );
};

export default SaveChangesButton;
