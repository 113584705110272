import { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import toast from 'utils/toast';
import i18n from 'i18n';
import { every, map, find, pick } from 'lodash';
import { useCachedQuery } from 'graphql/utils';
import { gql } from '@apollo/client';
import { userShoppingCartPDFsQuery } from 'graphql/queries';
import filenamify from 'filenamify';
import moment from 'moment';
import { useComparingDocumentTemplates } from 'graphql/hooks';

export const stateLoadingPdf = {
  inactive: 'INACTIVE',
  generate: 'GENERATE',
  download: 'DOWNLOAD',
  save: 'SAVE',
};
export const printArrayBuffer = (data) => {
  const blob = convertArrayBufferToBlob(data);

  let objectURL = URL.createObjectURL(blob);
  const objFra = document.createElement('iframe'); // Create an IFrame.
  objFra.className = 'hiddenAbsolute'; // Hide the frame.
  objFra.src = objectURL; // Set source.
  objectURL = URL.revokeObjectURL(blob);

  document.body.appendChild(objFra); // Add the frame to the web page.

  objFra.contentWindow.focus(); // Set focus.
  objFra.contentWindow.print();
};

export function convertArrayBufferToBlob(data) {
  const blob = new Blob([data], { type: 'application/pdf' });
  return blob;
}
const withError =
  ({ fn, onError }) =>
  (err, data) => {
    const isGenerating = err?.message?.includes('503 S');
    const isNotFound = err?.message?.includes('404 N');
    if (err) {
      onError();
      if (isGenerating) toast.error(i18n.t('user.ShoppingCart.Buttons.pdfGenerating'));
      if (isNotFound) toast.error(i18n.t('user.ShoppingCart.Buttons.pdfNotFound'));
    } else {
      fn(data);
    }
  };

export const useDownloadShoppingCartPdf = ({ document, generatedFiles, addGeneratedPdf, options }) => {
  const [stateLoading, setStateLoading] = useState(stateLoadingPdf.inactive);
  const [progress, setProgress] = useState('');
  const onDownload = useCallback(
    ({ onOk = () => {} }) => {
      setStateLoading(stateLoadingPdf.generate);
      const cacheData = generatedFiles[document.link];
      const filename = `${document.name.replace(/(\.pdf)+$/, '')}.pdf`;

      if (cacheData) {
        setStateLoading(stateLoadingPdf.save);
        onOk(cacheData);
        const pdf = convertArrayBufferToBlob(cacheData);
        if (options?.isSaveAs) saveAs(pdf, filename);
        setStateLoading(stateLoadingPdf.inactive);
        return;
      }
      const url = document.link;
      JSZipUtils.getBinaryContent(url, {
        progress: ({ percent }) => {
          setProgress(`${percent?.toFixed?.(0)}%`);
          if (stateLoading !== stateLoadingPdf.download) setStateLoading(stateLoadingPdf.download);
        },
        callback: withError({
          fn: (data) => {
            setStateLoading(stateLoadingPdf.save);
            const blob = convertArrayBufferToBlob(data);
            addGeneratedPdf(document.link, data);
            onOk(data);
            if (options?.isSaveAs) saveAs(blob, filename);
            setStateLoading(stateLoadingPdf.inactive);
          },
          onError: () => setStateLoading(stateLoadingPdf.inactive),
        }),
      });
    },
    [addGeneratedPdf, document, generatedFiles, options, stateLoading],
  );

  const loading = [stateLoadingPdf.generate, stateLoadingPdf.download].includes(stateLoading);
  return [onDownload, { loading, progress, stateLoading }];
};

export const useDownloadZipShoppingCartPdf = ({ documents, generatedFiles, addGeneratedPdf, companyName }) => {
  const [stateLoading, setStateLoading] = useState(stateLoadingPdf.inactive);
  const [progress, setProgress] = useState('');

  const onSaveZip = useCallback((zip, zipFilename) => {
    setStateLoading(stateLoadingPdf.save);
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      saveAs(zipFile, `${zipFilename}.zip`);
      setStateLoading(stateLoadingPdf.inactive);
      setProgress('');
    });
  }, []);

  const onDownload = () => {
    setStateLoading(stateLoadingPdf.generate);
    const urls = documents.map(({ link, name }) => ({ url: link, name }));
    const zip = new JSZip();
    const zipFilename = `${companyName} - ${moment().format('YYYY-MM-DD')}`;
    let count = 0;

    urls.map(({ url, name }) => {
      const filename = filenamify(`${name}.pdf`.replace('.pdf.pdf', '.pdf'), { replacement: '_' }); // https://stackoverflow.com/a/42210346

      const cacheData = generatedFiles && generatedFiles[url];

      if (cacheData) {
        zip.file(filename, cacheData, { binary: true });
        count += 1;
        if (count === urls.length) onSaveZip(zip, zipFilename);
        return null;
      }
      // loading a file and add it in a zip file
      return JSZipUtils.getBinaryContent(url, {
        progress: ({ percent }) => {
          setProgress(`${percent?.toFixed?.(0)}%`);
          if (stateLoading !== stateLoadingPdf.download) setStateLoading(stateLoadingPdf.download);
        },
        callback: withError({
          fn: (data) => {
            zip.file(filename, data, { binary: true });
            addGeneratedPdf && addGeneratedPdf(url, data);
            count += 1;
            if (count === urls.length) onSaveZip(zip, zipFilename);
          },
          onError: () => setStateLoading(stateLoadingPdf.inactive),
        }),
      });
    });
  };

  const loading = [stateLoadingPdf.generate, stateLoadingPdf.download].includes(stateLoading);
  return [onDownload, { loading, progress, stateLoading }];
};

export const usePrintPdf = ({ document, generatedFiles, addGeneratedPdf }) => {
  const [onDownload, options] = useDownloadShoppingCartPdf({
    document,
    generatedFiles,
    addGeneratedPdf,
  });
  const onPrint = useCallback(() => {
    onDownload({ onOk: (data) => printArrayBuffer(data) });
  }, [onDownload]);
  return [onPrint, options];
};

export const useDocuments = ({ shoppingCartId, fetchPolicy }) => {
  const { data, loading, error, startPolling, stopPolling } = useCachedQuery(userShoppingCartPDFsQuery, {
    variables: { _id: shoppingCartId },
  });

  const {
    data: documentTemplates,
    loading: documentTemplatesLoading,
    error: documentTemplatesError,
  } = useComparingDocumentTemplates({ shoppingCartId, fetchPolicy });
  const documents =
    data &&
    map(data?.userShoppingCart?.pdfs, (e) => ({
      ...e,
      ...pick(find(documentTemplates, { _id: e.documentTemplateId }), ['comparisonType', 'initializationConfigDate']),
      name: e.name || e.templateName,
    }));
  const isAllDocumentsGenerated = every(documents, (d) => !d.isGenerating);
  useEffect(() => {
    if (isAllDocumentsGenerated || (error && !error?.networkError)) stopPolling();
    else startPolling(1500);
    return stopPolling;
  }, [isAllDocumentsGenerated, error, startPolling, stopPolling]);
  return {
    documents,
    isAllDocumentsGenerated,
    companyName: data?.userShoppingCart?.company?.name,
    loading: loading || documentTemplatesLoading,
    error: error || documentTemplatesError,
  };
};

const getEmailStatusQuery = gql`
  query getEmailStatusQuery($_id: ID!) {
    getEmailStatus(_id: $_id)
  }
`;

export const useEmailStatus = ({ _id }) => {
  const { data, loading, error, startPolling, stopPolling } = useCachedQuery(getEmailStatusQuery, {
    variables: { _id },
    skip: !_id,
  });
  const status = data?.getEmailStatus;
  console.log(status);
  useEffect(() => {
    if (status === 'done' || error) stopPolling();
    else startPolling(1500);
    return stopPolling;
  }, [error, startPolling, status, stopPolling]);
  return { status, loading, error };
};
