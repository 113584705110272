import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { ComparisonTypes } from 'constants/shoppingCart';
import { userShoppingCartComparingDocumentTemplatesQuery, userComparingDocumentTemplatesQuery } from 'graphql/queries';
import { omit } from 'lodash';
import useComparingData from './useComparingData';
import { applyGlobalToIndependent } from './utils';

const documentTemplateComparedProps = ['name', 'order', 'description', 'blocks'];

const omitBlockProps = (documentTemplate) => ({
  ...documentTemplate,
  blocks: documentTemplate.blocks.map((block) => omit(block, ['_apolloCacheKey'])),
});

const useComparingDocumentTemplates = ({ shoppingCartId, fetchPolicy = 'cache-and-network' }) => {
  const { global, independent, loading, error } = useComparingData({
    queries: [userComparingDocumentTemplatesQuery, userShoppingCartComparingDocumentTemplatesQuery],
    shoppingCartId,
    isFetchGlobal: true, // TODO: enable comparison feature; remove isFetch Global
    fetchPolicy,
  });

  const documentTemplates = useMemo(() => {
    if (!shoppingCartId && global) return grabFirstGQLDataResult(global);
    if (!global || !independent) return null;
    const globalDocumentTemplates = grabFirstGQLDataResult(global);
    const independentDocumentTemplates = grabFirstGQLDataResult(independent).documentTemplates;
    return applyGlobalToIndependent({
      disableComparisonTypes: [ComparisonTypes.NEW],
      independent: independentDocumentTemplates,
      global: globalDocumentTemplates,
      comparedProps: documentTemplateComparedProps,
      formatDocument: omitBlockProps,
    });
  }, [global, independent, shoppingCartId]);

  return { loading, error, data: documentTemplates, global: grabFirstGQLDataResult(global) };
};

export default useComparingDocumentTemplates;
