import { gql } from '@apollo/client';
import userComparingDocumentTemplatesFragment from './userComparingDocumentTemplatesFragment';
import userTotalValueFragment from './userTotalValueFragment';

const userShoppingCartFragment = gql`
  fragment userShoppingCartFragment on ShoppingCart {
    _id
    isLatest
    isCompanyOutdated
    categories {
      _apolloCacheKey
      _id
      discountId
      inputFields {
        _apolloCacheKey
        _id
        value
        defaultValue
      }
      bookmarkedItems {
        _id
        customPrice
        officialReasonText
        internalNoteToTeam
        showCalculatedPrice
      }
      items {
        _id
        customPrice
        officialReasonText
        internalNoteToTeam
        showCalculatedPrice
      }
      startOfService
    }
    createdAt
    documentTemplates {
      initializationConfigDate
      ...userComparingDocumentTemplatesFragment
    }
    name
    feeType
    initializationConfigDate
    hiddenNote
    initializationConfigDateForCompany
    # region TODO: enable the isShoppingCartChanged modal
    # isSomethingChanged
    # endregion
    documentTemplateBlocks {
      _id
      props
    }
    pdfs {
      _id
      type
      link
      isGenerating
      name
    }
    showDiscounts
    showPrices
    monthly {
      ...userTotalValueFragment
    }
    oneOff {
      ...userTotalValueFragment
    }
    yearly {
      ...userTotalValueFragment
    }
    vatType
    showVat
    startOfContract
    meetingAt
    migrated
    contacts {
      _id
      _apolloCacheKey
      email
      initializationConfigDate
    }
    company {
      _id
      _apolloCacheKey
      initializationConfigDate
    }
    companyTypeId
  }
  ${userComparingDocumentTemplatesFragment}
  ${userTotalValueFragment}
`;

export default userShoppingCartFragment;
