import { Layout } from 'antd';
import AppHeader from 'components/layout/AppHeader';
import Footer from 'components/layout/Footer';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Logo from 'components/layout/Logo';
import { useIsUserRole } from 'router/route-paths';
import classNames from 'classnames';
import { ThemeProvider } from 'styled-components';
import { useThemeRichEditor } from 'graphql/hooks';
import NavBar, { CustomTriggerMemo } from '../layout/NavBar';
import s from './AppLayout.module.less';

const { Content } = Layout;
function LeftHeaderMenu({ visible, onClick }) {
  return (
    <div className={s.leftHeaderMenu}>
      <CustomTriggerMemo sizeIcon={25} visible={visible} onClick={onClick} />
      <Logo onClick={onClick} />
    </div>
  );
}

export default function AppLayout({ children }) {
  const [visible, setVisible] = useState(false);

  const { theme } = useThemeRichEditor();

  const isSmallWidthDevice = useMediaQuery({ maxWidth: 991 });
  useEffect(() => {
    setVisible(false);
  }, [isSmallWidthDevice]);
  const isUser = useIsUserRole();
  return (
    <ThemeProvider theme={theme}>
      <Layout className={classNames(s.layout, { 'user-role': isUser })}>
        <NavBar breakpoint={isSmallWidthDevice} visible={visible} setVisible={setVisible} />
        <Layout className={s.layout}>
          {isSmallWidthDevice ? <LeftHeaderMenu onClick={() => setVisible(true)} visible={visible} /> : null}
          <AppHeader />
          <Content className={s.content}>{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </ThemeProvider>
  );
}
