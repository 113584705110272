import { gql } from '@apollo/client';

const createShoppingCartRevisionMutation = gql`
  mutation createShoppingCartRevisionMutation($prevCartId: ID!, $shoppingCart: ShoppingCartInput!) {
    createShoppingCartRevision(prevCartId: $prevCartId, shoppingCart: $shoppingCart) {
      _id
    }
  }
`;

export default createShoppingCartRevisionMutation;
