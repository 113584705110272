import { gql } from '@apollo/client';
import adminDiscountFragment from './adminDiscountFragment';
import adminInputFieldFragment from './adminInputFieldFragment';
import adminItemFragment from './adminItemFragment';
import adminStaticItemFragment from './adminStaticItemFragment';

const adminCategoryListFragment = gql`
  fragment adminCategoryListFragment on Category {
    _id
    introForDocument
    introForStaticItem
    outroForStaticItem
    guidanceText
    guidanceVideoId
    name
    order
    __typename
    inputFields {
      ...adminInputFieldFragment
    }
    discounts {
      ...adminDiscountFragment
    }
    items {
      ...adminItemFragment
    }
    staticItems {
      ...adminStaticItemFragment
    }
    companyTypeIds
    subTitle
    isNew @client
  }
  ${adminInputFieldFragment}
  ${adminDiscountFragment}
  ${adminStaticItemFragment}
  ${adminItemFragment}
`;

export default adminCategoryListFragment;
