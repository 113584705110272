import apollo from 'graphql/apollo';
import { deleteEmailTemplateMutation } from 'graphql/mutations';
import { adminEmailTemplatesQuery, currentUserQuery } from 'graphql/queries';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { grabFirstGQLDataResult } from 'utils/helpers';
import toast from 'utils/toast';
import { gql } from '@apollo/client';

const shoppingCartPreferencesFragment = gql`
  fragment shoppingCartPreferencesFragment on ShoppingCartPreferences {
    emailTemplateId
  }
`;

const deleteEmailTemplate = async ({ _id, isLibrary }) =>
  apollo.mutate({
    mutation: deleteEmailTemplateMutation,
    variables: { _id },
    update: (cache, { data }) => {
      const isRemoved = grabFirstGQLDataResult(data);
      if (isRemoved) {
        if (!isLibrary) {
          const tenantId = cache.readQuery({ query: currentUserQuery })?.me?.tenantId;
          const preferences = cache.readFragment({
            id: `ShoppingCartPreferences___${tenantId}`,
            fragment: shoppingCartPreferencesFragment,
          });
          if (preferences?.emailTemplateId === _id) {
            cache.writeFragment({
              id: `ShoppingCartPreferences___${tenantId}`,
              fragment: shoppingCartPreferencesFragment,
              data: {
                emailTemplateId: null,
              },
            });
          }
        }

        const cachedData = cache.readQuery({
          query: adminEmailTemplatesQuery,
          variables: { isLibrary },
        });
        cache.writeQuery({
          query: adminEmailTemplatesQuery,
          variables: { isLibrary },
          data: {
            emailTemplates: cachedData.emailTemplates.filter((emailTemplate) => emailTemplate._id !== _id),
          },
        });
      }
    },
  });

export const deleteEmailTemplatesWithConfirmation = ({ _id, isLibrary }) =>
  confirmModal({
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    okType: 'danger',
    onOk: async () => {
      deleteEmailTemplate({ _id, isLibrary })
        .then(() => toast.successDefault())
        .catch(() => toast.error(i18n.t('admin.EmailTemplates.modal.error.delete')));
    },
    title: i18n.t('admin.EmailTemplates.modal.deleteTitle'),
  });

export default deleteEmailTemplate;
