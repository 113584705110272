import { gql } from '@apollo/client';
import { currentUserFragment } from 'graphql/fragments';

const adminUsersInTenantQuery = gql`
  query adminUsersInTenantQuery {
    usersInTenant {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`;

export default adminUsersInTenantQuery;
