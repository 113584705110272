import { Checkbox, Input, Radio, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import { InputNumber } from 'components/common/InputNumber';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { RichEditorField } from 'components/common/RichEditor';
import { PricingFormulaSuggestionsFormik } from 'components/admin/itemModal/PricingFormulaSuggestions';
import { useLibraryContext } from 'contexts/LibraryContext';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { RichText, Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { Alert, Tooltip } from 'antd';
import MemoryStorage from 'utils/MemoryStorage';
import { useCachedQuery } from 'graphql/utils';
import { adminGetCategoryListQuery, adminRoundPricesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { BulbOutlined, CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import cn from 'classnames';
import i18n from 'i18n';
import equal from 'fast-deep-equal/es6/react';
import { useFormikField } from 'hooks/common/useFormikField';
import { isMobile } from 'react-device-detect';
import { CalculationMode, HighlightBindingness } from 'constants/item';
import apollo from 'graphql/apollo';
import { find, filter } from 'lodash';
import PricingFormulaInput from './PricingFormulaInput';
import classes from './components.module.less';

const MobileRoundPriceIdFormikInput = ({ ComponentGroup, Component, isSelectInput, roundPrices }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { value, onChange } = useFormikField('roundPriceId');
  const suffixIcon = useMemo(() => {
    if (value && isOpen) return <CloseCircleFilled onClick={() => onChange(false)} />;

    return <DownOutlined />;
  }, [isOpen, onChange, value]);

  return (
    <ComponentGroup
      allowClear
      className={isSelectInput || classes.roundPrices}
      name="roundPriceId"
      onDropdownVisibleChange={setIsOpen}
      placeholder={t('admin.Setting.ShoppingCartPreferences.pricingSetting.roundPrice.defaultOption')}
      suffixIcon={suffixIcon}
    >
      {roundPrices?.map(({ _id, label }) => (
        <Component key={_id} value={_id}>
          {t(label)}
        </Component>
      ))}
    </ComponentGroup>
  );
};

export const RoundPriceIdFormikInput = ({ isSelectInput = true, label: labelProps, tooltip }) => {
  const { t } = useTranslation();
  const { data } = useCachedQuery(adminRoundPricesQuery);
  const roundPrices = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const [ComponentGroup, Component] = useMemo(
    () => (isSelectInput ? [Select, Select.Option] : [Radio.Group, Radio]),
    [isSelectInput],
  );
  return (
    <FormItem
      name="roundPriceId"
      label={labelProps || t('admin.Setting.ShoppingCartPreferences.pricingSetting.roundPrice.title')}
      tooltip={tooltip}
    >
      {isMobile ? (
        <MobileRoundPriceIdFormikInput
          Component={Component}
          ComponentGroup={ComponentGroup}
          isSelectInput={isSelectInput}
          roundPrices={roundPrices}
        />
      ) : (
        <ComponentGroup
          name="roundPriceId"
          className={isSelectInput || classes.roundPrices}
          placeholder={t('admin.Setting.ShoppingCartPreferences.pricingSetting.roundPrice.defaultOption')}
          allowClear
        >
          {roundPrices?.map(({ _id, label }) => (
            <Component name="roundPriceId" key={_id} value={_id}>
              {t(label)}
            </Component>
          ))}
        </ComponentGroup>
      )}
    </FormItem>
  );
};

export const MinimalPriceFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="minPrice" label={t('admin.itemModal.inputs.minPrice.label')} {...props}>
      <InputNumber min={0} name="minPrice" />
    </FormItem>
  );
};

export const PricingFormulaFormikInput = ({ categoryId, _id, ...props }) => {
  return (
    <PricingFormulaInput
      name="pricingFormula"
      label="admin.itemModal.inputs.pricingFormula.label"
      hookProps={{
        categoryId,
        parentId: _id,
      }}
      toolbarProps={{ isAlwaysShow: true }}
      {...props}
    />
  );
};
export const PaymentIntervalFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="paymentInterval" label={t('admin.itemModal.inputs.paymentInterval.label')} {...props}>
      <Radio.Group name="paymentInterval">
        <Radio.Button value="oneOff">{t('admin.itemModal.inputs.paymentInterval.oneOffLabel')}</Radio.Button>
        <Radio.Button value="monthly">{t('admin.itemModal.inputs.paymentInterval.monthlyLabel')}</Radio.Button>
        <Radio.Button value="yearly">{t('admin.itemModal.inputs.paymentInterval.yearlyLabel')}</Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};

const getItemsUsingItem = ({ isLibrary, categoryId, parentId }) => {
  try {
    const categories = grabFirstGQLDataResult(
      apollo.readQuery({
        query: adminGetCategoryListQuery(isLibrary),
      }),
    );
    return filter(find(categories, { _id: categoryId })?.items, (item) => item._id !== parentId).filter(
      ({ pricingFormula }) => {
        const formula = Mentions.richTextToFormula(pricingFormula, { hideMentionText: true });
        return !!Mentions.getInputFieldIdsFromFormula(formula).find((_id) => _id === parentId);
      },
    );
  } catch {
    return [];
  }
};

export const CalculationModeFormikInput = memo(({ _id, categoryId, ...props }) => {
  const { t } = useTranslation();
  const calculationModeName = useMemo(() => Object.values(CalculationMode), []);
  const { isLibrary } = useLibraryContext();
  const items = useMemo(
    () => getItemsUsingItem({ categoryId, isLibrary, parentId: _id }),
    [_id, categoryId, isLibrary],
  );
  const disabled = useMemo(() => {
    if (items.length)
      return t('common.Item.switchToOnActualCost', {
        items: items.map(({ name }) => `"${name}"`).join(', '),
      });
    return '';
  }, [items, t]);

  return (
    <FormItem
      name="calculationMode"
      label={t('admin.itemModal.inputs.calculationMode.label')}
      tooltip={t('admin.itemModal.inputs.calculationMode.tooltip')}
      {...props}
    >
      <Radio.Group name="calculationMode">
        {calculationModeName.map((calculationMode) => {
          return (
            <Tooltip title={disabled}>
              <Radio.Button
                disabled={disabled && calculationMode === CalculationMode.ON_ACTUAL_COST}
                value={calculationMode}
              >
                {t(`common.Item.calculationMode.${calculationMode}`)}
              </Radio.Button>
            </Tooltip>
          );
        })}
      </Radio.Group>
    </FormItem>
  );
}, equal);

export const HighlightBindingnessFormikInput = memo(({ ...props }) => {
  const { t } = useTranslation();
  const highlightBindingnessName = useMemo(() => Object.values(HighlightBindingness), []);
  return (
    <FormItem
      name="highlightBindingness"
      label={t('admin.itemModal.inputs.highlightBindingness.label')}
      tooltip={t('admin.itemModal.inputs.highlightBindingness.tooltip')}
      {...props}
    >
      <Select name="highlightBindingness">
        {highlightBindingnessName.map((highlightBindingness) => (
          <Select.Option key={highlightBindingness} value={highlightBindingness}>
            {t(`common.Item.highlightBindingness.${highlightBindingness}`)}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
}, equal);

export const ConfigurePricingScalesCheckbox = () => {
  const { t } = useTranslation();
  return (
    <FormItem
      name="scalesEnabled"
      className={cn('row-reverse hide-form-item-colon', classes.checkboxInput)}
      label={t('admin.itemModal.inputs.scales.label')}
      tooltip={t('admin.itemModal.inputs.scales.tooltip')}
    >
      <Checkbox name="scalesEnabled" />
    </FormItem>
  );
};
export const NameFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="name" label={t('admin.itemModal.inputs.name.label')} {...props}>
      <Input autoFocus name="name" placeholder={t('admin.itemModal.inputs.name.placeholder')} />
    </FormItem>
  );
};

export const InfoTextFormikInput = ({ props }) => (
  <RichEditorField
    name="infoText"
    rootElement="paragraph"
    label="admin.itemModal.inputs.infoText.label"
    tooltip={i18n.t('admin.itemModal.inputs.infoText.tooltip')}
    hidePlaceholdersButton
    {...props}
  />
);
export const PleaseNoteFormikInput = ({ props }) => (
  <RichEditorField
    rootElement="paragraph"
    name="pleaseNote"
    label="admin.itemModal.inputs.pleaseNote.label"
    tooltip={i18n.t('admin.itemModal.inputs.pleaseNote.tooltip')}
    {...props}
  />
);
export const NotesForFeeAgreementFormikInput = ({ props }) => (
  <RichEditorField
    rootElement="paragraph"
    name="notesForFeeAgreement"
    label="admin.itemModal.inputs.notesForFeeAgreement.label"
    tooltip={i18n.t('admin.itemModal.inputs.notesForFeeAgreement.tooltip')}
    {...props}
  />
);
export const NotesToEmployeeFormikInput = ({ props }) => (
  <RichEditorField
    name="notesToEmployee"
    rootElement="paragraph"
    label="admin.itemModal.inputs.notesToEmployee.label"
    tooltip={i18n.t('admin.itemModal.inputs.notesToEmployee.tooltip')}
    hidePlaceholdersButton
    {...props}
  />
);
export const DescriptionForContractFormikInput = ({ props }) => (
  <RichEditorField
    name="descriptionForContract"
    rootElement="paragraph"
    label="admin.itemModal.inputs.descriptionForContract.label"
    tooltip={i18n.t('admin.itemModal.inputs.descriptionForContract.tooltip')}
    {...props}
  />
);
export const BenefitsFormikInput = ({ props }) => (
  <RichEditorField
    name="benefits"
    rootElement="paragraph"
    label="admin.itemModal.inputs.benefits.label"
    tooltip={i18n.t('admin.itemModal.inputs.benefits.tooltip')}
    {...props}
  />
);
export const SubTitleFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="subTitle" label={t('admin.itemModal.inputs.subTitle.label')} {...props}>
      <Input name="subTitle" placeholder={t('admin.itemModal.inputs.subTitle.placeholder')} />
    </FormItem>
  );
};
export const GuidanceTextFormikRichEditor = () => {
  const { t } = useTranslation();
  return (
    <RichEditorField
      rootElement="paragraph"
      hidePlaceholdersButton
      name="guidanceText"
      label={t('admin.categoryTexts.guidanceText.label')}
    />
  );
};
export const GuidanceVideoFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="guidanceVideoId" label={t('admin.itemModal.inputs.guidanceVideoId.label')}>
      <Input name="guidanceVideoId" placeholder={t('admin.itemModal.inputs.guidanceVideoId.placeholder')} />
    </FormItem>
  );
};

export const PricingFormulaHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('admin.pricingFormulaInput.howUseButton')}
      title={t('admin.pricingFormulaInput.modalInfo.title')}
      helpText={t('admin.pricingFormulaInput.modalInfo.helpText')}
      videoCaption={t('admin.pricingFormulaInput.modalInfo.videoCaption')}
      videoUrl={t('admin.pricingFormulaInput.modalInfo.videoUrl')}
    >
      <PricingFormulaSuggestionsFormik />
    </MoreInfoWidget>
  );
};

export const ImportedAlert = memo(({ guidanceText, guidanceVideoId, uuid, type }) => {
  const alertUUID = `${uuid}_${type}_guidance_temp`;
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();

  const isShowAlertRef = useRef(!MemoryStorage.get(alertUUID)?.hidden);

  const onClose = useCallback(() => {
    isShowAlertRef.current = false;
    MemoryStorage.set(alertUUID, { hidden: true });
  }, [alertUUID]);

  if (!uuid) return null;
  if (!isLibrary && isShowAlertRef.current && (!RichText.isRichTextEmpty(guidanceText) || guidanceVideoId))
    return (
      <Alert
        onClose={() => onClose()}
        closable
        className="alert-info"
        message={t(`admin.guidanceAlert.${type}`)}
        icon={<BulbOutlined />}
        showIcon
        description={
          <>
            {!RichText.isRichTextEmpty(guidanceText) ? RichText.renderRichText(guidanceText) : null}
            {guidanceVideoId ? (
              <div>
                <MoreInfoWidget
                  videoUrl={guidanceVideoId}
                  title={t('admin.itemModal.guideAlert.guidanceVideoModalTitle')}
                  buttonText={t('admin.itemModal.guideAlert.guidanceVideoButton')}
                />
              </div>
            ) : null}
          </>
        }
      />
    );

  return null;
}, equal);
