import { ChromePicker } from 'react-color';
import { useFormikField } from 'hooks/common/useFormikField';
import { Button, Dropdown, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldSearchDropdown } from 'pages/user/ShoppingCart/ContactData/components/Inputs';
import { useCachedQuery } from 'graphql/utils';
import { adminAvailableFontsQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import { useFunctionToRefCB } from 'memo';
import equal from 'fast-deep-equal/es6/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'utils/toast';
import cn from 'classnames';
import classes from './Inputs.module.less';

function insertSharp(string) {
  return string && string[0] === '#' ? string : `#${string}`;
}
const DropdownChromePicker = ({ fast, onChange, disabled, className, color, onClose }) => {
  const firstRenderRef = useRef(false);
  const onChangeThrottled = useMemo(() => throttle(onChange, 20), [onChange]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!firstRenderRef.current) {
      firstRenderRef.current = true;
      return;
    }
    if (!visible && fast) {
      onClose();
    }
  }, [fast, onClose, visible]);

  const onVisibleChange = useCallback((_visible) => {
    setVisible(_visible);
  }, []);
  return (
    <Dropdown
      disabled={disabled}
      visible={visible}
      trigger={['click']}
      onVisibleChange={onVisibleChange}
      className={`${classes.dropdown} ${className}`}
      icon={<DownOutlined />}
      overlay={
        <ChromePicker
          color={color}
          // eslint-disable-next-line no-shadow
          onChange={onChangeThrottled}
          className={cn(classes.colorPiker)}
        />
      }
    >
      <Button icon={<DownOutlined />} />
    </Dropdown>
  );
};
const DropdownChromePickerMemo = memo(DropdownChromePicker, equal);

const HexColorInput = ({ color, onChange: onChangeProps, onBlur, ...props }) => {
  const onChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 7) return;
      const hex = insertSharp(value);
      if (!hex.match(/^#[0-9abcdefABCDEF]{0,7}$/)) return;
      onChangeProps({ hex: e.target.value });
    },
    [onChangeProps],
  );
  return <Input className="remove-right-border" value={color} onChange={onChange} onBlur={onBlur} {...props} />;
};
const HexColorInputMemo = memo(HexColorInput, equal);

const ColorPicker = ({ value, disabled, onChange: onChangeProps, className, fast }) => {
  const [internalStateHex, setInternalStateHex] = useState(value);
  useEffect(() => {
    setInternalStateHex(value);
  }, [value]);

  const { t } = useTranslation();
  const color = useMemo(() => {
    const currentColor = fast ? internalStateHex : value;
    if (!currentColor) return '';
    return insertSharp(currentColor);
  }, [fast, internalStateHex, value]);

  const onChange = useCallback(
    ({ hex }) => {
      if (fast) setInternalStateHex(hex);
      else onChangeProps(hex);
    },
    [fast, onChangeProps],
  );
  const onChangeFormik = useFunctionToRefCB(() => {
    onChangeProps(internalStateHex);
  });

  const onCopy = useCallback((_, result) => result && toast.success(t('common.toast.success.clipboard')), [t]);

  return (
    <div className={classes.containerColorPickerInput}>
      <div className={classes.colorHexInput}>
        <HexColorInputMemo disabled={disabled} color={color} onChange={onChange} onBlur={onChangeFormik} />
        <CopyToClipboard text={color} onCopy={disabled || onCopy}>
          <div
            className={cn(classes.colorPreview, disabled && 'cursor-not-allowed')}
            style={{ backgroundColor: color }}
          />
        </CopyToClipboard>
      </div>
      <DropdownChromePickerMemo
        fast={fast}
        color={color}
        onChange={onChange}
        onClose={onChangeFormik}
        disabled={disabled}
        className={className}
        onChangeFormik={onChangeFormik}
      />
    </div>
  );
};

const ColorPickerMemo = memo(ColorPicker, equal);
export function ColorPickerFormikInput({ name, className, fast = true, disabled = false }) {
  const { value, onChange } = useFormikField(name);
  return (
    <ColorPickerMemo
      name={name}
      value={value}
      disabled={disabled}
      onChange={useFunctionToRefCB(onChange)}
      className={className}
      fast={fast}
    />
  );
}

export const FontFormikInput = ({ name }) => {
  const { data, loading } = useCachedQuery(adminAvailableFontsQuery);
  const fonts = useMemo(() => {
    const result = grabFirstGQLDataResult(data);
    return result?.map((e) => ({ ...e, _id: e.name }));
  }, [data]);
  const { t } = useTranslation();

  return (
    <FieldSearchDropdown
      placeholder={t('admin.Setting.CorporateIdentity.inputs.font.placeholder')}
      options={fonts}
      loading={loading}
      name={name}
      // eslint-disable-next-line react/no-unstable-nested-components
      itemRender={(font) => <span style={{ fontFamily: font.name }}>{font.name}</span>}
    />
  );
};
