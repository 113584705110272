import apollo from 'graphql/apollo';
import { updateUserMutation } from 'graphql/mutations';

const updateUser = async ({ profile, role, email, userId }) =>
  apollo.mutate({
    mutation: updateUserMutation,
    variables: { profile, role, email, userId },
  });

export default updateUser;
