import { gql } from '@apollo/client';

const adminEmailServiceFragment = gql`
  fragment adminEmailServiceFragment on EmailService {
    _id
    mailServiceProvider
    smtpUserName
    from
    smtpPassword
    smtpServer
    smtpPort
    useTLS
    useSSLv3
    requireTLS
  }
`;

export default adminEmailServiceFragment;
