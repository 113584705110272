// https://github.com/formium/formik/issues/284
import equal from 'fast-deep-equal/es6/react';
import { useFormikContext } from 'formik';
import { InputNumber as InputNumberAntd } from 'antd';
import useNumberFormat, { useDecimalSeparator } from 'hooks/common/useNumberFormat';
import { useFunctionToRefCB } from 'memo';
import { memo } from 'react';
import classNames from 'classnames';
import './InputNumber.less';
import { FieldFormikWithDebounce } from './FieldFormikWithDebounce';

const I = memo(InputNumberAntd, equal);
const II = memo(FieldFormikWithDebounce, equal);

export const InputNumber = ({ addonAfter, className, debounceTime, ...props }) => {
  const { setFieldValueAndTouched } = useFormikContext();
  const { decimalSeparator, thousandSeparator } = useDecimalSeparator();
  const { formatter, parser } = useNumberFormat({ decimalSeparator, thousandSeparator });
  return (
    <div className={classNames('ant-input-wrapper', 'ant-input-group', className, { addonAfterInput: addonAfter })}>
      <II
        formatter={formatter}
        parser={parser}
        onChange={useFunctionToRefCB((e) => setFieldValueAndTouched(props.name, e))}
        debounceTime={debounceTime}
        controls={false}
        component={I}
        {...props}
      />
      {addonAfter && (
        // https://github.com/ant-design/ant-design/issues/12794
        <span className="ant-input-group-addon height-100-percent">{addonAfter}</span>
      )}
    </div>
  );
};

const InputNumberMemo = memo(InputNumber, equal);
export default InputNumberMemo;
