import { useCachedQuery } from 'graphql/utils';
import { Button, Tooltip } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { userPreferencesShoppingCartViewQuery } from 'graphql/queries';
import i18n from 'i18n';
import { intersectionBy } from 'lodash';
import { sendEmailModal } from 'pages/user/ShoppingCart/components/SendEmail';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrMail } from 'react-icons/gr';
import { useNavigate, generatePath } from 'react-router-dom';
import routePaths from 'router/route-paths';
import confirmModal from 'utils/confirmModal';
import { grabFirstGQLDataResult } from 'utils/helpers';

const sendEmailConfirmation = ({ onOk }) => {
  return confirmModal({
    cancelText: i18n.t('common.cancel'),
    okText: i18n.t('common.ok'),
    onOk,
    closable: true,
    maskClosable: true,
    width: 800,
    title: i18n.t('viewer.ShoppingCartView.sendEmailConfirmationTitle'),
  });
};

const SendEmailButton = ({ tooltip, ...props }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={tooltip}>
      <Button
        {...props}
        icon={
          <span className="anticon anticon-edit">
            <GrMail size={19} />
          </span>
        }
      >
        {t('viewer.ShoppingCartView.sendEmailButton')}
      </Button>
    </Tooltip>
  );
};
const SendEmail = ({ preferences, shoppingCart, allRecipients = [], className }) => {
  const navigate = useNavigate();
  const { emailTemplateId, signature } = useMemo(() => {
    return {
      emailTemplateId: preferences?.shoppingCartPreferences?.emailTemplateId,
      signature: preferences?.emailPreferences?.signature,
    };
  }, [preferences?.emailPreferences?.signature, preferences?.shoppingCartPreferences?.emailTemplateId]);

  const [initialValues, setInitialValues] = useState({
    emailTemplateId,
    signature,
  });

  const onCloseAfter = useCallback((formik) => setInitialValues((state) => ({ ...state, ...formik.values })), []);
  const onOk = useCallback(
    (values, closeConfirmation) => {
      setInitialValues((state) => ({ ...state, ...values }));
      sendEmailConfirmation({
        onOk: async () => {
          const state = {
            shoppingCartId: shoppingCart._id,
            subject: values?.subject,
            body: values?.body,
            signature: values?.signature,
            bcc: values?.bcc,
            recipients: intersectionBy(allRecipients, values?.recipients, (e) =>
              typeof e === 'object' ? e._id : e,
            ).map((recipient) => ({
              name: [recipient?.firstName, recipient?.lastName].filter(Boolean).join(' '),
              email: recipient?.email,
            })),
          };
          const route = generatePath(routePaths.sendEmailShoppingCart, {
            id: shoppingCart?._id,
          });

          navigate(route, { state });
          closeConfirmation();
        },
      });
    },
    [allRecipients, navigate, shoppingCart._id],
  );
  const onOpen = useCallback(() => {
    sendEmailModal({
      allRecipients,
      initialRecipients: initialValues.recipients || allRecipients.map((contact) => contact._id),
      onOk,
      onCloseAfter,
      shoppingCart,
      ...initialValues,
    });
  }, [allRecipients, initialValues, onCloseAfter, onOk, shoppingCart]);
  return <SendEmailButton onClick={onOpen} className={className} />;
};

const SendEmailContainer = ({ shoppingCart, className }) => {
  const { t } = useTranslation();
  const { data } = useCachedQuery(userPreferencesShoppingCartViewQuery);
  const preferences = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const allRecipients = useMemo(
    () => shoppingCart?.contacts?.filter((contact) => !!contact.email),
    [shoppingCart?.contacts],
  );
  const disabled = useMemo(() => {
    return !(shoppingCart && data && allRecipients?.length);
  }, [allRecipients?.length, data, shoppingCart]);

  if (disabled)
    return (
      <SendEmailButton
        disabled={disabled}
        tooltip={allRecipients?.length ? '' : t('viewer.ShoppingCartView.noRecipientsWithEmail')}
        className={className}
      />
    );
  return (
    <SendEmail
      allRecipients={allRecipients}
      shoppingCart={shoppingCart}
      preferences={preferences}
      className={className}
    />
  );
};
export default memo(SendEmailContainer, equal);
