import Card from 'components/common/Card';
import CategoryInCart from 'components/user/shoppingCart/CategoryInCart';
import EmptyBox from 'components/common/EmptyBox';
import I18nFormik from 'components/common/I18nFormik';
import PageContainer from 'components/layout/PageContainer';
import cn from 'classnames';
import confirmModal from 'utils/confirmModal';
import equal from 'fast-deep-equal/es6/react';
import i18n from 'i18n';
import isEmpty from 'lodash/isEmpty';
import routePaths from 'router/route-paths';
import usePreferences from 'hooks/user/usePreferences';
import { Button, Form } from 'antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import {
  CategoriesContext,
  CustomPriceContext,
  VariablesContext,
  AffectedInputFieldIdsContext,
  AllApolloCategoriesContext,
  VATContext,
  ShowCategoryTotalContext,
  useVariablesContext,
  ShoppingCartRevisionContext,
} from 'components/user/shoppingCart/context';
import { FaStar } from 'react-icons/fa';
import { userVariableListQuery } from 'graphql/queries';
import { createShoppingCart, createShoppingCartRevision, createHtmlPreview } from 'graphql/methods';
import { documentModal } from 'components/user/document';
import { allInputFieldsInCategories, formikToResponse } from 'components/user/shoppingCart/utils';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { memo, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useNavigate, generatePath, useParams, useLocation } from 'react-router-dom';
import { useComparingVariables, useShoppingCartCategories } from 'graphql/hooks';
import { useTranslation } from 'react-i18next';
import { find, intersectionBy, intersection, filter, omit, isEqual } from 'lodash';
import { CreationMode, VATType } from 'constants/shoppingCart';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useStaticItemPlaceholderObject } from 'components/user/compareToGlobal/StaticItems';
import { CompareToGlobalProvider, useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import CompareToGlobalCard from 'components/user/compareToGlobal/CompareToGlobalCard';
import { useFormikContext } from 'formik';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { useFunctionToRefCB } from 'memo';
import { sendEmailModal } from './components/SendEmail';
import { deselectItems, getInitialValues, validationSchema } from './utils';
import { ContactData } from './ContactData';
import s from './ShoppingCart.module.less';
import TotalPricing from './TotalPricing';
import ShoppingCartOptions from './ShoppingCartOptions';
import ShoppingCartLogo from './components/ShoppingCartLogo';
import ShoppingCartHelperWidget from './ShoppingCartHelperWidget';
import DocumentTypesSelect from './DocumentTypesSelect';
import CompanyType, { showElement } from './CompanyType';
import Buttons, { useButtonState } from './Buttons';
import ErrorList from './components/ErrorList';
import GlobalModeAlert from './components/GlobalModeAlert';

const FormComponent = ({ categories, currentSubmitTypeRef, isEmptyList, isSubmitting }) => {
  const { t } = useTranslation();
  const buttonState = useButtonState();
  const { values, setFieldValueAndTouched } = useFormikContext();
  const { companyTypeId, showDigits } = values;
  const affectedInputFieldIds = useMemo(
    () => allInputFieldsInCategories(categories)?.map(({ _id }) => _id),
    [categories],
  );
  useEffect(() => {
    deselectItems({ categories, companyTypeId, setFieldValueAndTouched, values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const activeCategories = useMemo(
    () =>
      categories
        .map((category) => {
          const newItems = category.items.filter((item) => showElement(item.companyTypeIds, companyTypeId));

          const usedIdsInFormulas = newItems.map((item) => item.recursiveFormulaInfo.usedIdsInFormula).flat();
          const newInputs = filter(category.inputFields, (inputField) =>
            find(usedIdsInFormulas, (id) => id === inputField._id),
          );

          return { ...category, items: newItems, displayInputFields: newInputs };
        })
        .filter(
          (category) =>
            category?.items?.length && showElement(category.companyTypeIds, companyTypeId) && category.items.length,
        ),
    [categories, companyTypeId],
  );
  const placeholderObject = useStaticItemPlaceholderObject({ showDigits });
  const variables = useVariablesContext();
  const { isCompare } = useCompareToGlobalContext();
  return (
    <AffectedInputFieldIdsContext.Provider value={affectedInputFieldIds}>
      <ShoppingCartOptions categories={categories} ghost iconProps={{ size: 25, color: 'gray' }} />
      <ShoppingCartLogo />
      <GlobalModeAlert />
      {isCompare ? <CompareToGlobalCard variables={variables} isCategories={!!activeCategories.length} /> : null}
      <CompanyType changeVAT />
      <Card
        id="category-in-cart"
        icon={<FaStar />}
        title={t('user.ShoppingCart.categoriesCardTitle')}
        className={s.shoppingCartCategoriesCard}
      >
        <AlertFromFormik />
        <RichText.PlaceholderContextProvider value={placeholderObject}>
          {activeCategories.map((category) => (
            <CategoryInCart key={category._id} category={{ ...category }} />
          ))}
        </RichText.PlaceholderContextProvider>
        {isEmptyList ? <EmptyBox>{t('user.ShoppingCart.emptyCategoryListMessage')}</EmptyBox> : null}
      </Card>
      <TotalPricing />
      <ContactData />
      <DocumentTypesSelect
        buttonState={buttonState}
        isSubmitting={isSubmitting}
        currentSubmitTypeRef={currentSubmitTypeRef}
      />
      <ErrorList />
      <Buttons
        onlyButtons
        buttonState={buttonState}
        isSubmitting={isSubmitting}
        currentSubmitTypeRef={currentSubmitTypeRef}
      />
    </AffectedInputFieldIdsContext.Provider>
  );
};
const FormComponentMemo = memo(FormComponent, equal);

const storeShoppingCart = ({ onOk, title }) => {
  return confirmModal({
    cancelText: i18n.t('user.ShoppingCart.Buttons.submitRevisionModal.cancel'),
    okText: i18n.t('user.ShoppingCart.Buttons.submitRevisionModal.ok'),
    onOk,
    onCancel: () => {},
    onClose: () => {},
    closable: true,
    maskClosable: true,
    width: 800,
    title,
  });
};
const useInitialValues = ({ categories, dbValues, preferences, initialValues: initialValuesProps }) => {
  const location = useLocation();
  const params = useParams();
  const formatInitialValues = useFunctionToRefCB(() =>
    getInitialValues({
      categories,
      dbValues,
      preferences,
      initialValues: initialValuesProps,
      prevValues: location.state,
      isIndependent: params.mode === CreationMode.INDEPENDENT,
    }),
  );
  const [initialValues, setInitialValues] = useState(formatInitialValues);
  useEffect(() => {
    const newInitialValues = formatInitialValues();
    if (!isEqual(newInitialValues, initialValues)) setInitialValues(newInitialValues);
  }, [formatInitialValues, initialValues, params.mode]);
  return initialValues;
};
export const ShoppingCartComponent = ({
  categories,
  dbId,
  dbValues,
  initialValues: initialValuesProps,
  preferences,
  variables,
  vatVariable,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const categoriesWithItems = categories.filter((category) => category?.items?.length);
  const isEmptyList = isEmpty(categoriesWithItems) || isEmpty(categories);
  const currentSubmitTypeRef = useRef({ type: undefined, documentTemplate: undefined }); // save or email or pdf

  const formikRef = useRef();
  window.formikRef = formikRef; // development only
  window.categories = categories; // development only
  const initialValues = useInitialValues({ categories, dbValues, preferences, initialValues: initialValuesProps });
  const validationSchemaWithRef = useCallback(
    (field, values) => validationSchema({ values, formikRef, categories }),
    [categories],
  );

  const [isSubmitting, setIsSubmitting] = useState();

  const onSubmit = async (allValues) => {
    const {
      body,
      companyId,
      initializationConfigDateForCompany,
      initializationConfigDate,
      companyTypeId,
      contacts,
      documentTemplateBlocks: documentTemplateBlocksFormik = {},
      documentTemplates,
      emailTemplateId,
      feeType,
      hiddenNote,
      meetingAt,
      name,
      showDigits,
      showDiscounts,
      showPrices,
      showVat,
      signature,
      startOfContract,
      subject,
      vatType,
    } = allValues;
    if (isSubmitting) return;
    setIsSubmitting(true);
    const documentTemplateBlocks = Object.entries(documentTemplateBlocksFormik).map(([_id, value]) => ({
      _id,
      ...value,
    }));
    try {
      const currentType = currentSubmitTypeRef.current.type;
      const currentDocumentTemplate = currentSubmitTypeRef.current.documentTemplate;
      const { cart } = formikToResponse({ categories, values: allValues });
      const shoppingCart = {
        categories: cart,
        companyId,
        companyTypeId,
        contactIds: contacts.map((contact) => contact._id),
        documentTemplateBlocks,
        documentTemplates: documentTemplates.map((documentTemplate) => JSON.parse(documentTemplate)),
        feeType,
        hiddenNote,
        meetingAt,
        name,
        roundPriceId: preferences.roundPriceId,
        showDigits,
        showDiscounts,
        showPrices,
        showVat,
        startOfContract,
        vatType,
      };
      if (params.mode === CreationMode.INDEPENDENT) {
        shoppingCart.initializationConfigDate = initializationConfigDate;
        shoppingCart.initializationConfigDateForCompany = initializationConfigDateForCompany;
      }
      const onSuccess = async ({ data, to, closeConfirmation, ...props }) => {
        const shoppingCartId = grabFirstGQLDataResult(data)._id;
        let route;
        let state;
        switch (to) {
          case routePaths.printShoppingCart:
            state = true;
            route = generatePath(routePaths.printShoppingCart, {
              id: shoppingCartId,
            });
            break;
          case routePaths.shoppingCartView:
            route = generatePath(routePaths.shoppingCartView, {
              id: shoppingCartId,
            });
            break;
          case routePaths.sendEmailShoppingCart:
            state = {
              data,
              subject: props?.subject,
              body: props?.body,
              bcc: props?.bcc,
              signature: props?.signature,
              recipients: intersectionBy(contacts, props?.recipients, (e) => (typeof e === 'object' ? e._id : e)).map(
                (recipient) => ({
                  name: [recipient?.firstName, recipient?.lastName].filter(Boolean).join(' '),
                  email: recipient?.email,
                }),
              ),
            };
            route = routePaths.sendEmailShoppingCart;
            break;
          default:
            route = routePaths.shoppingCartEntries;
        }
        closeConfirmation?.();
        navigate(route, { state });
      };
      const createNewShoppingCart = async ({
        skipCreateShoppingCart,
        to = routePaths.shoppingCartView,
        ...props
      } = {}) => {
        const createData = {
          shoppingCart,
        };
        return (skipCreateShoppingCart ? Promise.resolve({ data: createData }) : createShoppingCart(createData)).then(
          ({ data }) => onSuccess({ data, to, ...props }),
        );
      };

      const createNewShoppingCartRevision = async ({
        skipCreateShoppingCart,
        to = routePaths.shoppingCartView,
        ...props
      } = {}) => {
        const createData = {
          prevCartId: dbId,
          shoppingCart,
        };
        return (
          skipCreateShoppingCart ? Promise.resolve({ data: createData }) : createShoppingCartRevision(createData)
        ).then(({ data }) => onSuccess({ data, to, ...props }));
      };

      if (currentType === 'save') {
        storeShoppingCart({
          onOk: dbValues ? createNewShoppingCartRevision : createNewShoppingCart,
          title: t('user.ShoppingCart.Buttons.submitRevisionModal.saveTitle'),
        });
      }
      if (currentType === 'email') {
        const onCloseAfter = (formik) => {
          const { values } = formik;
          formikRef.current.setValues((state) => ({ ...state, ...omit(values, 'documentTemplates') }));
        };
        const onOk = (values, closeConfirmation) => {
          setTimeout(() =>
            formikRef.current.setValues((state) => ({ ...state, ...omit(values, 'documentTemplates') })),
          );
          storeShoppingCart({
            onOk: () =>
              (dbValues ? createNewShoppingCartRevision : createNewShoppingCart)({
                skipCreateShoppingCart: true,
                to: routePaths.sendEmailShoppingCart,
                closeConfirmation,
                ...values,
              }),
            title: t('user.ShoppingCart.Buttons.submitRevisionModal.sendTitle'),
          });
        };
        const allRecipients = contacts.filter((contact) => contact?.email);
        const recipients = (() => {
          const allRecipientIds = allRecipients.map((r) => r._id);
          const formikRecipients = intersection(allValues.recipients, allRecipientIds);
          return formikRecipients.length ? formikRecipients : allRecipientIds;
        })();
        sendEmailModal({
          allRecipients,
          body,
          emailTemplateId,
          initialRecipients: recipients,
          onCloseAfter,
          onOk,
          shoppingCart,
          signature,
          subject,
        });
      }

      if (currentType === 'html') {
        documentModal({
          value: createHtmlPreview({
            shoppingCart,
            documentTemplate: currentDocumentTemplate,
          }),
          type: 'html',
        });
      }

      if (currentType === 'print') {
        storeShoppingCart({
          onOk: () =>
            (dbValues ? createNewShoppingCartRevision : createNewShoppingCart)({ to: routePaths.printShoppingCart }),
          title: t('user.ShoppingCart.Buttons.submitRevisionModal.printTitle'),
        });
      }
    } catch (e) {
      toastGraphQLError(e);
    }
    window.setTimeout(() => setIsSubmitting(false), 0);
  };
  const documentTemplateBlocks = useMemo(
    () => dbValues?.cart?.documentTemplateBlocks?.filter((block) => block?.props),
    [dbValues?.cart?.documentTemplateBlocks],
  );
  const customPriceContextProviderValue = useMemo(
    () => ({ allowCustomPrices: preferences.allowCustomPrices, showCalculatedPrice: preferences.showCalculated }),
    [preferences.allowCustomPrices, preferences.showCalculated],
  );
  const shoppingCartRevision = useMemo(() => dbValues?.cart ?? {}, [dbValues?.cart]);
  return (
    <ShowCategoryTotalContext.Provider value={preferences.showCategoryTotal}>
      <CustomPriceContext.Provider value={customPriceContextProviderValue}>
        <VATContext.Provider value={vatVariable}>
          <VariablesContext.Provider value={variables}>
            <CategoriesContext.Provider value={categories}>
              <ShoppingCartRevisionContext.Provider value={shoppingCartRevision}>
                <I18nFormik
                  ref={formikRef}
                  reInitOnSubmit={false}
                  initialValues={initialValues}
                  enableReinitialize
                  onSubmit={onSubmit}
                  validationSchema={validationSchemaWithRef}
                  disableIsValidating // main prop that speeds up shopping cart validation
                >
                  <Form layout="vertical">
                    <FormComponentMemo
                      categories={categories}
                      currentSubmitTypeRef={currentSubmitTypeRef}
                      documentTemplateBlocks={documentTemplateBlocks}
                      isEmptyList={isEmptyList}
                      isSubmitting={isSubmitting}
                    />
                  </Form>
                </I18nFormik>
              </ShoppingCartRevisionContext.Provider>
            </CategoriesContext.Provider>
          </VariablesContext.Provider>
        </VATContext.Provider>
      </CustomPriceContext.Provider>
    </ShowCategoryTotalContext.Provider>
  );
};

const ShoppingCartComponentMemo = memo(ShoppingCartComponent, equal);

const ShoppingCartComponentContainer = (props) => {
  const { dbId, preferences, categories } = props;
  const { t } = useTranslation();
  const isPrivatePerson = useMemo(
    () => !preferences?.companyTypeId || preferences?.companyTypeId === 'privatePerson',
    [preferences?.companyTypeId],
  );
  if (dbId) return <ShoppingCartComponentMemo {...props} />;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initialValues, setInitialValues] = useState();
  if (initialValues?.companyTypeId) return <ShoppingCartComponentMemo {...props} initialValues={initialValues} />;
  return (
    <div>
      <I18nFormik
        onSubmit={(values) => setInitialValues(values)}
        initialValues={{
          companyTypeId: preferences?.companyTypeId || 'privatePerson',
          feeType: preferences?.feeType,
          showPrices: preferences?.showPrices,
          showDiscounts: preferences?.showDiscounts,
          showVat: isPrivatePerson,
          vatType: isPrivatePerson ? VATType.PERSONAL : VATType.COMPANY,
          hiddenNote: '',
        }}
      >
        {({ handleSubmit }) => (
          <>
            <ShoppingCartOptions categories={categories} ghost iconProps={{ size: 25, color: 'gray' }} />
            <ShoppingCartLogo />
            <CompanyType
              changeVAT
              // eslint-disable-next-line react/no-unstable-nested-components
              nextButton={() => (
                <Button className={s.companyTypeNextButton} type="primary" onClick={handleSubmit}>
                  {t('common.next')}
                </Button>
              )}
            />
          </>
        )}
      </I18nFormik>
    </div>
  );
};

export const ShoppingCart = ({ dbId, dbValues, dbLoading }) => {
  const { id: shoppingCartId, mode } = useParams();
  const fetchShoppingCartId = useMemo(
    () => (mode === CreationMode.INDEPENDENT ? shoppingCartId : undefined),
    [mode, shoppingCartId],
  );
  const {
    preferences,
    loading: preferencesLoading,
    error: errorPreferences,
  } = usePreferences({ shoppingCartId: fetchShoppingCartId });
  const {
    data: variables,
    loading: loadingData,
    error: errorVariables,
  } = useComparingVariables({
    shoppingCartId: fetchShoppingCartId,
    query: userVariableListQuery,
  });

  const {
    data: categories,
    getAllApolloCategories,
    loading,
    error: errorCategories,
  } = useShoppingCartCategories({
    fetchPolicy: 'network-only',
    shoppingCartId: fetchShoppingCartId,
  });
  const vatVariable = useMemo(() => find(variables, { name: 'vatPercent' }), [variables]);
  const someLoading = loading || loadingData || dbLoading || preferencesLoading;
  return (
    <AllApolloCategoriesContext.Provider value={getAllApolloCategories}>
      <PageContainer
        className={cn(s.pageContainer, 'shopping-cart')}
        title=""
        left={<ShoppingCartHelperWidget buttonClassName={s.helperWidgetButton} />}
      >
        <div className="xs-mt-20">
          <GraphQLLoadingWrapper
            data={categories && preferences && variables && (!shoppingCartId || (shoppingCartId && dbValues))}
            loading={someLoading}
            error={errorCategories || errorVariables || errorPreferences}
          >
            <ShoppingCartComponentContainer
              categories={categories}
              dbId={dbId}
              dbValues={dbValues}
              preferences={preferences}
              variables={variables}
              vatVariable={vatVariable}
            />
          </GraphQLLoadingWrapper>
        </div>
      </PageContainer>
    </AllApolloCategoriesContext.Provider>
  );
};

export default (props) => (
  <CompareToGlobalProvider>
    <ShoppingCart {...props} />
  </CompareToGlobalProvider>
);
