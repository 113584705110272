/*
  In most cases you need routePaths default export
  But if the route includes a variable, you may need to interpolate this value into template. To do this, you can import routePatterns

  Usage:

  import { routePatterns } from 'router/route-paths';
  const path = routePatterns.userProfile.stringify({ id: params.id }))

  Where "userProfile" is a key in routePaths with a variable like this { userProfile: '/user/:id' }
*/
import UrlPattern from 'url-pattern';
import ROLES from 'constants/roles';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { pickBy, includes, pick } from 'lodash';

const routePaths = {
  home: '/secure',
  dashboard: '/secure/dashboard',
  tenantOnboarding: '/onboarding',
  tenantOnboardingCompleted: '/onboarding/completed',
  catalogueConfiguration: '/secure/admin/catalog',
  itemCatalogueConfiguration: '/secure/admin/catalog/item/:id&:categoryId',
  commonLibraryConfiguration: '/secure/admin/common-library',
  documentTemplateList: '/secure/admin/document-templates',
  commonLibraryDocumentTemplateList: '/secure/admin/common-library-document-templates',
  documentTemplateConfiguration: '/secure/admin/document-templates/:id',
  commonLibraryDocumentTemplateConfiguration: '/secure/admin/common-library-document-templates/:id',
  commonLibraryItemConfiguration: '/secure/admin/common-library/item/:id/:categoryId',
  shoppingCartEntries: '/secure/shopping-cart-entries',
  shoppingCart: '/secure/shopping-cart',
  login: '/login',
  resetPassword: '/reset-password',
  shoppingCartRevision: '/secure/shopping-cart-revision',
  routerShoppingCartRevision: '/secure/shopping-cart-revision/:id/:mode',
  manageUsers: '/secure/admin/manage-user',
  managePersons: '/secure/admin/manage-persons',
  importCompanies: '/secure/admin/import-companies',

  emailTemplates: '/secure/admin/email-templates',
  commonLibraryEmailTemplates: '/secure/admin/common-library-email-templates',

  emailSettings: '/secure/admin/email-settings',
  manageShoppingCartStatus: '/secure/admin/manage-shopping-cart-status',
  manageTenants: '/secure/super-admin/manage-tenants',
  manageAllUsers: '/secure/super-admin/manage-all-users',

  printShoppingCart: '/secure/shopping-cart/:id/print',
  sendEmailShoppingCart: '/secure/shopping-cart/send-to-email',

  shoppingCartView: '/secure/viewer/shopping-cart-view/:id',

  otherSettings: '/secure/admin/setting/other-settings',
  corporateIdentitySetting: '/secure/admin/setting/corporate-identity',
  licenseOverviewSetting: '/secure/admin/setting/license-overview',
  companySetting: '/secure/admin/setting/company',
  shoppingCartPreferences: '/secure/shopping-cart-preferences',
  accountSetting: '/secure/setting/account',
  constantsAndPlaceholdersSetting: '/secure/setting/constants-and-placeholders',

  acceptInvitation: '/verify/accept-invitation/:invitationToken',

  migrations: '/secure/dev/migrations',
  dbCalls: '/secure/dev/db-calls',
  profileBackendCPU: '/secure/dev/profile-backend-cpu',
  CPUMEMUsage: '/secure/dev/cpu-mem-usage',
  devLogging: '/secure/dev/logging',
};
export const useIsUserRole = () => {
  const [me] = useCurrentUser();
  const role = me?.role;
  return role === ROLES.USER;
};

export const usePreloadLogoRoutes = () =>
  pick(routePaths, ['shoppingCart', 'shoppingCartRevision', 'routerShoppingCartRevision']);

export const useGetRoutePathsNavBar = () => {
  const hideRoutePaths = [routePaths.login, routePaths.resetPassword];
  const hideRoutePathsForUser = [routePaths.shoppingCart, routePaths.routerShoppingCartRevision];

  const isUser = useIsUserRole();
  if (isUser) {
    return Object.values(pickBy(routePaths, (value) => !includes(hideRoutePathsForUser, value)));
  }
  return Object.values(pickBy(routePaths, (value) => !includes(hideRoutePaths, value)));
};

export const routePatterns = Object.entries(routePaths).reduce(
  (p, [key, pattern]) => ({ ...p, [key]: new UrlPattern(pattern) }),
  {},
);

export default routePaths;
