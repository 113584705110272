export default {
  translation: {
    BackendErrors: {
      category: {
        categoryNotFound: 'Category not found',
        disallowInternalInputField: 'Internal input field cannot be added to the category',
      },
      changesWereNotSaved: {
        unknownError: 'The changes were not saved due to unknown error. Please try again.',
      },
      company: {
        companyIdentifierExists: 'Such a company identifier already exists',
        countCompanies100: '$t(Warnings.countCompanies100)',
      },
      contact: {
        contactAlreadyExists: 'Please, enter another email because contact already exists with the same email',
      },
      discount: {
        notFound: 'Discount not found',
      },
      documentTemplate: {
        documentTemplateDeleted: 'Document template not found',
        documentTemplateIsUsed: 'Document template is used in existing shopping carts',
      },
      input: {
        notFound: 'Input not found',
      },
      item: {
        notFound: 'Item not found',
      },
      permissionDenied: 'Permission denied',
      settings: {
        attachmentsSizeLimit:
          'Please, enter another attachments size limit because attachments size limit exceeded range',
      },
      shoppingCart: {
        notFound: 'Shopping cart not found',
      },
      staticItem: {
        notFound: 'Static item not found',
      },
      status: {
        removeDefaultStatus:
          'Impossible to delete the default status. If you want to delete the status, then set another one to default firstly and then try again',
      },
      usedInFormula: {
        calculationMode: '$t(common.Item.switchToOnActualCost)',
        category: 'of category',
        changePaymentInterval:
          'You can NOT set this item payment interval to one-off because it is the last monthly/yearly item in this category and the referred category is used in the pricing-formula of item {{items}}',
        changePaymentInterval_plural:
          'You can NOT set this item payment interval to one-off because it is the last monthly/yearly item in this category and the referred category is used in the pricing-formula of items {{items}}',
        delete: 'Item can NOT be deleted because it is used in pricing formula of item {{items}}',
        deleteInput: 'Input field can NOT be deleted because it is used in pricing formula of item {{items}}',
        deleteLastItem:
          'You can NOT delete the last yearly or monthly payment interval item because it is used in the existing category and the category is referred used in the pricing-formula of item {{items}}',
        deleteLastItem_plural:
          'You can NOT delete the last yearly or monthly payment interval item because it is used in the existing category and the category is referred used in the pricing-formulas of items {{items}}',
        delete_plural: 'Item can NOT be deleted because it is used in pricingFormula of items {{items}}',
        inactive: 'You can NOT set this item inactive because it is used in the pricing-formula of item {{items}}',
        inactiveCategory:
          'You can NOT set this item inactive because it is the last active item in this category and the category is referred used in the pricing-formula of item {{items}}',
        inactiveCategory_plural:
          'You can NOT set this item inactive because it is the last active item in this category and the category is referred used in the pricing-formulas of items {{items}}',
        inactiveUsedItems:
          'You can NOT activate this item because the item {{items}} are used in its pricing formula and are inactive. Please active the item and try again.',
        inactiveUsedItems_plural:
          'You can NOT activate this item because the items {{items}} are used in its pricing formula and are inactive. Please active the items and try again.',
        inactive_plural:
          'You can NOT set this item inactive because it is used in the pricing-formulas of items {{items}}',
        usedVariableInFormula: {
          emailSignature: 'Variable is used in email signature',
          emailTemplates_plural: 'Variable is used in subject or body of e-mail templates {{emailTemplates}}',
          items_plural: 'Variable is used in pricing formula of items {{items}} ',
          staticItems_plural: 'Variable is used in static items of categories {{staticItemCategories}}',
        },
      },
      user: {
        noValidToken: 'Not valid token',
        passwordIsNotValidOrExpired: 'One-time password is not valid or expired. Please try again',
        tenantDisabled: 'You account is inactive - please contact our team',
        userExists: 'Please, enter another email because user already exists with the same email',
        userHasNoPasswordSet: 'User has no password set',
        userNotFound: 'User not found',
        userNotFoundForEmail: 'User not found for email {{email}}',
      },
      variable: {
        duplicated: 'Variable with the same name already exists',
        outsideRange:
          'Variable can needs to be within {{largestMinValue}} and {{lowestMaxValue}} as it is used as default for internal input field of item(s) {{itemNames}}',
      },
    },
    BackendWarnings: {
      item: {
        removedFailedMentions: 'Some placeholders have been invalid due to copy & paste. We replaced those by 0',
        removedFailedMentionsFromFormula: '$t(BackendWarnings.item.removedFailedMentions)',
      },
    },
    CurrentUserDropdown: {
      locale: 'Locale: <1>$t(locale)</1>',
      loggedInAs: 'Logged in as <1>{{name}}</1>',
      logout: 'Logout',
      noUserName: 'No name',
      role: 'Role: <1>{{role}}</1>',
    },
    DashboardPage: {
      HelperWidget: {
        howUseButton: '$t(common.howUseButton)',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'How to use dashboard',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
      },
      IntroductionHelperWidget: {
        howUseButton: 'Watch 5 Minute introduction video',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Introduction video',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
      },
      actionsBlock: {
        configureOurCatalogue: 'Configure our Catalogue',
        createNewShoppingCart: 'Create a new shopping-cart entry',
        manageShoppingCarts: 'Manage Shopping Carts',
        title: 'What would you like to do?',
      },
      helpCard: {
        book: {
          calendly: 'Book 15 minute onboarding session',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          sendEmail: 'Send e-mail',
          sendEmailUrl: '$t(common.urls.sendEmailUrl)',
          title: 'Do you still have questions? The please book a session with us',
        },
        title: 'Need help?',
        tutorial: {
          title: 'Watch Tutorial-Video',
        },
      },
      onboardingChecklist: {
        infoAlert: {
          description:
            'If you are using KanzleiPilot as an administrator for the first time, we recommend the following steps:',
          title: 'Tip for easy set-up',
        },
        step: 'Step',
        step1: {
          description: 'Get to know KanzleiPilot',
          modalInfo: {
            title: '5 minute tour',
            videoUrl: 'https://vimeo.com/541269034',
          },
          title: 'Look the 5 minute demo',
        },
        step2: {
          description: 'Send invite',
          modalInfo: {
            title: 'How to invite user',
            videoUrl: 'https://vimeo.com/541649035',
          },
          title: 'Invite user',
        },
        step3: {
          description: 'Do the following',
          modalInfo: {
            title: 'How to create a shopping cart',
            videoUrl: 'https://vimeo.com/541665850',
          },
          title: 'Create first shopping cart',
        },
        step4: {
          description: 'Do it',
          modalInfo: {
            title: 'How to edit catalogue',
            videoUrl: 'https://vimeo.com/541664949',
          },
          step: '',
          title: 'Edit your catalogue',
        },
        step5: {
          description: 'Edit them',
          modalInfo: {
            title: 'How to edit documents',
            videoUrl: 'https://vimeo.com/541664771',
          },
          title: 'Edit documents',
        },
        title: 'Step by step instructions for law firm owners and administrators',
      },
      title: 'Dashboard',
    },
    FooterComponent: {
      copyright: '© KanzleiPilot {{year}}',
    },
    FormulaMentions: {
      RVG_Value_Fee: 'RVG_Wertgebühr',
    },
    FormulaNames: {
      category: 'Monthly fixed value of category "{{name}}"',
      inputField: 'Input field "{{name}}" of category "{{categoryName}}"',
      inputFieldItem: 'Input field "{{name}}" of item "{{categoryName}}"',
      inputField_internal: 'Internal Input "{{name}}" ({{default}})',
      item: 'Item "{{name}}" of category "{{categoryName}}"',
      staticItem: 'Static item "{{name}}" of category "{{categoryName}}"',
      variable: 'Value of variable "{{name}}"',
    },
    InputErrors: {
      phoneNumber: {
        noCode: 'Code is a required field',
        noPhone: 'Phone is a required field',
      },
    },
    Login: {
      inputs: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          error: 'Password is incorrect',
          label: 'Password',
          placeholder: 'Password',
        },
      },
      resetPassword: 'I forgot my password',
      submit: 'Submit',
      title: 'Login',
    },
    NavBar: {
      accountSetting: {
        title: '$t(user.Setting.AccountSetting.title)',
      },
      catalogueConfiguration: {
        title: '$t(admin.CatalogueConfiguration.title) ',
      },
      commonLibrary: {
        catalogueCommonLibrary: {
          title: 'Common library catalogue configuration',
          titleSort: 'Catalogue configuration',
        },
        documentCommonLibrary: {
          title: 'Common library document templates',
          titleSort: 'Document templates',
        },
        emailCommonLibrary: {
          title: 'Common library email templates',
          titleSort: 'Email templates',
        },
        title: 'Super-admin settings',
      },
      companyTenant: {
        title: '$t(admin.Setting.TenantCompany.title)',
      },
      constantsAndPlaceholders: {
        title: '$t(admin.ConstantsAndPlaceholders.title)',
      },
      corporateIdentity: {
        title: '$t(admin.Setting.CorporateIdentity.title)',
      },
      createShoppingCart: {
        title: '$t(ShoppingCartEntriesPage.createShoppingCart)',
        tooltip: '$t(ShoppingCartEntriesPage.createShoppingCart)',
      },
      dashboard: {
        title: '$t(DashboardPage.title)',
        tooltip: '$t(DashboardPage.title)',
      },
      documentTemplateListPage: {
        title: '$t(admin.DocumentTemplateListPage.title)',
      },
      emailSettings: {
        title: '$t(admin.Setting.Email.title)',
      },
      emailTemplates: {
        title: '$t(admin.EmailTemplates.title)',
      },
      importCompanies: {
        title: 'Import companies',
      },
      licenseOverview: {
        title: '$t(admin.Setting.LicenseOverview.title)',
      },
      logo: {
        tooltip: 'Logo',
      },
      manageAllUsers: {
        title: '$t(superAdmin.ManageAllUsers.title)',
      },
      managePersons: {
        title: '$t(admin.ManagePersons.title)',
      },
      manageShoppingCartStatus: {
        title: '$t(admin.ManageShoppingCartStatus.title)',
      },
      manageTenants: {
        title: '$t(superAdmin.ManageTenants.title)',
      },
      manageUsers: {
        title: '$t(admin.ManageUsers.title)',
      },
      otherSettings: {
        title: '$t(admin.OtherSettings.title)',
      },
      ourCompany: {
        title: 'Our Company',
      },
      settingTitle: 'Setting',
      shoppingCart: {
        title: 'Shopping Cart',
      },
      shoppingCartEntries: {
        title: '$t(ShoppingCartEntriesPage.title)',
        tooltip: '$t(ShoppingCartEntriesPage.title)',
      },
      shoppingCartPreferences: {
        title: '$t(admin.Setting.ShoppingCartPreferences.title) ',
      },
      warnings: {
        title: '$t(Warnings.title)',
        tooltip: '$t(Warnings.tooltip)',
      },
    },
    ResetPassword: {
      buttons: {
        changePassword: 'Change password',
        goToLogin: 'Go back to login',
        verify: 'Verify',
      },
      enterEmail: "Please enter the email address you'd like your password reset information sent to.",
      errors: {
        noMatch: 'Passwords must match',
      },
      fields: {
        email: 'Email',
        newPassword: 'New password',
        otp: 'One Time Password',
        passwordConfirmation: 'Password confirmation',
      },
      steps: {
        email: 'Enter email',
        setNewPassword: 'Set new password',
        verification: 'Enter one time password',
      },
      title: 'Reset Password',
      verificationDescription:
        'A verification message has been sent to your email address {{email}}. Enter the verification code for the otp input. If the message does not arrive within 5 minutes, you can resend it.',
    },
    ShoppingCartEntriesPage: {
      HelperWidget: {
        howUseButton: '$t(common.howUseButton)',
        modalInfo: {
          helpText: '',
          imageUrl: '/cdn/page-previews/shopping-cart-entries.png',
          title: 'How to use shopping cart entries',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
      },
      catalogue_configuration_button: 'Catalogue configuration',
      createShoppingCart: 'Create a shopping cart',
      entriesTable: {
        columnTitles: {
          actions: 'Actions',
          companyIdentifier: 'Company Identifier',
          companyName: 'Company name',
          contact: 'Contact',
          createdAt: 'Created at',
          name: 'Project',
          relatedContacts: 'Related contact(s)',
          status: 'Status',
          valueMonthly: 'Value Monthly',
          valueOneOff: 'Value One-Off',
          valueYearly: 'Value Yearly',
        },
      },
      noStatus: 'No status',
      search: 'Filter shopping carts by typing in your company identifier, company name, or related contacts',
      searchTitle: 'Search shopping carts',
      statusAll: 'All',
      statusModal: {
        placeholder: 'Select existing status',
        title: 'Edit status of shopping cart',
      },
      title: 'Shopping cart entries',
    },
    Warnings: {
      countCompanies100: 'You can NOT create any more companies because you reached your limit',
      countCompanies80: 'Number of companies exceeded 80%',
      countCompanies90: 'Number of companies exceeded 90%',
      countUsers100: 'You can NOT create any more users because you reached your limit',
      countUsers80: 'Number of users exceeded 80%',
      countUsers90: 'Number of users exceeded 90%',
      title: 'Warnings',
      tooltip: 'Warnings',
    },
    admin: {
      CatalogueConfiguration: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/conditional-visibility.png',
            title: 'How to use category conditional visibility',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ConfigureCategoryProperties: {
          properties: {
            conditionalVisibility: {
              bodyTitle: 'Show this category (in shopping cart) if companyBookingType equals:',
              title: 'Conditional Visibility',
            },
            discounts: {
              title: 'Discounts',
            },
            inputFields: {
              title: 'Input Fields',
            },
            items: {
              title: 'Items',
            },
            staticItems: {
              title: 'Static Items',
            },
            texts: {
              title: 'Texts',
            },
          },
          title: 'Configure category properties',
        },
        DiscountsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use category discounts',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        EditCategoryHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to edit category',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        EmailHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Learn about e-mail settings',
            videoCaption: 'This is how you configure email',
            videoUrl: 'https://vimeo.com/563388433',
          },
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/catalogue-configuration.png',
            title: 'How to use catalogue configuration',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ImportHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/import-categories.png',
            title: 'How to use import categories from common library',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use category input fields',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        StaticItemsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/static-items.png',
            title: 'How to use static items',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        StaticItemsTab: {
          inputs: {
            intro: {
              label: 'Intro-text',
              tooltip: 'This text appears above list of static items of this category',
            },
            outro: {
              label: 'Outro-text',
              tooltip: 'This text appears below list of static items of this category',
            },
          },
        },
        TextsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use category texts',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addCategory: 'Add category',
        collapseOptions: {
          duplicate: 'Duplicate category',
          edit: 'Edit category',
          remove: 'Remove category',
        },
        copyItem: {
          addonAfter: '(copy)',
          cancel: '$t(common.cancel)',
          complexPricingFormula:
            '[{"type":"paragraph","children":[{"text":"The item or item\'s comboboxes contain a \\"complex\\" pricing formula. We can copy it but with some restrictions: "}]},{"type":"bulleted-list","listType":"disc","children":[{"type":"list-item","children":[{"text":"references to other categories will be replaced by 1 (in the copy)"}]},{"type":"list-item","children":[{"text":"references to other items will be replaced by 1 (in the copy)"}]},{"type":"list-item","children":[{"text":"references to category-input fields will be replaced by 1 (in the copy)"}]}]},{"type":"paragraph","children":[{"text":"This simply means that you need to adjust the pricing formula afterwards in the copy."}]}]',
          duplicatedErrorMessage: 'Item names are unique. Please enter the new name',
          inputs: {
            category: {
              errorLabel: 'Category',
              label: 'Select your category',
            },
            name: {
              label: '$t(common.fields.name.label)',
            },
          },
          ok: 'Copy item',
          openModalButtonTitle: 'Copy to different category',
          singleImport: 'single import',
          success: {
            subTitle: 'We successfully imported the item',
            title: 'Success',
          },
          title: 'Which category do you want to transport to',
        },
        discounts: {
          title: 'Discounts',
        },
        emptyCategoryListMessage: 'There are no categories yet. Create one by clicking the "Add category" button',
        emptyDiscountListMessage: 'There are no discounts yet. Create one by clicking the "Add discount" button',
        emptyInputFieldListMessage:
          'There are no input fields yet. Create one by clicking the "Add input field" button',
        emptyInputFieldOptionListMessage:
          'There are no input field options yet. Create one by clicking the "Add option" button',
        emptyItemListMessage: 'There are no items yet. Create one by clicking the "Add item" button',
        emptyStaticItemListMessage:
          'There are no static items yet. Create one by clicking the "Add static item" button',
        importCategoryPresets: 'Import category-presets',
        itemsValidation: {
          button: 'Check all item pricing formula',
          error: '{{itemName}} item has an invalid formula in the {{categoryName}} category',
          success: 'There are no errors in the pricing formulas',
        },
        title: 'Catalogue configuration',
        titleCommonLibrary: 'Common library',
      },
      ConstantsAndPlaceholders: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use constants and placeholders',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addVariableButton: 'Add variable',
        addVariableModalTitle: 'Create a new variable',
        columns: {
          description: 'Description',
          mandatory: 'Mandatory',
          name: 'Name',
          type: 'Type',
          value: '$t(common.fields.value.label)',
        },
        editVariableModalTitle: 'Edit variable',
        fields: {
          description: {
            label: '$t(common.fields.description.label)',
          },
          name: {
            label: '$t(common.fields.name.label)',
          },
          type: {
            label: '$t(common.fields.type.label)',
          },
          value: {
            label: '$t(common.fields.value.label)',
          },
        },
        title: 'Constants and Placeholders',
        variableListTitle: 'Constants and Placeholders List',
      },
      DocumentTemplateConfigurationPage: {
        AddBlockHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to add block',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        BlockHelperWidget: {
          howUseButton: 'Instructions: {{displayName}} set up',
          modalInfo: {
            FreeText: {
              title: 'How to set up the Free Text block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            FullPageImage: {
              title: 'How to set up the Full Page Image',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/586189327',
            },
            Introduction: {
              title: 'How to set up the Introduction block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Investment: {
              title: 'How to set up the Investment block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ListBookmarkedItems: {
              title: 'How to set up the List of Bookmarked Items block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            NextSteps: {
              title: 'How to set up the Next Steps block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ServiceCatalogue: {
              title: 'How to set up the Service Catalogue block',
              videoCaption: 'Learn how to use the Service Catalogue block',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ServiceDetails: {
              title: 'How to set up the Service Details block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            ServiceDetailsForContract: {
              title: 'How to set up the Service Details for Contract block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Signatures: {
              title: 'How to set up the Signatures block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            SpecialAgreement: {
              title: 'How to set up the Special Agreement Items block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            TableOfContent: {
              title: 'How to set up the Table of Content block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Testimonial: {
              title: 'How to set up the Testimonial block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            Title: {
              title: 'How to set up the Title block',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/527775922',
            },
            helpText: '',
          },
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/edit-document-template.png',
            title: 'How to use edit document template',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addBlockModal: {
          addToBottomButton: 'add to bottom',
          addToTopButton: 'add to top',
          availableBlocksTitle: 'Available Blocks',
          blockActionsColumnTitle: 'Actions',
          blockNameColumnTitle: 'Name',
          blockPreviewTitle: 'Block Preview',
          title: 'Add a block to document template',
        },
        backTitle: 'Back to Document Templates',
        blockSubtitles: {
          catalogue: 'Catalogue Settings',
          category: 'Category Level:',
          endOfBlock: 'End of block',
          item: 'Item Level:',
          page: 'Page Settings',
          pricingSection: 'Pricing Section',
        },
        blocks: {
          body: 'Personalized text',
          introductionBlockNumber: '(introduction-block #{{number}})',
        },
        deleteBlockConfirmation: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title:
            'Do you want to remove this block? Warning: your custom configuration for the block (texts, settings, etc.) will be lost.',
        },
        generalInformationSection: {
          descriptionInputLabel: 'Description',
          descriptionInputTooltip:
            'Internal description of the document template. Good to document for your team what exactly this is about.',
          lastUsedDocumentNr: 'Last used number: {{n}}',
          nameInputLabel: 'Name',
          nameInputTooltip:
            'Name of the document template. Appears like this for selection in the interactive menu card.',
          nrOfDocumentsCreated: '{{count}} document created',
          nrOfDocumentsCreated_plural: '{{count}} documents created',
          title: 'General information',
        },
        layoutSection: {
          addBlockButton: 'Add Block',
          emptyBlockList:
            'There are no blocks in your document template. Please use "Add Block" button above to add blocks',
          infoSectionTitle: 'Info',
          title: 'Layout',
          tooltip:
            'The layout of a document template consists of blocks that you can edit here. Click on a block below to customize the texts. Click on "+ Add Block" to add a new block to the layout.',
        },
        title: 'Edit Document Template',
      },
      DocumentTemplateListPage: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/document-templates.png',
            title: 'How to use document templates',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ImportHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/import-document-templates.png',
            title: 'How to use import document templates from common library',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        actions: {
          deleteDocumentTemplate: 'Delete the document template',
          duplicateDocumentTemplate: 'Duplicate document-template',
          editDocumentTemplate: 'Edit the document template',
        },
        addDocumentTemplateButton: 'Add Document Template',
        createdAt: 'Created {{date}}',
        duplicateDocumentTemplateModal: {
          cancel: '$t(common.cancel)',
          duplicatedErrorMessage: 'Document template with the same name already exists',
          ok: '$t(common.ok)',
          title: 'Please enter name for new document-template',
        },
        emptyListMessage:
          'There are no document templates yet. Create one by clicking the "$t(admin.DocumentTemplateListPage.addDocumentTemplateButton)" button',
        errorMessageInModal: 'Categories with such names {{duplicatedDocuments}} have already been added to the list',
        importEmptyListMessage: 'There are no document templates yet',
        importFromLibraryButton: 'Import from library',
        importFromLibraryModalTitle: 'Import Document Templates from Common Library',
        lastUsedDocumentNr: 'Current #:{{n}}',
        nrOfDocumentsCreated: '{{count}} document created',
        nrOfDocumentsCreated_plural: '{{count}} documents created',
        title: 'Document Templates',
        titleCommonLibrary: 'Common Library Document Templates',
      },
      EmailTemplates: {
        add: 'Add email templates',
        columns: {
          actions: '$t(common.columns.actions)',
          body: '$t(common.columns.body)',
          isDefault: 'Is Default',
          name: '$t(common.columns.name)',
          subject: '$t(common.columns.subject)',
        },
        fields: {
          body: {
            label: '$t(common.fields.body.label)',
            placeholder: '$t(common.fields.body.placeholder)',
          },
          isDefault: {
            label: 'Is default',
            no: '$t(common.no)',
            yes: '$t(common.yes)',
          },
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          subject: {
            label: '$t(common.fields.subject.label)',
            placeholder: '$t(common.fields.subject.placeholder)',
          },
        },
        import: 'Import from common library',
        importEmptyEmailTemplateListMessage: 'There are no email templates yet.',
        importErrorMessage:
          'Email templates with such names {{duplicatedEmailTemplates}} have already been added to the list',
        importModalTitle: 'Import Email Templates Presets from Common Library',
        modal: {
          addTitle: 'Create a new email template',
          deleteTitle: 'Are you sure you want to remove the email template?',
          editTitle: 'Edit email template',
          error: {
            delete: 'Failed to delete email template',
            duplicatedErrorMessage: 'Email template with the same name already exists',
          },
        },
        title: 'Email Templates',
      },
      ManagePersons: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use persons management',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addPerson: 'Add person',
        addPersonModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Add person',
        },
        cardTitle: 'Person',
        deleteConfirmModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Are you sure you want to remove the person?',
        },
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          position: {
            label: '$t(common.fields.position.label)',
            placeholder: '$t(common.fields.position.placeholder)',
          },
          signature: {
            label: 'Signature',
            tooltip: 'Note: this signature is only shown in "introduction” block.',
          },
        },
        title: 'Manage person',
        uploadLabel: 'Upload signature image',
      },
      ManageShoppingCartStatus: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use statuses management',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addStatus: 'Add status',
        addStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Create a new status',
        },
        columns: {
          name: '$t(common.columns.name)',
        },
        defaultStatusTitle: 'Select default status',
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
        },
        statusListTitle: 'Status List',
        title: 'Manage Shopping cart status',
        updateStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Update status',
        },
      },
      ManageUsers: {
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use users management',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        buttons: {
          sendInvitation: 'Send Invitation',
        },
        modal: {
          editUser: 'Edit user',
        },
        title: 'Manage users',
        userInvitation: {
          inputs: {
            email: {
              label: 'Email',
            },
            firstName: {
              label: 'First name',
            },
            lastName: {
              label: 'Last name',
            },
            position: {
              label: 'Position',
            },
            role: {
              label: 'Role',
              tooltip:
                'Role and rights of the user. We distinguish between 4 roles: 1) "Users with read-only access" can display menu cards - useful, e.g. for reception. 2) "User (can create menu cards)" can create and edit menu cards (menu cards are test-proof). 3) Administrators have access to settings and can, for example, edit the service catalog. 4) Owners can do anything',
            },
          },
          title: 'Invite new user',
        },
        userList: {
          fields: {
            email: '$t(common.fields.email.label)',
            name: '$t(common.fields.name.label)',
            onboarding: 'Onboarding',
            position: '$t(common.fields.position.label)',
            role: '$t(common.fields.role.label)',
            status: '$t(common.fields.status.label)',
          },
          title: 'List of users',
        },
      },
      OtherSettings: {
        globalSettings: {
          inputs: {
            hideCountryInAddresses: {
              label: 'Hide country for addresses in same country like our country',
            },
          },
          title: 'Global settings',
        },
        title: 'Other settings',
      },
      Placeholders: {
        allContactsSeparated: {
          label: 'All contacts separated with comma/and',
        },
        allContactsWithFullSalutation: {
          label: 'All contacts with full salutation',
        },
        categoryTotals: {
          deleted: 'Deleted Category',
          label: 'Category Totals',
          mention: 'Category Total of category "{{name}}" (monthly + yearly/12)',
        },
        client: {
          label: 'Client',
        },
        company: {
          label: 'Company',
        },
        currentDate: {
          label: 'Current date',
        },
        document: {
          label: 'Document',
        },
        documentCreatedAt: {
          label: 'Document creation date',
        },
        documentNumber: {
          label: 'Document number',
        },
        endOfContract: {
          label: 'End of Contract',
        },
        environment: {
          label: 'Environment',
        },
        meetingAt: {
          label: 'Meeting Date',
        },
        projectName: {
          label: 'Project name',
        },
        shoppingCart: {
          label: 'Shopping Cart',
        },
        shoppingCartCompanyCity: {
          label: 'Shopping cart company city',
        },
        shoppingCartCompanyCommercialObject: {
          label: 'Commercial object',
        },
        shoppingCartCompanyFullAddress: {
          label: 'Shopping cart company full address',
        },
        shoppingCartCompanyOrFullname: {
          label: 'Shopping cart company name or full name',
        },
        shoppingCartVatType: {
          label: 'Vat wording',
        },
        shoppingCartVatTypeWithPercentage: {
          label: 'VAT Wording info-text with percentage',
        },
        startOfContract: {
          label: 'Start of Contract',
        },
        tenantCompany: {
          label: 'Our own company',
        },
        tenantCompanyCity: {
          label: 'Our company city',
        },
        tenantCompanyCityCode: {
          label: 'Our company city code',
        },
        tenantCompanyCountry: {
          label: 'Our company country',
        },
        tenantCompanyCurrentUserName: {
          label: 'Current user name',
        },
        tenantCompanyFullAddress: {
          label: 'Our company full address',
        },
        tenantCompanyHouseNumber: {
          label: 'Our company house number',
        },
        tenantCompanyName: {
          label: 'Our company name',
        },
        tenantCompanyOfficeEmail: {
          label: 'Our office e-mail',
        },
        tenantCompanyPhoneNumber: {
          label: 'Our company phone number',
        },
        tenantCompanyPositionOfCurrentUserName: {
          label: 'Position of current user name (from his profile)',
        },
        tenantCompanyStreet: {
          label: 'Our company street',
        },
        tenantCompanyWebsite: {
          label: 'Our company website url',
        },
        totalMonthlyFixedPrice: {
          label: 'Total monthly fixed price (pure value)',
        },
        totalMonthlyFixedPriceDetailed: {
          label: 'Total monthly fixed price (with detailed explanation)',
        },
        totalMonthlyValue: {
          label: 'Total monthly value (pure value)',
        },
        totalMonthlyValueDetailed: {
          label: 'Total monthly value (with detailed explanation)',
        },
        totalOneOffValue: {
          label: 'Total one-off value (pure value)',
        },
        totalOneOffValueDetailed: {
          label: 'Total one-off value (with detailed explanation)',
        },
        totalYearlyFixedPrice: {
          label: 'Total Yearly Fixed Price',
        },
        totalYearlyFixedPriceDetailed: {
          label: 'Total yearly fixed price (with detailed explanation)',
        },
        totalYearlyValue: {
          label: 'Total yearly value (pure value)',
        },
        totalYearlyValueDetailed: {
          label: 'Total yearly value (with detailed explanation)',
        },
        variables: {
          deleted: 'Deleted Variable',
          label: 'Variables',
        },
      },
      RichEditor: {
        toolbar: {
          link: {
            createModalName: 'Link name',
            createModalTitle: 'Add a link',
            createModalValue: 'Link URL',
            modalValueInvalidError: 'Link URL must be a valid URL',
            modalValueTooltipHTML:
              '<div><div>Example:</div><div>https://google.com - insert a web site link</div><div>mailto:someone@gmail.com - insert a create email link</div><div>tel:+49123456789 - insert a phone number</div></div>',
          },
        },
      },
      Setting: {
        CorporateIdentity: {
          BodyPrimary: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              bodyPrimary: 'Body primary',
              bodyPrimaryFooter: 'Body primary footer',
            },
            title: 'Body Primary',
          },
          CorporatePrimaryColor: {
            inputs: {
              color: 'Primary Contrast Color',
              fontFamily: 'Font Family',
              primaryColor: 'Primary Color',
            },
            preview: 'Preview of button (Primary+Contrast)',
            title: 'Corporate Primary Color',
          },
          HelperWidget: {
            howUseButton: '$t(common.howUseButton)',
            modalInfo: {
              helpText: '',
              imageUrl: '/cdn/page-previews/corporate-identity.png',
              title: 'How to use corporate identity',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/163721649',
            },
          },
          TableOfContent: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            title: 'Table of content',
          },
          TableTheme: {
            field: {
              background: 'Background color',
              color: 'Text color',
              fontFamily: 'Font family',
              fontSize: 'Font size',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              table: 'Table',
              tableContent: 'Table content',
              tableFooter: 'Table footer',
              tableHeaderPrimary: 'Table header primary',
              tableHeaderSecondary: 'Table header secondary',
              tableLeftColumn: 'Table left column',
            },
            title: 'Styles for Table',
          },
          TenantLogo: {
            bigImage: 'The image is too big, please use image with width and height lower than 1000px',
            logo: 'Logo',
            remove: 'Remove Image',
            select: 'Select Image',
            title: 'Tenant Logo',
          },
          TextStylesForDocuments: {
            field: {
              color: 'Color',
              fontFamily: 'Font',
              fontSize: 'Font Size',
              lineHeight: 'Line Height',
              marginBottom: 'Margin bottom',
              marginTop: 'Margin top',
            },
            inputs: {
              h1: 'H1',
              h2: 'H2',
              h3: 'H3',
              testimonial: 'Testimonial',
              testimonialSmallText: 'Testimonial small text',
              textBlock: 'Text-block',
              textLessImportant: 'Text color (less important)',
              title: 'Title',
            },
            title: 'Text Styles for Documents',
          },
          confirmSubmit: 'New colour is gonna be set. Continue?',
          customize: 'Customize in more detail',
          errors: {
            reset: 'Oops. There was a problem resetting the theme',
          },
          inputs: {
            font: {
              placeholder: 'Select existing font',
            },
          },
          reset: 'Reset',
          resetConfirmModalMessage:
            'Danger: Are you sure you want to reset CI to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          title: 'Corporate Primary Colour',
        },
        Email: {
          inputs: {
            activeUserToBCC: {
              label: 'Send BCC copy to active user',
              tooltip:
                'Should the current user get a copy of a sending email on its email address? We recommend to activate this function.',
            },
            attachmentsSizeLimit: {
              label: 'Attachments size limit (MB)',
            },
            bcc: {
              label: 'Send all emails as a BCC copy to this adress',
            },
            from: {
              label: 'From',
              tooltip: 'Accountant name <info@accountant.com>',
            },
            mailServiceProvider: {
              default: 'Send via Kanzlei Pilot (Not whitelabelled)',
              smtp: 'Send via own E-Mail server',
            },
            options: {
              label: 'Connection options',
              requireTLS: {
                tooltip:
                  'If this is checked and Use TLS is unchecked then backend tries to use STARTTLS even if the server does not advertise support for it. If the connection can not be encrypted then message is not sent',
              },
              useSSLv3: {
                tooltip:
                  'Require TLS even if server does not advertise support for it. In most cases use SSL v3 if TLS not work and you are connecting to port 465. For port 587 or 25 you can try to leave it unchecked',
              },
              useTLS: {
                tooltip:
                  'In most cases use TLS if SSL v3 not work and you are connecting to port 465. For port 587 or 25 you can try to leave it unchecked',
              },
            },
            requireTLS: 'Require TLS',
            smtpPassword: 'SMTP password',
            smtpPort: 'SMTP Port',
            smtpServer: 'SMTP Server',
            smtpUserName: 'SMTP Username',
            useSSLv3: 'Use SSL v3',
            useTLS: 'Use TLS',
          },
          otherSettingTitle: 'Other E-Mail Settings',
          saveAndTestEmail: 'Send Test-Email',
          saveAndTestEmailAlert: {
            description: 'SMTP rocks.',
            message: 'Hint',
          },
          saveAndTestEmailModal: {
            cancelText: '$t(common.cancel)',
            okText: '$t(common.ok)',
            title: 'We’ll send a text-email to your e-email {{email}}',
          },
          setBestSMTPSettingsTitle:
            'You just changed the SMTP-settings. Do you want us to find and set the most secure port + connection option for you? (Yes / NO)',
          settingTitle: 'SMTP Settings',
          signatureTitle: 'Email signature',
          smtpSetting: {
            button: 'Find & set best smtp setting',
            found:
              'We have found a SMTP setting and updated the settings. Please press the "Send Test Mail"-button to send a test-message to your account.',
            loading:
              'We are talking to the SMTP host right now. Please wait and leave this page open. It can take a few minutes...',
            notFound:
              'We have NOT found a SMTP setting. Something is wrong. Please look if you have a typo in the username, password or host. You can click the "show details"-button below to see what combinations we have tried.',
            showDetails: 'Show details',
            validationError:
              'Please set host, username, password and from-adress in form below first. Then press this button again.',
          },
          title: 'Email settings',
        },
        ImportCompanies: {
          alert: {
            step1: {
              description: 'Download a excel template',
              linkDescription: '/StammdatenImportMusterdatei.xlsx',
              message: 'In order to mass-import companies please upload a csv',
            },
            step3: {
              description: '',
              message: 'This is the default value we set it NO company type is set.',
            },
          },
          alertLimitCompany:
            "You can't create {{numberCompaniesInsert}} companies, only {{nrOfCompaniesLimit}} are available to create",
          completePage: {
            numberInsert: 'Insert {{count}} new company',
            numberInsert_plural: 'Insert {{count}} new companies',
            numberUpdate: 'Update {{count}} company',
            numberUpdate_plural: 'Update {{count}} companies',
            tip: 'Tip: You can see changes to a company in company history',
            title: 'Import successful',
          },
          errors: {
            duplicateIdentifier:
              'This row contains an non-unique identifier. The same identifier is used in at least 1 other row. Please clean up your csv-source to import this row.',
            duplicateName:
              'This row contains an non-unique company name. The same company name is used in at least 1 other row. Please clean up your csv-source to import this row.',
          },
          fields: {
            city: 'City',
            cityCode: 'City code',
            commercialObject: 'Commercial object',
            country: 'Country',
            defaultCompanyType: 'Default company type',
            houseNumber: 'House number',
            identifier: 'Identifier',
            importAcademicTitle: 'Import Academic Title',
            importCompanyType: 'Import company type',
            importEmail: 'Import Email',
            importSalutation: 'Import salutation',
            name: 'Name',
            street: 'Street',
          },
          navigationButtons: {
            import: '$t(common.navigationButtons.import)',
            next: '$t(common.navigationButtons.next)',
            previous: '$t(common.navigationButtons.previous)',
          },
          numberImport: '{{numberCompaniesInsert}} companies to be imported',
          numberUpdate: '{{numberCompaniesUpdate}} companies to be updated',
          status: {
            invalid: 'Invalid ',
            valid: 'Valid',
          },
          step1: 'Upload csv file',
          step2: 'Map Columns',
          step3: 'Set default Values',
          step4: 'Preview',
          table: {
            after: 'After',
            before: 'Before',
            city: '$t(admin.Setting.ImportCompanies.fields.city)',
            cityCode: '$t(admin.Setting.ImportCompanies.fields.cityCode)',
            columnSource: {
              placeholder: '(please choose)',
              title: 'Column Source',
            },
            columnTarget: 'Column Target',
            companyIdentifier: '$t(admin.Setting.ImportCompanies.fields.companyIdentifier)',
            country: '$t(admin.Setting.ImportCompanies.fields.country)',
            importType: {
              insert: 'insert',
              label: 'Import type',
              update: 'update',
            },
            name: '$t(admin.Setting.ImportCompanies.fields.name)',
            status: 'Status',
            street: '$t(admin.Setting.ImportCompanies.fields.street)',
          },
          title: 'Import Companies',
          uploadButton: 'Upload Csv',
        },
        LicenseOverview: {
          columns: {
            licenseStarted: 'License started',
            nrOfCompaniesCreated: 'Number of companies in system',
            nrOfCompaniesLimit: 'Number of companies allowed by license',
            nrOfUsersCreated: 'Number of users in system',
            nrOfUsersLimit: 'Number of users allowed by license',
          },
          restartOnboarding: 'Restart onboarding',
          title: 'License Overview',
        },
        ShoppingCartPreferences: {
          HelperWidget: {
            howUseButton: '$t(common.howUseButton)',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'How to use shopping cart preferences',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/163721649',
            },
          },
          card: {
            customPrice: 'Custom prices',
            viewOptions: 'Set view options by default',
          },
          fields: {
            allowCustomPrices: {
              label: 'Allow users to set custom prices',
              tooltip: 'If enabled, users can set custom prices in shopping-cart screen',
            },
            feeType: {
              tooltip:
                'Calculation mode in which KanzleiPilot calculates the prices. In standard mode everything is the same: one-time, monthly and annual services are displayed transparently. In the entrepreneur fee mode, monthly and annual items are combined at a monthly price and their individual prices are hidden with the note "The service is included in the entrepreneur fee". One-off services are also shown transparently in the company fee.',
            },
            showCalculated: {
              label: 'Show calculated price together with custom price in service-details',
            },
            showCategoryTotal: {
              label: 'Show Category total price preview',
              tooltip:
                'If switched on, the shopping cart will show "Category total price preview" for all categories by default.',
            },
            showDiscount: {
              label: 'Show Discounts',
              tooltip:
                'Should discounts be displayed when opening the menu card? We recommend you: Switch the display of discounts in the standard OFF and ONLY show it if a client asks for discounts and you have a suitable discount for him in the program. Tip: if a client “complains” about the price, ask them which additional services they can do without - instead of offering them a discount.',
            },
            showPrice: {
              label: 'Show Prices (of category & items, including discounted prices)',
              tooltip:
                'Should prices be displayed when opening the menu card? We recommend you: Switch the price display OFF in the standard. In this way, when talking to your client, you can first take care of the current situation and record their wishes with them. If you are then asked about the price, you can display the prices on the menu card at the push of a button (please watch the explanatory video in the interactive menu card).',
            },
          },
          pricingSetting: {
            roundPrice: {
              defaultOption: 'Use global standard',
              title: 'Round single items',
            },
            showDigits: {
              title: 'Show digits',
            },
            title: 'Pricing Setting',
          },
          resetConfirmModalMessage:
            'Danger: Are you sure you want to reset Settings to KanzleiPilots defaults? You will loose your customized settings! Continue?',
          selectDefaultEmailTemplate: 'Select default email template',
          title: 'Shopping Cart preferences',
        },
        TenantCompany: {
          HelperWidget: {
            howUseButton: '$t(common.howUseButton)',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'How to use your company data',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/163721649',
            },
          },
          inputs: {
            city: {
              title: 'City',
            },
            cityCode: {
              title: 'City code',
            },
            country: {
              title: 'Country',
            },
            houseNumber: {
              title: 'House number',
            },
            name: {
              title: 'Name',
            },
            officeEmail: {
              title: 'Office email',
            },
            phoneNumber: {
              title: 'Phone number',
            },
            street: {
              title: 'Street',
            },
            website: {
              title: 'Website',
            },
          },
          title: 'Your Company Data',
        },
      },
      addCategoryModal: {
        addModalTitle: 'Create a new category',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Category with the same name already exists',
        nameFieldDescription: 'Category name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addDiscountButton: 'Add Discount',
      addDocumentTemplateModal: {
        addModalTitle: 'Create a new document template',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Document template with the same name already exists',
        nameFieldDescription: 'Document name',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addInputButton: 'Add input field',
      addItemButton: 'Add Item',
      addStaticItemButton: 'Add static item',
      categoryNameInput: {
        label: 'Name',
      },
      categoryTexts: {
        guidanceText: {
          label: '$t(common.fields.guidanceText.label)',
        },
        guidanceVideoId: {
          label: '$t(common.fields.guidanceVideoId.label)',
        },
        introForDocument: {
          label: '$t(common.fields.introForDocument.label)',
          tooltip:
            "Is displayed in the block type “Service Details” below the area name and appears in the offer, for example. Give a bird's eye view of the area, describe the benefits for the customer and position yourself as an expert.",
        },
        name: {
          label: '$t(common.fields.name.label)',
          tooltip: 'Name of the area. This is how it appears in the interactive menu map and in the documents.',
        },
        subject: {
          tooltip:
            'Brief description that appears in the interactive menu map below the area name. Useful to emphasize your expert status and the benefit for the client.',
        },
      },
      deleteCategory: 'Delete Category',
      deleteCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the category?',
      },
      deleteDiscountConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the discount?',
      },
      deleteInputConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the input field?',
      },
      deleteItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Remove item',
        title: 'Are you sure you want to remove the item?',
      },
      deleteStaticItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the static item?',
      },
      deleteStatusConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Sure to delete the status? Existing shopping carts will be set to default status',
      },
      deleteUserConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the user?',
      },
      deleteVariableConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Are you sure you want to remove the variable?',
      },
      discountFieldModal: {
        addModalTitle: 'Create a new Discount',
        duplicatedErrorMessage: 'Discount with the same name already exists',
        editModalTitle: 'Edit Discount',
        nameFieldDescription: 'Discount name',
        nameFieldLabel: 'Name',
        typeFieldAbsolute: '$t(common.Discount.absolute)',
        typeFieldLabel: 'Type',
        typeFieldPercent: '$t(common.Discount.percent)',
        valueFieldDescription: 'Discount value',
        valueFieldLabel: 'Value',
      },
      discountTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
        value: 'Value',
      },
      discountUsedInShoppingCartConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        titleDelete: 'Discount is used in existing shopping carts. Remove?',
        titleUpdate: 'Discount is used in existing shopping carts. Update?',
      },
      discountsTitle: 'Discounts',
      duplicateCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Category names are unique. Please enter the new name',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Duplicate category',
        title: 'Please enter new category name',
      },
      duplicateItemConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Item names are unique. Please enter the new name',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Duplicate item',
        title: 'Please enter new item name',
      },
      guidanceAlert: {
        category: 'Infos about this category',
        closeForever: {
          buttonTitle: 'Close forever',
          title: 'Do you want to hide forever {{name}} guide?',
        },
        item: 'Infos about this item',
      },
      importCategoryPresetsModal: {
        errorMessage: 'Categories with such names {{duplicatedCategories}} have already been added to the list',
        import: 'import',
        title: 'Import Category-Presets from Common Library',
      },
      inputFieldModal: {
        addModalTitle: 'Create a new Input Field',
        cancel: '$t(common.cancel)',
        comboOptions: {
          addOption: 'add Option',
          boxLabel: 'Combobox options',
          modal: {
            addTitle: 'Create new option',
            cancel: '$t(common.cancel)',
            duplicatedErrorMessage: 'Input field option with the same name already exists',
            editTitle: 'Edit option',
            nameFieldDescription: 'Input Field option name',
            nameFieldLabel: 'Name',
            ok: 'Ok',
            valueFieldDescription: 'Input Field option value',
            valueFieldLabel: 'Value',
          },
          table: {
            actionsColumnLabel: '',
            deleteOptionConfirmation: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Are you sure you want to remove the option?',
            },
            nameColumnLabel: 'Name',
            valueColumnLabel: 'Value',
          },
        },
        duplicatedErrorMessage: 'Input Field with the same name already exists',
        editModalTitle: 'Edit Input Field',
        fields: {
          allowedRange: {
            label: 'Allowed value range',
          },
          allowedRangeMaxValue: {
            label: 'Max-value',
            maxIsLessThenDefaultValue: 'Please correct the "max value" as it\'s less than the "default value"',
            maxIsLessThenMin: 'Please correct the "max value" as it\'s less than the "min value"',
          },
          allowedRangeMinValue: {
            label: 'Min-value',
            minIsMoreThenDefaultValue: 'Please correct the "min value" as it\'s greater than the "default value"',
            minIsMoreThenMax: 'Please correct the "min value" as it\'s greater than the "max value"',
          },
          defaultInput: {
            label: '$t(common.fields.defaultValue.label)',
          },
          defaultType: {
            input: 'enter number',
            label: 'How do you want to set the default-value?',
            variable: 'take from variable',
          },
          defaultValue: {
            defaultValueIsOutOfRange: 'Please correct the "default value" as it\'s out of the allowed range',
          },
          defaultVariable: {
            label: 'Take default-value from variable:',
          },
          name: {
            description: 'Input Field name',
            label: '$t(common.fields.name.label)',
          },
          optionPDFNameTitle: {
            label: 'Title for option',
            placeholder: 'Title for option',
          },
          optionPDFValueTitle: {
            label: 'Title for value',
            placeholder: 'Title for value',
          },
        },
        ok: 'Ok',
        pdfConfigurationLabel: 'PDF configuration',
        typeFieldLabel: 'Type',
        unitNameDescription: 'Unit Field name',
        unitNameLabel: 'Unit name',
        variableAlert:
          'Variable that you have chosen is equal to {{value}}. Please consider that when filling allowed range',
      },
      inputFieldTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
      },
      inputsTitle: 'Input Fields',
      itemModal: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item conditional visibility',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item input fields',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        PriceHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item price',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        ScalesHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item pricing scales',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        TextsHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use item texts',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        addTitle: 'New item',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Item with the same name already exists',
        editSubTitleForItem: 'Edit item {{itemName}}',
        editTitle: 'Edit item',
        guideAlert: {
          guidanceVideoButton: 'Display guidance video',
          guidanceVideoModalTitle: 'Guidance video',
        },
        inputs: {
          benefits: {
            label: '$t(common.fields.benefits.label)',
            tooltip:
              'Benefit for the customer who appears in the offer. Focus here on the added value that the customer has through their investment in the service.',
          },
          calculationMode: {
            label: '$t(common.fields.calculationMode.label)',
            tooltip:
              'Do you want to give a price/value for the item OR communicate that the price is on "$t(common.Item.calculationMode.onActualCost)"? Make your choice here.',
          },
          descriptionForContract: {
            label: '$t(common.fields.descriptionForContract.label)',
            tooltip:
              'In the scope of the order, you clearly describe the scope of your service. Clearly demarcate your performance here. The standard text appears in the remuneration agreement and in the tax advisory contract.',
          },
          guidanceText: {
            label: '$t(common.fields.guidanceText.label)',
            placeholder: '$t(common.fields.guidanceText.label)',
          },
          guidanceVideoId: {
            label: '$t(common.fields.guidanceVideoId.label)',
            placeholder: '$t(common.fields.guidanceVideoId.label)',
          },
          highlightBindingness: {
            label: '$t(common.fields.highlightBindingness.label)',
            tooltip:
              'Do you want to put special focus on the bindingness of the given price? If yes, then you can make your choice here and the bindingness will be highlighted.',
          },
          infoText: {
            label: '$t(common.fields.infoText.label)',
            tooltip:
              'This text appears in the interactive menu map when you press the info button. Emphasize the benefits for the customer.',
          },
          minPrice: {
            label: '$t(common.fields.minPrice.label)',
            tooltip:
              'Minimum price that applies if the price calculated in the price formula is too low. Enter 0 to set NO reserve price.',
          },
          name: {
            label: 'Name',
            placeholder: '',
            tooltip: 'Name of the service. This is how it appears in the interactive menu map and in the documents.',
          },
          notesForFeeAgreement: {
            label: '$t(common.fields.notesForFeeAgreement.label)',
            tooltip:
              'Use this text to add notes for fee-agreement. It is available in service-details and bookmarked-items block.',
          },
          notesToEmployee: {
            label: '$t(common.fields.notesToEmployee.label)',
            tooltip:
              'Space for internal information for your law firm team. The text appears in the reading view for an existing menu card. Useful, e.g. for a reference to related order numbers, etc.',
          },
          paymentInterval: {
            label: '$t(common.fields.paymentInterval.label)',
            monthlyLabel: '$t(common.Item.paymentIntervalValue.monthly)',
            oneOffLabel: '$t(common.Item.paymentIntervalValue.oneOff)',
            tooltip:
              'Payment interval of the service. In the entrepreneur fee mode, annual and monthly services for the client are summarized in the menu card in a monthly price.',
            yearlyLabel: '$t(common.Item.paymentIntervalValue.yearly)',
          },
          pleaseNote: {
            label: '$t(common.fields.pleaseNote.label)',
            tooltip:
              'Set up a set of rules here: If the price applies, what does the client have to deliver? What information do you need from him? Which service is not included? In the standard system, the text appears in the offer, the remuneration agreement and in the tax advisory contract.',
          },
          pricingFormula: {
            editConstants: 'Edit constants',
            errors: {
              invalid: '{{name}} is invalid',
            },
            insertVariableMenu: {
              disabledMenuTooltip: {
                categoryMonthlyAdvance:
                  'Category does NOT contain an active item with paymentInterval monthly or yearly.',
                inactiveItem: 'Item is set inactive.',
                onActualCostItem: 'Placeholder is disabled because the item is on actual cost mode.',
              },
              subMenu: {
                category: 'Inputs from category',
                categoryMonthlyAdvance: 'Categories total sum',
                categoryTotal: 'Total value from category',
                function: 'Functions',
                item: 'Inputs from position',
                itemTotal: 'Total value from item',
                scaleFunctionParameters: 'Scale function parameters',
                variable: 'Variables',
              },
              title: 'Insert Placeholder',
            },
            label: '$t(common.fields.pricingFormula.label)',
            tooltip:
              'The price formula based on which the price is calculated. Tip: Take a look at the explanatory videos to get to know this important function in detail.',
          },
          roundPrice: {
            label: 'Round single items',
            tooltip:
              'Set this option to round the value calculated by pricing formula. Tip: You can set global settings at settings > shopping-cart > shopping-cart preferences',
          },
          scales: {
            addModalLabel: 'Create a new Scale',
            atLeastTwoError: 'There must be at least two scales',
            cancel: '$t(common.cancel)',
            deleteModal: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Are you sure you want to remove the Scale?',
            },
            duplicatedError: 'Each scale must be unique',
            duplicatedErrorMessage: 'Scale with the same name already exists',
            editModalLabel: 'Edit Scale',
            emptyScaleList:
              'There are no scales yet. Create one by clicking the "$t(admin.itemModal.inputs.scales.addModalLabel)" button',
            emptyTable:
              'There are no scales yet. Create one by clicking the "$t(admin.itemModal.inputs.scales.addModalLabel)" button',
            inputs: {
              formula: {
                error: 'Formula is invalid',
                label: 'scale formula',
              },
              graduatedScaleMode: {
                graduated: 'Graduated',
                label: 'Scaled pricing mode',
                standard: 'Standard',
                tooltip:
                  'You can choose between 2 modes: 1) in standard mode the scale function simply returns the value for the given tier. 2) in “graduated mode“ the scale function automatically sums up the values with the tier-prices of the previous tiers, p.e. at at setup of 1-3 = 2 & 4 - 6 = 1,5 it will return 7,50 for the input value of 4. Calculation = 3*2 + 1 * 1,5',
              },
              pricingFormulaTitle: {
                label: 'Pricing formula title',
                placeholder: 'Pricing formula title (for PDF display)',
                tooltip:
                  'In the price formula you can use the scale function to implement graduated prices. Here you can configure the scales and values, which you can then call up in the price formula with the scale function.',
              },
              pricingFormulaUnit: {
                label: 'Pricing formular unit',
                placeholder: 'Unit',
                tooltip:
                  'Here you can configure an unit for the pricing column for the pricing table. If this field is empty, then the pricing column has no unit. Default unit is € (Euro).',
              },
              scaleTitle: {
                label: 'Scale title',
                placeholder: 'Scale title (for PDF display)',
                tooltip:
                  'In the price formula you can use the scale function to implement graduated prices. Here you can configure the scales and values, which you can then call up in the price formula with the scale function.',
              },
              scaleUnit: {
                label: 'Scale unit',
                placeholder: 'Unit',
                tooltip:
                  'Here you can configure an unit for the scale column for the pricing table. If this field is empty, then the scale column has no unit.',
              },
              unitName: {
                label: 'Scale unit name',
                placeholder: 'Scale unit name',
              },
              value: {
                label: 'scale value',
              },
            },
            label: 'Configure "Pricing Scales" function?',
            ok: 'Ok',
            pdfConfigurationLabel: 'PDF configuration',
            pdfConfigurationUnitsLabel: 'PDF units configuration',
            table: {
              columns: {
                pricingFormula: 'Pricing Formula for Scale',
                value: 'Scale',
              },
            },
            tooltip:
              'In the price formula, you can use the scale function to implement graduated prices. Here you can configure the scales and values, which you can then call up in the price formula with the scale function.',
            wrapperLabel: '"Pricing Scales" function',
          },
          scalesList: {
            label: 'Scales list',
          },
          subTitle: {
            label: 'Subtitle',
            placeholder: '',
            tooltip:
              'Brief description of the service that appears in the interactive menu map below the area name. Useful to direct the focus on a certain service, to emphasize the benefit for the client and to position yourself as an expert.',
          },
        },
        ok: 'Ok',
        pricingWarning:
          'Warning: You have a negative pricingFormula Formula and a minPrice of "{{value}}" configured. The minPrice will affect the calculated price. Please remove the minPrice if you want the calculated price to be negative.',
        tabs: {
          conditionalVisibility: 'Conditional Visibility',
          inputs: 'Input Fields',
          price: 'Price',
          texts: 'Texts',
        },
      },
      itemTable: {
        columns: {
          actions: '',
          active: 'active',
          name: 'Name',
          paymentInterval: 'Payment Interval',
          pricingFormula: 'Pricing Formula',
        },
        title: 'Items',
      },
      pricingFormulaInput: {
        functions: {
          StBVV_TabelleA: 'StBVV_TabelleA(nr or formula) - p.e. "StBVV_TabelleA(140000)" will be 1773',
          StBVV_TabelleB: 'StBVV_TabelleB(nr or formula) - p.e. "StBVV_TabelleB(5000)" will be 81',
          StBVV_TabelleC: 'StBVV_TabelleC(nr or formula) - p.e. "StBVV_TabelleC(45000)" will be 122',
          StBVV_TabelleD_a: 'StBVV_TabelleD_a(nr or formula) - p.e. "StBVV_TabelleD_a(951)" will be 1738',
          StBVV_TabelleD_b: 'StBVV_TabelleD_b(nr or formula) - p.e. "StBVV_TabelleD_b(42500)" will be 380',
          round: 'round(nr or formula) - p.e. "round(7.8)" will round up to 8',
          scale:
            'scale(nr or formula) - p.e. "scale(342, 23)" will pass parameters 342 and 23 as inputValue1 and inputValue2 into Pricing Scales function defined in this page below',
        },
        functionsListText: 'The following functions are available:',
        howUseButton: '$t(common.howUseButton)',
        inputsListText: 'The following Input Fields are available:',
        modalInfo: {
          helpText: '',
          title: 'How to use pricing formula input',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/163721649',
        },
        syntaxInstructions:
          'Operators: +, -, /, *, %, ^\nType a "@" to insert an input field\nType a "#" to insert a function',
      },
      resetTextStylesConfirmation: {
        cancel: '$t(common.cancel)',
        title:
          'Are you sure that you want to reset text-styles? All styling information (bullet-points, bold, italic, underline, etc.) will be lost. Continue?',
        yes: '$t(common.yes)',
      },
      staticItemsFieldModal: {
        addModalTitle: 'Create a new static item',
        duplicatedErrorMessage: 'Static item with the same name already exists',
        editModalTitle: 'Edit Static item',
        errors: {
          isEmpty: '{{label}} is a required field',
        },
        nameFieldDescription: 'Static item name',
        nameFieldLabel: 'Name',
        valueFieldDescription: 'Static item value',
        valueFieldLabel: 'Value',
      },
      staticItemsTableColumns: {
        actions: '',
        name: 'Name',
        value: 'Value',
      },
      staticItemsTitle: 'Static Items',
      textsTabSubmitButton: 'Save changes',
      textsTabTitle: 'Texts',
      textsTitle: 'Texts',
    },
    common: {
      ConditionalVisibility: {
        balanceOfAccountsGmbH: 'Balance of accounts - GmbH',
        balanceOfAccountsGmbHAndCoKg: 'Balance of accounts - GmbH & Co. Kg',
        balanceOfAccountsGmbHAndCoKgSelfBooker: 'Balance of accounts - GmbH & Co. Kg (self-booker)',
        balanceOfAccountsGmbHSelfBooker: 'Balance of accounts - GmbH (self-booker)',
        balanceOfAccountsOther: 'Balance of accounts - other',
        balanceOfAccountsOtherSelfBooker: 'Balance of accounts - other (self-booker)',
        balanceOfAccountsSingle: 'Balance of accounts - single',
        balanceOfAccountsSingleSelfBooker: 'Balance of accounts - single (self-booker)',
        balanceOfAccountsUG: 'Balance of accounts - UG (with limited liability)',
        balanceOfAccountsUGSelfBooker: 'Balance of accounts - UG (with limited liability) (self-booker)',
        balanceOfUnion: 'Balance of accounts - union',
        balanceOfUnionSelfBooker: 'Balance of accounts - union (self-booker)',
        cashBasisAccountingCapital: 'Cash basis accounting - capital',
        cashBasisAccountingCapitalSelfBooker: 'Cash basis accounting - capital (self-booker)',
        cashBasisAccountingSingle: 'Cash basis accounting - single',
        cashBasisAccountingSingleSelfBooker: 'Cash basis accounting - single (self-booker)',
        cashBasisAccountingUnion: 'Cash basis accounting - union',
        cashBasisAccountingUnionSelfBooker: 'Cash basis accounting - union (self-booker)',
        checkAll: 'Check all',
        other: 'Other',
        otherSelfBooker: 'Other (self-booker)',
        privatePerson: 'Private person',
        uncheckAll: 'Uncheck all',
      },
      Discount: {
        absolute: 'absolute',
        percent: 'percent',
      },
      Error: {
        required: '{{field}} is required',
      },
      InputField: {
        combo: 'combobox',
        input: 'input',
        internal: 'internal',
        name: 'Input field',
      },
      Item: {
        calculationMode: {
          onActualCost: 'On actual costs',
          value: 'Value',
        },
        highlightBindingness: {
          dynamic: 'Dynamic',
          dynamicDativ: 'dynamic',
          dynamicDativ_plural: 'dynamic',
          estimated: 'Estimated',
          estimatedDativ: 'estimated',
          estimatedDativ_plural: 'estimated',
          fixPriced: 'Fix priced',
          fixPricedDativ: 'fix priced',
          fixPricedDativ_plural: 'fix priced',
          noHighlight: "Don't highlight",
        },
        paymentIntervalValue: {
          fixedMonthly: 'fixed monthly price',
          monthly: 'monthly',
          oneOff: 'one-off',
          yearly: 'yearly',
        },
        recursive: "This item's formula includes items that reference this item (recursive reference)",
        scaleError: 'The formula references scale function which needs to be defined below',
        switchToOnActualCost:
          'Item Calculation can not be switched because the item is currently on "value"-item-calculation-mode and is used in pricing formula of item {{items}}. It can NOT be switched to on actual cost.',
      },
      MoreInfoWidget: {
        book: {
          calendly: 'Book 15 minute onboarding session',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          title: 'Do you still have questions? The please book a session with us',
        },
      },
      RoundPrices: {
        noRound: 'Do NOT round',
        roundUpTo1: 'Round up to 1 €',
        roundUpTo10: 'Round up to 10 €',
        roundUpToMultiple25: 'Round up to 25 € (0, 25, 50 or 75)',
      },
      Variables: {
        types: {
          float: 'Float',
          percent: 'Percent',
          pricePerHour: 'Price per hour',
        },
      },
      activateYourAccount: 'Activate your account',
      alertInAcceptInvitationForm: 'Please set your password to activate your KanzleiPilot-account',
      and: 'and',
      buttons: {
        resendInvitation: {
          tooltip: 'Resend invitation E-Mail',
        },
        resendVerificationEmail: 'Resend verification email',
      },
      cancel: 'Cancel',
      closeToastText: 'Close this message',
      columns: {
        actions: 'Actions',
        body: 'Body',
        category: 'Category',
        createdAt: 'Created At',
        createdBy: 'Created By',
        data: 'Data',
        document: 'Document',
        field: 'Field',
        internalNote: 'Internal Note',
        interval: 'Interval',
        item: 'Item',
        name: 'Name',
        nrOfAllowedCompaniesByPricingPlan: 'Number Of Allowed Companies By Pricing Plan',
        nrOfAllowedUsersByPricingPlan: 'Number Of Allowed Users By Pricing Plan',
        price: 'Price',
        rootAdminEmail: 'Root-Admin Email',
        rootAdminName: 'Root-Admin Name',
        status: 'Status',
        subject: 'Subject',
        tenantName: 'Tenant',
        type: 'Type',
        value: 'Value',
        valueMonthly: 'Value Monthly',
        valueOneOff: 'Value OneOff',
        valueYearly: 'Value Yearly',
      },
      company: {
        addCompany: 'Add company',
        changeCompany: 'Replace company',
        editCompany: 'Edit Company',
      },
      contact: {
        addContact: 'Add contact',
        deleteContact: 'Delete contact',
        editContact: 'Edit contact',
      },
      dataPicker: {
        thisYear: 'This year',
      },
      duplicatedErrorMessage: '{{name}} with the same name already exists',
      error: 'Error',
      fields: {
        actions: {
          label: 'Actions',
        },
        benefits: {
          label: 'Benefits',
        },
        body: {
          label: 'Body',
          placeholder: 'Body',
        },
        calculationMode: {
          label: 'Item Calculation Mode',
        },
        defaultValue: {
          label: 'Default value',
        },
        descriptionForContract: {
          label: 'Description for contract',
        },
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        guidanceText: {
          label: 'Guidance text',
          placeholder: 'Guidance text',
        },
        guidanceVideoId: {
          label: 'Guidance video',
          placeholder: 'Guidance video',
        },
        highlightBindingness: {
          label: 'Bindingness Mode',
        },
        infoText: {
          label: 'Info',
        },
        introForDocument: {
          label: 'Intro for document',
        },
        isDefault: {
          label: 'Is default',
        },
        minPrice: {
          label: 'Minimal Price',
        },
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        nameRaw: {
          label: '$t(common.fields.name.label)',
        },
        notesForFeeAgreement: {
          label: 'Fee details for fee-agreement',
        },
        notesToEmployee: {
          label: 'Notes to employee',
        },
        nrOfAllowedCompaniesByPricingPlan: {
          label: 'Number Of Allowed Companies By Pricing Plan',
          placeholder: 'Number Of Allowed Companies By Pricing Plan',
        },
        nrOfAllowedUsersByPricingPlan: {
          label: 'Number Of Allowed Users By Pricing Plan',
          placeholder: 'Number Of Allowed Users By Pricing Plan',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        passwordConfirmation: {
          label: 'Password confirmation',
          placeholder: 'Password confirmation',
        },
        paymentInterval: {
          label: 'Payment Interval',
        },
        pleaseNote: {
          label: 'Please note',
        },
        position: {
          label: 'Position',
          placeholder: 'Position',
        },
        pricingFormula: {
          label: 'Pricing Formula',
        },
        projectName: {
          label: 'Project name',
        },
        role: {
          label: 'Role',
          placeholder: 'Role',
        },
        status: {
          label: 'Status',
        },
        subject: {
          label: 'Subject',
          placeholder: 'Subject',
        },
        type: {
          label: 'Type',
        },
        value: {
          label: 'Value',
        },
        valueRaw: {
          label: '$t(common.fields.value.label)',
        },
      },
      howUseButton: "Help? Let's get started",
      inputs: {
        otp: {
          title: 'One Time Password',
        },
      },
      navigationButtons: {
        done: 'Done',
        import: 'Import',
        next: 'Next',
        previous: 'Previous',
      },
      next: 'Next',
      no: 'No',
      off: 'Off',
      ok: 'Ok',
      on: 'On',
      quotes: [
        {
          quote: 'Gut zuhören können ist der halbe Erfolg',
        },
        {
          quote: 'Zeige deinen Mandanten, wie sehr du dich um sie kümmerst',
        },
        {
          quote: 'Persönlicher Service gewinnt das Spiel',
        },
        {
          author: 'Angelehnt an Mahatma Gandhi',
          quote: 'Ein Mandant ist der wichtigste Besucher in unserem Hause',
        },
        {
          author: 'Chip Bell',
          quote: 'Loyale Mandanten bestehen darauf, dass auch ihre Freunde mit dir Geschäfte machen',
        },
        {
          quote: 'Wir hören gut zu und liefern echten Mehrwert',
        },
        {
          quote: 'Tue Gutes und rede darüber',
        },
      ],
      roles: {
        ADMIN: 'Admin',
        ROOT_ADMIN: 'Root Admin',
        SUPER_ADMIN: 'Super Admin',
        USER: 'User',
        VIEWER: 'Viewer',
      },
      saved: 'Saved',
      saving: 'Saving',
      showDigits: {
        always: 'always show full digits (p.e. 10,00 €)',
        avoid: 'only show full digits for uneven numbers (p.e. 10 € or 8,99 €)',
      },
      stateInvitation: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive (left company)',
        INVITATION_SEND: 'Invitation sent',
      },
      statusTypes: {
        shoppingCart: 'Shopping Cart',
      },
      success: 'Success!',
      successToastText: '$t(common.success)',
      tenant: {
        noAllowedToCreateCompany: 'Not allowed to create more companies',
      },
      toast: {
        errors: {
          activateInvitation: 'Failed to activate',
          passwordReset: 'Failed to send password reset e-Mail',
          resendInvitation: 'Failed to resend invitation',
          sendInvitation: 'Failed to send invitation',
          user: {
            delete: 'Failed to delete user',
          },
        },
        info: {
          updateIntroductionBlock:
            'Some persons have been deleted, so we will automatically update the Introduction block data',
        },
        success: {
          acceptInvitation: 'Invitation successfully accepted',
          activateInvitation: '{{name}} has been activated',
          changePassword: 'Password changed successfully',
          clipboard: 'Copied to clipboard',
          passwordReset: 'E-Mail sent',
          resendInvitation: 'Invitation resent',
          sendChangeEmailVerificationEmail:
            'Please check your email, {{email}}, for further instructions on changing your email.',
          sendChangePasswordVerificationEmail:
            'Please check your email, {{email}}, for further instructions on resetting your password.',
          sendInvitation: 'Invitation sent',
        },
      },
      unsavedChangesModal: {
        cancel: '$t(common.cancel)',
        proceed: 'Proceed',
        title: 'The changes will not be saved. Proceed?',
      },
      urls: {
        calendlyUrl:
          'https://calendly.com/kanzleipilot-erfolgsteam/kanzleipilot-fragen-und-antworten-session-15-minuten',
        sendEmailUrl: 'mailto:support@kanzleipilot.de',
      },
      vatType: {
        company: 'Company VAT',
        personal: 'Personal VAT',
      },
      yes: 'Yes',
    },
    dateFormats: {
      date: 'dd/MM/yyyy',
      dateMoment: 'DD/MM/YYYY',
      dateTime: 'dd/MM/yyyy HH:mm',
      month: 'MM/yyyy',
    },
    decimalSeparator: '.',
    deleteDocumentTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed:
        'Certain documents or shopping cart settings use this document. Are you sure that you want to remove it?',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the document template?',
    },
    deleteShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Are you sure you want to delete the shopping cart?',
    },
    duplicateShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Do you want to store a duplicate of the shopping-cart as a new shopping-cart instance?',
    },
    editShoppingCartModal: {
      HelperWidget: {
        howUseButton: 'Learn about different between global and independent',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Learn about different between global and independent',
          videoCaption: 'Learn how to use independent and global modes',
          videoUrl: 'https://vimeo.com/715925363',
        },
      },
      explanationGlobal: 'You use the new configuration. (The settings are administrated by users with admin-rights)',
      explanationIndependent:
        'You use the configuration, which was active at the time of the last shopping cart revision creation',
      globalOption: 'Edit with global configuration',
      independentOption: 'Edit with independent configuration',
      labelGlobal: 'Global configuration',
      labelIndependent: 'Independent configuration',
      migrationInfoText:
        'This shopping cart was migrated before saving of "independent configurations" and therefore you can\'t edit it with "independent configuration". You have to edit this shopping cart one time with the "global configuration" to can use the "independent configuration".',
      title: 'Which configuration do you want to use for editing the shopping cart?',
    },
    locale: 'English',
    localeCode: 'en',
    onboarding: {
      companyInfo: {
        stepTitle: 'Company info',
        textAboveVideo: '',
        title: 'Company info',
      },
      corporateIdentity: {
        chooseFontsTitle: 'Choose your company fonts',
        colorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.color)',
        colorPreview: 'Preview of button (Primary + Contrast)',
        fontFamilyInputLabel: 'Pick your text font',
        pickCompanyColors: 'Pick your company colors',
        preview: 'preview',
        primaryColorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.primaryColor)',
        stepTitle: 'Corporate Identity',
        textAboveVideo: '',
        textFontPreview:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
        title: 'What does your company look like?',
        titleFontFamilyInputLabel: 'Pick your title font',
        titleFontPreview: 'This is a nice title',
        uploadLogoAlert: 'Please upload your logo in PNG or JPG format with maximal 1000 pixel height and width.',
        uploadLogoTitle: 'Upload your company logo',
        videoCaption: 'Henning explains CI',
        videoUrl: 'https://vimeo.com/163721649',
      },
      finish: {
        buttonsTitle: 'What do you want to do next?',
        calendly: 'Book Onboarding-Session (via Calendly)',
        calendlyUrl: '$t(common.urls.calendlyUrl)',
        catalogueConfigurationButton: 'Check items (and adjust pricing)',
        manageUsersButton: 'Invite your team-mates',
        shoppingCartButton: 'Create a first shopping cart',
        stepTitle: 'Finish',
        successfulMessage: 'Successful tenant registration',
        textAboveVideo: 'Hello {{userFirstName}}, congratulations! You are done!',
        title: 'Done and ready to go!',
        videoCaption: 'Henning talks next steps',
        videoUrl: 'https://vimeo.com/163721649',
      },
      navigationButtons: {
        done: '$t(common.navigationButtons.done)',
        next: '$t(common.navigationButtons.next)',
        previous: '$t(common.navigationButtons.previous)',
      },
      pickCategories: {
        stepTitle: 'Pick Categories',
        textAboveVideo: '',
        title: 'Pick your categories',
        videoCaption: 'Henning talks pricing levels',
        videoUrl: 'https://vimeo.com/163721649',
      },
      pickDocuments: {
        infoConfirmationMessage: 'Please note that those documents are just templates. Please press ok (ij)',
        infoMessage:
          'Important: we do not warranty legal correctness of those documents. They are just templates for you to build on.',
        stepTitle: 'Pick Documents',
        textAboveVideo: '',
        title: 'Pick your documents',
        videoCaption: 'Henning talks document',
        videoUrl: 'https://vimeo.com/163721649',
      },
      pricingLevel: {
        hourlyRatesTable: {
          accountant: 'Accountant',
          employee: 'Employee',
          jobType: 'Job type',
          pricingFactor: 'Pricing Factor',
          title: 'What are the hourly rates?',
        },
        pricingTable: {
          category: 'Category',
          loans: 'Loans',
          monthlyBookkeeping: 'Monthly Bookkeeping',
          pricingFactor: 'Pricing Factor',
          title: '',
          yearlyBusinessAccountsBalanceSheetBEU: 'Pricing level yearly business accounts (BEU)',
          yearlyBusinessAccountsBalanceSheetRest: 'Pricing level yearly business accounts (rest)',
          yearlyBusinessAccountsCompanies: 'Pricing level yearly business accounts (EÜR companies)',
          yearlyBusinessAccountsSingle: 'Pricing level yearly business accounts (EÜR)',
          yearlyPersonalAccounts: 'Yearly Personal Accounts',
        },
        stepTitle: 'Pricing Level',
        textAboveVideo: '',
        title: 'What is your pricing level?',
        videoCaption: 'Henning talks pricing levels',
        videoUrl: 'https://vimeo.com/163721649',
      },
      pricingMode: {
        fixedMonthlyModeDescription:
          'One-time services are priced transparently. Monthly and yearly items are summed up to a monthly fixed fee. Gives your customer planning reliabilty.',
        fixedMonthlyModeTitle: 'Monthly Fixed Fee',
        standardModeDescription:
          'Shows prices of all positions and all interval-types. This is what 80 % of accountants work in',
        standardModeTitle: 'Transparent Mode',
        stepTitle: 'Pricing Mode',
        textAboveVideo: '',
        title: 'Which pricing-mode do you work in?',
        videoCaption: 'Henning talks pricing levels',
        videoUrl: 'https://vimeo.com/163721649',
      },
      signatures: {
        addButton: 'Add',
        per: ' must have at least 1 person',
        stepTitle: 'Signatures',
        submitError: 'Please, add at least 1 person',
        textAboveVideo: 'Here you can add signatures which will appear at the introduction of a document',
        title: 'Persons authorized to sign',
        videoCaption: 'Henning talks signatures',
        videoUrl: 'https://vimeo.com/163721649',
      },
      welcome: {
        stepTitle: 'Welcome',
        textAboveVideo:
          "Hello {{userFirstName}}, let's install KanzleIPilot for your company! It's gonna take 5 minutes of your time. We'll ask you a few questions and then automatically populate your catalogue and pricing",
        title: 'Welcome to KanzleiPilot',
        videoCaption: 'Henning says hi',
        videoUrl: 'https://vimeo.com/163721649',
      },
    },
    sharedPackage: {
      actualCostCalculation: {
        include: 'Includes',
        plus: 'Plus',
      },
      code: '{{paymentInterval}} {{discountedValue}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice:
        '{{paymentInterval}} {{discountedCustomPrice}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_discount:
        '{{discountedCustomPrice}} {{paymentInterval}}{{newlineOrWhitespace}}discounted by {{discount}} <i>(original price <del>{{customPrice}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice:
        '{{paymentInterval}} {{customPrice}} <i>(instead of calculated price <del>{{discountedValue}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice_discount:
        'Your custom price: {{paymentInterval}} {{discountedCustomPrice}} <i>(discounted {{discount}}, your custom price before discount <del>{{customPrice}}</del>, Calculated price <del>{{priceBeforeDiscount}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount:
        '{{discountedValue}} {{paymentInterval}}{{newlineOrWhitespace}}discounted by {{discount}} <i>(original price <del>{{priceBeforeDiscount}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount_noDiscountValue:
        '{{discountedValue}} {{paymentInterval}} <i>(instead of {{priceBeforeDiscount}} due to discounts)</i>',
      code_on_actual_cost: '{{paymentInterval}} on actual cost',
      empty_pricing_formula: 'empty pricing formula NOT allowed',
      fixedMonthlyFeeHint:
        '{{paymentInterval}} price is included in "fixed monthly price" listed below in "Final Price Preview" section$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      fixedMonthlyFeeHint_discount:
        '{{paymentInterval}} price is included in "fixed monthly price" listed below in "Final Price Preview" section$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      free_service: 'free {{paymentInterval}} service$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      highlightBindingness: {
        dynamic: '<br/><i>$t(common.Item.highlightBindingness.{{highlightBindingness}})</i>',
        estimated: '$t(sharedPackage.highlightBindingness.dynamic)',
        fixPriced: '$t(sharedPackage.highlightBindingness.dynamic)',
        noHighlight: '',
      },
      numberOfActualCost:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} item on actual cost',
      numberOfActualCost_plural:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} items on actual cost',
      numberOfHighlightBindingness:
        '{{count}} item with $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) value {{value}}',
      numberOfHighlightBindingness_plural:
        '{{count}} items with $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) values {{value}}',
      variable_is_empty: '{{variable}} is empty',
    },
    superAdmin: {
      ManageAllUsers: {
        title: 'Manage All Users',
      },
      ManageTenants: {
        addTenant: 'Add tenant',
        addTenantModalTitle: 'Create a new tenant',
        columns: {
          licenseStarted: 'License Started',
          locale: 'Locale',
          nrOfAllowedCompaniesByPricingPlan: '$t(common.columns.nrOfAllowedCompaniesByPricingPlan)',
          nrOfAllowedUsersByPricingPlan: '$t(common.columns.nrOfAllowedUsersByPricingPlan)',
          nrOfCatalogueConfigurationEdits: 'Number Of  "Edits" In Catalogue-Configuration',
          nrOfCompanies: 'Number Of Companies',
          nrOfCompanyTypeEdits: 'Number Of  "Edits" In DocumentType-Configuration',
          nrOfCreatedShoppingCarts: 'Number Of Shopping-Carts Created',
          nrOfLogins: 'Number Of Logins',
          nrOfTotalActions: 'Number Of Total Actions',
          nrOfUsers: 'Number Of Users',
          rootAdminEmail: '$t(common.columns.rootAdminEmail)',
          rootAdminName: '$t(common.columns.rootAdminName)',
          status: '$t(common.columns.status)',
          tenantName: '$t(common.columns.tenantName)',
          tenantStatus: 'Tenant Status',
          timeStampOfLastAction: 'Timestamp Of Last Action',
          viewLogbook: 'View Logbook',
        },
        editTenantModalTitle: 'Edit tenant',
        emptyListMessage: "There are no tenant's actions yet",
        fields: {
          disabled: {
            label: 'Disable Tenant',
            values: {
              disabled: 'Disabled',
              enabled: 'Enabled',
            },
          },
          licenseStarted: {
            label: 'License Started',
          },
          locale: {
            label: 'Locale',
            placeholder: 'Locale',
            values: {
              de: 'German',
              en: 'English',
            },
          },
          nrOfAllowedCompaniesByPricingPlan: {
            label: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.placeholder)',
          },
          nrOfAllowedUsersByPricingPlan: {
            label: '$t(common.fields.nrOfAllowedUsersByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedUsersByPricingPlan.placeholder)',
          },
          rootAdminEmail: {
            label: 'Email of Root-Admin',
            placeholder: 'Email of Root-Admin',
          },
          rootAdminFirstName: {
            label: 'Root-Admin First Name',
            placeholder: 'Root-Admin First Name',
          },
          rootAdminLastName: {
            label: 'Root-Admin Last Name',
            placeholder: 'Root-Admin Last Name',
          },
          tenantName: {
            label: 'Company',
            placeholder: 'Company',
          },
        },
        loadingTenantsActions: "Loading tenant's actions",
        logBookModalTitle: 'Tenant\'s actions of the "{{tenantName}}"',
        stateInvitation: {
          ACTIVE: 'Active',
          INACTIVE: 'Inactive',
          INVITATION_SEND: 'Invitation sent',
        },
        title: 'Manage Tenants',
      },
    },
    user: {
      CompareToGlobal: {
        changed: {
          drawer: {
            global: 'Global version',
            independent: 'Independent version',
            label: 'The independent configuration differs from global configuration',
            notSet: 'n/a',
          },
          label: 'The independent configuration differs from global configuration. Please click to compare',
        },
        deleted: {
          label: 'This element was removed from global configuration',
        },
        new: {
          label: 'This element was added to global configuration',
          staticItems: 'Static items added to global (and not available here)',
        },
      },
      MigratedHelperWidget: {
        howUseButton: 'Learn what this means',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Learn what this means',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/667201315',
        },
      },
      PrintShoppingCartPage: {
        compareTitle:
          'Global document-template was updated AFTER {{createdAt}}-date of previous valse-revision of independent SCR. Please be aware that elements might be differ.',
        download: 'Download',
        downloadAll: 'Download all',
        downloadDocuments: 'Download Documents',
        fields: {
          actions: '$t(common.fields.actions.label)',
          name: '$t(common.fields.name.label)',
        },
        print: 'Print',
        stateLoading: {
          download: 'Download Pdf',
          generate: 'Generating Pdf',
          save: 'Saving Pdf',
        },
        successfulMessage: 'Entry has been stored',
      },
      SendShoppingCartPage: {
        loadingMessage: 'Sending email...',
        successfulMessage: 'E-Mail successfully sent',
      },
      Setting: {
        AccountSetting: {
          tabs: {
            main: {
              cancelChangeEmail: '$t(common.cancel)',
              changeEmail: 'Change Email',
              fields: {
                email: 'Email',
                firstName: 'First name',
                lastName: 'Last name',
                newEmail: 'New email',
                otp: 'One Time Password',
                phone: 'Phone',
                position: 'Position',
              },
              saveEmail: 'Save Email',
              title: 'Main',
            },
            password: {
              errors: {
                currentPassworWrong: 'Password is incorrect',
                noMatch: 'Passwords must match',
              },
              fields: {
                currentPassword: 'Current password',
                newPassword: 'New password',
                otp: 'One Time Password',
                passwordConfirmation: 'Password confirmation',
              },
              savePassword: 'Save password',
              title: 'Password',
            },
            variables: {
              title: 'Variables',
            },
          },
          title: 'Edit Account',
        },
      },
      ShoppingCart: {
        Buttons: {
          email: 'Send E-Mail',
          errors: {
            formHasErrors: 'There are validation-errors in the form. Please correct',
            noCompanyError: 'Please add company to enable those buttons',
            noContactsError: 'Please add contact to enable those buttons',
            noItemsSelectedError: 'Please select items to enable those buttons',
            noRecipientsWithEmail: 'Please set contact with e-mail address to be able to send e-mail',
          },
          html: 'Preview of delivery slip HTML',
          pdf: 'Preview of delivery slip PDF',
          pdfGenerating: 'PDF is generating. Please wait and retry.',
          pdfNotFound: 'PDF not found. Please contact the support and we will solve the problem.',
          print: 'Print PDF',
          printModal: {
            title: 'Entry will be stored. Ok?',
          },
          store: 'Store to db',
          submitRevisionModal: {
            cancel: '$t(common.cancel)',
            ok: '$t(common.ok)',
            printTitle: 'Do you want to print the shopping cart as PDF?',
            saveTitle: 'Do you want to store the shopping cart to db?',
            sendTitle: 'Do you want send the e-mail?',
          },
        },
        Category: {
          Discount: {
            label: 'Select Discount',
          },
          InputField: {
            mustBeANumberMessage: '{{name}} must be a number',
            requiredMessage: 'Please enter value in {{name}}',
          },
          Item: {
            bookmarkTooltip: 'Mark as strategic recommendation (appears as an optional service)',
            customPriceDrawer: {
              CustomPriceHelperWidget: {
                howUseButton: 'Set prices',
                modalInfo: {
                  helpText: '',
                  imageUrl: '',
                  title: 'Set prices',
                  videoCaption: 'Learn how to set prices',
                  videoUrl: 'https://vimeo.com/693703597',
                },
              },
              cancelConfirmation: 'Your changes will be lost. Continue?',
              deleteCustomPriceConfirmation: 'Are you sure you want to remove the custom price?',
              fields: {
                customPrice: {
                  label: 'Final custom price',
                  tooltip:
                    'If you set an individual price, you will overwrite the calculated price. Please note that active discounts will still be applied to the individual price.',
                },
                defaultValue: {
                  label: '$t(common.fields.defaultValue.label)',
                },
                internalInputField: {
                  errors: {
                    infinity: 'Division by 0 occurred with values of internal input-field(s). Please fix',
                    max: '{{name}} must be ≤ {{value}}',
                    min: '{{name}} must be ≥ {{value}}',
                  },
                  label: 'Customize Internal Inputs',
                  tooltip:
                    'Here you see the list of internal input-fields used in pricing-formula of this item. Here you can customize their value. You can later overwrite the value by entering a "Final custom price".',
                },
                internalNoteToTeam: {
                  label: 'Internal note to team',
                  tooltip:
                    'Here you can add an internal note to the team. What is specific to the customer for this item? The note will be invisible to the customer and only appear in the view-shoppingcart-screen.',
                },
                newPrice: {
                  label: 'New price by customized internal inputs',
                  tooltip:
                    'This price is calculated by pricingFormula and the given parameters (including the internal input values you entered above). If you have set a rounding-preference (globally or for this item) then the rounding is applied here.',
                },
                officialReasonText: {
                  label: 'Official reason text',
                },
                originalPrice: {
                  label: 'Original price',
                  tooltip:
                    'The original price is the price calculated by pricingFormula with the given parameters. If the item does NOT have internal input fields and you have set a rounding-preference (globally or for this item), then the rounding is applied here.',
                },
                showCalculatedPrice: {
                  disabledTooltip:
                    'In monthly fixed mode price is always hidden in documents for monthly and yearly items.',
                  label: 'Show original price?',
                  tooltip:
                    'If show original price is on, then it will be shown next to custom price in service-details block.',
                },
              },
              removeCustomPrice: 'Remove',
              title: 'Edit pricing',
            },
            displayInternalNoteToTeam: 'From user:',
            displayNotesToEmployee: 'From catalogue:',
            displayOfficialReasonText: 'Note:',
            errors: {
              infinityPrice:
                'The price of the item is invalid. Please, check that input field values are entered correctly',
            },
            graduatedScaleMode: 'Scale function is working in graduated-mode',
            infoTextModal: {
              benefits: '$t(common.fields.benefits.label)',
              collapsePanelTitle: 'Show more details',
              descriptionForContract: 'The following services are included',
              notesForFeeAgreement: '$t(common.fields.notesForFeeAgreement.label)',
              notesToEmployee: '$t(common.fields.notesToEmployee.label)',
              pleaseNote: '$t(common.fields.pleaseNote.label)',
            },
            minPrice: 'Minimal Price: {{minPrice}}',
            paymentIntervalAndHighlightBindingness: 'Payment interval: {{paymentIntervalAndHighlightBindingness}}',
            pricingFormula: 'Pricing formula: ',
            referenceError: {
              referenceIsSelectedButInvalid:
                'Please have a look at {{itemName}}. It is invalid and the price calculation depends on it. Please solve.',
              referenceNotSelectedAndInvalid:
                'Please have a look at {{itemName}}. Select it and fill in the input-values.',
              referenceNotSelectedButValid:
                'Please have a look at {{itemName}}. The price-calculation of this item depends on it and it needs to be selected',
            },
            requiredFieldsAlertText: 'Please fill in the marked fields',
            roundPrice: 'The value of the pricing-formula is rounded up in steps of {{step}}',
            selectTooltip: 'Offer and include in the scope of the order',
          },
          StaticItems: 'Show static items',
          Total: {
            label: 'Category total price preview',
            vatTypeLabel: 'Show $t(common.vatType.{{vatType}})',
          },
          ValidationLabels: {
            discount: 'Discount',
            startOfService: 'Start of service',
          },
          showStaticItems: 'Show static items',
          startOfService: 'start of service',
          startOfServiceRequired: 'Start of service is required',
        },
        CompanyType: {
          confirmChange:
            'Are you sure to change selecting? If you change then existing selections will be lost. Change selection?',
          inputs: {
            companyType: {
              label: 'What is your company type?',
              placeholder: 'Select company type',
            },
            selfBooker: 'Are you a self-booker?',
          },
          noTypes: 'No types',
          title: 'Company Type',
        },
        ContactData: {
          buttons: {
            addCompany: '$t(common.company.addCompany)',
            addContact: '$t(common.contact.addContact)',
            changeCompany: '$t(common.company.changeCompany)',
          },
          company: {
            addExistingCompanyTab: 'Add existing company',
            createNewCompanyTab: 'Create new company',
            duplicatedErrorMessage: {
              name: 'Company with the same name already exists',
            },
            empty: 'Add company',
            inputs: {
              city: {
                label: 'City',
                placeholder: 'City',
              },
              cityCode: {
                label: 'Citycode',
                placeholder: 'Citycode',
              },
              commercialObject: {
                label: 'Commercial object',
                placeholder: 'Commercial object',
              },
              company: {
                label: 'Company',
                placeholder: 'Search existing company-name',
              },
              country: {
                label: 'Country',
                placeholder: 'Country',
              },
              houseNumber: {
                label: 'House number',
                placeholder: 'Housenr',
              },
              identifier: {
                label: 'Company identifier',
                placeholder: 'Company identifier',
              },
              name: {
                company: {
                  label: 'Company name',
                  placeholder: 'Company name',
                },
                individual: {
                  label: 'Account name',
                  placeholder: 'Account name',
                },
              },
              street: {
                label: 'Street',
                placeholder: 'Street',
              },
              type: {
                company: 'Company',
                individual: 'Single Person',
              },
            },
            noCountries: 'No countries',
            notFoundContent: {
              create: 'Create a company',
              noFound: 'No companies',
            },
          },
          contact: {
            addExistingContactTab: 'Add existing contact',
            createNewContactTab: 'Create new contact',
            duplicatedContactAlert: {
              editMessage:
                'Please note that the email that you have entered is used by another contact. But you still can proceed with it and the email will be assigned to this contact too',
              message:
                'Continue if you want to create a new contact with the same email and enrich it with different information. If not, then please',
              useExistingContactButton: 'use and assign existing contact instead',
            },
            duplicatedErrorMessage: {
              contact: 'Contact is already imported',
              email: 'Contact with the same email already exists',
            },
            empty: 'Add contact',
            inputs: {
              contact: {
                label: 'Contact',
                placeholder: 'Search existing contact',
              },
              email: {
                label: 'E-mail Address',
                placeholder: 'E-mail Address',
              },
              firstName: {
                label: 'First name',
                placeholder: 'First name',
              },
              fullSalutation: {
                label: 'Full salutation',
                placeholder: 'Full salutation',
              },
              lastName: {
                label: 'Last name',
                placeholder: 'Last name',
              },
              phoneNumber: {
                label: 'Phone-Number business',
                placeholder: 'Phone-Number business',
              },
              phoneNumberAlternative: {
                label: 'Phone-Number alternative',
                placeholder: 'Phone-Number alternative',
              },
              position: {
                label: 'Position',
                placeholder: 'Position',
              },
              salutation: {
                label: 'Gender',
                options: {
                  Mr: 'Mr',
                  Mrs: 'Mrs',
                  Ms: 'Ms',
                },
                placeholder: 'Mr',
              },
            },
            notFoundContent: {
              create: 'Create a contact',
              noFound: 'No contacts',
            },
            title: 'Contacts',
          },
          fetchFromGlobal: 'Update data',
          label: 'Contact Data',
          modal: {
            cancel: '$t(common.cancel)',
            doNotReplace: "$t(common.no), don't replace",
            ok: '$t(common.ok)',
            replace: '$t(common.yes), replace',
            titles: {
              addCompany: '$t(common.company.addCompany)',
              addContact: '$t(common.contact.addContact)',
              changeCompany: '$t(common.company.changeCompany)',
              changeCompanyContacts:
                'Do you also want to replace set contacts in the current shopping-cart with the default contacts of the company?',
              deleteContact: '$t(common.contact.deleteContact)',
              editCompany: '$t(common.company.editCompany)',
              editContact: '$t(common.contact.editContact)',
            },
          },
          oldData:
            'Data is historic and outdated. Please click the "Update data" button to refresh data for editing company or contact data',
          prefillContact: {
            button: 'Prefill contact with suggested values',
            columns: {
              fieldTitle: '$t(common.columns.field)',
              fields: {
                commercialObject: 'Commercial Object',
                importAcademicTitle: 'Import Academic Title',
                importCompanyType: 'Import Company Type',
                importEmail: 'Import Email',
                importSalutation: 'Import Salutation',
              },
              valueTitle: '$t(common.columns.value)',
            },
            contactCollapse: 'Show hidden import fields',
          },
        },
        Discounts: {
          activeDiscountsLabel: 'Active Discounts list',
          activeDiscountsListLabel: 'The following discounts are active:',
          activeForCategory:
            '"{{discountName}}" is for "{{categoryName}}" ({{discountValue}} {{discountUnitName}} discount)',
        },
        DocumentTypes: {
          activeTooltip: 'You have set a custom text for {{blockName}}. Click to edit',
          blocks: {
            body: 'Personalized text',
          },
          customPersonalizedText: '{{blockName}}: custom text',
          inactiveTooltip: 'Click to enter a custom {{blockName}}-text',
          label: 'Select Document-types to generate',
          projectLabel: 'Manage project',
          sections: {
            global: 'Global configuration',
            independent: 'Independent configuration',
          },
          typesSelectLabel: 'Which documents should be generated?',
        },
        DocumentTypesTable: {
          actionsHeader: 'Actions',
          nameHeader: 'Document-type',
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '/cdn/page-previews/shopping-cart.png',
            title: 'How to use shopping cart',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        NextActions: {
          buttons: {
            backToOffer: 'Go to offer (edit mode will store changes as revision)',
            goToDashBoard: 'Go to Dashboard',
            goToView: 'Go to the view of the shopping cart',
          },
          title: 'Next Actions',
        },
        SendEmail: {
          attachments: 'Attachments',
          bcc: 'BCC',
          changeEmailTemplatesConfirmation: 'Sure to load a different template? This will overwrite edits. continue?',
          fields: {
            body: {
              label: 'Body',
            },
            emailTemplates: {
              label: 'Email template',
            },
            recipients: {
              label: 'Recipients',
            },
            signature: {
              label: 'Signature',
            },
            subject: {
              label: 'Subject',
            },
          },
          ok: 'Send E-Mail',
          preview: 'Preview',
          success: 'Email has been sent',
          successfulMessage: 'Entry has been stored',
          title: 'Send E-Mail',
          unablePlaceholdersInBody: '{{placeholders}} will  be available after saving the shopping cart',
        },
        TotalPricing: {
          TotalTable: {
            headers: {
              VAT: '$t(common.vatType.{{vatType}}) ({{vatValue}} %)',
              interval: 'Interval',
              notes: 'Notes',
              price: 'Price (without vat)',
              priceWithVAT: 'Total price (including vat)',
            },
          },
          error: 'Please fix validation errors above to see price',
          fixedMonthlyFeeLine_monthly: '{{count}} monthly item is selected and is included in the monthly fixed price',
          fixedMonthlyFeeLine_monthly_plural:
            '{{count}} monthly items are selected and are included in the monthly fixed price',
          fixedMonthlyFeeLine_yearly: '{{count}} yearly item is selected and is included in the monthly fixed price',
          fixedMonthlyFeeLine_yearly_plural:
            '{{count}} yearly items are selected and are included in the monthly fixed price',
          fixedMonthlyFeeTypeHint:
            'Prices for selected Monthly and Yearly items are not shown here. They are included in the "fixed monthly price" listed below in "Final Price Preview" section',
          label: 'Total Pricing',
        },
        buttonLabel: 'Shopping Cart Options',
        categoriesCardTitle: 'Select your categories and items',
        compareOptions: {
          global: 'Compare to global configuration',
          previous: '',
        },
        emptyCategoryListMessage: 'There are no categories yet.',
        errorList: {
          scroll: 'Scroll to the right position',
          title: 'Validation errors exist.<br/>Please click below to scroll to the position',
        },
        globalModeAlert: {
          GlobalModeHelperWidget: {
            howUseButton: 'Learn more',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Learn more',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/713982308',
            },
          },
          title:
            'You have pressed "edit based on global configuration". Our system tries to map all previous independent configuration (categories, items, variables and documents) from {{date}} to the latest global configuration. Please carefully check each position. Once you press "store" the SCR will be stored in independent configuration again with the latest configuration.',
        },
        inputs: {
          companyId: {
            label: 'Company',
          },
          companyTypeId: {
            label: 'Company type',
          },
          contacts: {
            label: 'Contacts',
          },
          email: {
            label: 'E-Mail-Address',
          },
          meetingAt: {
            errorLabel: 'Meeting Date',
            label: 'When was the meeting?',
          },
          name: {
            default: 'Project initiated at {{date}}',
            label: '$t(common.fields.projectName.label)',
          },
          startOfContract: {
            label: 'Start Of Contract',
          },
        },
        options: {
          cancel: '$t(common.cancel)',
          debugMode: {
            label: 'debug mode',
            tooltip:
              'Show the price configuration directly below the service and see how the prices of the individual areas are calculated.',
          },
          feeType: {
            fixedMonthlyFee: 'Fixed monthly fee',
            label: 'Pricing mode',
            standardFee: 'Standard fee',
            tooltip:
              'Calculation mode in which KanzleiPilot calculates the prices. In standard mode everything is the same: one-time, monthly and annual services are displayed transparently. In the entrepreneur fee mode, monthly and annual items are combined at a monthly price and their individual prices are hidden with the note "The service is included in the entrepreneur fee". One-off services are also shown transparently in the company fee.',
          },
          hiddenNote: {
            placeholder: 'Note area',
            title: 'Hidden note area',
          },
          hideHiddenNote: 'Hide hidden note',
          ok: '$t(common.ok)',
          showDiscounts: {
            disabled: 'You can NOT hide discounts because at least 1 discount is active in the shopping cart.',
            label: 'show discounts',
            tooltip:
              "Should discounts be displayed? We recommend you: Switch the display of discounts in the standard OFF and focus on making the client's use clear (see “Settings > Shopping Cart > Shopping cart preferences”).",
          },
          showHiddenNote: 'Show hidden note',
          showPrices: {
            label: 'show prices (of category and items, including discounted prices)',
            tooltip:
              'Should prices be displayed when opening the menu card? We recommend: Switch the price display in the standard OFF (see “Settings > Shopping Cart > Shopping Cart Preferences”).',
          },
          showVat: {
            label: 'show VAT',
            tooltip:
              'If "show VAT" is on, then prices will be shown with vat - in the shopping cart screen itself and also in the generated PDF (Your investment, service details and bookmarked items section)',
          },
          switch: {
            off: '$t(common.off)',
            on: '$t(common.on)',
          },
          title: 'Options',
          vatType: {
            company: '$t(common.vatType.company)',
            label: 'VAT Wording',
            personal: '$t(common.vatType.personal)',
          },
        },
        somethingChangedWhenOpenedEdit: 'Something has changed in the cart, displaying new data',
        titleAlt: 'Tenant Logo',
        validityContract: {
          thisMonth: 'This Month',
          title: 'When does the contract start?',
        },
      },
      lockedIcon: {
        LockHelperWidget: {
          howUseButton: 'Learn more',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Learn more',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        confirmationTitle: 'Data you entered manually is lost. We try to map to global configuration. Continue?',
        editButton: 'Edit based on global configuration',
        title: 'SCR was stored with independent configuration at {{date}}.',
      },
    },
    validation: {
      simplifiedUrl: 'Please enter a valid URL',
    },
    verify: {
      AcceptInvitation: {
        title: 'Accept Invitation',
      },
    },
    viewer: {
      ShoppingCartView: {
        BookedServices: {
          bookmarkedItems: 'Bookmarked items',
          categoryTotals: 'Category Totals',
          items: 'Selected items',
          staticItems: 'Static Items',
        },
        CompareToGlobal: {
          title: 'Compare to global configuration',
          usedVariablesTitle: 'Used variables',
        },
        CreatedDocuments: {
          addDocument: 'Add more documents based on existing shopping-cart',
          addDocumentsModal: {
            placeholder: 'Select existing document templates',
            title: 'Add document templates',
          },
          disabledAddingTooltip: 'You can NOT add document template because its core data has changed.',
          downloadAllPDFs: 'Download all PDFs',
          noAvailableDocumentsTooltip: 'There is no document template that can be added to the shopping cart.',
          title: 'Created Documents',
          viewPDF: 'View PDF',
        },
        GeneralProjectData: {
          generalProjectData: 'Project',
          projectName: 'Project name',
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'How to use view shopping cart',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/163721649',
          },
        },
        HistoricRevisions: {
          title: 'Historic Revision of this Shopping Cart Entry',
        },
        Overview: {
          fields: {
            company: 'Company',
            companyIdentifier: 'Company identifier',
            contacts: 'Contacts',
            createdAt: 'Created at',
            createdBy: 'Created by',
            meetingAt: 'Meeting at',
            monthlyFeeMode: 'Monthly Fee Mode',
            monthlyFixedFee: 'Fixed Monthly Price',
            number: 'Order Card Entry Number',
            startOfContract: 'Start Of Contract',
            status: 'Status',
            valueMonthly: 'Monthly Value',
            valueOneOff: 'One off Value',
            valueYearly: 'Yearly Value',
          },
          title: 'Shopping Cart overview',
        },
        ShoppingCartHistoricItems: {
          ShoppingCart: {
            changeStatus: 'Status changed from {{oldStatusName}} to {{newStatusName}}',
            created: 'Shopping cart{{revision}} created',
            deleted: 'Shopping cart{{revision}} deleted',
            sentByEmail: 'Shopping cart sent by email',
          },
          revision: ' revision',
          sendingError: 'Error appeared sending email',
          title: 'Shopping Cart history items',
        },
        columns: {
          actions: '$t(common.columns.actions)',
          category: '$t(common.columns.category)',
          createdAt: '$t(common.columns.createdAt)',
          createdBy: '$t(common.columns.createdBy)',
          data: '$t(common.columns.data)',
          document: '$t(common.columns.document)',
          inputFields: 'Input Values',
          inputParameters: 'Input-Parameters (use)',
          internalNote: '$t(common.columns.internalNote)',
          interval: '$t(common.columns.interval)',
          item: '$t(common.columns.item)',
          price: '$t(common.columns.price)',
          selectedCategories: 'Selected Categories',
          selectedItems: 'Selected Items',
          value: '$t(common.columns.value)',
          valueMonthly: '$t(common.columns.valueMonthly)',
          valueOneOff: '$t(common.columns.valueOneOff)',
          valueYearly: '$t(common.columns.valueYearly)',
        },
        customInternalValue: 'custom',
        editShoppingCart: 'Edit shopping cart',
        feeMode: {
          fixedMonthly: '$t(onboarding.pricingMode.fixedMonthlyModeTitle)',
          standard: '$t(onboarding.pricingMode.standardModeTitle)',
        },
        menu: {
          delete: 'Delete the shopping cart',
          disableDuplicate: 'You can NOT duplicate this shopping-cart because its core data has changed.',
          duplicate: 'Duplicate shopping-cart',
        },
        migratedAlert:
          'This shopping cart was migrated to independent mode on {{migrationDate}}. This means that the independent configuration might contain other data as you have initially agreed on with the company. Please the PDFs attached to see original agreement and manually check.',
        noRecipientsWithEmail: 'There are no recipients',
        rows: {
          calculationMode: '$t(common.fields.calculationMode.label)',
          highlightBindingness: '$t(common.fields.highlightBindingness.label)',
          minPrice: '$t(common.fields.minPrice.label)',
          name: '$t(common.fields.name.label)',
          paymentInterval: '$t(common.fields.paymentInterval.label)',
          pricingFormula: '$t(common.fields.pricingFormula.label)',
          scales: 'Scales',
        },
        sendEmailButton: 'Send E-Mail',
        sendEmailConfirmationTitle: 'Are you sure you want to send the email?',
        subtitle: 'created at {{createdAt}} by {{createdBy}}',
        title: 'View Shopping-Cart for {{companyName}}',
      },
    },
  },
};
