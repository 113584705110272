import { filter, find, isObject, map, merge, omit, pick, flattenDeep } from 'lodash';
import { Calc, Cart } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useFormikContext } from 'formik';
import { useFunctionToRefCB } from 'memo';
import { VATType } from 'constants/shoppingCart';
import { Trans } from 'react-i18next';
import { InputFieldType } from 'constants/inputField';

export const getDiscountForCategory = ({ category, values }) =>
  values[`${category._id}_discount`] && find(category.discounts, { _id: values[`${category._id}_discount`] });

export const calcItem = ({
  values,
  category,
  item,
  calcCategoryTotalById,
  allCategoryIds,
  variables_a /* constants */,
}) => {
  const appliedDiscount = getDiscountForCategory({ category, values });
  let price;
  const variablesFromInputFields = Calc.inputFieldsToVariables(allInputFieldsInCategories([category]));
  const variables_a_ = merge(...variables_a.map(({ _id, value }) => ({ [_id]: value })));
  try {
    const customPrice =
      typeof values[`${item._id}_customPrice`] === 'number' ? values[`${item._id}_customPrice`] : null;

    const variables__ = {
      ...values,
      ...variablesFromInputFields,
      ...variables_a_,
    };
    if (Number.isFinite(customPrice))
      item.internalInputFields.forEach((inputField) => {
        variables__[inputField._id] = inputField.value;
      });
    const calcData = {
      documents: [...category.inputFieldDocuments, ...category.items],
      document: omit(item, ['customPrice']),
      variables: variables__,
      calcCategoryTotalById,
      allCategoryIds,
    };
    const { value: priceBeforeDiscount } = Calc.calcItem(calcData);

    const showCalculatedPrice = values[`${item._id}_showCalculatedPrice`];

    const discountedCustomPrice = Calc.applyDiscountToValue({ value: customPrice, discount: appliedDiscount }) || 0;
    const discountedValue = Calc.applyDiscountToValue({ value: priceBeforeDiscount, discount: appliedDiscount });
    const value = {
      priceBeforeDiscount,
      discountedValue,
    };
    price = {
      value,
      customPrice,
      discountedCustomPrice,
      showCalculatedPrice,
      calcData,
    };
    price.isPrice = Number.isFinite(price?.value?.priceBeforeDiscount);
  } catch (e) {
    price = {};
    console.debug(item.name, e);
  }
  return price;
};

const checkIsInputFieldValue = (e) => Number.isFinite(e) || typeof e === 'string';

const isInputFieldValue = ({ inputField, value, allInputFieldsOptions }) => {
  const isComboboxField = InputFieldType.COMBO === inputField.type;
  return (
    (!isComboboxField && Number.isFinite(value)) || (isComboboxField && !!find(allInputFieldsOptions, { _id: value }))
  );
};

export const formikToResponse = ({ categories, values }) => {
  const discounts = categories.map((c) => c.discounts).flat();

  const items = categories
    .map((c) => c.items)
    .flat()
    .filter((item) => values[item._id] || values[`${item._id}_bookmark`])
    .map((item) => ({
      ...item,
      customPrice: values[`${item._id}_customPrice`],
    }));

  const inputFields = allInputFieldsInCategories(categories);
  const cart = categories
    .filter((c) => c.items?.length)
    .map((c) => ({
      _id: c._id,
      discountId: values[`${c._id}_discount`] || null,
      // TODO: remove itemIds
      itemIds: c.items.filter((item) => values[item._id]).map((i) => i._id),
      bookmarkedItems: c.items
        .filter((item) => values[`${item._id}_bookmark`])
        .map(({ _id, calculationMode, highlightBindingness }) => ({
          _id,
          calculationMode,
          customPrice: values[`${_id}_customPrice`],
          highlightBindingness,
          internalNoteToTeam: values[`${_id}_internalNoteToTeam`],
          officialReasonText: values[`${_id}_officialReasonText`],
          showCalculatedPrice: values[`${_id}_showCalculatedPrice`],
        })),
      items: c.items
        .filter((item) => values[item._id])
        .map(({ _id, calculationMode, highlightBindingness }) => ({
          _id,
          calculationMode,
          customPrice: values[`${_id}_customPrice`],
          highlightBindingness,
          internalNoteToTeam: values[`${_id}_internalNoteToTeam`],
          officialReasonText: values[`${_id}_officialReasonText`],
          showCalculatedPrice: values[`${_id}_showCalculatedPrice`],
        })),
      inputFields: filter(inputFields, { categoryId: c._id })
        .map((inputField) => {
          const { _id, type } = inputField;
          const data = {
            _id,
            value: values[_id],
          };
          if (type === InputFieldType.INTERNAL) data.defaultValue = inputField.value;
          if (type === InputFieldType.INTERNAL && !values[`${_id}_isCustom`]) data.value = undefined;
          return data;
        })
        .filter((i) => checkIsInputFieldValue(i.value) || Number.isFinite(i.defaultValue)),
      startOfService: values[`${c._id}_startOfService`],
    }));
  return { cart, inputFields, items, discounts, categories };
};

export const responseToFormik = ({ cart, prevValues: _prevValues, discounts, allInputFields, isIndependent }) => {
  const prevValues = isObject(_prevValues) ? _prevValues : {};
  let values = {};
  const {
    categories,
    company,
    companyTypeId,
    contacts,
    documentTemplateBlocks,
    documentTemplates,
    feeType,
    hiddenNote,
    isSomethingChanged,
    meetingAt,
    name,
    pdf,
    showDiscounts,
    showPrices,
    showVat,
    startOfContract,
    initializationConfigDate,
    initializationConfigDateForCompany,
    vatType,
  } = cart;
  const produceValue = (v) =>
    !isIndependent && v ? omit(v, ['initializationConfigDate', 'initializationConfigDateForCompany']) : v;
  values = produceValue({
    companyTypeId: companyTypeId || undefined,
    feeType: feeType || undefined,
    hiddenNote: hiddenNote || undefined,
    isSomethingChanged: isSomethingChanged || undefined,
    meetingAt: meetingAt || undefined,
    name: name || undefined,
    pdf: pdf || undefined,
    showDiscounts: showDiscounts || undefined,
    showPrices: showPrices || undefined,
    showVat: typeof showVat === 'boolean' ? showVat : values.companyTypeId === 'privatePerson',
    startOfContract: startOfContract || undefined,
    vatType: vatType || (values.companyTypeId === 'privatePerson' ? VATType.PERSONAL : VATType.COMPANY),
    companyId: company?._id || undefined,
    contacts: contacts || undefined,
    initializationConfigDate: initializationConfigDate || undefined,
    initializationConfigDateForCompany: initializationConfigDateForCompany || undefined,
    documentTemplateBlocks:
      documentTemplateBlocks?.reduce((acc, block) => {
        if (!block) return acc;
        acc[block._id] = { props: block.props };
        return acc;
      }, {}) || undefined,
  });
  values = { ...values, ...pick(prevValues, Object.keys(values)) };

  values.documentTemplates = map(
    (prevValues.documentTemplates ? prevValues.documentTemplates.map(JSON.parse) : undefined) ?? documentTemplates,
    (t) => JSON.stringify(produceValue({ _id: t._id, initializationConfigDate: t.initializationConfigDate })),
  );
  const allInputFieldsOptions = allInputFields
    .map((inputField) => (inputField.type === 'combo' ? inputField.options : []))
    .flat();
  categories.forEach((category) => {
    const discountId = prevValues[`${category._id}_discount`] ?? category.discountId;
    const startOfService = prevValues[`${category._id}_startOfService`] ?? category.startOfService;
    if (category.discountId && discounts.find((discount) => discount._id === discountId))
      values[`${category._id}_discount`] = discountId;
    else values[`${category._id}_discount`] = null;
    category.startOfService && (values[`${category._id}_startOfService`] = startOfService);
    category.inputFields.forEach((inputField) => {
      const inputFieldFromCatalogue = find(allInputFields, { _id: inputField._id });
      if (!inputFieldFromCatalogue) return;
      const isInternalInputField = InputFieldType.INTERNAL === inputFieldFromCatalogue.type;
      const value =
        (isInternalInputField && !prevValues[`${inputField._id}_isCustom`] ? undefined : prevValues[inputField._id]) ??
        inputField.value;
      if (isInputFieldValue({ allInputFieldsOptions, inputField: inputFieldFromCatalogue, value })) {
        values[inputField._id] = value;
        if (isInternalInputField) values[`${inputField._id}_isCustom`] = value !== inputField.defaultValue;
      }
    });
    [category.items, category.bookmarkedItems].forEach((items, isBookmarked) =>
      items.forEach(({ _id, customPrice, officialReasonText, internalNoteToTeam, showCalculatedPrice } = {}) => {
        values[_id + (isBookmarked ? `_bookmark` : '')] = prevValues[_id + (isBookmarked ? `_bookmark` : '')] ?? true;

        values[`${_id}_customPrice`] = prevValues[`${_id}_customPrice`] ?? customPrice ?? null;
        values[`${_id}_internalNoteToTeam`] = prevValues[`${_id}_internalNoteToTeam`] ?? internalNoteToTeam ?? null;
        values[`${_id}_officialReasonText`] = prevValues[`${_id}_officialReasonText`] ?? officialReasonText ?? null;
        values[`${_id}_showCalculatedPrice`] = prevValues[`${_id}_showCalculatedPrice`] ?? showCalculatedPrice ?? null;
      }),
    );
  });
  return values;
};

export const errorWithTouched = ({ error, formikRef, fieldName = 'pricingFormula' }) => {
  formikRef.current.setFieldTouched(fieldName, true, false);
  return error;
};

export const shouldPricePreviewBeVisibleForCurrentFeeType = ({ item, feeType }) => {
  if (Cart.isCurrentFeeTypeStandard(feeType)) return false;
  const should = item.paymentInterval === 'oneOff';
  return !should && <Trans i18nKey="sharedPackage.fixedMonthlyFeeHint" values={item} />;
};
export const allInputFieldsInCategories = (categories) => {
  return flattenDeep(
    categories.map((category) => [category.inputFields, category.items.map((input) => input.inputFields)]),
  ).filter(Boolean);
};
export const useDeselectItem = () => {
  const { values, setValues, setFieldValueAndTouched } = useFormikContext();
  return useFunctionToRefCB(({ itemId, isBookmark }) => {
    const currentItem = itemId + (isBookmark ? '_bookmark' : '');
    const name = itemId + (isBookmark ? '' : '_bookmark');
    if (!values[currentItem] && values[name]) setValues(() => ({ ...values, [name]: false, [currentItem]: true }));
    else setFieldValueAndTouched(currentItem, !values[currentItem]);
  });
};
