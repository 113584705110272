import { BrowserRouter as Router, useNavigate, useMatch, Routes, Route } from 'react-router-dom';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { ConfirmAlertContainer } from '@uplab/react-confirm-alert';
import { LibraryProvider } from 'contexts/LibraryContext';
import { useCallback, useEffect, useState } from 'react';
import AppRouter from './AppRouter';
import VerifyRouter from './VerifyRouter';
import AuthRouter from './AuthRouter';
import useCurrentUser from '../hooks/auth/useCurrentUser';
import routePaths from './route-paths';
import CustomFrontendRouter from './CustomFrontendRouter';

const WindowHistory = () => {
  const navigate = useNavigate();
  window.currentNavigate = navigate;
  return null;
};

const CommonLibraryContext = ({ children }) => {
  const isLibrary = !![
    routePaths.commonLibraryConfiguration,
    routePaths.commonLibraryDocumentTemplateConfiguration,
    routePaths.commonLibraryDocumentTemplateList,
    routePaths.commonLibraryEmailTemplates,
    routePaths.commonLibraryItemConfiguration,
  ]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    .map((path) => useMatch(path))
    .filter(Boolean).length;
  return <LibraryProvider value={{ isLibrary }}>{children}</LibraryProvider>;
};

const useLoadTheme = () => {
  const [user, loading] = useCurrentUser();
  const [themeLoading, setThemeLoading] = useState(true);
  const loadTheme = useCallback(async () => {
    let element = document.head.querySelector('#antdStyles');
    if (!element) {
      element = document.createElement('style');
      element.id = 'antdStyles';
      document.head.appendChild(element);
    }
    const css = await fetch('/css').then((resp) => resp.text());
    element.innerHTML = css;
  }, []);
  const userId = user?._id;
  useEffect(() => {
    if (loading) return;
    userId;
    setThemeLoading(true);
    loadTheme().then(() => setThemeLoading(false));
  }, [loadTheme, loading, userId]);
  return [themeLoading];
};

const ChildRouter = ({ user }) => {
  const match = useMatch('/view/:id');
  if (match) return <CustomFrontendRouter match={match} />;
  return user ? <AppRouter /> : <AuthRouter />;
};

const RootRouter = ({ children }) => {
  const [user, loading] = useCurrentUser();
  const [loadingTheme] = useLoadTheme();

  if (loading || loadingTheme) {
    return <LoadingOverlay />;
  }
  // remove location state on reload
  window.history.replaceState({}, document.title);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <CommonLibraryContext>
        <WindowHistory />
        {children}
        <Routes>
          <Route path="verify/*" element={<VerifyRouter />} />
          <Route path="*" element={<ChildRouter user={user} />} />
        </Routes>
        <ConfirmAlertContainer />
      </CommonLibraryContext>
    </Router>
  );
};

export default RootRouter;
