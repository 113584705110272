import { useCachedQuery } from 'graphql/utils';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/exports';
import { Alert, Col, Drawer, Row } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { useFormikContext } from 'formik';
import {
  adminEmailTemplatesQuery,
  getPlaceholderObjectByIdQuery,
  getPlaceholderObjectQuery,
  userBCCQuery,
} from 'graphql/queries';
import i18n from 'i18n';
import { memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import inputConfirmModal from 'utils/inputConfirmModal';
import { intersectionBy, uniqBy } from 'lodash';
import Card from 'components/common/Card';
import { MailOutlined } from '@ant-design/icons';
import { useDocumentTemplateList } from 'graphql/hooks';
import cn from 'classnames';
import { FaFile } from 'react-icons/fa';
import { RecipientsContext } from 'components/user/shoppingCart/context';
import { GrMail } from 'react-icons/gr';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import classes from './SendEmail.module.less';
import documentTypesSelectClasses from '../DocumentTypesSelect/DocumentTypesSelect.module.less';
import SendEmailForm, { emailTemplatesSchema, RecipientsFormikSelect } from './SendEmailForm';

const RecipientsPreview = ({ recipients }) => {
  const { t } = useTranslation();
  if (!recipients?.length) return null;
  return (
    <div>
      {t('user.ShoppingCart.SendEmail.fields.recipients.label')}: {recipients.map((r) => r.email).join(', ')}
    </div>
  );
};
const BCCPreview = ({ bcc }) => {
  const { t } = useTranslation();
  if (!bcc) return null;
  return (
    <div>
      {t('user.ShoppingCart.SendEmail.bcc')}: {bcc.toString().replace(',', ', ')}
    </div>
  );
};
const unableMentionInFrontend = ['documentNumber'];

const AlertMention = ({ richText }) => {
  const { t } = useTranslation();
  const placeholders = useMemo(() => {
    if (!richText) return null;
    const parsedBody = JSON.parse(richText);
    return parsedBody
      .map((p) => {
        const res = p.children.filter((child) => {
          return unableMentionInFrontend.includes(child?.name);
        });
        return res;
      })
      .flat()
      .filter(Boolean);
  }, [richText]);
  if (placeholders?.length)
    return (
      <Alert
        type="info"
        showIcon
        message={t('user.ShoppingCart.SendEmail.unablePlaceholdersInBody', {
          placeholders: uniqBy(placeholders, (placeholder) => placeholder.name)
            .map((placeholder) => `"${t(`admin.Placeholders.${placeholder.name}.label`)}"`)
            .join(', '),
        })}
      />
    );
  return null;
};
export const PreviewEmailCard = memo(({ subject, body, bcc, signature, recipients, className }) => {
  return (
    <Card
      className={cn(classes.previewEmailTemplateCard, className)}
      icon={<MailOutlined />}
      title={subject}
      subtitle={
        <div className={classes.previewEmailTemplateCardSubtitle}>
          {recipients ? <RecipientsPreview recipients={recipients} /> : <RecipientsFormikSelect />}
          <BCCPreview bcc={bcc} />
        </div>
      }
    >
      <div className={classes.previewBody}>{body}</div>
      {signature ? <div className={classes.previewFooter}>{signature}</div> : null}
    </Card>
  );
}, equal);

export const PreviewEmailTemplate = memo(({ body, subject, title, signature, recipients, bcc }) => {
  return (
    <div className={classes.previewEmailTemplate}>
      {title ? <p>{title}</p> : null}
      <AlertMention richText={body} />
      <PreviewEmailCard
        subject={RichText.renderRichText(subject)}
        body={RichText.renderRichText(body)}
        bcc={bcc}
        signature={!RichText.isRichTextEmpty(signature) ? RichText.renderRichText(signature) : null}
        recipients={recipients}
      />
    </div>
  );
}, equal);

const AttachmentsEmailTemplates = ({ attachments, className }) => {
  const { t } = useTranslation();
  if (!attachments?.length) return null;
  return (
    <>
      <div className={cn(classes.attachments, 'margin-bottom-10', className)}>
        <FaFile />
        {t('user.ShoppingCart.SendEmail.attachments')}
      </div>
      <div className={documentTypesSelectClasses.documentTemplates}>
        {attachments.map((attachment) => (
          <div key={attachment._id} className={cn(documentTypesSelectClasses.documentTemplateCart, 'padding-16')}>
            <div>{attachment.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

const AttachmentsEmailTemplatesMemo = memo(AttachmentsEmailTemplates, equal);

const DocumentTemplateAttachments = ({ documentTemplates }) => {
  const list = useDocumentTemplateList();
  const templates = intersectionBy(list?.data, documentTemplates, (e) => (typeof e === 'object' ? e._id : e));
  if (!templates?.length) return null;
  return <AttachmentsEmailTemplatesMemo attachments={templates} />;
};

const DocumentTemplateAttachmentsMemo = memo(DocumentTemplateAttachments, equal);

const PreviewEmailTemplateWrapper = ({ shoppingCart }) => {
  const { t } = useTranslation();
  const { data, ...rest } = useCachedQuery(
    shoppingCart?._id ? getPlaceholderObjectByIdQuery : getPlaceholderObjectQuery,
    {
      variables: shoppingCart?._id
        ? { _id: shoppingCart?._id }
        : {
            shoppingCart,
          },
      fetchPolicy: 'cache-and-network',
    },
  );
  const placeholderObject = useMemo(
    () =>
      data && {
        ...JSON.parse(grabFirstGQLDataResult(data)),
      },
    [data],
  );
  const { values, setFieldValue } = useFormikContext();
  const { data: BCCData } = useCachedQuery(userBCCQuery);
  const bcc = useMemo(() => grabFirstGQLDataResult(BCCData)?.emailPreferences?.bccWithActiveUserApplied, [BCCData]);
  useEffect(() => {
    if (bcc) setFieldValue('bcc', bcc, false);
  }, [bcc, setFieldValue]);
  const { subject, body, signature, documentTemplates } = values;

  return (
    <GraphQLLoadingWrapper data={data} {...rest}>
      <RichText.PlaceholderContextProvider value={placeholderObject}>
        <PreviewEmailTemplate
          body={body}
          signature={signature}
          subject={subject}
          bcc={bcc}
          title={t('user.ShoppingCart.SendEmail.preview')}
        />
      </RichText.PlaceholderContextProvider>
      <DocumentTemplateAttachmentsMemo documentTemplates={documentTemplates} />
    </GraphQLLoadingWrapper>
  );
};

const SendEmail = ({ shoppingCart }) => {
  const { data, ...rest } = useCachedQuery(adminEmailTemplatesQuery, { variables: { isLibrary: false } });
  const emailTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);

  return (
    <GraphQLLoadingWrapper data={data} {...rest}>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <SendEmailForm emailTemplates={emailTemplates} />
        </Col>
        <Col xs={24} sm={12}>
          <PreviewEmailTemplateWrapper shoppingCart={shoppingCart} />
        </Col>
      </Row>
    </GraphQLLoadingWrapper>
  );
};

export const sendEmailModal = ({
  allRecipients = [],
  body,
  emailTemplateId,
  initialRecipients = [],
  onOk,
  shoppingCart,
  signature,
  subject,
  ...props
}) => {
  inputConfirmModal({
    formContent: () => (
      <RecipientsContext.Provider value={allRecipients}>
        <SendEmail shoppingCart={shoppingCart} />
      </RecipientsContext.Provider>
    ),
    fields: [],
    onSubmitWithClose: async ({ values, close: closeConfirmation }) => {
      onOk(values, closeConfirmation);
    },
    value: {
      body: body || RichText.getDefaultRichEditorValue(),
      documentTemplates: shoppingCart.documentTemplates,
      emailTemplateId: emailTemplateId || '',
      recipients: initialRecipients,
      signature: signature || '',
      subject: subject || RichText.getDefaultRichEditorValue(),
    },
    extraContainerProps: { placement: 'bottom', className: 'drawer-footer-btn-float-left' },
    ContainerComponent: Drawer,
    footer: true,
    headerText: i18n.t(`user.ShoppingCart.SendEmail.title`),
    okIcon: (
      <span className={cn('anticon', classes.okSendIcon)}>
        <GrMail size={19} />
      </span>
    ),
    okText: i18n.t('user.ShoppingCart.SendEmail.ok'),
    cancelText: i18n.t('common.cancel'),
    validationSchema: emailTemplatesSchema,
    forceMultiField: true,
    height: '700px',
    ...props,
  });
};

export default memo(SendEmail, equal);
