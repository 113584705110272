import { memo, useCallback, useState } from 'react';
import { Button, Card, Skeleton, Row, Col, Typography, Dropdown, Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { useTranslation } from 'react-i18next';
import EmptyBox from 'components/common/EmptyBox';
import {
  EditOutlined,
  PlusOutlined,
  ImportOutlined,
  MoreOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import PageContainer from 'components/layout/PageContainer';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import { formatDateTime } from 'utils/date';
import {
  deleteDocumentTemplateWithConfirmation,
  createDocumentTemplate,
  duplicateDocumentTemplate,
} from 'graphql/methods';
import TextClamp from 'react-string-clamp';
import { useLibraryContext } from 'contexts/LibraryContext';
import { ImportDocumentFromLibrary } from 'components/admin/DocumentTemplate/modals';
import { useDocumentTemplateList } from 'graphql/hooks';
import equal from 'fast-deep-equal/es6/react';
import inputConfirmModal from 'utils/inputConfirmModal';
import { Form } from 'formik-antd';
import { NameFormikInput } from 'components/admin/itemModal/components';
import i18n from 'i18n';
import * as Yup from 'yup';
import { textsSchema } from 'components/admin/itemModal/schema';
import DocumentTemplateHelperWidget from './DocumentTemplatesHelperWidget';

const skeletons = [...new Array(6)].map((_, i) => i + 1);
const duplicateDocumentTemplateForm = () =>
  function DuplicateDocumentTemplateForm() {
    return (
      <div>
        <Form layout="vertical">
          <NameFormikInput />
        </Form>
      </div>
    );
  };
const FieldDiv = () => [{ component: duplicateDocumentTemplateForm({}), label: '' }];

const duplicateDocumentTemplateModal = ({ _id, isLibrary }) =>
  inputConfirmModal({
    fields: FieldDiv(),
    onSubmit: ({ name }) =>
      duplicateDocumentTemplate({
        documentTemplateId: _id,
        name,
        isLibrary,
      }),
    value: {
      name: '',
    },
    errorResolver: {
      Duplicated: [
        'name',
        i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.duplicatedErrorMessage'),
      ],
    },
    headerText: i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.title'),
    okText: i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.ok'),
    cancelText: i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.cancel'),
    validationSchema: () =>
      Yup.object().shape({
        ...textsSchema({}),
      }),
    forceMultiField: true,
    width: '600px',
  });

export default function DocumentTemplateListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLibrary } = useLibraryContext();
  const { error, loading, data: documentTemplates } = useDocumentTemplateList(isLibrary);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onClickImportButton = () => setIsModalVisible(true);
  const onDuplicate = useCallback(
    (_id) => {
      duplicateDocumentTemplateModal({ _id, isLibrary });
    },
    [isLibrary],
  );
  const onDelete = useCallback(
    (_id) => {
      deleteDocumentTemplateWithConfirmation({ _id, isLibrary });
    },
    [isLibrary],
  );
  return (
    <PageContainer
      title={t(`admin.DocumentTemplateListPage.${isLibrary ? 'titleCommonLibrary' : 'title'}`)}
      rightProps={{ style: { flex: 0.5 } }}
      right={
        <div className="ant-modal-confirm-btns">
          {isLibrary ? null : (
            <Button icon={<ImportOutlined />} onClick={onClickImportButton}>
              {t('admin.DocumentTemplateListPage.importFromLibraryButton')}
            </Button>
          )}
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              createDocumentTemplate({
                isLibrary,
                onSuccess: (documentTemplate) => {
                  navigate(
                    routePatterns[
                      isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
                    ].stringify({ id: documentTemplate._id }),
                  );
                },
              });
            }}
            type="primary"
          >
            {t('admin.DocumentTemplateListPage.addDocumentTemplateButton')}
          </Button>
        </div>
      }
      left={<DocumentTemplateHelperWidget />}
    >
      <div className="xs-mt-20">
        <QueryErrorAlert error={error} />
        {!loading && !documentTemplates.length ? (
          <Card>
            <EmptyBox label={t('admin.DocumentTemplateListPage.emptyListMessage')} />
          </Card>
        ) : null}
        {loading && !documentTemplates.length ? (
          <DocumentTemplateCardListWrapper>
            {skeletons.map((k) => (
              <DocumentTemplateCardWrapper key={k}>
                <Skeleton title loading={loading} active />
              </DocumentTemplateCardWrapper>
            ))}
          </DocumentTemplateCardListWrapper>
        ) : null}
        <DocumentTemplateCardListWrapper>
          {documentTemplates.map((documentTemplate) => (
            <DocumentTemplateCardWrapper
              isLibrary={isLibrary}
              title={documentTemplate.name}
              onDelete={() => onDelete(documentTemplate._id)}
              onDuplicate={() => onDuplicate(documentTemplate._id)}
              link={routePatterns[
                isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
              ].stringify({ id: documentTemplate._id })}
            >
              <DocumentTemplateCardContent documentTemplate={documentTemplate} />
            </DocumentTemplateCardWrapper>
          ))}
        </DocumentTemplateCardListWrapper>
        {/* <TableMemo columns={columns} {...paginationProps} /> */}
      </div>
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          <ImportDocumentFromLibrary visible={isModalVisible} handleClose={setIsModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
}

function DocumentTemplateCardContent({ documentTemplate }) {
  const { t } = useTranslation();
  return (
    <>
      <TextClamp text={documentTemplate.description} lines={4} />
      <div className="xs-mb-20">
        {documentTemplate.lastUsedDocumentNr
          ? t('admin.DocumentTemplateListPage.lastUsedDocumentNr', { n: documentTemplate.lastUsedDocumentNr })
          : null}
        <Typography.Text type="secondary">
          {t('admin.DocumentTemplateListPage.nrOfDocumentsCreated', { count: documentTemplate.nrOfDocumentsCreated })}
          <br />
          {t('admin.DocumentTemplateListPage.createdAt', { date: formatDateTime(documentTemplate.createdAt) })}
        </Typography.Text>
      </div>
      <Button className="ant-btn-default" icon={<EditOutlined />} ghost type="primary" />{' '}
    </>
  );
}

const DocumentTemplateCardListWrapper = ({ children }) => <Row gutter={[10, 10]}>{children}</Row>;

const DocumentTemplateActions = ({ onDelete, onDuplicate, link }) => {
  const { t } = useTranslation();
  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={link}>
          <EditOutlined />
          <span>{t('admin.DocumentTemplateListPage.actions.editDocumentTemplate')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={onDuplicate}>
        <FileAddOutlined />
        <span>{t('admin.DocumentTemplateListPage.actions.duplicateDocumentTemplate')}</span>
      </Menu.Item>
      <Menu.Item onClick={onDelete} danger>
        <DeleteOutlined />
        <span>{t('admin.DocumentTemplateListPage.actions.deleteDocumentTemplate')}</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <div tabIndex={0} role="button" onClick={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()}>
      <Dropdown overlay={menu} placement="bottomCenter">
        <MoreOutlined className="options hover-background-light-primary" />
      </Dropdown>
    </div>
  );
};
const DocumentTemplateActionsMemo = memo(DocumentTemplateActions, equal);

const DocumentTemplateCardWrapper = ({ children, link, title, onDelete, onDuplicate }) => {
  return (
    <Col xs={24} md={8}>
      {link ? (
        <Link to={link}>
          <Card
            className="card-borderless margin-bottom-0 height-100-percent"
            title={title}
            extra={<DocumentTemplateActionsMemo onDelete={onDelete} onDuplicate={onDuplicate} link={link} />}
          >
            {children}
          </Card>
        </Link>
      ) : (
        <Card title={title}>{children}</Card>
      )}
    </Col>
  );
};
