import classes from './ShortKeyValueBlock.module.less';

const ShortKeyValueBlockRow = ({ key, keyForValue, value }) => {
  return (
    <div key={key + keyForValue} className={classes.ShortKeyValueBlockRow}>
      <div className={classes.ShortKeyValueBlockKeyColumn}>
        <b>{keyForValue}</b>
      </div>
      <div className={classes.ShortKeyValueBlockValueColumn}>{value}</div>
    </div>
  );
};

const ShortKeyValueBlock = ({ key, keyValuePairs, className }) => {
  if (!keyValuePairs?.length) return null;

  const rows = keyValuePairs.map((keyValuePair) => {
    return <ShortKeyValueBlockRow key={key} keyForValue={keyValuePair.key} value={keyValuePair.value} />;
  });

  return <div className={className}> {rows} </div>;
};

export default ShortKeyValueBlock;
