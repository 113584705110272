import { gql } from '@apollo/client';

const onboardingQuery = gql`
  query onboarding {
    onboarding {
      variables {
        yearlyBusinessAccountsSingle
        yearlyBusinessAccountsCompanies
        yearlyBusinessAccountsBalanceSheetBEU
        yearlyBusinessAccountsBalanceSheetRest
        monthlyBookkeeping
        yearlyPersonalAccounts
        accountant
        employee
      }
      theme {
        primaryColor
        color
        titleFontFamily
        fontFamily
      }
      companyInfo {
        companyName
        street
        streetNr
        cityCode
        city
        country
        phoneNr
        websiteUrl
        officeEmail
      }
      logo
      pricingMode
    }
  }
`;

export default onboardingQuery;
