import React from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useCachedQuery } from 'graphql/utils';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import { onboardingQuery } from 'graphql/queries';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { completeOnboarding } from 'graphql/methods';
import { useNavigate } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { onboardingDataTransformer, steps } from './Onboarding.utils';
import { OnboardingContextProvider, useOnboardingContext } from './OnboardingContext';
import OnboardingLayout from './OnboardingLayout';

const Onboarding = () => {
  const { currentIndex, currentStep } = useOnboardingContext();
  return (
    <OnboardingLayout currentIndex={currentIndex}>
      <currentStep.Content />
    </OnboardingLayout>
  );
};
export default function OnboardingWithContext() {
  const { data, loading, error } = useCachedQuery(onboardingQuery);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const onComplete = React.useCallback(
    async (values) => {
      const transformed = onboardingDataTransformer.transformFormValuesToGraphQLParams(values);
      setIsSubmitting(true);
      try {
        await completeOnboarding(transformed);
        navigate(routePaths.tenantOnboardingCompleted);
        window.location.reload();
      } catch (err) {
        toastGraphQLError(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [navigate],
  );

  if (loading) return <LoadingOverlay />;
  if (error && !data) return <QueryErrorAlert error={error} />;
  const formValues = onboardingDataTransformer.transformGraphQLParamsToFormValues(grabFirstGQLDataResult(data));

  return (
    <OnboardingContextProvider
      formValues={formValues}
      steps={steps}
      onComplete={onComplete}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
    >
      <Onboarding />
    </OnboardingContextProvider>
  );
}
