import apollo from 'graphql/apollo';
import { createTenantMutation } from 'graphql/mutations';
import { superAdminTenantsQuery } from 'graphql/queries';

const createTenant = async ({
  name,
  firstName,
  lastName,
  email,
  nrOfAllowedCompaniesByPricingPlan,
  licenseStarted,
  locale,
}) =>
  apollo.mutate({
    mutation: createTenantMutation,
    variables: {
      name,
      firstName,
      lastName,
      email,
      nrOfAllowedCompaniesByPricingPlan,
      licenseStarted,
      locale,
    },
    refetchQueries: [{ query: superAdminTenantsQuery }],
  });
export default createTenant;
