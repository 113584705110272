import apollo from 'graphql/apollo';
import { updateEmailServiceMutation } from 'graphql/mutations';
import { adminEmailPreferencesQuery, adminEmailServiceQuery } from 'graphql/queries';

const updateEmailService = async ({
  mailServiceProvider,
  smtpUserName,
  smtpPassword,
  smtpServer,
  smtpPort,
  useTLS,
  useSSLv3,
  requireTLS,
  from,
}) =>
  apollo.mutate({
    mutation: updateEmailServiceMutation,
    variables: {
      emailService: {
        mailServiceProvider,
        smtpUserName,
        smtpPassword,
        smtpServer,
        smtpPort,
        useTLS,
        useSSLv3,
        requireTLS,
        from,
      },
    },
    refetchQueries: [{ query: adminEmailPreferencesQuery }],
    update: (cache, { data }) => {
      cache.writeQuery({
        query: adminEmailServiceQuery,
        data: {
          getEmailService: data?.updateEmailService,
        },
      });
    },
  });
export default updateEmailService;
