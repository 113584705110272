import gql from 'graphql-tag';

const userDocumentTemplateMultiSelectQuery = gql`
  query userDocumentTemplateMultiSelectQuery($isLibrary: Boolean) {
    documentTemplates(isLibrary: $isLibrary) {
      _id
      name
    }
  }
`;

export default userDocumentTemplateMultiSelectQuery;
