import { gql } from '@apollo/client';
import documentTemplateBlockDescriptorFragment from './documentTemplateBlockDescriptorFragment';

const adminDocumentTemplateListFragment = gql`
  fragment adminDocumentTemplateListFragment on DocumentTemplate {
    _id
    name
    order
    description
    lastUsedDocumentNr
    nrOfDocumentsCreated
    blocks {
      _id
      name
      props
      descriptor {
        ...documentTemplateBlockDescriptorFragment
      }
    }
    createdAt
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default adminDocumentTemplateListFragment;
