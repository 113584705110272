export default {
  translation: {
    BackendErrors: {
      category: {
        categoryNotFound: 'Bereich ist nicht vorhanden',
        disallowInternalInputField: 'Internes Eingabefeld kann nicht zum Bereich hinzugefügt werden.',
      },
      changesWereNotSaved: {
        unknownError: 'Die Änderungen wurden NICHT gespeichert (Serverfehler). Bitte versuche es erneut',
      },
      company: {
        companyIdentifierExists: 'Die Mandantennummer existiert bereits.',
        countCompanies100: '$t(Warnings.countCompanies100)',
      },
      contact: {
        contactAlreadyExists:
          'Trage bitte eine andere E-Mail Adresse ein. Es existiert bereits ein Mandant mit dieser E-Mail',
      },
      discount: {
        notFound: 'Nachlass wurde NICHT gefunden.',
      },
      documentTemplate: {
        documentTemplateDeleted: 'Dokumentenvorlage wurde NICHT gefunden.',
        documentTemplateIsUsed: 'Es gibt Menükarten, die diese Dokumentenvorlage verwenden',
      },
      input: {
        notFound: 'Eingabefeld wurde NICHT gefunden.',
      },
      item: {
        notFound: 'Leistung wurde NICHT gefunden.',
      },
      permissionDenied: 'Zugriff verweigert',
      settings: {
        attachmentsSizeLimit:
          'Please, enter another attachments size limit because attachments size limit exceeded range',
      },
      shoppingCart: {
        notFound: 'Menükarte wurde NICHT gefunden.',
      },
      staticItem: {
        notFound: 'Statische Leistung wurde NICHT gefunden.',
      },
      status: {
        removeDefaultStatus:
          'Du kannst den Standard-Status nicht löschen. Bitte setze zuerst einen anderen Status als Standard-Status und versuche es erneut',
      },
      usedInFormula: {
        calculationMode: '$t(common.Item.switchToOnActualCost)',
        category: 'in Kategorie',
        changePaymentInterval:
          'Du kannst das Intervall NICHT auf einmalig stellen, da dies hier die letzte aktive Leistung in dieser Kategorie ist und der Wert dieser Kategorie in der Formel der folgenden Leistung(en) verwendet wird: {{items}}. Die Lösung: Bitte korrigiere die Honorarformel in diesen Leistungen (entferne die Referenz auf diese Kategorie) und führe dann die Änderung hier durch.',
        changePaymentInterval_plural:
          'You can NOT set this item payment interval to one-off because it is the last monthly/yearly item in this category and the referred category is used in the pricing-formula of items {{items}}',
        delete:
          'Leistung kann NICHT deaktiviert werden, weil sie in der Honorarformel der Leistungen {{items}} verwendet wird. Bitte ändere dies, um die Leistung anschließend deaktivieren zu können',
        deleteInput:
          'Eingabefeld kann NICHT gelöscht werden, da es in der Honorarformel der folgenden Leistungen verwendet wird: {{items}}',
        deleteLastItem:
          ' Du kannst diese Leistung nicht löschen, da sie die letzte aktive Leistung mit dem Zahlungsintervall „monatlich“ oder „jährlich“ in der Kategorie ist. Die Kategorie wir in der Honorarformel der folgenden Leistungen verwendet: {{items}}',
        deleteLastItem_plural:
          ' Du kannst diese Leistung nicht löschen, da sie die letzte aktive Leistung mit dem Zahlungsintervall „monatlich“ oder „jährlich“ in der Kategorie ist. Die Kategorie wir in der Honorarformel der folgenden Leistungen verwendet: {{items}}',
        delete_plural:
          'Leistung kann NICHT deaktiviert werden, weil sie in der Honorarformel der Leistungen {{items}} verwendet wird. Bitte ändere dies, um die Leistung anschließend deaktivieren zu können',
        inactive:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        inactiveCategory:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        inactiveCategory_plural:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        inactiveUsedItems:
          'Du kannst die Leistung NICHT aktivieren, weil in der Honorarformel die Leistungen {{items}} verwendet werden und inaktiv sind. Bitte aktiviere zunächst die Leistungen.',
        inactiveUsedItems_plural:
          'Du kannst die Leistung NICHT aktivieren, weil in der Honorarformel die Leistungen {{items}} verwendet werden und inaktiv sind. Bitte aktiviere zunächst die Leistungen.',
        inactive_plural:
          'Du kannst diese Leistung NICHT deaktivieren, da sie in der Honorarformel der Leistungen {{items}} verwendet wird.',
        usedVariableInFormula: {
          emailSignature: 'Variable wird in E-Mail Signatur verwendet',
          emailTemplates_plural:
            'Variable wird im Betreff oder Textinhalten von E-Mail Vorlagen {{emailTemplates}} verwendet',
          items_plural: 'Variable wird in Honorarformel von Leistungen {{items}} verwendet',
          staticItems_plural:
            'Variable wird in statischem Leistungskatalog von Kategorien {{staticItemCategories}} verwendet',
        },
      },
      user: {
        noValidToken: 'Ungültiger Token (Serverfehler)',
        passwordIsNotValidOrExpired: 'Falsches Passwort. Bitte versuche es nochmal',
        tenantDisabled: 'Dein Account ist deaktiviert. Bitte kontaktiere das KanzleiPilot-Team',
        userExists: 'Nutzer mit dieser E-Mail Adresse existiert bereits. Bitte trage eine andere E-Mail ein',
        userHasNoPasswordSet: 'Der Benutzer hat KEIN Passwort',
        userNotFound: 'Benutzer wurde NICHT gefunden',
        userNotFoundForEmail: 'Benutzer mit der E-Mail {{email}} existiert NICHT',
      },
      variable: {
        duplicated: 'Variable mit diesem Namen existiert bereits',
        outsideRange:
          'Der Wert muss zwischen {{largestMinValue}} und {{lowestMaxValue}} liegen, de er als Standardwert in den Eingabefeldern {{itemNames}} verwende wird',
      },
    },
    BackendWarnings: {
      item: {
        removedFailedMentions: 'Einige Platzhalter sind nach den Kopieren ungültig. Diese werden durch 0 ersetzt',
        removedFailedMentionsFromFormula: '$t(BackendWarnings.item.removedFailedMentions)',
      },
    },
    CurrentUserDropdown: {
      locale: 'Sprache: <1>$t(locale)</1>',
      loggedInAs: 'Eingelogged als <1>{{name}}</1>',
      logout: 'Ausloggen',
      noUserName: 'Unbekannter Name',
      role: 'Rolle: <1>{{role}}</1>',
    },
    DashboardPage: {
      HelperWidget: {
        howUseButton: 'Anleitung: Cockpit verwenden',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Anleitung für Cockpit',
          videoCaption: 'Erfahre, wie du das Cockpit verwendest',
          videoUrl: 'https://vimeo.com/541269734',
        },
      },
      IntroductionHelperWidget: {
        howUseButton: 'Erste Schritte in 5 Minuten erklärt',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Einführungsvideo',
          videoCaption: 'KanzleiPilot in 5 Minuten erklärt',
          videoUrl: 'https://vimeo.com/541269034',
        },
      },
      actionsBlock: {
        configureOurCatalogue: 'Leistungskatalog konfigurieren',
        createNewShoppingCart: 'Neue Menükarte anlegen',
        manageShoppingCarts: 'Menükarten verwalten',
        title: 'Was möchtest du gerne machen?',
      },
      helpCard: {
        book: {
          calendly: '15 Minuten Zoom-Gespräch verabreden',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          sendEmail: 'Kontaktiere den KanzleiPilot-Support per E-Mail',
          sendEmailUrl: '$t(common.urls.sendEmailUrl)',
          title: 'Hast du noch Fragen? Dann buche gerne ein Zoom-Gespräch',
        },
        title: 'Benötigst du Hilfe?',
        tutorial: {
          title: 'Erklärvideos ansehen',
        },
      },
      onboardingChecklist: {
        infoAlert: {
          description:
            'Wenn du KanzleiPilot als Kanzleiinhaber gerade das erste mal nutzt, empfehlen wir dir folgende Schritte:',
          title: 'Tipp für die leichte Einrichtung',
        },
        step: 'Schritt',
        step1: {
          description: 'Erhalte in 5 Minuten einen Überblick über die wichtigen Funktionen.',
          modalInfo: {
            title: '5 minute tour',
            videoUrl: 'https://vimeo.com/541269034',
          },
          title: 'Look the 5 minute demo',
        },
        step2: {
          description: 'Lade mindestens einen Mitarbeiter ein, der technisch versiert ist und dich unterstützt.',
          modalInfo: {
            title: 'How to invite user',
            videoUrl: 'https://vimeo.com/541649035',
          },
          title: 'Invite user',
        },
        step3: {
          description:
            'Generiere testweise ein Angebot, einen Steuerberatervertrag und eine Vergütungsvereinbarung. Drucke die Dokumente aus und sichte sie. Markiere deine Änderungswünsche (ganz analog) mit einem Textmarker.',
          modalInfo: {
            title: 'How to create a shopping cart',
            videoUrl: 'https://vimeo.com/541665850',
          },
          title: 'Create first shopping cart',
        },
        step4: {
          description: 'Passe nach Bedarf deinen Leistungskatalog an.',
          modalInfo: {
            title: 'How to edit catalogue',
            videoUrl: 'https://vimeo.com/541664949',
          },
          step: '',
          title: 'Edit your catalogue',
        },
        step5: {
          description: 'Passe nach Bedarf deinen Leistungskatalog an.',
          modalInfo: {
            title: 'How to edit documents',
            videoUrl: 'https://vimeo.com/541664771',
          },
          title: 'Edit documents',
        },
        title: 'Schritt für Schritt Anleitung für Kanzleiinhaber',
      },
      title: 'KanzleiPilot Cockpit',
    },
    FooterComponent: {
      copyright: '© {{year}} Softwarepiloten GmbH',
    },
    FormulaMentions: {
      RVG_Value_Fee: 'RVG_Wertgebühr',
    },
    FormulaNames: {
      category: 'Monatshonorar der Kategorie "{{name}}"',
      inputField: 'Bereichseingabefeld "{{name}}"',
      inputFieldItem: 'Eingabefeld "{{name}}"',
      inputField_internal: 'Internes Eingabefeld "{{name}}" ({{default}})',
      item: 'Honorar der Leistung "{{name}}"',
      staticItem: 'Statische Leistung "{{name}}" des Bereichs "{{categoryName}}"',
      variable: 'Variablenwert "{{name}}"',
    },
    InputErrors: {
      phoneNumber: {
        noCode: 'Ländercode ist ein Pflichtfeld',
        noPhone: 'Telefonnummer ist ein Pflichtfeld',
      },
    },
    Login: {
      inputs: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        password: {
          error: 'Passwort ist falsch',
          label: 'Passwort',
          placeholder: 'Passwort',
        },
      },
      resetPassword: 'Ich habe mein Passwort vergessen',
      submit: 'Einloggen',
      title: 'Logge dich bei KanzleiPilot ein',
    },
    NavBar: {
      accountSetting: {
        title: '$t(user.Setting.AccountSetting.title)',
      },
      catalogueConfiguration: {
        title: '$t(admin.CatalogueConfiguration.title) ',
      },
      commonLibrary: {
        catalogueCommonLibrary: {
          title: 'Gemeinsamen Leistungskatalog konfigurieren',
          titleSort: 'Gemeinsamen Leistungskatalog konfigurieren',
        },
        documentCommonLibrary: {
          title: 'Gemeinsame Dokumentenvorlagen konfigurieren',
          titleSort: 'Gemeinsame Dokumentenvorlagen konfigurieren',
        },
        emailCommonLibrary: {
          title: 'Gemeinsame E-Mail Vorlagen konfigurieren',
          titleSort: 'E-Mail Vorlagen',
        },
        title: 'Superadmin Einstellungen',
      },
      companyTenant: {
        title: '$t(admin.Setting.TenantCompany.title)',
      },
      constantsAndPlaceholders: {
        title: '$t(admin.ConstantsAndPlaceholders.title)',
      },
      corporateIdentity: {
        title: '$t(admin.Setting.CorporateIdentity.title)',
      },
      createShoppingCart: {
        title: '$t(ShoppingCartEntriesPage.createShoppingCart)',
        tooltip: '$t(ShoppingCartEntriesPage.createShoppingCart)',
      },
      dashboard: {
        title: '$t(DashboardPage.title)',
        tooltip: '$t(DashboardPage.title)',
      },
      documentTemplateListPage: {
        title: '$t(admin.DocumentTemplateListPage.title)',
      },
      emailSettings: {
        title: '$t(admin.Setting.Email.title)',
      },
      emailTemplates: {
        title: '$t(admin.EmailTemplates.title)',
      },
      importCompanies: {
        title: 'Mandanten importieren',
      },
      licenseOverview: {
        title: '$t(admin.Setting.LicenseOverview.title)',
      },
      logo: {
        tooltip: 'Menü aufklappen',
      },
      manageAllUsers: {
        title: '$t(superAdmin.ManageAllUsers.title)',
      },
      managePersons: {
        title: '$t(admin.ManagePersons.title)',
      },
      manageShoppingCartStatus: {
        title: '$t(admin.ManageShoppingCartStatus.title)',
      },
      manageTenants: {
        title: '$t(superAdmin.ManageTenants.title)',
      },
      manageUsers: {
        title: '$t(admin.ManageUsers.title)',
      },
      otherSettings: {
        title: '$t(admin.OtherSettings.title)',
      },
      ourCompany: {
        title: 'Kanzlei & Team',
      },
      settingTitle: 'Einstellungen',
      shoppingCart: {
        title: 'Menükarte',
      },
      shoppingCartEntries: {
        title: '$t(ShoppingCartEntriesPage.title)',
        tooltip: '$t(ShoppingCartEntriesPage.title)',
      },
      shoppingCartPreferences: {
        title: '$t(admin.Setting.ShoppingCartPreferences.title) ',
      },
      warnings: {
        title: '$t(Warnings.title)',
        tooltip: '$t(Warnings.tooltip)',
      },
    },
    ResetPassword: {
      buttons: {
        changePassword: 'Passwort ändern',
        goToLogin: 'Zurück',
        verify: 'Verifizieren',
      },
      enterEmail: 'Bitte trage die E-Mail ein, für die du das Passwort zurück setzen möchtest',
      errors: {
        noMatch: 'Passwörter stimmen leider NICHT überein',
      },
      fields: {
        email: 'E-Mail',
        newPassword: 'Neues Passwort',
        otp: 'Einmaliges Passwort',
        passwordConfirmation: 'Neues Passwort (bitte wiederholen)',
      },
      steps: {
        email: 'Passwort eintragen',
        setNewPassword: 'Neues Passwort eintragen',
        verification: 'Gib das einmalige Passwort ein',
      },
      title: 'Passwort neu setzen',
      verificationDescription:
        'Wir haben an die E-Mail {{email}} einen Bestätigungscode gesendet. Bitte trage deinen Bestätigungscode ein.',
    },
    ShoppingCartEntriesPage: {
      HelperWidget: {
        howUseButton: 'Anleitung: So verwaltest du deine Menükarten',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Anleitung für das Verwalten der Menükarten',
          videoCaption: 'Erfahre, wie du die Menükarten verwaltest',
          videoUrl: 'https://vimeo.com/542067268',
        },
      },
      catalogue_configuration_button: 'Menükarte konfigurieren',
      createShoppingCart: 'Neue Menükarte anlegen',
      entriesTable: {
        columnTitles: {
          actions: 'Aktionen',
          companyIdentifier: 'Mandantennummer',
          companyName: 'Mandant',
          contact: 'Kontakt',
          createdAt: 'Erstellt am',
          name: 'Projekt',
          relatedContacts: 'Kontaktpersonen',
          status: 'Status',
          valueMonthly: 'Wert monatlich',
          valueOneOff: 'Wert einmalig',
          valueYearly: 'Wert jährlich',
        },
      },
      noStatus: 'Kein Status',
      search: 'Suche nach einer Mandantennummer, dem Namen eines Mandanten oder Kontaktes...',
      searchTitle: 'Suche',
      statusAll: 'Alle',
      statusModal: {
        placeholder: 'Wähle den Status aus',
        title: 'Status der Menükarte bearbeiten',
      },
      title: 'Menükarten verwalten',
    },
    Warnings: {
      countCompanies100:
        'Du kannst leider KEINE weiteren Mandanten hinzufügen, weil du das Limit deines Mandantenkontingents erreicht hast. Bitte upgrade deine Lizenz.',
      countCompanies80: 'Du hast 80 % deines Mandantenkontingents aufgebraucht',
      countCompanies90: 'Du hast 90 % deines Mandantenkontingents aufgebraucht',
      countUsers100:
        'Du kannst keine weiteren Nutzer hinzufügen, da du dein Limit erreicht hast. Willst du dennoch weitere Nutzer hinzufügen, kontaktiere das KanzleiPilot-Team',
      countUsers80: 'Du hast dein Nutzerlimit zu 80% ausgelastet',
      countUsers90: 'Du hast dein Nutzerlimit zu 90% ausgelastet',
      title: 'Warnung',
      tooltip: 'Warnung',
    },
    admin: {
      CatalogueConfiguration: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: 'Anleitung: Sichtbarkeit nach Mandantentyp einstellen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Bearbeiten des Bereichs: Sichtbarkeit nach Mandantentyp',
            videoCaption: 'Erfahre, wie du den Bereich Sichtbarkeit nach Mandantentyp bearbeiten kannst ',
            videoUrl: 'https://vimeo.com/541286084',
          },
        },
        ConfigureCategoryProperties: {
          properties: {
            conditionalVisibility: {
              bodyTitle: 'Zeige diesen Bereich bei folgenden Mandantentypen:',
              title: 'Sichtbarkeit nach Mandantentyp',
            },
            discounts: {
              title: 'Nachlässe',
            },
            inputFields: {
              title: 'Eingabefelder',
            },
            items: {
              title: 'Leistungen',
            },
            staticItems: {
              title: 'Statischer Leistungskatalog',
            },
            texts: {
              title: 'Texte',
            },
          },
          title: 'Bereich bearbeiten',
        },
        DiscountsHelperWidget: {
          howUseButton: 'Anleitung: Nachlässe einrichten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Konfiguration von Nachlässen',
            videoCaption: 'Erfahre, wie du Nachlässe anlegen, sortieren und löschen kannst',
            videoUrl: 'https://vimeo.com/541285375',
          },
        },
        EditCategoryHelperWidget: {
          howUseButton: 'Anleitung: Bereich bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Bereich bearbeiten',
            videoCaption: 'Erfahre, wie du eine Bereich am einfachsten bearbeiten kannst',
            videoUrl: 'https://vimeo.com/541279223',
          },
        },
        EmailHelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: So konfigurierst du den E-Mail Versand',
            videoCaption: 'Erfahre warum du einen eigenen SMTP Server einstellen solltest',
            videoUrl: 'https://vimeo.com/563388433',
          },
        },
        HelperWidget: {
          howUseButton: 'Anleitung: So konfigurierst du deinen Leistungkatalog',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Konfigurieren des Leistungskatalogs',
            videoCaption: 'Erfahre, wie du den Leistungskatalog konfigurieren kannst',
            videoUrl: 'https://vimeo.com/541270313',
          },
        },
        ImportHelperWidget: {
          howUseButton: 'Anleitung: Leistungskatalog importieren',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Leistungskatalog importieren',
            videoCaption: 'Erfahre, wie du aus der Bibliothek importieren kannst',
            videoUrl: 'https://vimeo.com/541270670',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: 'Anleitung: Eingabefelder verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Verwaltung von Eingabefeldern',
            videoCaption: 'Erfahre, wie du Eingabefelder verwalten kannst',
            videoUrl: 'https://vimeo.com/541287358',
          },
        },
        StaticItemsHelperWidget: {
          howUseButton: 'Anleitung: Statischen Leistungskatalog verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Konfiguration des statischen Leistungskatalogs',
            videoCaption: 'Erfahre, wie du Leistungen anlegen, bearbeiten oder löschen kannst',
            videoUrl: 'https://vimeo.com/541285598',
          },
        },
        StaticItemsTab: {
          inputs: {
            intro: {
              label: 'Einleitungstext für Dokumente',
              tooltip: 'Dieser Text erscheint oberhalb des statischen Leistungskatalog dieser Kategorie',
            },
            outro: {
              label: 'Abschlusstext für Dokumente',
              tooltip: 'Dieser Text erscheint unterhalb des statischen Leistungskatalog dieser Kategorie',
            },
          },
        },
        TextsHelperWidget: {
          howUseButton: 'Anleitung: Texte des Bereichs bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Bearbeiten von Texten',
            videoCaption: 'Erfahre, wie du Texte in einem Bereich bearbeiten kannst',
            videoUrl: 'https://vimeo.com/541287581',
          },
        },
        addCategory: 'Neuen Bereich anlegen',
        collapseOptions: {
          duplicate: 'Bereich duplizieren',
          edit: 'Bereich bearbeiten',
          remove: 'Bereich löschen',
        },
        copyItem: {
          addonAfter: '(Kopie)',
          cancel: '$t(common.cancel)',
          complexPricingFormula:
            '[{"type":"paragraph","children":[{"text":"Die Leistung oder ein Auswahlfeld der Leistung enthält eine \\"komplexe\\" Honorarformel. Die Leistung kann nur unter einigen Einschränkungen kopiert werden: "}]},{"type":"bulleted-list","listType":"disc","children":[{"type":"list-item","children":[{"text":"Verweist die Honorarformel auf andere Bereiche, werden die Bereichs-Verweise durch eine 1 ersetzt (in der Honorarformel der Kopie)"}]},{"type":"list-item","children":[{"text":"Verweist die Honorarformel auf andere Leistungen, werden die Leistungs-Verweise durch eine 1 ersetzt (in der Honorarformel der Kopie)"}]},{"type":"list-item","children":[{"text":"Verweist die Honorarformel auf andere Bereichs-Eingabefelder, werden die Bereichs-Eingabefeld-Verweise durch eine 1 ersetzt (in der Honorarformel der Kopie)"}]}]},{"type":"paragraph","children":[{"text":"Bitte überprüfe nach dem Kopiervorgang bitte die Honorarformel der Kopie. Die Texte und andere Einstellungen werden 1-zu-1 kopiert."}]}]',
          duplicatedErrorMessage:
            'Name des Duplikats von Leistungen sind in KanzleiPilot eindeutig. Bitte trage den Namen des Duplikats ein.',
          inputs: {
            category: {
              errorLabel: 'Kategorie',
              label: 'Bitte wähle den Bereich aus',
            },
            name: {
              label: 'Name des Duplikats',
            },
          },
          ok: 'Leistung kopieren',
          openModalButtonTitle: 'Kopiere in anderen Bereich',
          singleImport: 'importieren',
          success: {
            subTitle: 'Die Leistung wurde erfolgreich importiert',
            title: 'Import war erfolgreich',
          },
          title: 'In welchen Bereich möchtest du die Leistung kopieren?',
        },
        discounts: {
          title: 'Nachlässe',
        },
        emptyCategoryListMessage: 'Es gibt noch KEINEN Bereich. Bitte klicke auf "Neuen Bereich anlegen"',
        emptyDiscountListMessage: 'Es gibt noch KEINE Nachlässe. Bitte klicke auf "Neuen Nachlass anlegen"',
        emptyInputFieldListMessage: 'Es gibt noch KEINE Eingabefelder. Bitte klicke auf "Neues Eingabefeld anlegen"',
        emptyInputFieldOptionListMessage:
          'Es gibt noch KEINE Auswahlfeld Optionen. Bitte klicke auf "Neue Option anlegen"',
        emptyItemListMessage: 'Es gibt noch KEINE Leistungen. Bitte klicke auf "Neue Leistung anlegen"',
        emptyStaticItemListMessage:
          'Es sind keine statischen Leistungen vorhanden. Drücke auf "+ neue Leistung anlegen"',
        importCategoryPresets: 'Aus Bibliothek importieren',
        itemsValidation: {
          button: 'Alle Honorarformeln validieren',
          error: '{{itemName}} in {{categoryName}} hat eine ungültige Formel',
          success: 'Keine Fehler in den Honorarformeln gefunden',
        },
        title: 'Leistungskatalog konfigurieren',
        titleCommonLibrary: 'Gemeinsame Bibliothek',
      },
      ConstantsAndPlaceholders: {
        HelperWidget: {
          howUseButton: 'Anleitung: Variablen verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Variablen verwalten',
            videoCaption: 'Erfahre, wie du die Variablen verwaltest',
            videoUrl: 'https://vimeo.com/541298165',
          },
        },
        addVariableButton: 'Neue Variable anlegen',
        addVariableModalTitle: 'Neue Variable anlegen',
        columns: {
          description: 'Beschreibung',
          mandatory: 'System-Variable',
          name: 'Name',
          type: 'Typ',
          value: 'Wert',
        },
        editVariableModalTitle: 'Variable bearbeiten',
        fields: {
          description: {
            label: '$t(common.fields.description.label)',
          },
          name: {
            label: '$t(common.fields.name.label)',
          },
          type: {
            label: '$t(common.fields.type.label)',
          },
          value: {
            label: '$t(common.fields.value.label)',
          },
        },
        title: 'Variablen verwalten',
        variableListTitle: 'Liste der Variablen',
      },
      DocumentTemplateConfigurationPage: {
        AddBlockHelperWidget: {
          howUseButton: 'Anleitung: Block hinzufügen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Hinzufügen von Blöcken',
            videoCaption: 'Erfahre, wie du Blöcke hinzufügen kannst',
            videoUrl: 'https://vimeo.com/542072198',
          },
        },
        BlockHelperWidget: {
          howUseButton: 'Anleitung: {{displayName}} einstellen',
          modalInfo: {
            FreeText: {
              title: 'Anleitung: Freien Text einstellen',
              videoCaption: 'Erfahre, was für Optionen du im Freien Text Block hast',
              videoUrl: 'https://vimeo.com/541297800',
            },
            FullPageImage: {
              title: 'Anleitung: Bildseite einstellen',
              videoCaption: 'Erfahre, wie der Bildseiten-Block funktioniert',
              videoUrl: 'https://vimeo.com/586189327',
            },
            Introduction: {
              title: 'Anleitung: Begrüßung einstellen',
              videoCaption: 'Erfahre, was für Optionen du im Begrüßung-Block hast',
              videoUrl: 'https://vimeo.com/541296373',
            },
            Investment: {
              title: 'Anleitung: Ihre Investition einstellen',
              videoCaption: 'Erfahre, wie die Kosten des Mandanten separat aufgelistet werden',
              videoUrl: 'https://vimeo.com/541296650',
            },
            ListBookmarkedItems: {
              title: 'Anleitung: Details zu Strategische Empfehlungen einstellen',
              videoCaption: 'Erfahre, wie der Block “Strategische Empfehlungen“ funktioniert',
              videoUrl: 'https://vimeo.com/563209294',
            },
            NextSteps: {
              title: 'Anleitung: Nächste Schritte einstellen',
              videoCaption: 'Erfahre, wie der nächste Schritte Block funktioniert',
              videoUrl: 'https://vimeo.com/541297365',
            },
            ServiceCatalogue: {
              title: 'Anleitung: Leistungskatalog einstellen',
              videoCaption: 'Erfahre, wie der Leistungskatalog-Block funktioniert',
              videoUrl: 'https://vimeo.com/715753797',
            },
            ServiceDetails: {
              title: 'Anleitung: Details zu den ausgewählten Dienstleistungen einstellen',
              videoCaption: 'Erfahre, wie die Details zu den ausgewählten Dienstleistungen funktionieren',
              videoUrl: 'https://vimeo.com/541296849',
            },
            ServiceDetailsForContract: {
              title: 'Anleitung: Auftragsumfang einstellen',
              videoCaption: 'Auftragsumfang listet alle ausgewählten Dienstleistungen auf',
              videoUrl: 'https://vimeo.com/541297050',
            },
            Signatures: {
              title: 'Anleitung: Signaturen einstellen',
              videoCaption: 'Erfahre, wie der Signaturen-Block funktioniert',
              videoUrl: 'https://vimeo.com/541297697',
            },
            SpecialAgreement: {
              title: 'Sondervereinbarung',
              videoCaption: 'Erfahre, wie du den Sondervereinbarung Bereich optimal nutzt',
              videoUrl: 'https://vimeo.com/568893139',
            },
            TableOfContent: {
              title: 'Anleitung: Inhaltsverzeichnis einstellen',
              videoCaption: 'Erfahre, wie der Inhaltsverzeichnis-Block funktioniert',
              videoUrl: 'https://vimeo.com/541296041',
            },
            Testimonial: {
              title: 'Anleitung: Testimonial einstellen',
              videoCaption: 'Erfahre, wie der Testimonial-Block funktioniert',
              videoUrl: 'https://vimeo.com/541295938',
            },
            Title: {
              title: 'Anleitung: Titel einstellen',
              videoCaption: 'Erfahre, wie der Titel-Block funktioniert',
              videoUrl: 'https://vimeo.com/541295693',
            },
            helpText: '',
          },
        },
        HelperWidget: {
          howUseButton: 'Anleitung: Dokumentenvorlage bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Dokumentenvorlage bearbeiten',
            videoCaption: 'Erfahre, wie du eine Dokumentenvorlage bearbeitest',
            videoUrl: 'https://vimeo.com/541288371',
          },
        },
        addBlockModal: {
          addToBottomButton: 'füge unten hinzu',
          addToTopButton: 'füge oben hinzu',
          availableBlocksTitle: 'Verfügbare Blöcke',
          blockActionsColumnTitle: 'Aktionen',
          blockNameColumnTitle: 'Name',
          blockPreviewTitle: 'Vorschau',
          title: 'Block hinzufügen',
        },
        backTitle: 'Zurück zu den Dokumentenvorlagen',
        blockSubtitles: {
          catalogue: 'Leistungskatalog Filter',
          category: 'Bereichsebene:',
          endOfBlock: 'Ende des Blocks',
          item: 'Leistungsebene:',
          page: 'Allgemein',
          pricingSection: 'Honorarbereich',
        },
        blocks: {
          body: 'Personalisierter Text',
          introductionBlockNumber: '(introduction-block #{{number}})',
        },
        deleteBlockConfirmation: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title:
            'Möchtest du den Block wirklich löschen? Warnung: deine Block-Einstellungen gehen verloren (Texte, Einstellungen, etc.). Fortfahren?',
        },
        generalInformationSection: {
          descriptionInputLabel: 'Beschreibung',
          descriptionInputTooltip:
            'Interne Beschreibung der Dokumentenvorlage. Gut um für dein Team zu dokumentieren, worum es sich hier genau handelt.',
          lastUsedDocumentNr: 'Aktuelle Dokumenten-Nummer: {{n}}',
          nameInputLabel: 'Name',
          nameInputTooltip: 'Name der Dokumentenvorlage. Erscheint so zur Auswahl in der interaktiven Menükarte.',
          nrOfDocumentsCreated: '{{count}} Dokument erzeugt',
          nrOfDocumentsCreated_plural: '{{count}} Dokumente erzeugt',
          title: 'Allgemeine Information',
        },
        layoutSection: {
          addBlockButton: 'Block hinzufügen',
          emptyBlockList: 'Füge deine Dokumentenvorlage einen Block hinzu und drücke auf "Block hinzufügen"',
          infoSectionTitle: 'Info',
          title: 'Layout',
          tooltip:
            'Das Layout einer Dokumentenvorlage besteht aus Blöcke, die du hier bearbeiten,kannst. Klicke unten auf einen Block, um die Texte anzupassen. Klicke auf „ + neuen Block hinzufügen“ um einen neuen Block in das Layout einzufügen.',
        },
        title: 'Dokumentenvorlagen bearbeiten',
      },
      DocumentTemplateListPage: {
        HelperWidget: {
          howUseButton: 'Anleitung: Dokumentenvorlagen verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für die Nutzung von Dokumentenvorlagen',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/541287917',
          },
        },
        ImportHelperWidget: {
          howUseButton: 'Anleitung: Dokumentenvorlagen importieren',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Dokumentenvorlagen aus Bibliothek importieren',
            videoCaption: 'Erfahre, wie du Dokumentenvorlagen aus der Bibliothek importieren kannst',
            videoUrl: 'https://vimeo.com/541288193',
          },
        },
        actions: {
          deleteDocumentTemplate: 'Dokumentenvorlage löschen',
          duplicateDocumentTemplate: 'Dokumentenvorlage duplizieren',
          editDocumentTemplate: 'Dokumentenvorlage bearbeiten',
        },
        addDocumentTemplateButton: 'Dokumentenvorlagen hinzufügen',
        createdAt: 'Erstellt am {{date}}',
        duplicateDocumentTemplateModal: {
          cancel: '$t(common.cancel)',
          duplicatedErrorMessage: 'Dokumentenvorlage mit diesem Namen existiert bereits',
          ok: '$t(common.ok)',
          title: 'Bitte trage den Namen für die neue Dokumentenvorlage ein',
        },
        emptyListMessage: 'Du hast noch keine Dokumentenvorlagen angelegt',
        errorMessageInModal: 'Bereiche mit den Namen {{duplicatedDocuments}} existieren bereits',
        importEmptyListMessage: 'Bis jetzt ist keine Dokumententemplate vorhanden',
        importFromLibraryButton: 'Aus gemeinsamer Bibliothek importieren',
        importFromLibraryModalTitle: 'Dokumentenvorlagen importieren',
        lastUsedDocumentNr: 'Aktuelle #:{{n}}',
        nrOfDocumentsCreated: '{{count}} Dokument erstellt',
        nrOfDocumentsCreated_plural: '{{count}} Dokumente erstellt',
        title: 'Dokumentenvorlagen',
        titleCommonLibrary: 'Gemeinsame Dokumentenvorlagen',
      },
      EmailTemplates: {
        add: 'E-Mail Vorlage hinzufügen',
        columns: {
          actions: '$t(common.columns.actions)',
          body: '$t(common.columns.body)',
          isDefault: 'Bei Neuanlage importieren?',
          name: '$t(common.columns.name)',
          subject: '$t(common.columns.subject)',
        },
        fields: {
          body: {
            label: '$t(common.fields.body.label)',
            placeholder: '$t(common.fields.body.placeholder)',
          },
          isDefault: {
            label: 'Bei Neuanlage importieren?',
            no: '$t(common.no)',
            yes: '$t(common.yes)',
          },
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          subject: {
            label: '$t(common.fields.subject.label)',
            placeholder: '$t(common.fields.subject.placeholder)',
          },
        },
        import: 'Aus Bibliothek importieren',
        importEmptyEmailTemplateListMessage: 'There are no email templates yet.',
        importErrorMessage: 'E-Mail Vorlage mit dem Namen {{duplicatedEmailTemplates}} existieren bereits',
        importModalTitle: 'E-Mail Vorlagen aus Bibliothek importieren',
        modal: {
          addTitle: 'Neue E-Mail Vorlage anlegen',
          deleteTitle: 'Bist du sicher, dass du die E-Mail Vorlage löschen möchtest?',
          editTitle: 'E-Mail Vorlage bearbeiten',
          error: {
            delete: 'Fehler beim Löschen der E-Mail Vorlage',
            duplicatedErrorMessage: 'E-Mail Vorlage mit diesem Namen existiert bereits',
          },
        },
        title: 'E-Mail Vorlagen',
      },
      ManagePersons: {
        HelperWidget: {
          howUseButton: 'Anleitung: Unterschriftsberechtige Personen verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Unterschriftsberechtige Personen verwalten',
            videoCaption: 'Erfahre, wie und wozu du die Unterschriften einrichtest',
            videoUrl: 'https://vimeo.com/541301636',
          },
        },
        addPerson: 'Unterschriftsberechtige Person anlegen',
        addPersonModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Unterschriftsberechtige Person anlegen',
        },
        cardTitle: 'Unterschriftsberechtigte Person',
        deleteConfirmModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Möchtest du die unterschriftsberechtige Person löschen?',
        },
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
          position: {
            label: '$t(common.fields.position.label)',
            placeholder: '$t(common.fields.position.placeholder)',
          },
          signature: {
            label: 'Unterschrift',
            tooltip: 'Hinweis: Diese Unterschrift wird nur im Begrüßungsblock eines Dokumentes verwendet',
          },
        },
        title: 'Unterschriftsberechtige Personen verwalten',
        uploadLabel: 'Unterschriftbild hochladen',
      },
      ManageShoppingCartStatus: {
        HelperWidget: {
          howUseButton: 'Anleitung: Status verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Status für Menükarten verwenden und konfigurieren',
            videoCaption: 'Erfahre, alles über die Status. Wozu sind sie nützlich und wie verwaltest du sie?',
            videoUrl: 'https://vimeo.com/541298786',
          },
        },
        addStatus: 'Neuen Status anlegen',
        addStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Neuen Status anlegen',
        },
        columns: {
          name: '$t(common.columns.name)',
        },
        defaultStatusTitle: 'Standard Status',
        fields: {
          name: {
            label: '$t(common.fields.name.label)',
            placeholder: '$t(common.fields.name.placeholder)',
          },
        },
        statusListTitle: 'Status Liste',
        title: 'Status der Menükarten verwalten',
        updateStatusModal: {
          cancel: '$t(common.cancel)',
          ok: '$t(common.ok)',
          title: 'Status ändern',
        },
      },
      ManageUsers: {
        HelperWidget: {
          howUseButton: 'Anleitung: Nutzer verwalten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Verwalten von Nutzern',
            videoCaption: 'Erfahre, wie du neue Nutzer einlädst und existierende Nutzer verwaltest',
            videoUrl: 'https://vimeo.com/541301373',
          },
        },
        buttons: {
          sendInvitation: 'Einladung senden',
        },
        modal: {
          editUser: 'Nutzer bearbeiten',
        },
        title: 'Nutzer verwalten',
        userInvitation: {
          inputs: {
            email: {
              label: 'E-Mail',
            },
            firstName: {
              label: 'Vorname',
            },
            lastName: {
              label: 'Nachname',
            },
            position: {
              label: 'Position',
            },
            role: {
              label: 'Berechtigung',
              tooltip:
                'Rolle bzw. Rechte des Nutzers. Wir unterscheiden 4 Rollen: 1) “Nutzer nur mit Lesezugriff” können Menükarten betrachten - sinnvoll z.B. für den Empfang. 2) “Nutzer (darf Menükarten anlegen)“ darf Menükarten anlegen und bearbeiten (Menükarten sind revisionssicher). 3) Administratoren haben Zugriff auf Einstellungen und können z.B. auch den Leistungskatalog bearbeiten 4) Inhaber dürfen alles',
            },
          },
          title: 'Nutzer einladen',
        },
        userList: {
          fields: {
            email: 'E-Mail',
            name: 'Name',
            onboarding: 'Onboarding',
            position: '$t(common.fields.position.label)',
            role: 'Berechtigung',
            status: 'Status',
          },
          title: 'Liste der Nutzer',
        },
      },
      OtherSettings: {
        globalSettings: {
          inputs: {
            hideCountryInAddresses: {
              label: 'Verstecke das Land in Adressen, wenn es im selben Land liegt, wie unsere Kanzlei',
            },
          },
          title: 'Globale Einstellungen',
        },
        title: 'Sonstige Einstellungen',
      },
      Placeholders: {
        allContactsSeparated: {
          label: 'Ansprechpartner (separiert mit Komma/und)',
        },
        allContactsWithFullSalutation: {
          label: 'Briefanrede für Ansprechpartner',
        },
        categoryTotals: {
          deleted: 'Gelöschte Kategorie',
          label: 'Monatliches Unternehmer-Honorar für einzelnen Bereich',
          mention: 'Monatliches Unternehmer-Honorar für einzelnen Bereich "{{name}}"',
        },
        client: {
          label: 'Mandant',
        },
        company: {
          label: 'Mandant',
        },
        currentDate: {
          label: 'Datum (tagesaktuell bei Generierung)',
        },
        document: {
          label: 'Dokument',
        },
        documentCreatedAt: {
          label: 'Erstellt am',
        },
        documentNumber: {
          label: 'Dokumentennummer',
        },
        endOfContract: {
          label: 'Vertragsende',
        },
        environment: {
          label: 'Sonstiges',
        },
        meetingAt: {
          label: 'Datum des Honorargesprächs',
        },
        projectName: {
          label: 'Projektname',
        },
        shoppingCart: {
          label: 'Menükarte',
        },
        shoppingCartCompanyCity: {
          label: 'Ort Mandant',
        },
        shoppingCartCompanyCommercialObject: {
          label: 'Unternehmensgegenstand',
        },
        shoppingCartCompanyFullAddress: {
          label: 'Anschrift Mandant',
        },
        shoppingCartCompanyOrFullname: {
          label: 'Firmierung Mandant',
        },
        shoppingCartVatType: {
          label: 'Bezeichnung Umsatzsteuer oder Mehrwertsteuer',
        },
        shoppingCartVatTypeWithPercentage: {
          label: 'Umsatzsteuer Infotext mit brutto-netto und Prozent-Angaben',
        },
        startOfContract: {
          label: 'Vertragsstart',
        },
        tenantCompany: {
          label: 'Kanzlei',
        },
        tenantCompanyCity: {
          label: 'Sitz Kanzlei',
        },
        tenantCompanyCityCode: {
          label: 'PLZ Kanzlei',
        },
        tenantCompanyCountry: {
          label: 'Land Kanzlei',
        },
        tenantCompanyCurrentUserName: {
          label: 'Name des aktiven Benutzers',
        },
        tenantCompanyFullAddress: {
          label: 'Anschrift Kanzlei',
        },
        tenantCompanyHouseNumber: {
          label: 'Hausnummer Kanzlei',
        },
        tenantCompanyName: {
          label: 'Kanzleiname',
        },
        tenantCompanyOfficeEmail: {
          label: 'E-Mail Kanzlei',
        },
        tenantCompanyPhoneNumber: {
          label: 'Telefonnummer Kanzlei',
        },
        tenantCompanyPositionOfCurrentUserName: {
          label: 'Position des aktiven Benutzers (aus seinem Profil)',
        },
        tenantCompanyStreet: {
          label: 'Straße Kanzlei',
        },
        tenantCompanyWebsite: {
          label: 'Webseite Kanzlei',
        },
        totalMonthlyFixedPrice: {
          label: 'Monatliches Unternehmerhonorar',
        },
        totalMonthlyFixedPriceDetailed: {
          label: 'Monatliches Unternehmerhonorar - mit Infos über Zusammensetzung',
        },
        totalMonthlyValue: {
          label: 'Monatlicher Wert',
        },
        totalMonthlyValueDetailed: {
          label: 'Monatlicher Wert - mit Infos über Zusammensetzung',
        },
        totalOneOffValue: {
          label: 'Einmaliger Wert',
        },
        totalOneOffValueDetailed: {
          label: 'Einmaliger Wert - mit Infos über Zusammensetzung',
        },
        totalYearlyFixedPrice: {
          label: 'Jährliche Investition (monatliche + jährliche Positionen)',
        },
        totalYearlyFixedPriceDetailed: {
          label: 'Jährliche Investition (monatliche + jährliche Positionen) - mit Infos über Zusammensetzung',
        },
        totalYearlyValue: {
          label: 'Jährlicher Wert',
        },
        totalYearlyValueDetailed: {
          label: 'Jährlicher Wert - mit Infos über Zusammensetzung',
        },
        variables: {
          deleted: 'Gelöschte Variable',
          label: 'Variablen',
        },
      },
      RichEditor: {
        toolbar: {
          link: {
            createModalName: 'Link name',
            createModalTitle: 'Link hinzufügen',
            createModalValue: 'Link URL',
            modalValueInvalidError: 'Link muss eine validide URL sein (z.B. https://www.kanzleipilot.de)',
            modalValueTooltipHTML:
              '<div><div>Beispiel:</div><div>https://www.kanzleipilot.de - Webadresse verlinken</div><div>mailto:name@gmail.com - E-Mail verlinken</div><div>tel:+49123456789 - Telefonnummer verlinken</div></div>',
          },
        },
      },
      Setting: {
        CorporateIdentity: {
          BodyPrimary: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Farbe',
              fontFamily: 'Schriftart',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            inputs: {
              bodyPrimary: 'Flächen',
              bodyPrimaryFooter: 'Flächen (Fußzeile)',
            },
            title: 'Flächen',
          },
          CorporatePrimaryColor: {
            inputs: {
              color: 'Primäre Kontrastfarbe',
              fontFamily: 'Schriftart',
              primaryColor: 'Primärfarbe',
            },
            preview: 'Vorschau eines Buttons in Ihrer Farbgebung (Primärfarbe und Kontrastfarbe)',
            title: 'Konfiguration der Corporate Identify',
          },
          HelperWidget: {
            howUseButton: 'Anleitung: Corporate Identify einstellen',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung Corporate Identify',
              videoCaption: 'Erfahre, wie du KanzleiPilot an deine Farben anpassen kannst',
              videoUrl: 'https://vimeo.com/541302959',
            },
          },
          TableOfContent: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Textfarbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            title: 'Inhaltsverzeichnis',
          },
          TableTheme: {
            field: {
              background: 'Hintergrund-Farbe',
              color: 'Textfarbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              marginBottom: 'Abstand oben',
              marginTop: 'Abstand unten',
            },
            inputs: {
              table: 'Tabelle',
              tableContent: 'Tabelleninhalt',
              tableFooter: 'Tabelle Fußzeile',
              tableHeaderPrimary: 'Tabellenkopf Primärfarbe',
              tableHeaderSecondary: 'Tabellenkopf Sekundärfarbe',
              tableLeftColumn: 'Linke Tabellenspalte',
            },
            title: 'Tabellen-Stil',
          },
          TenantLogo: {
            bigImage: 'Die Bilddatei ist zu groß. Bitte wähle Datei mit maximal 1000px Höhe und Breite',
            logo: 'Logo',
            remove: 'Logo entfernen',
            select: 'Logo auswählen',
            title: 'Logo der Kanzlei',
          },
          TextStylesForDocuments: {
            field: {
              color: 'Farbe',
              fontFamily: 'Schriftart',
              fontSize: 'Schriftgröße',
              lineHeight: 'Zeilenabstand',
              marginBottom: 'Abstand unten',
              marginTop: 'Abstand oben',
            },
            inputs: {
              h1: 'h1',
              h2: 'h2',
              h3: 'H3',
              testimonial: 'Testimonial',
              testimonialSmallText: 'Testimonial kleiner Text',
              textBlock: 'Standard-Text',
              textLessImportant: 'Unwichtiger-Text',
              title: 'Titel',
            },
            title: 'Text-Stile für Dokumente',
          },
          confirmSubmit: 'Bitte bestätige kurz, dass du die Farbe ändern möchtest. Möchtest du die Farbe nun ändern?',
          customize: 'Detail-Einstellungen anzeigen',
          errors: {
            reset: 'Es gab ein Problem beim resetten der Stile',
          },
          inputs: {
            font: {
              placeholder: 'Wähle eine Schriftart',
            },
          },
          reset: 'Stile zurücksetzen',
          resetConfirmModalMessage:
            'Achtung: möchtest du wirklich die Stile zurücksetzen? Du wirst deine Farb-Einstellungen verlieren. Fortfahren?',
          title: 'Corporate Identity',
        },
        Email: {
          inputs: {
            activeUserToBCC: {
              label: 'Sende BCC Kopie an aktuellen Benutzer',
              tooltip:
                'Soll der aktuelle Benutzer beim Senden einer E-Mail eine Kopie an seine E-Mail-Adresse erhalten? Wir empfehlen, die Funktion anzuschalten.',
            },
            attachmentsSizeLimit: {
              label: 'Größenlimit für E-Mail Anhang (MB)',
            },
            bcc: {
              label: 'Kopie aller E-Mails an folgende E-Mail als BCC senden',
            },
            from: {
              label: 'Absender E-Mail Adresse',
              tooltip: 'Die hier eingetragene E-Mail erscheint als Absender E-Mail Adresse',
            },
            mailServiceProvider: {
              default: 'über KanzleiPilot Server senden',
              smtp: 'über eigenen SMTP-Server senden',
            },
            options: {
              label: 'Verbindungs-Optionen',
              requireTLS: {
                tooltip:
                  'Wenn hier ein Haken gesetzt und "TLS aktivieren" deaktiviert ist, versucht der Server STARTTLS zu verwenden.',
              },
              useSSLv3: {
                tooltip:
                  'SSL v3 ist eine Alternative, die wir verwenden, wenn TLS nicht funktioniert (Port 465). For Port 587 oder 25 kannst du es optional deaktivieren.',
              },
              useTLS: {
                tooltip: 'Aktiviere TLS, wenn du Port 465 nutzt. Lasse es Port 587 oder 25 abgewählt',
              },
            },
            requireTLS: 'TLS verbindlich anfordern',
            smtpPassword: 'SMTP Passwort',
            smtpPort: 'SMTP Port',
            smtpServer: 'SMTP Server',
            smtpUserName: 'SMTP Nutzername',
            useSSLv3: 'SSL aktivieren',
            useTLS: 'TLS aktivieren',
          },
          otherSettingTitle: 'Sonstige Einstellungen',
          saveAndTestEmail: 'Teste den E-Mail-Versand',
          saveAndTestEmailAlert: {
            description:
              'Mit der Option "über KanzleiPilot Server senden" kannst du ganz ohne technische Konfiguration E-Mails über KanzleiPilot versenden. Mit der Option werden die E-Mails über den E-Mail Server von KanzleiPilot gesendet. Als Absender-Adresse erscheint beim Empfänger z.B. "deine.email@kanzlei.de gesendet über kanzleipilot.de". Alternativ kannst du mit der Option "über eigenen SMTP-Server senden" kannst du die E-Mails über deinen eigenen Server versenden - "gesendet über kanzleipilot.de" taucht damit dann nirgendwo auf. Bitte kontaktiere deinen ASP Dienstleister für die Zugangsdaten zu deinem SMTP Server.',
            message: 'Tipp',
          },
          saveAndTestEmailModal: {
            cancelText: '$t(common.cancel)',
            okText: '$t(common.ok)',
            title: 'Wir werden eine Text-E-Mail an deine Adresse ({{email}}) schicken',
          },
          setBestSMTPSettingsTitle:
            'Du hast gerade die SMPT Einstellungen geändert. Möchtest du, dass wir nun die sicherste Port und Verbindungs-Option für dich finden?',
          settingTitle: 'SMTP Server Einstellungen',
          signatureTitle: 'E-Mail Signatur',
          smtpSetting: {
            button: 'Finde und setze beste SMTP Konfiguration',
            found:
              'Wir haben eine SMTP-Konfiguration gefunden und die Einstellungen aktualisiert. Bitte drücke den "Teste den E-Mail-Versand"-Knopf um eine Test-Email zu versenden.',
            loading:
              'Wir suchen gerade das beste SMTP Setting für den Host. Bitte warte und lass diese Seite geöffnet...',
            notFound:
              'Wir haben leider KEINE passende SMTP Konfiguration gefunden. Etwas stimmt nicht. Bitte überpüfe, ob bei der Eingabe des SMTP Nutzernamens, Passworts oder Servers ein Tippfehler existiert. Du kannst unten auf "Details anzeigen" drücken, um zu sehen, welche Einstellungen wir getestet haben.',
            showDetails: 'Details anzeigen',
            validationError:
              'Bitte fülle zuerst in der Form unten folgende Felder aus: SMTP Nutzername, SMTP Passwort, SMTP Server und From". Drücke dann den Knopf erneut.',
          },
          title: 'E-Mail Einstellungen',
        },
        ImportCompanies: {
          alert: {
            step1: {
              description: 'Excel-Muster herunterladen',
              linkDescription: '/StammdatenImportMusterdatei.xlsx',
              message: 'Lade hier eine CVS Datei hoch, um Mandantendaten zu importieren',
            },
            step3: {
              description: '',
              message:
                'Wähle, welcher Wert automatisch gesetzt werden soll, sollte für einen Datensatz KEIN Unternehmenstyp vorhanden sein.',
            },
          },
          alertLimitCompany:
            'Deine Lizenz reicht nicht aus, um {{numberCompaniesInsert}} Mandanten zu importieren. Du hast nur noch {{nrOfCompaniesLimit}} freie Mandantenplätze. Bitte kontaktiere das KanzleiPilot-Team, damit wir dir helfen können.',
          completePage: {
            numberInsert: '{{count}} neues Mandant angelegt',
            numberInsert_plural: '{{count}} neue Mandanten angelegt',
            numberUpdate: '{{count}} Mandat aktualisiert',
            numberUpdate_plural: '{{count}} Mandanten aktualisiert',
            tip: 'Hinweis: Die Änderungen kannst du in der Mandantenansicht nachvollziehen.',
            title: 'Import erfolgreich abgeschlossen.',
          },
          errors: {
            duplicateIdentifier:
              'Diese Zeile enthält eine Mandantennummer, die in mindestens einer weiteren Zeile verwendet wird. Mandantennummern müssen eindeutig sein. Bitte bearbeite deine CSV Datei, um diese Zeile zu importieren.',
            duplicateName:
              'Diese Zeile enthält einen Mandantennamen, der in mindestens einer weiteren Zeile verwendet wird. Mandantennamen müssen eindeutig sein. Bitte bearbeite deine CSV Datei, um diese Zeile zu importieren.',
          },
          fields: {
            city: 'Ort',
            cityCode: 'PLZ',
            commercialObject: 'Unternehmensgegenstand',
            country: 'Land',
            defaultCompanyType: 'Standard Unternehmensform',
            houseNumber: 'Straße-Nr',
            identifier: 'Mandanten-Nummer',
            importAcademicTitle: 'Akademischer Grad',
            importCompanyType: 'Unternehmensform',
            importEmail: 'E-Mail',
            importSalutation: 'Anrede',
            name: 'Name',
            street: 'Straße',
          },
          navigationButtons: {
            import: '$t(common.navigationButtons.import)',
            next: '$t(common.navigationButtons.next)',
            previous: '$t(common.navigationButtons.previous)',
          },
          numberImport: '{{numberCompaniesInsert}} Mandanten zum Hinzufügen',
          numberUpdate: '{{numberCompaniesUpdate}} Mandanten zum Aktualisieren',
          status: {
            invalid: 'Ungültig',
            valid: 'Korrekt',
          },
          step1: 'Datensatz hochladen',
          step2: 'Spalten zuweisen',
          step3: 'Standardwerte setzen',
          step4: 'Vorschau',
          table: {
            after: 'After',
            before: 'Before',
            city: '$t(admin.Setting.ImportCompanies.fields.city)',
            cityCode: '$t(admin.Setting.ImportCompanies.fields.cityCode)',
            columnSource: {
              placeholder: 'bitte auswählen',
              title: 'Spalte in Csv-Datei',
            },
            columnTarget: 'Ziel',
            companyIdentifier: '$t(admin.Setting.ImportCompanies.fields.companyIdentifier)',
            country: '$t(admin.Setting.ImportCompanies.fields.сountry)',
            importType: {
              insert: 'neu',
              label: 'Aktion',
              update: 'aktualisieren (existiert bereits)',
            },
            name: '$t(admin.Setting.ImportCompanies.fields.name)',
            status: 'Status',
            street: '$t(admin.Setting.ImportCompanies.fields.street)',
          },
          title: 'Mandanten importieren',
          uploadButton: 'Lade CSV-Datei hoch',
        },
        LicenseOverview: {
          columns: {
            licenseStarted: 'Lizenz gestartet am',
            nrOfCompaniesCreated: 'Anzahl von Mandanten im System',
            nrOfCompaniesLimit: 'Mandanten-Limit',
            nrOfUsersCreated: 'Anzahl von Benutzern im System',
            nrOfUsersLimit: 'Anzahl durch Lizenz erlaubter Benutzer',
          },
          restartOnboarding: 'Einrichtungsassistent neu starten',
          title: 'Lizenz Übersicht',
        },
        ShoppingCartPreferences: {
          HelperWidget: {
            howUseButton: 'Anleitung: Voreinstellungen verwenden',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung für die Voreinstellungen der Menükarte',
              videoCaption: 'Erfahre, mehr über die Menükarte Voreinstellungen',
              videoUrl: 'https://vimeo.com/541298518',
            },
          },
          card: {
            customPrice: 'Individuelle Honorare',
            viewOptions: 'Setze deine Standard Ansichtsoptionen',
          },
          fields: {
            allowCustomPrices: {
              label: 'Erlaube Nutzern, individuelle Honorare zu setzen',
              tooltip:
                'Wenn diese Option aktiviert ist, kannst du in der interaktiven Menükarte Standardhonorare überschreiben und so für einzelne Leistungen individuelle Honorare vereinbaren. Klicke in der interaktiven Menükarte einfach auf das Honorar einer Leistung, um ein individuelles Honorar zu setzen',
            },
            feeType: {
              tooltip:
                'Kalkulations-Modus in dem KanzleiPilot die Honorare kalkuliert. Im Standard Modus ist alles wie gehabt: einmalige, monatliche und jährliche Leistungen werden transparent dargestellt. Beim Unternehmerhonorar Modus werden monatlich und jährliche Positionen zu einem monatlichen Honorar zusammengefasst und ihre Einzelhonorare werden mit dem Hinweis „Die Leistung ist im Unternehmerhonorar inklusive“ versteckt. Einmalige Leistungen werden auch im Unternehmerhonorar transparent dargestellt.',
            },
            showCalculated: {
              label: 'Zeige Standardhonorare in Dokumenten',
            },
            showCategoryTotal: {
              label: 'Honorarvorschau für jeden Bereich anzeigen',
              tooltip:
                'In der interaktiven Menükarte hat jeder Bereich eine Honorarvorschau. Hier kannst du einstellen, ob diese Vorschau im Standard angezeigt oder versteckt sein soll.',
            },
            showDiscount: {
              label: 'Nachlässe anzeigen',
              tooltip:
                'Sollen Nachlässe angezeigt werden? Wir empfehlen dir: Schalte die Anzeige von Nachlässen im Standard AUS und fokussiere dich darauf, dem Mandanten seinen Nutzen zu verdeutlichen (siehe “Einstellungen > Menükarte > Menükarte Voreinstellungen”).',
            },
            showPrice: {
              label: 'Honorare anzeigen',
              tooltip:
                'Sollen Honorare beim Öffnen der Menükarte angezeigt werden? Wir empfehlen dir: Schalte die Honoraranzeige im Standard AUS (siehe “Einstellungen > Menükarte > Menükarte Voreinstellungen”).',
            },
          },
          pricingSetting: {
            roundPrice: {
              defaultOption: 'Globale Einstellung anwenden',
              title: 'Runde alle Honorare wie folgt:',
            },
            showDigits: {
              title: 'Zeige Nachkommastellen',
            },
            title: 'Honorare runden',
          },
          resetConfirmModalMessage: 'Bist du sicher, dass du die Voreinstellungen zurücksetzen möchtest?',
          selectDefaultEmailTemplate: 'Standard E-Mail Vorlage auswählen',
          title: 'Menükarte Voreinstellungen',
        },
        TenantCompany: {
          HelperWidget: {
            howUseButton: 'Anleitung: Stammdaten der Kanzlei einstellen',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Anleitung: Stammdaten der Kanzlei einstellen',
              videoCaption: 'Erfahre, wie du die Stammdaten deiner Kanzlei einstellen kannst',
              videoUrl: 'https://vimeo.com/541298316',
            },
          },
          inputs: {
            city: {
              title: 'Stadt',
            },
            cityCode: {
              title: 'PLZ',
            },
            country: {
              title: 'Land',
            },
            houseNumber: {
              title: 'Hausnummer',
            },
            name: {
              title: 'Name der Kanzlei',
            },
            officeEmail: {
              title: 'E-Mail (Sekretariat)',
            },
            phoneNumber: {
              title: 'Telefon',
            },
            street: {
              title: 'Straße',
            },
            website: {
              title: 'Webseite',
            },
          },
          title: 'Unsere Kanzlei',
        },
      },
      addCategoryModal: {
        addModalTitle: 'Neuen Bereich anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Ein Bereich mit diesem Namen existiert bereits. Bitte ändere den Namen',
        nameFieldDescription: 'Name des Bereichs',
        nameFieldLabel: 'Name',
        ok: '$t(common.ok)',
      },
      addDiscountButton: 'Neuen Nachlass anlegen',
      addDocumentTemplateModal: {
        addModalTitle: 'Neuen Dokumentenvorlage anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Eine Dokumentenvorlage mit diesem Namen existiert bereits. Bitte ändere den Namen',
        nameFieldDescription: 'Name der Dokumentenvorlage',
        nameFieldLabel: 'Name',
        ok: 'Ok',
      },
      addInputButton: 'Neues Eingabefelder anlegen',
      addItemButton: 'Neue Leistung anlegen',
      addStaticItemButton: 'Neue Leistung anlegen',
      categoryNameInput: {
        label: 'Name',
      },
      categoryTexts: {
        guidanceText: {
          label: '$t(common.fields.guidanceText.label)',
        },
        guidanceVideoId: {
          label: '$t(common.fields.guidanceVideoId.label)',
        },
        introForDocument: {
          label: '$t(common.fields.introForDocument.label)',
          tooltip:
            'Wird im Blocktyp “Details zu den ausgewählten Dienstleistungen” unterhalb des Bereichsnamens angezeigt und erscheint so z.B. im Angebot. Gib hier eine Vogelperspektive über den Bereich, beschreibe den Nutzen für den Mandanten und positioniere dich als Experte.',
        },
        name: {
          label: 'Name',
          tooltip: 'Name des Bereiches. Erscheint so in der interaktiven Menükarte und in den Dokumenten.',
        },
        subject: {
          tooltip:
            'Kurzbeschreibung, die in der interaktiven Menükarte unterhalb des Bereichsnamens erscheint. Sinnvoll, um deinen Expertenstatus und den Nutzen für den Mandanten zu betonen.',
        },
      },
      deleteCategory: 'Bereich löschen',
      deleteCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du den Bereich löschen möchtest?',
      },
      deleteDiscountConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du den Nachlass löschen möchtest?',
      },
      deleteInputConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du das Eingabefelder löschen möchtest?',
      },
      deleteItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Leistung löschen',
        title: 'Bist du sicher, dass du die Leistung löschen möchtest?',
      },
      deleteStaticItemConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Leistung löschen möchtest?',
      },
      deleteStatusConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title:
          'Bist du sicher, dass du den Status löschen möchtest? Existierende Menükarten mit diesem Status werden auf den Standard-Status gesetzt',
      },
      deleteUserConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Leistung sichern möchtest?',
      },
      deleteVariableConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        title: 'Bist du sicher, dass du die Variable löschen möchtest?',
      },
      discountFieldModal: {
        addModalTitle: 'Neuen Nachlass anlegen',
        duplicatedErrorMessage: 'Ein Nachlass mit diesem Namen existiert bereits!',
        editModalTitle: 'Nachlass bearbeiten',
        nameFieldDescription: 'Name des Nachlasss',
        nameFieldLabel: 'Name',
        typeFieldAbsolute: '$t(common.Discount.absolute)',
        typeFieldLabel: 'Typ',
        typeFieldPercent: '$t(common.Discount.percent)',
        valueFieldDescription: 'Wert des Nachlasss (in %)',
        valueFieldLabel: 'Wert',
      },
      discountTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Type',
        value: 'Wert',
      },
      discountUsedInShoppingCartConfirmation: {
        cancel: '$t(common.cancel)',
        ok: '$t(common.ok)',
        titleDelete: 'Nachlass wird in einer bestehenden Menükarte verwendet. Möchtest du ihn löschen?',
        titleUpdate: 'Der Nachlass wird in einer existierenden Menükarte verwendet. Möchtest du ihn ändern?',
      },
      discountsTitle: 'Nachlässe',
      duplicateCategoryConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Ein Bereich mit diesem Namen existiert bereits. Bitte ändere den Namen',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Bereich duplizieren',
        title: 'Bereich duplizieren',
      },
      duplicateItemConfirmation: {
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage:
          'Name des Duplikats von Leistungen sind in KanzleiPilot eindeutig. Bitte trage den Namen des Duplikats ein.',
        ok: '$t(common.ok)',
        openModalButtonTitle: 'Leistung duplizieren',
        title: 'Bitte trage den Namen für die neue Leistung ein',
      },
      guidanceAlert: {
        category: 'Tipp vom KanzleiPilot-Team',
        closeForever: {
          buttonTitle: 'Für immer schließen',
          title: 'Do you want to hide forever {{name}} guide?',
        },
        item: 'Tipp vom KanzleiPilot-Team',
      },
      importCategoryPresetsModal: {
        errorMessage:
          'Ein Bereich mit diesem Namen existiert bereits. Bitte nenne den existierende Bereich zunächst um',
        import: 'importieren',
        title: 'Bereich und Leistungen aus Bibliothek importieren',
      },
      inputFieldModal: {
        addModalTitle: 'Neues Eingabefelder anlegen',
        cancel: '$t(common.cancel)',
        comboOptions: {
          addOption: 'Neue Option anlegen',
          boxLabel: 'Auswahlfeld Optionen',
          modal: {
            addTitle: 'Neue Option anlegen',
            cancel: '$t(common.cancel)',
            duplicatedErrorMessage: 'Eine Option mit diesem Namen existiert bereits!',
            editTitle: 'Option bearbeiten',
            nameFieldDescription: 'Option Name',
            nameFieldLabel: 'Name',
            ok: '$t(common.ok)',
            valueFieldDescription: 'Option Wert',
            valueFieldLabel: 'Wert',
          },
          table: {
            actionsColumnLabel: '',
            deleteOptionConfirmation: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Bist du sicher, dass du die Option löschen möchtest?',
            },
            nameColumnLabel: 'Name',
            valueColumnLabel: 'Wert',
          },
        },
        duplicatedErrorMessage: 'Ein Eingabefelder mit diesem Namen existiert bereits!',
        editModalTitle: 'Eingabefelder bearbeiten',
        fields: {
          allowedRange: {
            label: 'Erlaubter Wertebereich',
          },
          allowedRangeMaxValue: {
            label: 'Maximalwert',
            maxIsLessThenDefaultValue: 'Bitte trage einen Maximalwert ein, der größer als der Standardwert ist',
            maxIsLessThenMin: 'Bitte trage einen Maximalwert ein, der größer als der Mindestwert ist',
          },
          allowedRangeMinValue: {
            label: 'Mindestwert',
            minIsMoreThenDefaultValue: 'Bitte trage einen Mindestwert ein, der kleiner als der Standardwert ist',
            minIsMoreThenMax: 'Bitte trage einen Mindestwert ein, der größer als der Maximalwert ist',
          },
          defaultInput: {
            label: '$t(common.fields.defaultValue.label)',
          },
          defaultType: {
            input: 'manuellen Wert eintragen',
            label: 'Wie soll der Standardwert gesetzt werden?',
            variable: 'von Variable übernehmen',
          },
          defaultValue: {
            defaultValueIsOutOfRange: 'Bitte trage einen Standardwert ein, der sich im erlaubten Bereich befindet',
          },
          defaultVariable: {
            label: 'Übernehme Wert von folgender Variable',
          },
          name: {
            description: 'Eingabefelder Name',
            label: '$t(common.fields.name.label)',
          },
          optionPDFNameTitle: {
            label: 'Überschrift für Optionen',
            placeholder: 'Überschrift für Optionen',
          },
          optionPDFValueTitle: {
            label: 'Überschrift für Wert',
            placeholder: 'Überschrift für Wert',
          },
        },
        ok: '$t(common.ok)',
        pdfConfigurationLabel: 'Überschriften für PDF Dateien',
        typeFieldLabel: 'Typ',
        unitNameDescription: 'Einheit',
        unitNameLabel: 'Einheit',
        variableAlert: 'Hinweis: Der Wert der ausgewählten Variabel beträgt {{value}}',
      },
      inputFieldTableColumns: {
        actions: '',
        name: 'Name',
        type: 'Typ',
      },
      inputsTitle: 'Eingabefelder',
      itemModal: {
        ConditionalVisibilityHelperWidget: {
          howUseButton: 'Anleitung: Sichtbarkeit nach Mandantentyp bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Sichtbarkeit nach Mandantentyp bearbeiten',
            videoCaption: 'Erfahre, wie sich die Sichtbarkeit auswirkt',
            videoUrl: 'https://vimeo.com/542078173',
          },
        },
        InputFieldsHelperWidget: {
          howUseButton: 'Anleitung: Eingabefelder verwenden',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Eingabefelder in Leistung verwenden',
            videoCaption: 'Erfahre, wie du die Leistungs-Position bearbeiten kannst',
            videoUrl: 'https://vimeo.com/542032063',
          },
        },
        PriceHelperWidget: {
          howUseButton: 'Anleitung: Honorare konfigurieren',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Honorare konfigurieren (Einführung)',
            videoCaption: 'Erfahre, wie du Honorare in KanzleiPilot konfigurierst',
            videoUrl: 'https://vimeo.com/541286429',
          },
        },
        ScalesHelperWidget: {
          howUseButton: 'Anleitung: Staffelhonorare einstellen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Konfigurieren von Staffelhonoraren',
            videoCaption: 'Erfahre, wie du Staffelhonorare leicht in die Honorarformel einbaust',
            videoUrl: 'https://vimeo.com/541286974',
          },
        },
        TextsHelperWidget: {
          howUseButton: 'Anleitung: Texte bearbeiten',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung: Texte für Leistung bearbeiten',
            videoCaption: 'Erfahre, wo die Texte erscheinen',
            videoUrl: 'https://vimeo.com/542074372',
          },
        },
        addTitle: 'Neue Leistung anlegen',
        cancel: '$t(common.cancel)',
        duplicatedErrorMessage: 'Leistung mit diesem Namen existiert bereits!',
        editSubTitleForItem: 'Leistung {{itemName}} bearbeiten',
        editTitle: 'Leistung bearbeiten',
        guideAlert: {
          guidanceVideoButton: 'Display guidance video',
          guidanceVideoModalTitle: 'Guidance video',
        },
        inputs: {
          benefits: {
            label: '$t(common.fields.benefits.label) (erscheint im Angebot)',
            tooltip:
              'Nutzen für den Mandanten, der so im Angebot erscheint. Fokussiere dich hier auf den Mehrwert, den der Kunde durch seine Investition in die Leistung hat.',
          },
          calculationMode: {
            label: '$t(common.fields.calculationMode.label)',
            tooltip:
              'Wähle hier, ob du grundsätzlich einen Wert für dein Honorar berechnen möchtest, oder rein "nach Zeitaufwand" abrechnen möchtest. Wir empfehlen dir nach Möglichkeit einen Wert zu berechnen und zu kommunizieren, da dies für deinen Mandanten deutlich transparenter ist.',
          },
          descriptionForContract: {
            label: 'Auftragsumfang (erscheint in Vergütungsvereinbarung und Steuerberatungsvertrag)',
            tooltip:
              'Im Auftragsumfang beschreibst du ganz klar den Umfang deiner Dienstleitung. Grenze hier deine Leistung klar ab. Der Text erscheint im Standard in der Vergütungsvereinbarung und im Steuerberatervertrag.',
          },
          guidanceText: {
            label: '$t(common.fields.guidanceText.label)',
            placeholder: '$t(common.fields.guidanceText.label)',
          },
          guidanceVideoId: {
            label: '$t(common.fields.guidanceVideoId.label)',
            placeholder: '$t(common.fields.guidanceVideoId.label)',
          },
          highlightBindingness: {
            label: '$t(common.fields.highlightBindingness.label)',
            tooltip:
              'Möchtest du die Verbindlichkeit des Honorars besonders hervorheben? Dann wähle z.B. "dynamisch", um klar zu kommunizieren, dass dein Honorar mit veränderten/dynamischen Honorarparametern ein anderes sein wird. Tipp: Nutze das "Bitte beachten"-Textfeld einer Leistung, um die Verbindlichkeit noch weiter zu konkretisieren.',
          },
          infoText: {
            label: 'Info-Button (erscheint in Menükarte neben der Leistung)',
            tooltip:
              'Dieser Text erscheint in der interaktiven Menükarte, wenn du auf den Info-Button drückst. Betone den Nutzen für den Mandanten.',
          },
          minPrice: {
            label: '$t(common.fields.minPrice.label)',
            tooltip:
              'Mindesthonorar, welches dann gilt, wenn das in der Honorarformel errechnete Honorar geringer ausfällt. Lösche den Wert, um KEIN Mindesthonorar zu setzen.',
          },
          name: {
            label: 'Name',
            placeholder: '',
            tooltip: 'Name der Leistung. Erscheint so in der interaktiven Menükarte und in den Dokumenten.',
          },
          notesForFeeAgreement: {
            label: 'Honorarhinweis (erscheint in Vergütungsvereinbarung)',
            tooltip:
              'Der Honorarhinweis-Text erscheint im Standard in der Vergütungsvereinbarung. Hier kannst du z.B. die rechtliche Grundlage vermerken.',
          },
          notesToEmployee: {
            label: '$t(common.fields.notesToEmployee.label)',
            tooltip:
              'Platz für Interne Hinweise für dein Kanzlei-Team. Der Text erscheint in der Lese-Ansicht für eine existierende Menükarte. Sinnvoll, z.b: für den Hinweis zu verbundenen Auftragsnummern, etc.',
          },
          paymentInterval: {
            label: '$t(common.fields.paymentInterval.label)',
            monthlyLabel: '$t(common.Item.paymentIntervalValue.monthly)',
            oneOffLabel: '$t(common.Item.paymentIntervalValue.oneOff)',
            tooltip:
              'Zahlungsintervall der Leistung. Im Unternehmerhonorar Modus werden in der Menükarte jährliche und monatliche Leistungen für den Mandanten in einen monatlichen Honorar zusammengefasst.',
            yearlyLabel: '$t(common.Item.paymentIntervalValue.yearly)',
          },
          pleaseNote: {
            label: 'Bitte beachten (erscheint in Angebot, Steuerberatungsvertrag und Vergütungsvereinbarung)',
            tooltip:
              'Stelle hier ein Regelwerk auf: Wenn gilt das Honorar, was muss der Mandant liefern? Welche Informationen benötigst du von ihm? Welche Leistung ist nicht beinhaltet? Im Standard erscheint der Texte im Angebot, der Vergütungsvereinbarung und im Steuerberatervertrag.',
          },
          pricingFormula: {
            editConstants: 'Variablen bearbeiten',
            errors: {
              invalid: 'Bitte trage eine gültige {{name}} ein',
            },
            insertVariableMenu: {
              disabledMenuTooltip: {
                categoryMonthlyAdvance:
                  'Kategorie enthält keine aktive Leistung mit dem Zahlungsinterval monatlich oder jährlich.',
                inactiveItem: 'Leistung ist inaktiv.',
                onActualCostItem: 'Platzhalter ist deaktiviert, da die Leistung nach Zeitaufwand abgerechnet wird.',
              },
              subMenu: {
                category: 'Eingabefelder aus Bereich',
                categoryMonthlyAdvance: 'Monatliches Fixhonorar des Bereichs',
                categoryTotal: 'Wert eines Bereichs',
                function: 'Funktionen',
                item: 'Eingabefelder aus Leistung',
                itemTotal: 'Wert einer Leistung',
                scaleFunctionParameters: 'Parameter der Staffelfunktion',
                variable: 'Variablen',
              },
              title: 'Platzhalter einfügen',
            },
            label: '$t(common.fields.pricingFormula.label)',
            tooltip:
              'Die Honorarformel anhand der sich das Honorar berechnet. Tipp: Schaue dir hierzu die Erklär-Videos an, um diese wichtige Funktion genau kennen zu lernen.',
          },
          roundPrice: {
            label: 'Honorar runden',
            tooltip:
              'Wähle aus ob das von der Honorarformel errechnete Honorar gerundet werden soll. Tipp: Unter Einstellungen > Menükarte > Menükarte Voreinstellungen“ kannst du eine globale Rundung einstellen, die du hier aber für die individuelle Leistung überschreiben kannst.',
          },
          scales: {
            addModalLabel: 'Neue Staffelzeile anlegen',
            atLeastTwoError: 'Bitte lege mindestens 2 Staffeln an',
            cancel: '$t(common.cancel)',
            deleteModal: {
              cancel: '$t(common.cancel)',
              ok: '$t(common.ok)',
              title: 'Bist du sicher, dass du die Staffel löschen möchtest?',
            },
            duplicatedError: 'Staffeln müssen einzigartig sein',
            duplicatedErrorMessage: 'Staffel mit diesem Namen existiert bereits',
            editModalLabel: 'Staffel bearbeiten',
            emptyScaleList:
              'Es existieren noch keine Staffeln. Bitte klicke auf "$t(admin.itemModal.inputs.scales.addModalLabel)" um eine Staffel anzulegen',
            emptyTable:
              'Es existieren noch keine Staffeln. Bitte klicke auf "$t(admin.itemModal.inputs.scales.addModalLabel)" um eine Staffel anzulegen',
            inputs: {
              formula: {
                error: 'Honorarformel ist ein Pflichtfeld',
                label: 'Wert (Honorarformel)',
              },
              graduatedScaleMode: {
                graduated: 'Stufen',
                label: 'Modus der Staffel-Funktion',
                standard: 'Standard',
                tooltip:
                  'Im “Standard“-Modus gibt die Staffelfunktion den Wert der jeweiligen Staffel zurück - in der Honorarformlel kannst du damit dann weiter rechnen. 2) Im “Stufen“-Modus summiert die Staffelfunktion stufenweise die Werte der kleineren Staffeln mit auf. Beispiel: Bei einer Konfiguration von (1 - 3 = 2, 4 - 6 = 1,5) wird bei einem Parameter von 4 der Wert 7,50 errechnet. Kalkulation = 3*2 + 1 * 1,5',
              },
              pricingFormulaTitle: {
                label: 'Überschrift für “Wert“-Spalte',
                placeholder: '',
                tooltip:
                  'In der Honorarformel kannst du mit der scale-Funktion Staffelhonorare realisieren. Hier kannst du die Staffeln und Werte konfigurieren, die du in der Honorarformel dann mit der scale-Funktion abrufen kannst.',
              },
              pricingFormulaUnit: {
                label: 'Einheit für “Wert”-Spalte',
                placeholder: 'Einheit',
                tooltip:
                  'Hier kannst du die Einheit der “Wert”-Spalte für Dokumente konfigurieren. Wenn du das Feld leer lässt, besitzt die Spalte keine Einheit. Wenn du damit Preis ausdrückst empfehlen wir dir hier “€” einzutragen.',
              },
              scaleTitle: {
                label: 'Überschrift für “Staffel“-Spalte',
                placeholder: '',
                tooltip:
                  'In der Honorarformel kannst du mit der scale-Funktion Staffelhonorare realisieren. Hier kannst du die Staffeln und Werte konfigurieren, die du in der Honorarformel dann mit der scale-Funktion abrufen kannst.',
              },
              scaleUnit: {
                label: 'Einheit für “Staffel”-Spalte',
                placeholder: 'Einheit',
                tooltip:
                  'Hier kannst du die Einheit für die “Staffel”-Spalte für Dokumenten konfigurieren. Wenn du das Feld leer lässt, besitzt die Spalte keine Einheit.',
              },
              unitName: {
                label: 'Einheit',
                placeholder: 'Einheit',
              },
              value: {
                label: 'Staffel',
              },
            },
            label: 'Staffeln konfigurieren?',
            ok: '$t(common.ok)',
            pdfConfigurationLabel: 'Überschriften für Staffel-Auflistung in Dokumenten',
            pdfConfigurationUnitsLabel: 'Einheiten für Staffel-Auflistung in Dokumenten',
            table: {
              columns: {
                pricingFormula: 'Wert (Honorarformel)',
                value: 'Staffel',
              },
            },
            tooltip:
              'In der Honorarformel kannst du mit der scale-Funktion Staffelhonorare realisieren. Hier kannst du die Staffeln und Werte konfigurieren, die du in der Honorarformel dann mit der scale-Funktion abrufen kannst.',
            wrapperLabel: 'Staffeln konfigurieren',
          },
          scalesList: {
            label: 'Staffel-Liste',
          },
          subTitle: {
            label: 'Kurzbeschreibung',
            placeholder: '',
            tooltip:
              'Kurzbeschreibung der Leistung, die in der interaktiven Menükarte unterhalb des Bereichsnamens erscheint. Sinnvoll, um den Fokus auf eine bestimmte Leistung zu lenken, den Nutzen für den Mandanten zu betonen und dich als Experte zu positionieren.',
          },
        },
        ok: '$t(common.yes)',
        pricingWarning:
          'Achtung: du hast in der Honorarformel ein negatives Honorar konfiguriert und gleichzeitig das Mindesthonorar auf {{value}} gesetzt. Das Mindesthonorar wird das kalkulierte Honorar überschreiben. Bitte entferne das Mindesthonorar, wenn das kalkulierte Honorar negativ sein soll.',
        tabs: {
          conditionalVisibility: 'Sichtbarkeit nach Mandantentyp',
          inputs: 'Eingabefelder',
          price: 'Honorar',
          texts: 'Texte',
        },
      },
      itemTable: {
        columns: {
          actions: '',
          active: 'aktiv',
          name: 'Name',
          paymentInterval: 'Zahlungsintervall',
          pricingFormula: 'Honorarformel',
        },
        title: 'Leistungen',
      },
      pricingFormulaInput: {
        functions: {
          StBVV_TabelleA: 'StBVV_TabelleA() Beispiel: "StBVV_TabelleA(140000)" gibt 1773 zurück',
          StBVV_TabelleB: 'StBVV_TabelleB() Beispiel: "StBVV_TabelleB(5000)" gibt 81 zurück',
          StBVV_TabelleC: 'StBVV_TabelleC() Beispiel: "StBVV_TabelleC(45000)" gibt 122 zurück',
          StBVV_TabelleD_a: 'StBVV_TabelleD_a() Beispiel "StBVV_TabelleD_a(951)" gibt 1738 zurück',
          StBVV_TabelleD_b: 'StBVV_TabelleD_b() Beispiel "StBVV_TabelleD_b(42500)" gibt 380 zurück',
          round: 'round() Beispiel: "round(7.8)" gibt 8 zurück',
          scale: 'scale(Zahl) nutzt die unten definierbare Staffel-Konfiguration',
        },
        functionsListText: 'Folgende Funktionen sind verfügbar:',
        howUseButton: 'Anleitung: Honorarformel verwenden',
        inputsListText: 'Folgende Eingabefelder sind verfügbar:',
        modalInfo: {
          helpText: '',
          title: 'Anleitung: So funktioniert die Honorarformel',
          videoCaption: 'Erfahre, wie die Honorarformel genau funktioniert',
          videoUrl: 'https://vimeo.com/541286672',
        },
        syntaxInstructions:
          'Drücke "Platzhalter einfügen" um einen Platzhalter einzufügen\nDrücke "@" um eine Liste der Eingabefelder anzuzeigen\nDrücke "#" um eine Liste der Funktionen anzuzeigen',
      },
      resetTextStylesConfirmation: {
        cancel: '$t(common.cancel)',
        title:
          'Bist du sicher, dass du den Text-Stil entfernen möchtest? Alle Text-Formatierung, wie z.B. Aufzählungen, fette und kursive Formatierungen, werden entfernt. Der Text selber bleibt erhalten. Fortfahren?',
        yes: '$t(common.yes)',
      },
      staticItemsFieldModal: {
        addModalTitle: 'Neue Leistung anlegen',
        duplicatedErrorMessage: 'Leistung mit diesem Namen existiert bereits. Bitte wähle einen anderen Namen',
        editModalTitle: 'Leistung bearbeiten',
        errors: {
          isEmpty: '{{label}} ist ein Pflichtfeld',
        },
        nameFieldDescription: 'Leistung',
        nameFieldLabel: 'Leistung',
        valueFieldDescription: 'Wert der Leistung (z.B. "90 €")',
        valueFieldLabel: 'Wert',
      },
      staticItemsTableColumns: {
        actions: '',
        name: 'Leistung',
        value: 'Wert',
      },
      staticItemsTitle: 'Statischer Leistungskatalog',
      textsTabSubmitButton: 'Änderungen speichern',
      textsTabTitle: 'Texte',
      textsTitle: 'Texte',
    },
    common: {
      ConditionalVisibility: {
        balanceOfAccountsGmbH: 'Bilanz - GmbH',
        balanceOfAccountsGmbHAndCoKg: 'Bilanz - GmbH & Co. KG',
        balanceOfAccountsGmbHAndCoKgSelfBooker: 'Bilanz - GmbH & Co. Kg (Selbstbucher)',
        balanceOfAccountsGmbHSelfBooker: 'Bilanz - GmbH (Selbstbucher)',
        balanceOfAccountsOther: 'Bilanz - Personengesellschaft',
        balanceOfAccountsOtherSelfBooker: 'Bilanz - Personengesellschaft (Selbstbucher)',
        balanceOfAccountsSingle: 'Bilanz - Einzelunternehmer',
        balanceOfAccountsSingleSelfBooker: 'Bilanz - Einzelunternehmer (Selbstbucher)',
        balanceOfAccountsUG: 'Bilanz - UG (haftungsbeschränkt)',
        balanceOfAccountsUGSelfBooker: 'Bilanz - UG (haftungsbeschränkt) (Selbstbucher)',
        balanceOfUnion: 'Bilanz - Verein',
        balanceOfUnionSelfBooker: 'Bilanz - Verein (Selbstbucher)',
        cashBasisAccountingCapital: 'Einnahmen-Überschuss-Rechnung - Personengesellschaft',
        cashBasisAccountingCapitalSelfBooker: 'Einnahmen-Überschuss-Rechnung - Personengesellschaft (Selbstbucher)',
        cashBasisAccountingSingle: 'Einnahmen-Überschuss-Rechnung - Einzelunternehmer',
        cashBasisAccountingSingleSelfBooker: 'Einnahmen-Überschuss-Rechnung - Einzelunternehmer (Selbstbucher)',
        cashBasisAccountingUnion: 'Einnahmen-Überschuss-Rechnung - Verein',
        cashBasisAccountingUnionSelfBooker: 'Einnahmen-Überschuss-Rechnung - Verein (Selbstbucher)',
        checkAll: 'Alle auswählen',
        other: 'Sonstige Rechtsform',
        otherSelfBooker: 'Sonstige Rechtsform (Selbstbucher)',
        privatePerson: 'Privatperson',
        uncheckAll: 'Keine auswählen',
      },
      Discount: {
        absolute: 'absolut',
        percent: 'prozentual',
      },
      Error: {
        required: '{{field}} ist ein Pflichtfeld',
      },
      InputField: {
        combo: 'Auswahlfeld',
        input: 'Eingabefeld',
        internal: 'Internes Eingabefeld (mit Slider)',
        name: 'Eingabefeld',
      },
      Item: {
        calculationMode: {
          onActualCost: 'nach Zeitaufwand',
          value: 'Honorarformel',
        },
        highlightBindingness: {
          dynamic: 'dynamisch',
          dynamicDativ: 'dynamischem',
          dynamicDativ_plural: 'dynamischen',
          estimated: 'geschätzt',
          estimatedDativ: 'geschätztem',
          estimatedDativ_plural: 'geschätzten',
          fixPriced: 'pauschal',
          fixPricedDativ: 'pauschalem',
          fixPricedDativ_plural: 'pauschalem',
          noHighlight: 'nicht hervorheben',
        },
        paymentIntervalValue: {
          fixedMonthly: 'Monatliches Unternehmerhonorar',
          monthly: 'monatlich',
          oneOff: 'einmalig',
          yearly: 'jährlich',
        },
        recursive:
          'Du kannst dich NICHT auf diese Leistung beziehen, da sie sich selber in ihrer Honorarformel auf diese Leistung bezieht (zirkuläre Abhängigkeit)',
        scaleError:
          'Bitte konfiguriere zunächst Staffeln, um die “scale”-Funktion in der Honorarformel verwenden zu können',
        switchToOnActualCost:
          'Honorar-Modus kann nicht "nach Zeitaufwand" umgestellt werden, da sie in Leistungen {{items}} verwendet wird.',
      },
      MoreInfoWidget: {
        book: {
          calendly: '15 Minuten Zoom-Gespräch verabreden',
          calendlyUrl: '$t(common.urls.calendlyUrl)',
          title: 'Hast du noch Fragen? Dann buche gerne ein Zoom-Gespräch',
        },
      },
      RoundPrices: {
        noRound: 'NICHT runden',
        roundUpTo1: 'Aufrunden auf 1 €',
        roundUpTo10: 'Aufrunden auf 10 €',
        roundUpToMultiple25: 'Aufrunden auf 25 € (0, 25, 50 oder 75)',
      },
      Variables: {
        types: {
          float: 'Zahl',
          percent: 'Prozent',
          pricePerHour: 'Stundensatz (€)',
        },
      },
      activateYourAccount: 'Jetzt Zugang aktivieren',
      alertInAcceptInvitationForm: 'Bitte setze jetzt dein Passwort, um deinen KanzleiPilot-Zugang zu aktivieren',
      and: 'und',
      buttons: {
        resendInvitation: {
          tooltip:
            'Einladung via E-Mail senden. Tipp: Falls der Nutzer sein Passwort vergessen hat, kann er einfach im Login-Bildschirm die Funktion "Passwort vergessen" nutzen',
        },
        resendVerificationEmail: 'Bestätigungsmail erneut senden',
      },
      cancel: 'Abbrechen',
      closeToastText: 'Ok, verstanden',
      columns: {
        actions: 'Aktionen',
        body: 'Inhalt',
        category: 'Bereich',
        createdAt: 'Erstellt am',
        createdBy: 'Erstellt von',
        data: 'Daten',
        document: 'Dokument',
        field: 'Field',
        internalNote: '$t(common.fields.notesToEmployee.label)',
        interval: 'Intervall',
        item: 'Leistung',
        name: 'Name',
        nrOfAllowedCompaniesByPricingPlan: 'Anzahl der erlaubten Mandanten nach Lizenz',
        nrOfAllowedUsersByPricingPlan: 'Anzahl der erlaubten Nutzer nach Lizenz',
        price: 'Honorar',
        rootAdminEmail: 'Root-Admin Email',
        rootAdminName: 'Root-Admin Name',
        status: 'Status',
        subject: 'Betreff',
        tenantName: 'Mandant',
        type: 'Type',
        value: 'Wert',
        valueMonthly: 'Wert monatlich',
        valueOneOff: 'Wert einmalig',
        valueYearly: 'Wert jährlich',
      },
      company: {
        addCompany: 'Mandat hinzufügen',
        changeCompany: 'Mandat ändern',
        editCompany: 'Mandat bearbeiten',
      },
      contact: {
        addContact: 'Kontakt hinzufügen',
        deleteContact: 'Kontakt löschen',
        editContact: 'Kontakt bearbeiten',
      },
      dataPicker: {
        thisYear: 'This year',
      },
      duplicatedErrorMessage: '{{name}} with the same name already exists',
      error: 'Fehler',
      fields: {
        actions: {
          label: 'Aktionen',
        },
        benefits: {
          label: 'Nutzen',
        },
        body: {
          label: 'Inhalt',
          placeholder: 'Inhalt',
        },
        calculationMode: {
          label: 'Kalkulation',
        },
        defaultValue: {
          label: 'Standardwert',
        },
        descriptionForContract: {
          label: 'Auftragsumfang',
        },
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        guidanceText: {
          label: 'Coaching-Text',
          placeholder: 'Coaching-Text',
        },
        guidanceVideoId: {
          label: 'Coaching-Video',
          placeholder: 'Coaching-Video',
        },
        highlightBindingness: {
          label: 'Verbindlichkeit hervorheben',
        },
        infoText: {
          label: 'Info-Button',
        },
        introForDocument: {
          label: 'Einleitung für Dokumente',
        },
        isDefault: {
          label: 'Bei Neuanlage importieren?',
        },
        minPrice: {
          label: 'Mindesthonorar',
        },
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        nameRaw: {
          label: '$t(common.fields.name.label)',
        },
        notesForFeeAgreement: {
          label: 'Honorarhinweis',
        },
        notesToEmployee: {
          label: 'Interner Hinweis (für Kanzlei-Team)',
        },
        nrOfAllowedCompaniesByPricingPlan: {
          label: 'erlaubte Anzahl Mandanten (Lizenzlimit)',
          placeholder: 'erlaubte Anzahl Mandanten (Lizenzlimit)',
        },
        nrOfAllowedUsersByPricingPlan: {
          label: 'Number Of Allowed Users By Pricing Plan',
          placeholder: 'Number Of Allowed Users By Pricing Plan',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
        passwordConfirmation: {
          label: 'Passwort bestätigen',
          placeholder: 'Passwort bestätigen',
        },
        paymentInterval: {
          label: 'Zahlungsintervall',
        },
        pleaseNote: {
          label: 'Bitte beachten',
        },
        position: {
          label: 'Position',
          placeholder: 'Position',
        },
        pricingFormula: {
          label: 'Honorarformel',
        },
        projectName: {
          label: 'Projektname',
        },
        role: {
          label: 'Berechtigung',
          placeholder: 'Berechtigung',
        },
        status: {
          label: 'Status',
        },
        subject: {
          label: 'Betreff',
          placeholder: 'Betreff',
        },
        type: {
          label: 'Typ',
        },
        value: {
          label: 'Wert',
        },
        valueRaw: {
          label: '$t(common.fields.value.label)',
        },
      },
      howUseButton: 'Anleitung ansehen',
      inputs: {
        otp: {
          title: 'Einmaliges Passwort',
        },
      },
      navigationButtons: {
        done: 'Fertig',
        import: 'Import',
        next: 'Weiter',
        previous: 'Zurück',
      },
      next: 'Weiter',
      no: 'Nein',
      off: 'Aus',
      ok: 'Ok',
      on: 'An',
      quotes: [
        {
          quote: 'Gut zuhören können ist der halbe Erfolg',
        },
        {
          quote: 'Zeige deinen Mandanten, wie sehr du dich um sie kümmerst',
        },
        {
          quote: 'Persönlicher Service gewinnt das Spiel',
        },
        {
          author: 'Angelehnt an Mahatma Gandhi',
          quote: 'Ein Mandant ist der wichtigste Besucher in unserem Hause',
        },
        {
          author: 'Chip Bell',
          quote: 'Loyale Mandanten bestehen darauf, dass auch ihre Freunde mit dir Geschäfte machen',
        },
        {
          quote: 'Wir hören gut zu und liefern echten Mehrwert',
        },
        {
          quote: 'Tue Gutes und rede darüber',
        },
      ],
      roles: {
        ADMIN: 'Administrator',
        ROOT_ADMIN: 'Inhaber',
        SUPER_ADMIN: 'Super Administrator',
        USER: 'Nutzer (darf eigene Menükarten anlegen)',
        VIEWER: 'Nutzer (nur Lesezugriff)',
      },
      saved: 'gespeichert',
      saving: 'Änderung wird gespeichert....',
      showDigits: {
        always: 'immer anzeigen (z.B. 10,00 €)',
        avoid: 'nur wenn notwendig (z.B. 10 € or 8,99 €)',
      },
      stateInvitation: {
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv (hat Kanzlei verlassen)',
        INVITATION_SEND: 'Einladung gesendet',
      },
      statusTypes: {
        shoppingCart: 'Menükarte',
      },
      success: 'Aktualisierung erfolgreich durchgeführt!',
      successToastText: '$t(common.success)',
      tenant: {
        noAllowedToCreateCompany: 'Du kannst keine weiteren Mandanten anlegen',
      },
      toast: {
        errors: {
          activateInvitation: 'Aktivierung fehlgeschlagen',
          passwordReset: 'Failed to send password reset e-Mail',
          resendInvitation: 'Senden der Einladung fehlgeschlagen',
          sendInvitation: 'Senden der Einladung fehlgeschlagen',
          user: {
            delete: 'Löschen des Nutzers fehlgeschlagen',
          },
        },
        info: {
          updateIntroductionBlock:
            'Einige Personen wurden gelöscht, deshalb werden die Begrüßungsblock Daten automatisch angepasst',
        },
        success: {
          acceptInvitation: 'Einladung wurde erfolgreich akzeptiert',
          activateInvitation: '{{name}} wurde aktiviert',
          changePassword: 'Passwort wurde erfolgreich geändert',
          clipboard: 'Farbcode wurde in die Zwischenablage kopiert',
          passwordReset: 'E-Mail wurde gesendet',
          resendInvitation: 'Einladung wurde gesendet',
          sendChangeEmailVerificationEmail:
            'Bitte überprüfe deine Email {{email}}. Wir haben dir eine E-Mail gesendet, mit der du dein Passwort ändern kannst.',
          sendChangePasswordVerificationEmail:
            'Bitte überprüfe deine Email {{email}}. Wir haben dir eine E-Mail gesendet, mit der du dein Passwort ändern kannst.',
          sendInvitation: 'Einladung wurde gesendet',
        },
      },
      unsavedChangesModal: {
        cancel: '$t(common.cancel)',
        proceed: 'Fortfahren',
        title: 'Achtung: die Änderungen gehen verloren. Fortfahren?',
      },
      urls: {
        calendlyUrl:
          'https://calendly.com/kanzleipilot-erfolgsteam/kanzleipilot-fragen-und-antworten-session-15-minuten',
        sendEmailUrl: 'mailto:support@kanzleipilot.de',
      },
      vatType: {
        company: 'Umsatzsteuer',
        personal: 'Mehrwertsteuer',
      },
      yes: 'Ja',
    },
    dateFormats: {
      date: 'dd.MM.yyyy',
      dateMoment: 'DD.MM.YYYY',
      dateTime: "dd.MM.yyyy HH:mm 'Uhr'",
      month: 'MM.yyyy',
    },
    decimalSeparator: ',',
    deleteDocumentTemplateConfirmation: {
      cancel: '$t(common.cancel)',
      documentIsUsed:
        'Es existieren Menükarten, die diese Dokumentenvorlage verwenden. Möchtest du sie wirklich löschen? (Wichtig: Bereits generierte PDF-Dateien bleiben natürlich erhalten)',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die Dokumentenvorlage löschen möchtest?',
    },
    deleteShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Bist du sicher, dass du die Menükarte löschen möchtest?',
    },
    duplicateShoppingCartConfirmation: {
      cancel: '$t(common.cancel)',
      ok: '$t(common.ok)',
      title: 'Möchtest du die Menükarte duplizieren? Der Bezug der aktuellen Menükarte geht dann verloren?',
    },
    editShoppingCartModal: {
      HelperWidget: {
        howUseButton: 'Anleitung: So nutzt du die historische und aktuelle Konfiguration',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Anleitung: So nutzt du die historische und aktuelle Konfiguration',
          videoCaption: 'Erfahre, wie du die historischen und aktuelle Konfiguration nutzt',
          videoUrl: 'https://vimeo.com/715925363',
        },
      },
      explanationGlobal:
        'Du verwendest die aktuelle Konfiguration, die deine Kanzlei-Administratoren unter "Einstellungen > Leistungskatalog konfigurieren" bearbeiten können',
      explanationIndependent:
        'Du verwendest die Konfiguration, die zum Zeitpunkt der Erstellung der Menükarte aktiv war',
      globalOption: 'Bearbeitung mit aktueller Konfiguration',
      independentOption: 'Bearbeitung mit historischer Konfiguration',
      labelGlobal: 'Aktuelle Konfiguration',
      labelIndependent: 'Historische Konfiguration',
      migrationInfoText:
        'Diese Menükarte wurde vor der Speicherung von "historischen Konfigurationen" migriert und kann deshalb nicht mit der "historischen Konfiguration" bearbeitet werden. Du musst diese Menükarte einmal mit der "aktuellen Konfiguration" bearbeitet haben, um die "historische Konfiguration" nutzen zu können. Bei neuen Menükarten passiert das automatisch.',
      title: 'Welche Konfiguration willst du für die Bearbeitung der Menükarte verwenden?',
    },
    locale: 'Deutsch',
    localeCode: 'de',
    onboarding: {
      companyInfo: {
        stepTitle: 'Kanzleidaten',
        textAboveVideo: '',
        title: 'Kanzleidaten',
      },
      corporateIdentity: {
        chooseFontsTitle: 'Wähle die passende Schriftart für Überschriften',
        colorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.color)',
        colorPreview: 'Vorschau eines Buttons in Ihrer Farbgebung',
        fontFamilyInputLabel: 'Wähle die passende Schriftart für Textblöcke',
        pickCompanyColors: 'Wähle die Farben',
        preview: 'Vorschau',
        primaryColorInputLabel: '$t(admin.Setting.CorporateIdentity.CorporatePrimaryColor.inputs.primaryColor)',
        stepTitle: 'Corporate Identity',
        textAboveVideo: '',
        textFontPreview:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
        title: 'Wie sieht die Kanzlei aus?',
        titleFontFamilyInputLabel: 'Wähle die passende Schriftart',
        titleFontPreview: 'Dies ist eine Überschrift',
        uploadLogoAlert:
          'Wir empfehlen dir, dein Logo im PNG-Format hochzuladen. PNG unterstützt auch transparente Hintergründe und so kann sich dein Logo bestens in die interaktive Menükarte integrieren. Alternativ kannst du auch das JPG Format verwenden.',
        uploadLogoTitle: 'Corporate Identity: Logo und Schriftart',
        videoCaption: 'Erfahre, wie du deine Corporate Identity einstellst',
        videoUrl: 'https://vimeo.com/541265574',
      },
      finish: {
        buttonsTitle: 'Tipp: Schaue dir das Video an und erfahre, welche nächsten Schritte wir die vorschlagen.',
        calendly: 'Onboarding-Termin buchen (Calendly)',
        calendlyUrl: '$t(common.urls.calendlyUrl)',
        catalogueConfigurationButton: 'Leistungskatalog anpassen',
        manageUsersButton: 'Team-Mitglieder einladen',
        shoppingCartButton: 'Neue Menükarte anlegen',
        stepTitle: 'Einrichtung beenden',
        successfulMessage: 'Herzlichen Glückwunsch! Du hast die Einrichtung abgeschlossen',
        textAboveVideo: '',
        title: 'Einrichtung abgeschlossen',
        videoCaption: 'Henning Zacher empfiehlt dir die nächsten Schritte',
        videoUrl: 'https://vimeo.com/541268939',
      },
      navigationButtons: {
        done: '$t(common.navigationButtons.done)',
        next: '$t(common.navigationButtons.next)',
        previous: '$t(common.navigationButtons.previous)',
      },
      pickCategories: {
        stepTitle: 'Leistungskatalog',
        textAboveVideo: '',
        title: 'Auswahl der Bereiche für den Leistungskatalog',
        videoCaption: 'Erfahre, wie du den vorgefertigten Leistungskatalog importierst',
        videoUrl: 'https://vimeo.com/541268539',
      },
      pickDocuments: {
        infoConfirmationMessage:
          'Diese Dokumente wurde von Rechtsanwälten geprüft. Dennoch übernehmen wir für die Dokumente KEINE rechtliche Gewähr und machen KEINE Rechtsberatung. Die Dokumente sind nur als Vorlage gedacht. Bitte bestätige dies kurz.',
        infoMessage:
          'Wichtig: Diese Dokumente wurde von Rechtsanwälten geprüft. Dennoch übernehmen wir für die Dokumente KEINE rechtliche Gewähr und machen KEINE Rechtsberatung. Die Dokumente sind als Vorlage gedacht.',
        stepTitle: 'Dokumentenvorlagen',
        textAboveVideo: '',
        title: 'Auswahl der Dokumentenvorlagen',
        videoCaption: 'Erhalte einen Überblick über die Dokumentenvorlagen',
        videoUrl: 'https://vimeo.com/541268685',
      },
      pricingLevel: {
        hourlyRatesTable: {
          accountant: 'Steuerberater',
          employee: 'Mitarbeiter',
          jobType: 'Position',
          pricingFactor: 'Stundensatz',
          title: 'Wie sind die Stundensätze?',
        },
        pricingTable: {
          category: 'Bereich',
          loans: 'Lohn',
          monthlyBookkeeping: 'Finanzbuchhaltung',
          pricingFactor: 'Zehntelsätze',
          title: '',
          yearlyBusinessAccountsBalanceSheetBEU: 'Jahresabschluss (Bilanz BEU)',
          yearlyBusinessAccountsBalanceSheetRest: 'Jahresabschluss (Bilanz BGMBH, BGMCOKG, BPG)',
          yearlyBusinessAccountsCompanies: 'Jahresabschluss (EÜR - Personengesellschaft)',
          yearlyBusinessAccountsSingle: 'Jahresabschluss (EÜR - Einzelunternehmer)',
          yearlyPersonalAccounts: 'Einkommensteuer',
        },
        stepTitle: 'Honorarlevel',
        textAboveVideo: '',
        title: 'Wie ist dein Honorarlevel?',
        videoCaption: 'Erfahre, wie du das Honorarlevel setzt',
        videoUrl: 'https://vimeo.com/541268288',
      },
      pricingMode: {
        fixedMonthlyModeDescription:
          'Einmalige Leistungen werden transparent bepreist. Monatliche und jährliche Leistungen werden in einer monatlichen Gebühr zusammengefasst. Schafft Planungssicherheit für Ihren Mandanten.',
        fixedMonthlyModeTitle: 'Unternehmer­honorar-Modus',
        standardModeDescription:
          'Alle Leistungen werden transparent bepreist. 80 % der Steuerberater arbeiten im Standard Modus.',
        standardModeTitle: 'Standard-Modus',
        stepTitle: 'Honorarmodus',
        textAboveVideo: '',
        title: 'In welchem Honorarmodus möchtest du arbeiten?',
        videoCaption: 'Erfahre, wie sich die Honorarmodi unterscheiden',
        videoUrl: 'https://vimeo.com/541268439',
      },
      signatures: {
        addButton: 'neue unterschriftsberechtige Person hinzufügen',
        per: ' muss mindestens eine Person sein',
        stepTitle: 'Unterschriften',
        submitError: 'Bitte füge mindestens eine unterschriftsberechtige Person hinzu',
        textAboveVideo:
          'In einem typischen Angebot, welches du mit KanzleiPilot generierst, gibt es auch einen Begrüßungsblock. Hier erscheint unten der Name des Kanzleiinhaber / Partner. Lege hier bitte mindestens einen Steuerberater an und füge optional seine Unterschrift (als PNG- / JPEG-Datei) hinzu.',
        title: 'Unterschriften für Begrüßungsblatt',
        videoCaption: 'Erfahre, wo die Unterschriftsbilder erscheinen',
        videoUrl: 'https://vimeo.com/541268813',
      },
      welcome: {
        stepTitle: 'Willkommen',
        textAboveVideo:
          'Hallo {{userFirstName}}, lass uns nun KanzleiPilot einrichten! Es geht ganz schnell in nur 5 Minuten.',
        title: 'Willkommen bei KanzleiPilot',
        videoCaption: 'Henning Zacher heißt dich bei KanzleiPilot willkommen',
        videoUrl: 'https://vimeo.com/541261218',
      },
    },
    sharedPackage: {
      actualCostCalculation: {
        include: 'Beinhaltet',
        plus: 'Zzgl.',
      },
      code: '{{paymentInterval}} {{discountedValue}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice:
        '{{paymentInterval}} {{customPrice}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_discount:
        '{{paymentInterval}} {{discountedCustomPrice}}{{newlineOrWhitespace}}<i>(reduziert um {{discount}}, anstelle von <del>{{customPrice}}</del>)</i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice:
        '{{paymentInterval}} {{customPrice}} <i>(individuelles Honorar, anstelle von Standardhonorar <del>{{discountedValue}}</del>)<i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_customPrice_showCalculatedPrice_discount:
        '{{paymentInterval}} {{discountedCustomPrice}} (reduziert um {{discount}}. <i> Individuelles Honorar vor Nachlass <del>{{customPrice}}</del>, anstelle vom Standardhonorar <del>{{priceBeforeDiscount}}</del>)<i>$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount:
        '{{paymentInterval}} {{discountedValue}}{{newlineOrWhitespace}}<i>(reduziert um {{discount}}, anstelle von <del>{{priceBeforeDiscount}}</del></i>)$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      code_discount_noDiscountValue: '$t(sharedPackage.code)<i>(reduziert, anstelle von {{priceBeforeDiscount}})<i>',
      code_on_actual_cost: '{{paymentInterval}} nach Zeitaufwand',
      empty_pricing_formula: 'Bitte fülle die Honorarformel aus',
      fixedMonthlyFeeHint:
        '{{paymentInterval}}, Leistung ist im Unternehmerhonorar beinhaltet$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      fixedMonthlyFeeHint_discount:
        '{{paymentInterval}}, Leistung ist im Unternehmerhonorar beinhaltet$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      free_service:
        'Gratis Leistung{{separator}} {{paymentInterval}}$t(sharedPackage.highlightBindingness.{{highlightBindingness}})',
      highlightBindingness: {
        dynamic: '<br/><i>$t(common.Item.highlightBindingness.{{highlightBindingness}})</i>',
        estimated: '$t(sharedPackage.highlightBindingness.dynamic)',
        fixPriced: '$t(sharedPackage.highlightBindingness.dynamic)',
        noHighlight: '',
      },
      numberOfActualCost:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} Leistung nach Zeitaufwand',
      numberOfActualCost_plural:
        '$t(sharedPackage.actualCostCalculation.{{actualCostCalculation}}) {{count}} Leistungen nach Zeitaufwand',
      numberOfHighlightBindingness:
        '{{count}} Leistung mit $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) Wert {{value}}',
      numberOfHighlightBindingness_plural:
        '{{count}} Leistungen mit $t(common.Item.highlightBindingness.{{highlightBindingness}}Dativ) Werten {{value}}',
      variable_is_empty: 'Bitte trage einen Wert für {{variable}} ein',
    },
    superAdmin: {
      ManageAllUsers: {
        title: 'Alle Nutzer verwalten',
      },
      ManageTenants: {
        addTenant: 'Neue Kanzlei hinzufügen',
        addTenantModalTitle: 'Neue Kanzlei hinzufügen',
        columns: {
          licenseStarted: 'License Started',
          locale: 'Locale',
          nrOfAllowedCompaniesByPricingPlan: '$t(common.columns.nrOfAllowedCompaniesByPricingPlan)',
          nrOfAllowedUsersByPricingPlan: '$t(common.columns.nrOfAllowedUsersByPricingPlan)',
          nrOfCatalogueConfigurationEdits: 'Number Of  "Edits" In Catalogue-Configuration',
          nrOfCompanies: 'Number Of Companies',
          nrOfCompanyTypeEdits: 'Number Of  "Edits" In DocumentType-Configuration',
          nrOfCreatedShoppingCarts: 'Number Of Shopping-Carts Created',
          nrOfLogins: 'Number Of Logins',
          nrOfTotalActions: 'Number Of Total Actions',
          nrOfUsers: 'Number Of Users',
          rootAdminEmail: '$t(common.columns.rootAdminEmail)',
          rootAdminName: '$t(common.columns.rootAdminName)',
          status: '$t(common.columns.status)',
          tenantName: '$t(common.columns.tenantName)',
          tenantStatus: 'Tenant Status',
          timeStampOfLastAction: 'Timestamp Of Last Action',
          viewLogbook: 'View Logbook',
        },
        editTenantModalTitle: 'Kanzlei bearbeiten',
        emptyListMessage: 'Bis jetzt keinen Kanzleien-Aktionen vorhanden',
        fields: {
          disabled: {
            label: 'Aktiv',
            values: {
              disabled: 'Pausiert',
              enabled: 'Aktiv',
            },
          },
          licenseStarted: {
            label: 'Start der Lizenz',
          },
          locale: {
            label: 'Sprache',
            placeholder: 'Sprache',
            values: {
              de: 'Deutsch',
              en: 'Englisch',
            },
          },
          nrOfAllowedCompaniesByPricingPlan: {
            label: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedCompaniesByPricingPlan.placeholder)',
          },
          nrOfAllowedUsersByPricingPlan: {
            label: '$t(common.fields.nrOfAllowedUsersByPricingPlan.label)',
            placeholder: '$t(common.fields.nrOfAllowedUsersByPricingPlan.placeholder)',
          },
          rootAdminEmail: {
            label: 'E-Mail des Inhabers',
            placeholder: 'E-Mail des Inhabers',
          },
          rootAdminFirstName: {
            label: 'Vorname (Inhaber)',
            placeholder: 'Vorname (Inhaber)',
          },
          rootAdminLastName: {
            label: 'Nachname (Inhaber)',
            placeholder: 'Nachname (Inhaber)',
          },
          tenantName: {
            label: 'Kanzleiname',
            placeholder: 'Kanzleiname',
          },
        },
        loadingTenantsActions: "Loading tenant's actions",
        logBookModalTitle: 'Tenant\'s actions of the "{{tenantName}}"',
        stateInvitation: {
          ACTIVE: 'Aktiv',
          INACTIVE: 'Inaktiv',
          INVITATION_SEND: 'Einladung gesendet',
        },
        title: 'Kanzleien verwalten',
      },
    },
    user: {
      CompareToGlobal: {
        changed: {
          drawer: {
            global: 'Global',
            independent: 'Menükarte',
            label: 'Die festgeschriebene Honorarkonfiguration unterscheidet sich von der globalen',
            notSet: '(nicht gesetzt)',
          },
          label:
            'Die festgeschriebene Honorarkonfiguration unterscheidet sich von der globalen. Klicke auf das Icon, um die Details zu vergleichen',
        },
        deleted: {
          label:
            'Diese Element wurde in der globalen Konfiguration entfernt, ist hier in der festgeschriebenen Konfiguration noch vorhanden',
        },
        new: {
          label: 'Dieses Element wurde zur globalen Konfiguration hinzugefügt',
          staticItems: 'Statische Leistungen, die nur in globaler Konfiguration vorhanden sind',
        },
      },
      MigratedHelperWidget: {
        howUseButton: 'Erfahre, wie du mit migrierten Menükarten am Besten umgehst',
        modalInfo: {
          helpText: '',
          imageUrl: '',
          title: 'Erfahre, wie du mit migrierten Menükarten am Besten umgehst',
          videoCaption: '',
          videoUrl: 'https://vimeo.com/715753396',
        },
      },
      PrintShoppingCartPage: {
        compareTitle:
          'Die globale Dokumentenvorlage wurde in der Zwischenzeit aktualisiert (nach dem {{createdAt}}). Bitte beachte, dass sich die Elemente unterscheiden können.',
        download: 'Herunterladen',
        downloadAll: 'Alle Dokumente herunterladen',
        downloadDocuments: 'Dokumente',
        fields: {
          actions: '$t(common.fields.actions.label)',
          name: '$t(common.fields.name.label)',
        },
        print: 'Drucken',
        stateLoading: {
          download: 'Lade PDF herunter',
          generate: 'Generiere PDF',
          save: 'Speichere PDF',
        },
        successfulMessage: 'Menükarte wurde gespeichert',
      },
      SendShoppingCartPage: {
        loadingMessage: 'Dokumente werden generiert und E-Mail wird versendet...',
        successfulMessage: 'E-Mail wurde erfolgreich versendet',
      },
      Setting: {
        AccountSetting: {
          tabs: {
            main: {
              cancelChangeEmail: '$t(common.cancel)',
              changeEmail: 'Ändere deine E-Mail',
              fields: {
                email: 'E-Mail',
                firstName: 'Vorname',
                lastName: 'Nachname',
                newEmail: 'Neue E-Mail',
                otp: 'Einmaliges Passwort',
                phone: 'Telefon',
                position: 'Position',
              },
              saveEmail: 'E-Mail speichern',
              title: 'Dein Account',
            },
            password: {
              errors: {
                currentPassworWrong: 'Passwort ist falsch',
                noMatch: 'Passwörter müssen übereinstimmmen',
              },
              fields: {
                currentPassword: 'Aktuelles Passwort',
                newPassword: 'Neues Passwort',
                otp: 'Einmaliges Passwort',
                passwordConfirmation: 'Neues Passwort (bitte wiederholen)',
              },
              savePassword: 'Passwort speichern',
              title: 'Passwort',
            },
            variables: {
              title: 'Variablen',
            },
          },
          title: 'Dein Account',
        },
      },
      ShoppingCart: {
        Buttons: {
          email: 'E-Mail senden',
          errors: {
            formHasErrors: 'Es existieren Eingabefehler in dem Formular. Bitte behebe sie',
            noCompanyError: 'Bitte wähle ein Mandat aus, um diesen Button zu aktivieren',
            noContactsError: 'Bitte wähle einen Kontakt aus, um diesen Button zu aktivieren',
            noItemsSelectedError: 'Bitte wähle mindestens eine Leistung aus, um diese Aktionen zu aktivieren',
            noRecipientsWithEmail:
              'Bitte pflege zunächst für mindestens einen Kontakt des Mandanten eine E-Mail Adresse ein',
          },
          html: 'Vorschau öffnen',
          pdf: 'PDF Vorschau',
          pdfGenerating: 'PDF wird generiert. Bitte warte einen Moment und versuche es nochmal.',
          pdfNotFound:
            'PDF wurde nicht gefunden. Bitte kontaktiere unsere Support, damit wir das Problem beheben können.',
          print: 'Dokumente generieren',
          printModal: {
            title: 'Die Menükarte wird jetzt für den Druck gespeichert. Ok?',
          },
          store: 'Speichern',
          submitRevisionModal: {
            cancel: '$t(common.cancel)',
            ok: '$t(common.ok)',
            printTitle: 'Möchtest du die Menükarte speichern und die Dokumente generieren?',
            saveTitle: 'Möchtest du die Menükarte speichern?',
            sendTitle: 'Möchtest du die E-Mail versenden?',
          },
        },
        Category: {
          Discount: {
            label: 'Wähle einen Nachlass',
          },
          InputField: {
            mustBeANumberMessage: '{{name}} muss eine Zahl sein',
            requiredMessage: 'Bitte trage eine Werte für {{name}} ein',
          },
          Item: {
            bookmarkTooltip: 'Als Strategische Empfehlung markieren (erscheint als optionale Leistung)',
            customPriceDrawer: {
              CustomPriceHelperWidget: {
                howUseButton: 'Preise anpassen',
                modalInfo: {
                  helpText: '',
                  imageUrl: '',
                  title: 'Preise anpassen',
                  videoCaption: 'Erfahre, wie du die Preise anpasst',
                  videoUrl: 'https://vimeo.com/693703597',
                },
              },
              cancelConfirmation: 'Deine Änderungen gehen verloren. Fortfahren?',
              deleteCustomPriceConfirmation:
                'Möchtest du das individuelle Honorar entfernen und das Standardhonorar verwenden?',
              fields: {
                customPrice: {
                  label: 'Finales individuelles Honorar',
                  tooltip:
                    'Hier kannst du ein individuelles Honorar setzen, welches das Standardhonorar überschreibt. Bitte beachte, dass aktive Nachlässe auch auf das individuelle Honorar angewendet werden.',
                },
                defaultValue: {
                  label: '$t(common.fields.defaultValue.label)',
                },
                internalInputField: {
                  errors: {
                    infinity: 'Division durch 0 durch Werte der internen Eingabefelder. Bitte ändere die Werte',
                    max: '{{name}} muss kleiner oder gleich {{value}} sein',
                    min: '{{name}} muss größer oder gleich {{value}} sein',
                  },
                  label: 'Werte für interne Eingabefelder anpassen',
                  tooltip:
                    'Hier kannst du die Werte der internen Eingabefelder für die individuelle Kalkulation des Mandanten anpassen. Tipp: Nutze auch das Textfeld "Interne Notiz für das Kanzleiteam" um Details festzuhalten.',
                },
                internalNoteToTeam: {
                  label: 'Interne Notiz für das Kanzleiteam',
                  tooltip:
                    'Nutze dieses Textfeld, um deinem Team eine Notiz zu hinterlassen. Was ist bezüglich dieser Leistung besonders bei dem individuellen Mandanten? Der Mandant sieht diese Nachricht NICHT - sie erscheint lediglich in der Ansicht auf die Menükarte für dein Team.',
                },
                newPrice: {
                  label: 'Individuelles Honorar',
                  tooltip:
                    'Diese Honorar wird anhand der Preisformel und den angegebenen Honorarparametern (inkl. den oben gesetzten Werten für die internen Eingabefelder) ermittelt. Sollte für diese Leistung eine Rundungspräferenz eingestellt sein (global oder auf Leistungsebene), so ist Ergebnis aus der Honorarformular gerundet.',
                },
                officialReasonText: {
                  label: 'Hinweis für Mandant',
                },
                originalPrice: {
                  label: 'Standardhonorar',
                  tooltip:
                    'Das Standardhonorar dieser Leistung wird anhand der Preisformel und den angegebenen Honorarparametern ermittelt. Sollte die Leistung KEIN internes Eingabefeld besitzen und für diese Leistung eine Rundungspräferenz eingestellt sein (global oder auf Leistungsebene), so wird das Ergebnis der Honorarformular gerundet.',
                },
                showCalculatedPrice: {
                  disabledTooltip:
                    'Im Unternehmerhonorar-Modus sind monatlich und jährliche Preise von individuellen Preise nicht sichtbar.',
                  label: 'zeige Standardhonorar in Dokumenten?',
                  tooltip:
                    'Zeige das Standardhonorar neben dem individuellen Honorar in den Dokumenten. Wenn du diese Option deaktivierst, wird das Standardhonorar NICHT angezeigt. Im transparenten Modus werden dann zudem die Kalkulations-Details im Block "Details zu den ausgewählten Dienstleistungen" und "Strategische Empfehlungen" versteckt.',
                },
              },
              removeCustomPrice: 'entfernen',
              title: 'Bearbeite Honorar und Notizen',
            },
            displayInternalNoteToTeam: 'Individuell:',
            displayNotesToEmployee: 'Allgemein:',
            displayOfficialReasonText: 'Hinweis:',
            errors: {
              infinityPrice:
                'In der Honorarformel wurde eine Division durch 0 festgestellt. Bitte überprüfe die Eingabefelder',
            },
            graduatedScaleMode: 'Bitte beachten Sie, dass die Staffeln stufenweise berechnet werden',
            infoTextModal: {
              benefits: '$t(common.fields.benefits.label)',
              collapsePanelTitle: 'Details anzeigen',
              descriptionForContract: '$t(common.fields.descriptionForContract.label)',
              notesForFeeAgreement: '$t(common.fields.notesForFeeAgreement.label)',
              notesToEmployee: '$t(common.fields.notesToEmployee.label)',
              pleaseNote: '$t(common.fields.pleaseNote.label)',
            },
            minPrice: 'Mindesthonorar: {{minPrice}}',
            paymentIntervalAndHighlightBindingness: 'Zahlungsintervall: {{paymentIntervalAndHighlightBindingness}}',
            pricingFormula: 'Honorarformel: ',
            referenceError: {
              referenceIsSelectedButInvalid:
                'Bitte wähle die Leistung “{{itemName}}“ aus und trage dort korrekte Werte ein. Diese Leistung beruht darauf.',
              referenceNotSelectedAndInvalid:
                'Bitte wähle die Leistung “{{itemName}}“ aus und trage dort die Werte ein. Diese Leistung beruht darauf.',
              referenceNotSelectedButValid:
                'Bitte wähle die Leistung “{{itemName}}“ aus. Diese Leistung beruht darauf.',
            },
            requiredFieldsAlertText: 'Diese Eingabefelder sind Pflichtfelder',
            roundPrice: 'Der in der Honorarformel ermittelte Wert wird in {{step}}er Schritten aufgerundet.',
            selectTooltip: 'Anbieten und in Auftragsumfang aufnehmen',
          },
          StaticItems: 'Show static items',
          Total: {
            label: 'Zeige Honorar des Bereichs',
            vatTypeLabel: 'Weise $t(common.vatType.{{vatType}}) aus',
          },
          ValidationLabels: {
            discount: 'Nachlass',
            startOfService: 'Leistungsbeginn',
          },
          showStaticItems: 'Zeige statischen Leistungskatalog',
          startOfService: 'Leistungsbeginn (ab Monat / Jahr)',
          startOfServiceRequired: 'Leistungsbeginn erforderlich',
        },
        CompanyType: {
          confirmChange:
            'Bist du sicher, dass du den Mandantentyp ändern möchtest? Die bestehende Leistungsauswahl geht verloren. Möchtest du den Mandantentyp wechseln?',
          inputs: {
            companyType: {
              label: 'Welche Rechtsform haben Sie?',
              placeholder: 'Mandantentyp auswählen',
            },
            selfBooker: 'Erstellen Sie Ihre Buchhaltung selber?',
          },
          noTypes: 'Keine Mandantentyp',
          title: 'Mandantentyp auswählen',
        },
        ContactData: {
          buttons: {
            addCompany: '$t(common.company.addCompany)',
            addContact: '$t(common.contact.addContact)',
            changeCompany: '$t(common.company.changeCompany)',
          },
          company: {
            addExistingCompanyTab: 'Existierendes Mandat hinzufügen',
            createNewCompanyTab: 'Neues Mandat anlegen',
            duplicatedErrorMessage: {
              name: 'Mandat mit diesem Namen existiert bereits',
            },
            empty: 'Mandant hinzufügen',
            inputs: {
              city: {
                label: 'Stadt',
                placeholder: 'Stadt',
              },
              cityCode: {
                label: 'PLZ',
                placeholder: 'PLZ',
              },
              commercialObject: {
                label: 'Unternehmensgegenstand',
                placeholder: 'Unternehmensgegenstand',
              },
              company: {
                label: 'Mandant',
                placeholder: 'Suchen nach Mandat (z.B. Unternehmensname)',
              },
              country: {
                label: 'Land',
                placeholder: 'Land',
              },
              houseNumber: {
                label: 'Nr.',
                placeholder: 'Nr.',
              },
              identifier: {
                label: 'Mandatsnummer',
                placeholder: 'Mandatsnummer',
              },
              name: {
                company: {
                  label: 'Firmierung',
                  placeholder: 'Firmierung',
                },
                individual: {
                  label: 'Mandatsname',
                  placeholder: 'Mandatsname',
                },
              },
              street: {
                label: 'Straße',
                placeholder: 'Straße',
              },
              type: {
                company: 'Unternehmen',
                individual: 'Einzelperson',
              },
            },
            noCountries: 'No countries',
            notFoundContent: {
              create: 'Es existiert noch kein Mandat',
              noFound: 'Neues Mandat anlegen',
            },
          },
          contact: {
            addExistingContactTab: 'Existierenden Ansprechpartner hinzufügen',
            createNewContactTab: 'Neuen Ansprechpartner anlegen',
            duplicatedContactAlert: {
              editMessage:
                'Hinweis: Es existiert bereits ein Ansprechpartner beim Mandanten mit dieser E-Mail. Du kannst fortfahren, wenn du für den Kontakt andere Daten pflegen möchtest',
              message:
                'Hinweis: Es existiert bereits ein Ansprechpartner beim Mandanten mit dieser E-Mail. Du kannst fortfahren, wenn du für den Kontakt andere Daten pflegen möchtest',
              useExistingContactButton: 'existierenden Anprechpartner verwenden',
            },
            duplicatedErrorMessage: {
              contact: 'Ansprechpartner existiert bereits',
              email: 'Ansprechpartner mit dieser E-Mail existiert bereits',
            },
            empty: 'Ansprechpartner hinzufügen',
            inputs: {
              contact: {
                label: 'Ansprechpartner',
                placeholder: 'Suche Ansprechpartner',
              },
              email: {
                label: 'E-Mail Addresse',
                placeholder: 'E-Mail Addresse',
              },
              firstName: {
                label: 'Vorname',
                placeholder: 'Vorname',
              },
              fullSalutation: {
                label: 'Briefanrede',
                placeholder: 'Briefanrede',
              },
              lastName: {
                label: 'Nachname',
                placeholder: 'Nachname',
              },
              phoneNumber: {
                label: 'Telefon (geschäftlich)',
                placeholder: 'Telefon (geschäftlich)',
              },
              phoneNumberAlternative: {
                label: 'Telefon (alternativ)',
                placeholder: 'Telefon (alternativ)',
              },
              position: {
                label: 'Position',
                placeholder: 'Position',
              },
              salutation: {
                label: 'Anrede',
                options: {
                  Mr: 'Herr',
                  Mrs: 'Frau',
                  Ms: 'Frau',
                },
                placeholder: 'Herr',
              },
            },
            notFoundContent: {
              create: 'Neuen Ansprechpartner anlegen',
              noFound: 'Es existieren noch keine Ansprechpartner',
            },
            title: 'Ansprechpartner beim Mandanten',
          },
          fetchFromGlobal: 'Daten aktualisieren',
          label: 'Kontakt-Daten',
          modal: {
            cancel: '$t(common.cancel)',
            doNotReplace: '$t(common.no), nicht ersetzen',
            ok: '$t(common.ok)',
            replace: '$t(common.yes), Standard Ansprechpartner laden',
            titles: {
              addCompany: '$t(common.company.addCompany)',
              addContact: '$t(common.contact.addContact)',
              changeCompany: '$t(common.company.changeCompany)',
              changeCompanyContacts:
                'Möchtest du die Ansprechpartner, die sich aktuell in der Menükarte befinden, durch die Standard-Ansprechpartner des Mandanten ersetzten?',
              deleteContact: '$t(common.contact.deleteContact)',
              editCompany: '$t(common.company.editCompany)',
              editContact: '$t(common.contact.editContact)',
            },
          },
          oldData:
            'Die Daten sind nicht aktuell. Um die Mandantendaten oder die Daten von Ansprechpartnern zu bearbeiten, rufe die aktualisierten Daten mit einen Klick auf "Daten aktualisieren" ab',
          prefillContact: {
            button: 'Fülle Kontaktdaten vor',
            columns: {
              fieldTitle: '$t(common.columns.field)',
              fields: {
                commercialObject: 'Unternehmensgegenstand',
                importAcademicTitle: 'Akademischer Grad',
                importCompanyType: 'Unternehmensform (Import)',
                importEmail: 'E-Mail (Import)',
                importSalutation: 'Anrede',
              },
              valueTitle: '$t(common.columns.value)',
            },
            contactCollapse: 'Zeige Daten vom Stammdaten-Import an',
          },
        },
        Discounts: {
          activeDiscountsLabel: 'Liste der aktiven Nachlässe',
          activeDiscountsListLabel: 'Folgende Nachlässe sind aktiv:',
          activeForCategory:
            '"{{discountName}}" im Bereich "{{categoryName}}" ({{discountValue}} {{discountUnitName}} Nachlass)',
        },
        DocumentTypes: {
          activeTooltip: 'Du hast den {{blockName}}-Block individualisiert. Klicke hier um ihn zu bearbeiten',
          blocks: {
            body: 'Personalized text',
          },
          customPersonalizedText: '{{blockName}}: individualisierter Text',
          inactiveTooltip: 'Klicke hier, um den {{blockName}}-Block zu individualisieren',
          label: 'Dokumente',
          projectLabel: 'Finale Einstellungen',
          sections: {
            global: 'Aktuelle Konfiguration',
            independent: 'Historische Konfiguration',
          },
          typesSelectLabel: 'Welche Dokumente sollen generiert werden?',
        },
        DocumentTypesTable: {
          actionsHeader: '',
          nameHeader: 'Dokumentenvorlage',
        },
        HelperWidget: {
          howUseButton: '$t(common.howUseButton)',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Neue Menükarte anlegen',
            videoCaption: 'Erfahre, wie du die interaktive Menükarte am Besten bedienst',
            videoUrl: 'https://vimeo.com/541269240',
          },
        },
        NextActions: {
          buttons: {
            backToOffer: 'Menükarte bearbeiten',
            goToDashBoard: 'Zum Cockpit',
            goToView: 'Menükarte betrachten',
          },
          title: 'Nächste Schritte',
        },
        SendEmail: {
          attachments: 'E-Mail Anhänge',
          bcc: 'BCC',
          changeEmailTemplatesConfirmation: 'Sicher, dass du die E-Mail Vorlage laden möchtest?',
          fields: {
            body: {
              label: 'Inhalt',
            },
            emailTemplates: {
              label: 'E-Mail Vorlage',
            },
            recipients: {
              label: 'Empfänger',
            },
            signature: {
              label: 'Signatur',
            },
            subject: {
              label: 'Betreff',
            },
          },
          ok: 'E-Mail senden',
          preview: 'E-Mail Vorschau',
          success: 'E-Mail erfolgreich versendet',
          successfulMessage: 'Dokument wurde gespeichert',
          title: 'E-Mail senden',
          unablePlaceholdersInBody: 'Die Platzhalter {{placeholders}} werden beim Speichern der Menükarte gefüllt.',
        },
        TotalPricing: {
          TotalTable: {
            headers: {
              VAT: '$t(common.vatType.{{vatType}}) ({{vatValue}} %)',
              interval: 'Intervall',
              notes: 'Notes',
              price: 'Honorar (netto)',
              priceWithVAT: 'Honorar (brutto)',
            },
          },
          error: 'Bitte behebe die Fehler im Formular, um das Honorar zu sehen',
          fixedMonthlyFeeLine_monthly:
            '{{count}} monatliche Leistungen ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeLine_monthly_plural:
            '{{count}} monatliche Leistungen sind ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeLine_yearly: '{{count}} jährliche Leistungen ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeLine_yearly_plural:
            '{{count}} jährliche Leistungen sind ausgewählt und im Unternehmerhonorar beinhaltet',
          fixedMonthlyFeeTypeHint: 'Kosten sind im Unternehmerhonorar beinhaltet',
          label: 'Kalkulation',
        },
        buttonLabel: 'Optionen',
        categoriesCardTitle: 'Wunsch-Leistungen auswählen',
        compareOptions: {
          global: 'Vergleiche mit globaler Konfiguration',
          previous: '',
        },
        emptyCategoryListMessage: 'Es existieren bisher KEINE Bereiche',
        errorList: {
          scroll: 'Scroll zur richtigen Leistung und korrigiere die Eingabe',
          title:
            'Das Formular ist noch nicht korrekt ausgefüllt.<br/>Bitte klicke auf den Button, um zur richtigen Leistung zu scrollen',
        },
        globalModeAlert: {
          GlobalModeHelperWidget: {
            howUseButton: 'Erfahre, wie du eine Menükarte mit der globale Konfiguration abgleichst',
            modalInfo: {
              helpText: '',
              imageUrl: '',
              title: 'Erfahre, wie du eine Menükarte mit der globale Konfiguration abgleichst',
              videoCaption: '',
              videoUrl: 'https://vimeo.com/713982308',
            },
          },
          title:
            'Du hast den Button "Bearbeite mit aktueller Konfiguration" gedrückt. KanzleiPilot hat nun den historischen Leistungskatalog durch den aktuellen Leistungskatalog ersetzt. Bitte überprüfe nun genau die einzelnen Positionen. Es kann z.B. vorkommen, dass im aktuellen Leistungskatalog bestimmte Leistungen bzw. Nachlässe nicht mehr vorhanden sind, oder sich eine Honorarformel geändert hat. Sobald du unten auf Speichern drückst, wird die Menükarte mit der aktualisierten Konfiguration abgespeichert.',
        },
        inputs: {
          companyId: {
            label: 'Mandant',
          },
          companyTypeId: {
            label: 'Company type',
          },
          contacts: {
            label: 'Ansprechpartner',
          },
          email: {
            label: 'E-Mail-Address',
          },
          meetingAt: {
            errorLabel: 'Datum des Honorargesprächs',
            label: 'Gespräch geführt am',
          },
          name: {
            default: 'Projekt initiiert am {{date}}',
            label: '$t(common.fields.projectName.label)',
          },
          startOfContract: {
            label: 'Vertragsstart',
          },
        },
        options: {
          cancel: '$t(common.cancel)',
          debugMode: {
            label: 'Konfiguration anzeigen',
            tooltip:
              'Zeige die Kalkulationsdetails direkt unterhalb der Leistung an und überblicke, wie sich die Honorare der einzelnen Leistungen berechnen.',
          },
          feeType: {
            fixedMonthlyFee: 'Unternehmerhonorar Modus',
            label: 'Kalkulations-Modus',
            standardFee: 'Standard Modus',
            tooltip:
              'Kalkulations-Modus in dem KanzleiPilot die Honorare kalkuliert. Im Standard Modus ist alles wie gehabt: einmalige, monatliche und jährliche Leistungen werden transparent dargestellt. Beim Unternehmerhonorar Modus werden monatlich und jährliche Positionen zu einem monatlichen Honorar zusammengefasst und ihre Einzelhonorare werden mit dem Hinweis „Die Leistung ist im Unternehmerhonorar inklusive“ versteckt. Einmalige Leistungen werden auch im Unternehmerhonorar transparent dargestellt.',
          },
          hiddenNote: {
            placeholder: 'Versteckte Notizen',
            title: 'Versteckte Notizen',
          },
          hideHiddenNote: 'Notiz verbergen',
          ok: '$t(common.ok)',
          showDiscounts: {
            disabled:
              'Du kannst die Nachlässe nicht verstecken, da in der Menükarte mindestens 1 Nachlass ausgewählt ist.',
            label: 'Nachlässe anzeigen',
            tooltip:
              'Sollen Nachlässe angezeigt werden? Wir empfehlen dir: Schalte die Anzeige von Nachlässen im Standard AUS und fokussiere dich darauf, dem Mandanten seinen Nutzen zu verdeutlichen (siehe “Einstellungen > Menükarte > Menükarte Voreinstellungen”).',
          },
          showHiddenNote: 'Notiz anzeigen',
          showPrices: {
            label: 'Honorare anzeigen',
            tooltip:
              'Sollen Honorare beim Öffnen der Menükarte angezeigt werden? Wir empfehlen dir: Schalte die Honorareanzeige im Standard AUS (siehe “Einstellungen > Menükarte > Menükarte Voreinstellungen”).',
          },
          showVat: {
            label: 'Umsatzsteuer anzeigen',
            tooltip:
              'Aktiviere diese Option, um die Umsatzsteuer auszuweisen. Die Steuer wird in der interaktiven Menükarte und in den Dokumenten ausgewiesen (in den Blöcken "Ihre Investition", "Details zu den ausgewählten Dienstleistungen" und "Strategische Empfehlungen")',
          },
          switch: {
            off: '$t(common.off)',
            on: '$t(common.on)',
          },
          title: 'Optionen',
          vatType: {
            company: '$t(common.vatType.company)',
            label: 'Begriff verwenden',
            personal: '$t(common.vatType.personal)',
          },
        },
        somethingChangedWhenOpenedEdit:
          'Die Stamm-Konfiguration, auf der diese Menükarte zum Zeitpunkt ihrer letzten Bearbeitung beruht hat, hat sich geändert. Es werden aktuelle Werte dargestellt. Bitte überprüfe diese Menükarte genau.',
        titleAlt: 'Tenant Logo',
        validityContract: {
          thisMonth: 'Dieser Monat',
          title: 'Wann startet der Vertrag?',
        },
      },
      lockedIcon: {
        LockHelperWidget: {
          howUseButton: 'Erfahre, wie die festgeschriebene Konfiguration funktioniert',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Erfahre, wie die festgeschriebene Konfiguration funktioniert',
            videoCaption: '',
            videoUrl: 'https://vimeo.com/715751588',
          },
        },
        confirmationTitle:
          'Achtung: Die Daten, die du gerade eingegeben hast gehen beim Abgleichen mit der globalen Konfiguration verloren. Fortfahren?',
        editButton: 'Bearbeite mit aktueller Konfiguration',
        title: 'Die Menükarte basiert auf der historischen Konfiguration vom {{date}}.',
      },
    },
    validation: {
      simplifiedUrl: 'Bitte trage eine gültige URL ein (z.B. www.google.de)',
    },
    verify: {
      AcceptInvitation: {
        title: 'Aktive jetzt deinen KanzleiPilot Zugang',
      },
    },
    viewer: {
      ShoppingCartView: {
        BookedServices: {
          bookmarkedItems: 'Strategische Empfehlungen',
          categoryTotals: 'Kalkulation für Bereich',
          items: 'Beauftragte Leistungen',
          staticItems: 'Statische Leistungen',
        },
        CompareToGlobal: {
          title: 'Vergleiche mit globaler Konfiguration',
          usedVariablesTitle: 'Verwendete Variablen',
        },
        CreatedDocuments: {
          addDocument: 'Weitere Dokumentenvorlage hinzufügen',
          addDocumentsModal: {
            placeholder: 'Wähle die Dokumentenvorlagen aus',
            title: 'Wähle weitere Dokumentenvorlagen aus',
          },
          disabledAddingTooltip: 'Du kannst keine Dokumentenvorlagen hinzufügen, da sich die Daten geändert haben.',
          downloadAllPDFs: 'Alle Dokumente herunterladen',
          noAvailableDocumentsTooltip: 'Es sind keine Dokumentenvorlagen zum Hinzufügen zur Menükarte vorhanden.',
          title: 'Dokumente',
          viewPDF: 'Vorschau ansehen (während PDF generiert wird)',
        },
        GeneralProjectData: {
          generalProjectData: 'Projekt',
          projectName: 'Projektname',
        },
        HelperWidget: {
          howUseButton: 'Anleitung: So findest du alle Informationen',
          modalInfo: {
            helpText: '',
            imageUrl: '',
            title: 'Anleitung für das Betrachten der Menükarte',
            videoCaption: 'Erfahre, wie welche Informationen du in dieser Maske findest',
            videoUrl: 'https://vimeo.com/541269600',
          },
        },
        HistoricRevisions: {
          title: 'Revisionen',
        },
        Overview: {
          fields: {
            company: 'Mandant',
            companyIdentifier: 'Mandatsnummer',
            contacts: 'Ansprechpartner',
            createdAt: 'Erstellt am',
            createdBy: 'Erstellt durch',
            meetingAt: 'Gespräch geführt am',
            monthlyFeeMode: 'Kalkulations-Modus',
            monthlyFixedFee: 'Wert monatlich (Unternehmerhonorar)',
            number: 'Menükarte #',
            startOfContract: 'Vertragsstart',
            status: 'Status',
            valueMonthly: 'Wert monatlich',
            valueOneOff: 'Wert einmalig',
            valueYearly: 'Wert jährlich',
          },
          title: 'Details',
        },
        ShoppingCartHistoricItems: {
          ShoppingCart: {
            changeStatus: 'Status hat sich von {{oldStatusName}} auf {{newStatusName}} geändert',
            created: 'Menükarte{{revision}} erstellt',
            deleted: 'Menükarte{{revision}} gelöscht',
            sentByEmail: 'E-Mail erfolgreich gesendet',
          },
          revision: ' Revision',
          sendingError: 'Fehler beim Versand der E-Mail',
          title: 'Historie',
        },
        columns: {
          actions: '$t(common.columns.actions)',
          category: '$t(common.columns.category)',
          createdAt: '$t(common.columns.createdAt)',
          createdBy: '$t(common.columns.createdBy)',
          data: '$t(common.columns.data)',
          document: '$t(common.columns.document)',
          inputFields: 'Honorarparameter',
          inputParameters: 'Honorarparameter',
          internalNote: '$t(common.columns.internalNote)',
          interval: '$t(common.columns.interval)',
          item: '$t(common.columns.item)',
          price: '$t(common.columns.price)',
          selectedCategories: 'Ausgewählte Bereiche',
          selectedItems: 'Ausgewählte Leistungen',
          value: '$t(common.columns.value)',
          valueMonthly: '$t(common.columns.valueMonthly)',
          valueOneOff: '$t(common.columns.valueOneOff)',
          valueYearly: '$t(common.columns.valueYearly)',
        },
        customInternalValue: 'individuell',
        editShoppingCart: 'Menükarte bearbeiten',
        feeMode: {
          fixedMonthly: '$t(onboarding.pricingMode.fixedMonthlyModeTitle)',
          standard: '$t(onboarding.pricingMode.standardModeTitle)',
        },
        menu: {
          delete: 'Menükarte löschen',
          disableDuplicate:
            'Du kannst diese Menükarte NICHT duplizieren, da die sich die Basiskonfiguration (z.B. Honorarformel) geändert hat.',
          duplicate: 'Menükarte duplizieren',
        },
        migratedAlert:
          'Diese Menükarte wurde am {{migrationDate}} migriert. Die PDF Dateien und die hier gespeicherten Daten sind nicht verändert worden. Über den Button "Menükarte bearbeiten" kannst du die Menükarte mit der "aktuelle Konfiguration" bearbeiten.',
        noRecipientsWithEmail:
          'Bitte hinterlege für mindestens einen Kontakt eine E-Mail Adresse, um eine E-Mail versenden zu können',
        rows: {
          calculationMode: '$t(common.fields.calculationMode.label)',
          highlightBindingness: '$t(common.fields.highlightBindingness.label)',
          minPrice: '$t(common.fields.minPrice.label)',
          name: '$t(common.fields.name.label)',
          paymentInterval: '$t(common.fields.paymentInterval.label)',
          pricingFormula: '$t(common.fields.pricingFormula.label)',
          scales: 'Staffeln',
        },
        sendEmailButton: 'E-Mail senden',
        sendEmailConfirmationTitle: 'Möchtest du die E-Mail versenden?',
        subtitle: 'erstellt am {{createdAt}} von {{createdBy}}',
        title: 'Menükarte betrachten',
      },
    },
  },
};
