import { Alert, Dropdown, Menu } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { userShoppingCartViewQuery, userVariableListQuery, userViewShoppingCartCategoriesQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { fullNameFromUser } from 'components/layout/CurrentUserDropdown';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import roles from 'constants/roles';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { deleteShoppingCartWithConfirmation } from 'graphql/methods';
import cn from 'classnames';
import { formatDate, formatDateTime } from 'utils/date';
import { totalValueToString } from 'utils/formatValues';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { VariablesContext } from 'components/user/shoppingCart/context';
import { CompareToGlobalProvider } from 'contexts/CompareToGlobalContext';
import LockButton from 'components/user/compareToGlobal/LockButton';
import MigratedShoppingCartHelperWidget from 'components/user/compareToGlobal/MigratedShoppingCartHelperWidget';
import { useComparingShoppingCartCategories, useComparingVariables } from 'graphql/hooks';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import EditShoppingCartButton from 'components/user/shoppingCart/EditShoppingCartButton';
import { ShoppingCartChanges } from 'constants/shoppingCart';
import CreatedDocuments from './CreatedDocuments';
import HistoricRevisions from './HistoricRevisions';
import ShoppingCartOverview from './ShoppingCartOverview';
import classes from './ShoppingCartView.module.less';
import { getCategoryItems } from './utils';
import ShoppingCartHistoryItems from './ShoppingCartHistoryItems';
import ShoppingCartViewHelperWidget from './ShoppingCartViewHelperWidget';
import SendEmail from './SendEmail';
import Categories from './Categories';
import GeneralShoppingCartDataCard from './GeneralShoppingCartDataCard';

const ShoppingCartView = ({ shoppingCart, categories, isChangedCoreData }) => {
  const { t } = useTranslation();
  const items = useMemo(() => getCategoryItems({ shoppingCart, categories }), [categories, shoppingCart]);
  const overviewData = useMemo(
    () => ({
      companyIdentifier: shoppingCart.company?.identifier,
      company: shoppingCart.company,
      contacts: shoppingCart.contacts,
      valueMonthly: totalValueToString({
        paymentIntervalValues: shoppingCart.monthly,
        showDigits: shoppingCart.showDigits,
      }),
      valueOneOff: totalValueToString({
        paymentIntervalValues: shoppingCart.oneOff,
        showDigits: shoppingCart.showDigits,
      }),
      valueYearly: totalValueToString({
        paymentIntervalValues: shoppingCart.yearly,
        showDigits: shoppingCart.showDigits,
      }),
      monthlyFixedFee: totalValueToString({
        paymentIntervalValues: Calc.formatFixedValue({
          yearly: shoppingCart.yearly,
          monthly: shoppingCart.monthly,
        }),
        t,
        showDigits: shoppingCart.showDigits,
      }),
      monthlyFeeMode: t(`viewer.ShoppingCartView.feeMode.${shoppingCart.feeType}`),
      status: shoppingCart.status ? shoppingCart.status.name : t(`ShoppingCartEntriesPage.noStatus`),
      createdAt: formatDateTime(shoppingCart.createdAt),
      createdBy: fullNameFromUser(shoppingCart.createdBy),
      meetingAt: shoppingCart.meetingAt ? formatDate(shoppingCart.meetingAt) : null,
      startOfContract: shoppingCart.startOfContract ? formatDate(shoppingCart.startOfContract) : null,
    }),
    [
      shoppingCart.company,
      shoppingCart.contacts,
      shoppingCart.createdAt,
      shoppingCart.createdBy,
      shoppingCart.feeType,
      shoppingCart.meetingAt,
      shoppingCart.monthly,
      shoppingCart.oneOff,
      shoppingCart.showDigits,
      shoppingCart.startOfContract,
      shoppingCart.status,
      shoppingCart.yearly,
      t,
    ],
  );

  return (
    <>
      <ShoppingCartViewHelperWidget buttonClassName={classes.helpButton} />
      {shoppingCart.migrated ? (
        <Alert
          type="info"
          className="alert-info"
          showIcon
          description={
            <>
              <div>
                {t('viewer.ShoppingCartView.migratedAlert', { migrationDate: formatDate(shoppingCart.migrationDate) })}
              </div>
              <MigratedShoppingCartHelperWidget />
            </>
          }
        />
      ) : null}
      <GeneralShoppingCartDataCard projectName={shoppingCart.name} t={t} />
      <ShoppingCartOverview overviewData={overviewData} />
      <Categories shoppingCart={shoppingCart} categories={categories} />
      <CreatedDocuments shoppingCartId={shoppingCart._id} disabledAdding={isChangedCoreData} />
      <HistoricRevisions categories={categories} items={items} />
      <ShoppingCartHistoryItems />
    </>
  );
};

const ShoppingCartActionsMemo = memo(function ShoppingCartActions() {
  // #region TODO: enable duplicating shopping cart
  // ({ isShoppingCartChanged }) =>
  // #endregion
  const params = useParams();
  const [me] = useCurrentUser();
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const onDelete = useCallback(() => {
    deleteShoppingCartWithConfirmation({
      _id: params.id,
      onOk: () => {
        navigate(routePaths.shoppingCartEntries, { replace: true });
      },
    });
  }, [navigate, params.id]);

  // #region TODO: enable duplicating shopping cart
  // const onDuplicate = useCallback(() => {
  //   duplicateShoppingCartWithConfirmation({
  //     _id: params.id,
  //     onOk: (duplicatedShoppingCartId) => {
  //       navigate(generatePath(routePaths.shoppingCartView, { id: duplicatedShoppingCartId }));
  //     },
  //   });
  // }, [navigate, params.id]);
  // #endregion
  const menu = isViewer ? null : (
    <Menu>
      {
        // #region TODO: enable duplicating shopping cart
        /* 
          <Menu.Item onClick={onDuplicate} disabled={isShoppingCartChanged}>
            <Tooltip title={isShoppingCartChanged ? t('viewer.ShoppingCartView.menu.disableDuplicate') : ''}>
              <FileAddOutlined />
              {t('viewer.ShoppingCartView.menu.duplicate')}
            </Tooltip>
          </Menu.Item> 
          */
        // #endregion
      }
      <Menu.Item danger onClick={onDelete}>
        <DeleteOutlined />
        {t('viewer.ShoppingCartView.menu.delete')}
      </Menu.Item>
    </Menu>
  );
  if (isViewer) return null;
  return (
    <Dropdown overlay={menu} className="margin-left-8">
      <MoreOutlined className={cn('options', 'hover-background-light-primary')} />
    </Dropdown>
  );
}, equal);

const ShoppingCartViewWrapper = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [me] = useCurrentUser();
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);

  const { data, loading, error } = useCachedQuery(userShoppingCartViewQuery, {
    variables: { _id: params.id },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  // const isChangedCoreData = useMemo(
  //   () =>
  //     !!(error?.graphQLErrors?.length && error.graphQLErrors.find((err) => err.path.includes('isSomethingChanged'))),
  //   [error?.graphQLErrors],
  // );
  const shoppingCart = useMemo(() => grabFirstGQLDataResult(data), [data]);
  // #region TODO: enable displaying the isShoppingCartChanged modal
  // const modalDisplayedRef = useRef();
  // const isShoppingCartChanged = isChangedCoreData || shoppingCart?.isSomethingChanged;
  // if (!loading && isShoppingCartChanged && !modalDisplayedRef.current) {
  //   modalDisplayedRef.current = true;
  //   confirmModal({
  //     title: t('user.ShoppingCart.somethingChangedWhenOpenedEdit'),
  //     maskClosable: true,
  //     disableCloseButton: true,
  //     width: 800,
  //   });
  // }
  // #endregion

  const {
    data: variables,
    loading: variablesLoading,
    error: variablesError,
  } = useComparingVariables({ shoppingCartId: params.id, query: userVariableListQuery });

  const {
    data: categories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useComparingShoppingCartCategories({
    shoppingCartId: params.id,
    query: userViewShoppingCartCategoriesQuery,
  });

  return (
    <CompareToGlobalProvider>
      <PageContainer
        title={t('viewer.ShoppingCartView.title', { companyName: shoppingCart?.company?.name || '' })}
        right={
          <>
            {isViewer || (
              <>
                <LockButton
                  initializationConfigDate={shoppingCart?.initializationConfigDate}
                  className="display-inline-block margin-right-8"
                />{' '}
                <SendEmail shoppingCart={shoppingCart} /> <EditShoppingCartButton _id={params.id} />
              </>
            )}
            <ShoppingCartActionsMemo
            // #region TODO: enable displaying the isShoppingCartChanged modal
            // isShoppingCartChanged={isShoppingCartChanged}
            // isChangedCoreData={isChangedCoreData}
            // #endregion
            />
          </>
        }
      >
        <GraphQLLoadingWrapper
          data={variables && categories && shoppingCart}
          error={categoriesError || variablesError || error}
          loading={loading || categoriesLoading || variablesLoading}
        >
          <div className="xs-mt-20" style={{ marginTop: 0 }}>
            <VariablesContext.Provider value={variables}>
              <ShoppingCartView
                shoppingCart={shoppingCart}
                categories={categories}
                isChangedCoreData={shoppingCart?.isSomethingChanged === ShoppingCartChanges.CRITICAL}
              />
            </VariablesContext.Provider>
          </div>
        </GraphQLLoadingWrapper>
      </PageContainer>
    </CompareToGlobalProvider>
  );
};

export default memo(ShoppingCartViewWrapper, equal);
