import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo, useCallback } from 'react';
import Switch from 'components/common/Switch';
import { TotalTableComponent } from 'components/user/shoppingCart/TotalTable';
import { Calc, Cart } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useShowVATContext } from 'contexts/ShowVATContext';

const TotalPricing = ({
  isCategoryLevel,
  items,
  parent,
  showDigits,
  showVat,
  title,
  total: _total,
  vatType,
  migrated,
  feeType,
}) => {
  const { isShowVAT, toggleVAT } = useShowVATContext();
  const VATSwitch = useCallback(
    ({ label }) => (!showVat && migrated ? null : <Switch label={label} onChange={toggleVAT} checked={isShowVAT} />),
    [isShowVAT, migrated, showVat, toggleVAT],
  );
  const total = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(_total).map(([key, value]) => [
          key,
          { ...value, discountedValueWithVAT: Calc.round(value.discountedValue + value.discountedVAT) },
        ]),
      ),
    [_total],
  );
  const isFixedMonthlyFeeType = Cart.isCurrentFeeTypeMonthly(feeType);
  if (isFixedMonthlyFeeType && !isCategoryLevel)
    total.fixedMonthly = Calc.formatFixedValue({
      monthly: total.monthly,
      yearly: total.yearly,
    });
  return (
    <>
      <h2>{title}</h2>
      <TotalTableComponent
        isCategoryLevel={isCategoryLevel}
        items={items}
        parent={parent}
        showDigits={showDigits}
        showVat={isShowVAT}
        total={total}
        vatSwitch={VATSwitch}
        vatType={vatType}
        isDelimiter={!isCategoryLevel}
      />
    </>
  );
};

export default memo(TotalPricing, equal);
