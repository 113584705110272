import { Alert } from 'antd';
import { useGetWarnings } from 'graphql/hooks';
import { useTranslation } from 'react-i18next';

const WarningsComponent = ({ warningsTypes }) => {
  const { t } = useTranslation();
  const { data: warnings } = useGetWarnings(warningsTypes);
  return (
    <>
      {warnings.map((warning) => {
        return <Alert message={t(warning.message)} type={warning.level} />;
      })}
    </>
  );
};

export default WarningsComponent;
