import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormItem as OriginalFormItem } from 'formik-antd';

const FormItem = ({ containerProps, label, sublabel, tooltip, wideTooltip, ...props }) => {
  if (tooltip) {
    // eslint-disable-next-line no-param-reassign
    label = (
      <div>
        <div className="align-center">
          {label}{' '}
          <Tooltip title={tooltip} autoAdjustOverflow overlayStyle={wideTooltip ? { maxWidth: '500px' } : undefined}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        {sublabel ? <div>{sublabel}</div> : null}
      </div>
    );
  }
  const { name } = props;
  return (
    <div fieldname={name} {...containerProps}>
      <OriginalFormItem {...props} label={label} />
    </div>
  );
};
export default FormItem;
