/* eslint-disable no-console */
import { ApolloClient, ApolloLink, InMemoryCache, gql, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { print } from 'graphql';
import { afterLogout } from 'hooks/auth/useLogout';
import get from 'lodash/get';
import i18n from 'i18n';
import { serializeError } from 'serialize-error';
import { isNil } from 'lodash';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { Observable } from '@apollo/client/core';
import RefreshTokenService from '../utils/RefreshTokenService';
import settings from '../config/settings';
import { formatQueryByErrorPositions } from './utils';
import { currentUserQuery } from './queries';
import { logoutMutation } from './mutations';
import { formatBackendError } from './backendError';

// #region copy-paste
const Logging = new ApolloLink((operation, forward) => {
  const isEnabled = window.localStorage.getItem('isExtLogEnabled') === 'true';
  if (!isEnabled) return forward(operation);
  let opName;
  let actualNameStr;
  try {
    const { operationName, query, variables } = operation;
    opName = operationName;
    const { definitions } = query;
    const { operation: type } = definitions.find((d) => d?.kind === 'OperationDefinition') || {};
    const actualName = query.definitions
      .filter((e) => e?.kind === 'OperationDefinition')
      .map((e) => e?.selectionSet?.selections)
      .flat()
      .map((e) => e?.name?.value)
      .filter(Boolean)
      .join(', ');
    actualNameStr = actualName === opName ? '' : ` (${actualName})`;

    const startTime = Date.now();
    const accentColor = type === 'query' ? '#34ace0' : '#706fd3';
    const mainColor = '#0f0f0f';
    const defineTimeColor = () => {
      const duration = Date.now() - startTime;
      switch (true) {
        case duration < 400:
          return '#33d9b2';
        case duration < 800:
          return '#ffb142';
        case duration < 1000:
          return '#ff793f';
        default:
          return '#ff5252';
      }
    };
    return forward(operation).map((result) => {
      const data = Object.values(result?.data || {})[0];
      console.ext(
        `%c ${type?.padEnd(8) || 'etc.'} %c ${(Date.now() - startTime)
          .toString()
          .padEnd(5)}ms %c ${opName}${actualNameStr} `,
        `background:${result.errors ? '#ff5252' : accentColor}; color: ${mainColor};`,
        `background:${defineTimeColor()}; color: ${mainColor};`,
        `background:${mainColor}; color: ${result.errors ? '#ff5252' : accentColor}; font-weight: bold;`,
        { var: variables, res: data, ...(result.errors && { errors: result.errors }) },
      );
      return result;
    });
  } catch (e) {
    console.ext(
      `%c ${'error'.padEnd(8)} %c ${opName}${actualNameStr} logging:`,
      `background: #ff5252; color: #0f0f0f;`,
      `background: #0f0f0f; color: #ff5252;`,
      e.message,
    );
    return forward(operation);
  }
});
// #endregion copy-paste

const httpLink = new BatchHttpLink({
  uri: settings.graphqlServerUrl,
  credentials: 'include',
});
class WebSocketLink extends ApolloLink {
  // private client: Client;

  // constructor(options: ClientOptions) {
  constructor(options) {
    super();
    this.client = createClient(options);
  }

  // public request(operation: Operation): Observable<FetchResult> {
  request(operation) {
    return new Observable((sink) => {
      // return this.client.subscribe<FetchResult> (
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}
// eslint-disable-next-line import/no-mutable-exports
let apollo;
let key;
const wssLink = async () => {
  const { data } = await apollo.mutate({
    mutation: gql`
      mutation getWsLink {
        getWsLink {
          url
          key
        }
      }
    `,
  });
  key = data.getWsLink.key;
  return data.getWsLink.url;
}; // settings.graphqlServerUrl.replace('https', 'ws').replace('http', 'ws').replace('/graphql', '/wss');
// console.log(wssLink);
const webSocketLink = new WebSocketLink({
  url: wssLink,
  connectionParams: () => {
    return { jwt: key };
  },
});
const routeWebSocket = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  webSocketLink,
  httpLink,
);

const link = ApolloLink.from([
  Logging,
  new TokenRefreshLink({
    accessTokenField: 'token',
    isTokenValidOrUndefined: () => {
      return RefreshTokenService.isTokenValidOrUndefined();
    },
    fetchAccessToken: () => {
      console.log('fetching new access token');
      return RefreshTokenService.fetchNewAccessToken();
    },
    handleFetch: () => console.log('refreshed a token with apollo TokenRefreshLink'),
    handleResponse: () => (response) => {
      return response;
    },
    handleError: () => {
      window.setTimeout(() => {
        window?.currentNavigate?.('/login');
        window.setTimeout(async () => {
          try {
            await apollo.mutate({
              mutation: logoutMutation,
            });
          } catch (e) {
            console.log('Error while logout', e);
          }
          afterLogout(apollo);
        });
      });
    },
  }),
  setContext((_, context) => {
    context.headers = context.headers || {};
    context.headers['location-href'] = window?.location?.href;
    context.headers.locale = i18n.language;
    // const token = TokenManager.getToken();
    // if (token) {
    //   const authHeaders = {};
    //   return { headers: { ...headers, ...authHeaders } };
    // }
    return context;
  }),
  onError(({ graphQLErrors, networkError, operation }) => {
    // const code = response?.errors[0]?.extensions?.code;
    // if (code === 'UNAUTHENTICATED') {
    //   console.log('received unauthenticated error');
    //   window.location.replace(window.location.origin);
    // }
    const logError = (error, { errorType }) => {
      const { message, locations, path, extensions, stack } = error;
      if (extensions?.exception) extensions.exception.logged = true;
      const lExtensions = JSON.stringify({
        ...extensions,
        ...(extensions?.exception && {
          exception: { ...extensions.exception, ...(extensions.exception?.stacktrace && { stacktrace: '(below)' }) },
        }),
      });
      const lStacktrace =
        get(extensions, 'exception.stacktrace', []).reduce(
          (p, c, index) => (p || '').concat(index !== 0 ? '\n' : '', c),
          '',
        ) ||
        stack ||
        ''; // array to string
      const lVariables = JSON.stringify(operation.variables);
      const lQuery = formatQueryByErrorPositions({ queryString: print(operation.query), errorPositions: locations });
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lQuery && `Query:\n${lQuery}`,
          lVariables && `Variables: ${lVariables}`,
          lExtensions && `Extensions: ${lExtensions}`,
          lStacktrace && `Stacktrace: ${lStacktrace}`,
          error && `Serialized Error: ${JSON.stringify(serializeError(error))}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const doNotLogOnClient = get(error, 'extensions.exception.doNotLogOnClient');
        if (!doNotLogOnClient) logError(error, { errorType: 'GraphQL error' });
      });
    }

    if (!graphQLErrors && networkError) logError(networkError, { errorType: 'Network error' });
  }),
  onError(({ graphQLErrors }) => {
    if (graphQLErrors) graphQLErrors.forEach(formatBackendError);
  }),
  routeWebSocket,
]);

const cacheIndexBlacklist = [];

apollo = new ApolloClient({
  // request: operation => {
  //   const token = TokenManager.getToken();
  //   console.log(token);
  //   if (token) {
  //     const headers = { Authorization: `Bearer ${token}` };
  //     operation.setContext({ headers });
  //   }
  // },
  link,
  resolvers: {
    Category: {
      isNew: (category) => {
        return !!category.isNew;
      },
    },
  },
  cache: new InMemoryCache({
    dataIdFromObject: ({ _id, __typename, _apolloCacheKey }) => {
      if (cacheIndexBlacklist.includes(__typename)) return null;
      return _id ? [__typename, _id, _apolloCacheKey].filter((e) => !isNil(e)).join('___') : null;
    },
  }),
});

setInterval(async () => {
  if (!RefreshTokenService.isTokenValidOrUndefined()) {
    await apollo.query({
      query: currentUserQuery,
      fetchPolicy: 'network-only',
    });
  }
}, 3000);

window.gql = gql;
window.apollo = apollo;
window.apolloClient = apollo;

window.toggleExtLog = () => {
  window.localStorage.isExtLogEnabled = window.localStorage.isExtLogEnabled === 'true' ? 'false' : 'true';
};
console.ext = (...params) => {
  if (
    window.localStorage.isExtLogEnabled === 'true' ||
    (window.location.hostname === 'localhost' && window.localStorage.isExtLogEnabled !== 'false')
  )
    console.debug(...params);
};

/*
  (await devEval(`
  const cp=require('child_process');
  return cp.execSync('ls ./node_modules').toString();
  `)).data
*/
window.devEval = (code = 'return `ok`', isExact = false, notFixCircular = false) =>
  apollo
    .mutate({
      mutation: gql`
        mutation devEval($code: String, $isExact: Boolean, $notFixCircular: Boolean) {
          devEval(code: $code, isExact: $isExact, notFixCircular: $notFixCircular)
        }
      `,
      variables: { code, isExact, notFixCircular },
    })
    .then((r) => r?.data?.devEval);

export default apollo;
