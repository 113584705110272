import pick from 'lodash/pick';

import CorporateIdentityStep from './steps/CorporateIdentity';
// import FinishStep from './steps/Finish';
import PickCategoriesStep from './steps/PickCategories';
import PickDocumentsStep from './steps/PickDocuments';
import PricingLevelStep from './steps/PricingLevel';
import PricingModeStep from './steps/PricingMode';
import SignaturesStep from './steps/Signatures';
import WelcomeStep from './steps/Welcome';
import CompanyInfoStep, { defaultValues } from './steps/CompanyInfo';

class OnboardingDataTransformer {
  keys = {
    variables: [
      'yearlyBusinessAccountsSingle',
      'yearlyBusinessAccountsCompanies',
      'yearlyBusinessAccountsBalanceSheetBEU',
      'yearlyBusinessAccountsBalanceSheetRest',
      'monthlyBookkeeping',
      'yearlyPersonalAccounts',
      'accountant',
      'employee',
    ],
    theme: ['primaryColor', 'color', 'titleFontFamily', 'fontFamily'],
    companyInfo: Object.keys(defaultValues),
  };

  transformFormValuesToGraphQLParams = (values) => {
    const { importDocuments, importCategories, pricingMode } = values;
    const result = {
      importDocuments: importDocuments?.map((document) => pick(document, ['_id', 'name'])),
      importCategories: importCategories?.map((category) => pick(category, ['_id', 'name'])),
      pricingMode,
      variables: pick(values, this.keys.variables),
      theme: pick(values, this.keys.theme),
      companyInfo: pick(values, this.keys.companyInfo),
    };
    return result;
  };

  transformGraphQLParamsToFormValues = (graphqlParams) => {
    const { variables, theme, logo, pricingMode, companyInfo } = graphqlParams;
    const result = {
      logo,
      pricingMode,
      ...pick(variables, this.keys.variables),
      ...pick(theme, this.keys.theme),
      ...pick(companyInfo, this.keys.companyInfo),
    };
    return result;
  };
}

export const onboardingDataTransformer = new OnboardingDataTransformer();

export const steps = [
  {
    titleI18nKey: 'onboarding.welcome.stepTitle',
    Content: WelcomeStep,
  },
  {
    titleI18nKey: 'onboarding.companyInfo.stepTitle',
    Content: CompanyInfoStep,
  },
  {
    titleI18nKey: 'onboarding.corporateIdentity.stepTitle',
    Content: CorporateIdentityStep,
  },
  {
    titleI18nKey: 'onboarding.pricingLevel.stepTitle',
    Content: PricingLevelStep,
  },
  {
    titleI18nKey: 'onboarding.pricingMode.stepTitle',
    Content: PricingModeStep,
  },
  {
    titleI18nKey: 'onboarding.pickCategories.stepTitle',
    Content: PickCategoriesStep,
  },
  {
    titleI18nKey: 'onboarding.pickDocuments.stepTitle',
    Content: PickDocumentsStep,
  },
  {
    titleI18nKey: 'onboarding.signatures.stepTitle',
    Content: SignaturesStep,
  },
  // {
  //   titleI18nKey: 'onboarding.finish.stepTitle',
  //   Content: FinishStep,
  // },
];
