import { gql } from '@apollo/client';
import { adminShoppingCartPreferencesFragment, userEmailPreferencesFragment } from 'graphql/fragments';

const userPreferencesQuery = gql`
  query userPreferencesQuery($shoppingCartId: ID) {
    tenantSettings(shoppingCartId: $shoppingCartId) {
      _id
      _apolloCacheKey
      shoppingCartPreferences {
        ...adminShoppingCartPreferencesFragment
      }
      emailPreferences {
        ...userEmailPreferencesFragment
      }
    }
  }
  ${adminShoppingCartPreferencesFragment}
  ${userEmailPreferencesFragment}
`;

export default userPreferencesQuery;
