import orderBy from 'lodash/orderBy';
import { grabFirstGQLDataResult } from 'utils/helpers';
import apollo from 'graphql/apollo';
import { singleInputConfirmModal } from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { adminDocumentTemplateListQuery } from 'graphql/queries';
import { createDocumentTemplateMutation } from 'graphql/mutations';

const tAddDocumentTemplate = ({ name, isLibrary, createMutation, queryList, onSuccess }) =>
  apollo.mutate({
    mutation: createMutation,
    variables: { documentTemplate: { name }, isLibrary },
    update: (cache, { data }) => {
      const result = { ...grabFirstGQLDataResult(data), isNew: true };
      const cachedData = cache.readQuery({ query: queryList, variables: { isLibrary } });
      cache.writeQuery({
        query: queryList,
        variables: { isLibrary },
        data: { documentTemplates: orderBy([result, ...cachedData.documentTemplates], ['order'], ['desc']) },
      });
      if (typeof onSuccess === 'function') onSuccess(result);
    },
  });
const addDocumentTemplate = ({ name, onSuccess, isLibrary }) =>
  tAddDocumentTemplate({
    createMutation: createDocumentTemplateMutation,
    queryList: adminDocumentTemplateListQuery,
    name,
    isLibrary,
    onSuccess,
  });

const withAddDocumentTemplateModal =
  ({ fn }) =>
  ({ onSuccess, isLibrary }) =>
    singleInputConfirmModal({
      fieldLabel: i18n.t('admin.addDocumentTemplateModal.nameFieldLabel'),
      headerText: i18n.t('admin.addDocumentTemplateModal.addModalTitle'),
      okText: i18n.t('admin.addDocumentTemplateModal.ok'),
      cancelText: i18n.t('admin.addDocumentTemplateModal.cancel'),
      onSubmit: (name) => fn({ name: name.input, onSuccess, isLibrary }),
      fieldDescription: i18n.t('admin.addDocumentTemplateModal.nameFieldDescription'),
      errorResolver: { Duplicated: ['input', i18n.t('admin.addDocumentTemplateModal.duplicatedErrorMessage')] },
      value: { input: '' },
      forceMultiField: true,
    });

export const createDocumentTemplate = withAddDocumentTemplateModal({ fn: addDocumentTemplate });
