import OnboardingLayout from './OnboardingLayout';
import { steps } from './Onboarding.utils';
import Finish from './steps/Finish';

export default function OnboardingCompletedPage() {
  return (
    <OnboardingLayout currentIndex={steps.length}>
      <Finish />
    </OnboardingLayout>
  );
}
