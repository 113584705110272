import apollo from 'graphql/apollo';
import { importDocumentTemplatesFromLibraryMutation } from 'graphql/mutations';
import { adminDocumentTemplateListQuery } from 'graphql/queries';

const importDocumentTemplatesFromLibrary = async (documentTemplates) =>
  apollo.mutate({
    mutation: importDocumentTemplatesFromLibraryMutation,
    variables: { input: documentTemplates },
    refetchQueries: [{ query: adminDocumentTemplateListQuery, variables: { isLibrary: false } }],
  });

export default importDocumentTemplatesFromLibrary;
