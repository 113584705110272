import { Form, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { memo, useEffect, useRef, useMemo, useCallback, Fragment } from 'react';
import { useFormikField } from 'hooks/common/useFormikField';
import { intersectionBy, difference } from 'lodash';
import { useShoppingCartRevisionContext } from 'components/user/shoppingCart/context';
import { CreationMode } from 'constants/shoppingCart';
import { useParams } from 'react-router';
import { formatDateTime } from 'utils/date';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userDocumentTemplateMultiSelectQuery } from 'graphql/queries';
import classes from './DocumentTypesSelect.module.less';

const { Option, OptGroup } = Select;

const useDocumentTemplateList = () => {
  const { data, loading } = useCachedQuery(userDocumentTemplateMultiSelectQuery, {
    variables: { isLibrary: false },
    fetchPolicy: 'cache-and-network',
  });
  const documentTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return { data: documentTemplates, loading };
};

const useFormikDocumentTemplates = ({
  isAllowIndependentDocumentTemplates,
  globalDocumentTemplatesOptions,
  independentDocumentTemplatesOptions,
}) => {
  const { value, onChange } = useFormikField('documentTemplates');
  const params = useParams();
  const isFilterDocumentTemplates = useRef();
  useEffect(() => {
    isFilterDocumentTemplates.current = false;
  }, [params.mode]);
  useEffect(() => {
    if (!isFilterDocumentTemplates.current && independentDocumentTemplatesOptions && globalDocumentTemplatesOptions) {
      const allDocumentTemplates = [...independentDocumentTemplatesOptions, ...globalDocumentTemplatesOptions];
      onChange(intersectionBy(value, allDocumentTemplates, (e) => (typeof e === 'object' ? e.value : e)));
      isFilterDocumentTemplates.current = true;
    }
  }, [
    globalDocumentTemplatesOptions,
    independentDocumentTemplatesOptions,
    isAllowIndependentDocumentTemplates,
    onChange,
    value,
  ]);
};

const useDocumentTemplateOptions = ({ isAllowIndependentDocumentTemplates }) => {
  const { documentTemplates: independentDocumentTemplates } = useShoppingCartRevisionContext();
  const independentDocumentTemplatesOptions = useMemo(
    () =>
      isAllowIndependentDocumentTemplates
        ? independentDocumentTemplates.map((documentTemplate) => ({
            ...documentTemplate,
            name: `${documentTemplate.name} ${formatDateTime(documentTemplate.initializationConfigDate)}`,
            value: JSON.stringify({
              _id: documentTemplate._id,
              initializationConfigDate: documentTemplate.initializationConfigDate,
            }),
          }))
        : [],
    [independentDocumentTemplates, isAllowIndependentDocumentTemplates],
  );
  const { data: globalDocumentTemplates, loading } = useDocumentTemplateList();
  const globalDocumentTemplatesOptions = useMemo(
    () =>
      globalDocumentTemplates?.map((documentTemplate) => ({
        ...documentTemplate,
        name: documentTemplate.name,
        value: JSON.stringify({ _id: documentTemplate._id }),
      })),
    [globalDocumentTemplates],
  );
  return {
    independentDocumentTemplatesOptions,
    globalDocumentTemplatesOptions,
    loading,
  };
};

export const PDFTypeFormikSelect = () => {
  const { t } = useTranslation();
  const params = useParams();
  const isAllowIndependentDocumentTemplates = useMemo(() => params.mode === CreationMode.INDEPENDENT, [params.mode]);

  const { globalDocumentTemplatesOptions, independentDocumentTemplatesOptions, loading } = useDocumentTemplateOptions({
    isAllowIndependentDocumentTemplates,
  });

  useFormikDocumentTemplates({
    isAllowIndependentDocumentTemplates,
    globalDocumentTemplatesOptions,
    independentDocumentTemplatesOptions,
  });
  const { value, onChange: onChangeFormik } = useFormikField('documentTemplates');
  const options = useMemo(() => {
    const sections = [
      isAllowIndependentDocumentTemplates ? independentDocumentTemplatesOptions : [],
      globalDocumentTemplatesOptions,
    ].filter((documentTemplatesOptions) => documentTemplatesOptions?.length);
    const isSections = sections.length === 2;
    const Component = isSections ? OptGroup : Fragment;
    return sections.map((documentTemplatesOptions, isGlobal) => {
      const title = isSections
        ? t(`user.ShoppingCart.DocumentTypes.sections.${isGlobal ? 'global' : 'independent'}`)
        : null;
      return (
        <Component key={title} {...(isSections && { title })}>
          {documentTemplatesOptions?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Component>
      );
    });
  }, [globalDocumentTemplatesOptions, independentDocumentTemplatesOptions, isAllowIndependentDocumentTemplates, t]);
  const onChange = useCallback(
    (newValues) => {
      const newValue = difference(newValues, value);
      if (!newValue.length) return;
      const newValueObject = JSON.parse(newValue[0]);
      onChangeFormik(
        newValues.filter(
          (v) =>
            !(
              v.includes(newValueObject._id) &&
              (newValueObject.initializationConfigDate
                ? !v.includes('"initializationConfigDate"')
                : v.includes('"initializationConfigDate"'))
            ),
        ),
      );
    },
    [onChangeFormik, value],
  );

  return (
    <Form layout="vertical">
      <FormItem
        className="hide-form-item-colon form-item-no-margin"
        name="documentTemplates"
        label={t('user.ShoppingCart.DocumentTypes.typesSelectLabel')}
      >
        <div className={classes.documentTypesSelect}>
          <Select
            onChange={onChange}
            disabled={loading}
            name="documentTemplates"
            mode="multiple"
            allowClear
            placeholder={t('user.ShoppingCart.DocumentTypes.typesSelectLabel')}
          >
            {options}
          </Select>
        </div>
      </FormItem>
    </Form>
  );
};

export default memo(PDFTypeFormikSelect, equal);
