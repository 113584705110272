import { gql } from '@apollo/client';

const adminStaticItemFragment = gql`
  fragment adminStaticItemFragment on StaticItem {
    _id
    name
    nameRaw
    value
    valueRaw
    order
  }
`;

export default adminStaticItemFragment;
