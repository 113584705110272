import { Calc, Cart, Variable } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useFormikContext } from 'formik';
import { find, get, intersectionBy, mergeWith } from 'lodash';
import { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TableMemo } from 'memo';
import { getCategoriesCache } from 'graphql/cache';
import { VATFormikSwitchInPricingTable } from 'pages/user/ShoppingCart/ShoppingCartOptions';
import { CalculationMode, PaymentInterval, HighlightBindingness } from 'constants/item';
import { RequiredFieldsAlertWrapper } from 'pages/user/ShoppingCart/components/ShoppingCartFormItem';
import { useCategoriesContext, useCategoryContext, useVariablesContext, useVATContext } from './context';
import { getDiscountForCategory, formikToResponse } from './utils';

const HighlightBindingnessNames = Object.values(HighlightBindingness).filter(
  (highlightBindingness) => highlightBindingness !== HighlightBindingness.NO_HIGHLIGHT,
);

const isSelectedTypeItems = ({
  items,
  parent,
  paymentInterval: _paymentInterval,
  isCategoryLevel,
  isMonthlyFixed,
} = {}) => {
  const paymentInterval = _paymentInterval === 'fixedMonthly' ? PaymentInterval.MONTHLY : _paymentInterval;
  const categories = Array.isArray(parent) ? parent : [parent];
  const groupedCategories = categories.map((cartCategory) => {
    const selectedItems = intersectionBy(items, cartCategory.items, (item) => item._id);
    const groupedItems = Calc.groupedItemsForNotes({
      items: selectedItems,
      paymentInterval,
      mergeFixed:
        !isCategoryLevel &&
        (_paymentInterval === 'fixedMonthly' || (isMonthlyFixed && paymentInterval === PaymentInterval.MONTHLY)),
      isCalculateHighlightBindingness: true,
    });
    return groupedItems;
  });
  return mergeWith({}, ...groupedCategories, (objValue, srcValue) => (objValue ?? 0) + (srcValue ?? 0));
};

const renderPrice =
  ({ name, showVat }) =>
  (props) => {
    if (!props) return null;
    const { prices, error, isDisplayFixedMonthlyFeeType } = props;
    const price = prices[name];
    if (error || isDisplayFixedMonthlyFeeType) {
      if (name === 'price') {
        let children;
        if (error) children = <RequiredFieldsAlertWrapper>{error}</RequiredFieldsAlertWrapper>;
        else if (Array.isArray(prices)) children = prices[name];
        else children = prices;
        return {
          children,
          props: {
            colSpan: showVat ? 3 : 1,
          },
        };
      }
      return {
        children: null,
        props: { className: 'display-none' },
      };
    }
    return {
      children: price,
    };
  };

const totalPreviewColumns = ({ t, vatType, vatValue, showVat }) => [
  {
    title: t('user.ShoppingCart.TotalPricing.TotalTable.headers.interval'),
    dataIndex: 'interval',
    key: 'interval',
  },
  {
    title: t('user.ShoppingCart.TotalPricing.TotalTable.headers.price'),
    key: 'price',
    render: renderPrice({ name: 'price', showVat, t }),
  },
  ...(showVat
    ? [
        {
          title: t('user.ShoppingCart.TotalPricing.TotalTable.headers.VAT', { vatType, vatValue }),
          key: 'VAT',
          render: renderPrice({ name: 'VAT' }),
        },
        {
          title: t('user.ShoppingCart.TotalPricing.TotalTable.headers.priceWithVAT'),
          key: 'priceWithVAT',
          render: renderPrice({ name: 'priceWithVAT' }),
        },
      ]
    : []),
];

const formatSelectedItems = ({
  selectedItems = [],
  priceData,
  paymentInterval,
  isCategoryLevel,
  isFixedMonthlyFeeType,
}) =>
  !(!isCategoryLevel && paymentInterval === PaymentInterval.YEARLY && isFixedMonthlyFeeType) &&
  selectedItems.allSelectedItems
    ? {
        isOneOff: paymentInterval === PaymentInterval.ONE_OFF,
        paymentInterval,
        selectedItems,
        priceData,
      }
    : null;

const formatNotes = ({
  highlightBindingnessValues,
  isCategoryLevel,
  isFixedMonthlyFeeType,
  isOneOff,
  selectedItems,
  showDigits,
  t,
}) => {
  const highlightBindingnessNotes = HighlightBindingnessNames.map(
    (highlightBindingness) =>
      selectedItems[highlightBindingness] &&
      t('sharedPackage.numberOfHighlightBindingness', {
        highlightBindingness,
        count: selectedItems[highlightBindingness],
        value: Calc.formatCurrency(highlightBindingnessValues?.[highlightBindingness]?.discountedValue, { showDigits }),
      }),
  ).filter(Boolean);
  const onActualCostNote =
    selectedItems[CalculationMode.ON_ACTUAL_COST] &&
    t('sharedPackage.numberOfActualCost', {
      actualCostCalculation:
        isCategoryLevel && isFixedMonthlyFeeType && !isOneOff && !highlightBindingnessNotes ? 'include' : 'plus',
      count: selectedItems[CalculationMode.ON_ACTUAL_COST],
    });

  return [
    highlightBindingnessNotes.join(highlightBindingnessNotes.length <= 2 ? ` ${t('common.and')} ` : ', '),
    onActualCostNote ? <b>{onActualCostNote}</b> : null,
  ]
    .filter(Boolean)
    .map((note) => <div key={note}>{note}</div>);
};

const PaymentIntervalNames = [...Object.values(PaymentInterval), 'fixedMonthly'];

export const TotalTableComponent = ({
  discount,
  isCategoryLevel,
  isFixedMonthlyFeeType,
  items,
  parent,
  showDigits,
  showVat,
  total,
  vatSwitch: VATSwitch = VATFormikSwitchInPricingTable,
  vatType,
  isDelimiter,
}) => {
  const { t } = useTranslation();
  const { value } = useVATContext();
  const dataSource = useMemo(
    () =>
      PaymentIntervalNames.map(
        (paymentInterval) =>
          total[paymentInterval] &&
          formatSelectedItems({
            priceData: total[paymentInterval],
            paymentInterval,
            selectedItems: isSelectedTypeItems({
              items,
              parent,
              isCategoryLevel,
              paymentInterval,
              isMonthlyFixed: isFixedMonthlyFeeType,
            }),
            isCategoryLevel,
            isFixedMonthlyFeeType,
          }),
      )
        .filter(Boolean)
        .map(({ isOneOff, selectedItems: { allSelectedItems, ...selectedItems }, paymentInterval, priceData }) => {
          const notes = formatNotes({
            isCategoryLevel,
            highlightBindingnessValues: priceData?.highlightBindingnessValues,
            isFixedMonthlyFeeType,
            isOneOff,
            selectedItems,
            showDigits,
            t,
          });
          const translations = [
            {
              name: 'price',
              namePrice: 'value',
              nameDiscountedPrice: 'discountedValue',
              isNotes: true,
            },
            showVat && {
              name: 'VAT',
              namePrice: 'VAT',
              nameDiscountedPrice: 'discountedVAT',
            },
            showVat && {
              name: 'priceWithVAT',
              namePrice: 'valueWithVAT',
              nameDiscountedPrice: 'discountedValueWithVAT',
            },
          ]
            .filter(Boolean)
            .map(({ namePrice, nameDiscountedPrice, isNotes, name }) => ({
              name,
              isNotes,
              priceTranslation: Calc.priceInfoAsText({
                isTotalPrice: true,
                showDigits,
                currentFeeTypeMonthly: false, // KJ-854 - https://softwarepiloten.atlassian.net/browse/KJ-854
                paymentInterval,
                discount,
                hideInterval: true,
                value: {
                  discountedValue: get(priceData, nameDiscountedPrice),
                  priceBeforeDiscount: get(priceData, namePrice),
                },
              }),
            }));
          const prices =
            isCategoryLevel && isFixedMonthlyFeeType && !isOneOff ? (
              <>
                <span>
                  {t(`user.ShoppingCart.TotalPricing.fixedMonthlyFeeLine_${paymentInterval}`, {
                    count: allSelectedItems,
                  })}
                </span>
                {notes ? <div className="subtitle info-text">{notes}</div> : null}
              </>
            ) : (
              translations.reduce(
                (acc, { priceTranslation, isNotes, name }) => ({
                  ...acc,
                  [name]: (
                    <>
                      <Trans
                        i18nKey={`sharedPackage.${priceTranslation.code}`}
                        components={{ del: <del /> }}
                        values={{
                          ...priceTranslation.variables,
                          newlineOrWhitespace: '',
                          paymentInterval: '',
                        }}
                      />
                      {isNotes ? <div className="subtitle info-text">{notes}</div> : null}
                    </>
                  ),
                }),
                {},
              )
            );
          return [
            ...(isDelimiter && paymentInterval === 'fixedMonthly' ? [null] : []),
            {
              isDisplayFixedMonthlyFeeType: isCategoryLevel && isFixedMonthlyFeeType && !isOneOff,
              interval: t(`common.Item.paymentIntervalValue.${paymentInterval}`),
              prices,
              error: priceData?.value?.includes?.('error') ? t(`user.ShoppingCart.TotalPricing.error`) : null,
            },
          ];
        }),
    [total, items, parent, isCategoryLevel, isFixedMonthlyFeeType, showDigits, t, showVat, isDelimiter, discount],
  ).flat();
  const columns = useMemo(
    () =>
      totalPreviewColumns({
        vatType,
        vatValue: Variable.formatPercentValue(value),
        showVat,
        t,
      }),
    [vatType, value, showVat, t],
  );
  return (
    <>
      <TableMemo
        rowClassName={(row) => (!row ? 'ant-table-row-delimiter' : '')}
        className="table-first-colum-min-width shopping-cart-table"
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        reduceWidthBy={32} // 2 * border-spacing
        scroll={{
          x: 600,
        }}
      />
      <div className="flex-align-right">
        <VATSwitch label={t('user.ShoppingCart.Category.Total.vatTypeLabel', { vatType })} />
      </div>
    </>
  );
};
export const useCalcCategoryTotalById = () => {
  const cachedCategories = getCategoriesCache(); // get all categories
  const categories = useCategoriesContext();
  const vatVariable = useVATContext();
  const { values } = useFormikContext();
  const variables_a = useVariablesContext();
  const inputFieldDocuments = useMemo(
    () => categories.map((category) => category.inputFieldDocuments).flat(),
    [categories],
  );
  if (!values.showPrices) return null;
  const { cart, items, discounts, inputFields } = formikToResponse({ categories, values });
  const categoryTotalFn = (_id) => {
    try {
      const cartCategory = cart.find((c) => c._id === _id);
      const { monthly, oneOff, yearly } = Calc.calcCategoryTotal({
        cartCategory,
        documents: [...inputFieldDocuments, ...items],
        vat: values.showVat ? vatVariable.value : null,
        discount: cartCategory.discountId && find(discounts, { _id: cartCategory.discountId }),
        inputFields,
        throwErrors: true,
        calcCategoryTotalById: categoryTotalFn,
        allCategoryIds: cachedCategories.map((c) => c._id),
        variables_a,
      });
      return {
        oneOff,
        monthly,
        yearly,
      };
    } catch (e) {
      console.log(e);
      return 0;
    }
  };
  return categoryTotalFn;
};
const CategoryTotalTableNotMemo = () => {
  const category = useCategoryContext();
  const categories = useCategoriesContext();
  const { values } = useFormikContext();
  const calcCategoryTotalById = useCalcCategoryTotalById();
  const total = calcCategoryTotalById(category._id);
  if (!values.showPrices) return null;
  try {
    const discount = getDiscountForCategory({ category, values });
    const { cart, items } = formikToResponse({ categories, values });
    const cartCategory = cart.find((c) => c._id === category._id);
    const isFixedMonthlyFeeType = Cart.isCurrentFeeTypeMonthly(values.feeType);

    return (
      <TotalTableComponent
        {...{
          total,
          discount,
          parent: cartCategory,
          items,
          isFixedMonthlyFeeType,
          isCategoryLevel: true,
          showVat: values.showVat,
          showDigits: values.showDigits,
          vatType: values.vatType,
        }}
      />
    );
  } catch (e) {
    console.error(e);
    return 'Please fix validation errors above to see price';
  }
};
export const CategoryTotalTable = memo(CategoryTotalTableNotMemo);

const OverallTotalTableNotMemo = () => {
  const cachedCategories = getCategoriesCache(); // get all categories
  const categories = useCategoriesContext();
  const { values } = useFormikContext();
  const variables_a = useVariablesContext();
  const vatVariable = useVATContext();
  const inputFieldDocuments = useMemo(
    () => categories.map((category) => category.inputFieldDocuments).flat(),
    [categories],
  );
  if (!values.showPrices) return null;
  try {
    const { cart, items, discounts, inputFields } = formikToResponse({ categories, values });
    const { monthly, oneOff, yearly } = Calc.calcCartTotal({
      cart,
      categories: cachedCategories,
      discounts,
      inputFields,
      documents: [...inputFieldDocuments, ...items],
      throwErrors: true,
      variables_a,
      vat: values.showVat ? vatVariable.value : null,
    });

    let total;
    const isFixedMonthlyFeeType = Cart.isCurrentFeeTypeMonthly(values.feeType);
    if (isFixedMonthlyFeeType) total = { oneOff, fixedMonthly: Calc.formatFixedValue({ monthly, yearly }) };
    else total = { oneOff, monthly, yearly };
    return (
      <TotalTableComponent
        {...{
          total,
          parent: cart,
          items,
          isFixedMonthlyFeeType,
          showVat: values.showVat,
          vatType: values.vatType,
          showDigits: values.showDigits,
        }}
      />
    );
  } catch (e) {
    console.error(e);
    return 'Please fix validation errors above to see price';
  }
};
export const OverallTotalTable = memo(OverallTotalTableNotMemo);
