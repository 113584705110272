import { gql } from '@apollo/client';
import { superAdminUserFragment } from 'graphql/fragments';

const createTenantMutation = gql`
  mutation createTenantMutation(
    $name: String!
    $firstName: String
    $lastName: String
    $email: String!
    $nrOfAllowedCompaniesByPricingPlan: Int!
    $licenseStarted: DateTime
    $locale: String
  ) {
    addTenantAndUser(
      name: $name
      firstName: $firstName
      lastName: $lastName
      email: $email
      nrOfAllowedCompaniesByPricingPlan: $nrOfAllowedCompaniesByPricingPlan
      licenseStarted: $licenseStarted
      locale: $locale
    ) {
      ...superAdminUserFragment
    }
  }
  ${superAdminUserFragment}
`;

export default createTenantMutation;
