import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { createVariable } from 'graphql/methods';
import toast from 'utils/toast';
import variableTypes from 'constants/variableTypes';
import { Variable } from '@JavaScriptSuperstars/kanzleipilot-shared';
import VariableList from './VariableList';
import { getVariableFields, variableValidationSchema, errorResolver } from './utils';
import ConstantsAndPlaceholdersHelperWidget from './ConstantsAndPlaceholdersHelperWidget';

const addNewVariable = () =>
  inputConfirmModal({
    fields: getVariableFields(),
    onSubmit: (modifier) =>
      createVariable({
        ...modifier,
        value: modifier.type === 'percent' ? Variable.formatPercentValue(modifier.value, true) : modifier.value,
      }).then(toast.successDefault),
    value: {
      label: '',
      value: '',
      type: variableTypes.float,
      description: '',
    },
    errorResolver,
    headerText: i18n.t('admin.ConstantsAndPlaceholders.addVariableModalTitle'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    validationSchema: variableValidationSchema,
    forceMultiField: true,
    width: '600px',
  });

function CompanySettingWrapper() {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.ConstantsAndPlaceholders.title')}
      right={
        <div className="ant-modal-confirm-btns">
          <Button icon={<PlusOutlined />} onClick={addNewVariable} type="primary">
            {t('admin.ConstantsAndPlaceholders.addVariableButton')}
          </Button>
        </div>
      }
      left={<ConstantsAndPlaceholdersHelperWidget />}
    >
      <VariableList />
    </PageContainer>
  );
}

export default memo(CompanySettingWrapper, equal);
