import Card from 'components/common/Card';
import equal from 'fast-deep-equal/es6/react';
import { Form } from 'formik-antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import { EditOutlined } from '@ant-design/icons';
import Switch from 'components/common/Switch';

const CustomPrice = () => {
  const { t } = useTranslation();
  const { value: allowCustomPrices } = useFormikField('allowCustomPrices');
  return (
    <Card icon={<EditOutlined />} title={t('admin.Setting.ShoppingCartPreferences.card.customPrice')}>
      <Form layout="vertical">
        <Switch
          name="allowCustomPrices"
          label={t('admin.Setting.ShoppingCartPreferences.fields.allowCustomPrices.label')}
          tooltip={t('admin.Setting.ShoppingCartPreferences.fields.allowCustomPrices.tooltip')}
          checkedChildren={t('user.ShoppingCart.options.switch.on')}
          unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
        />
        {allowCustomPrices ? (
          <Switch
            name="showCalculated"
            label={t('admin.Setting.ShoppingCartPreferences.fields.showCalculated.label')}
            checkedChildren={t('user.ShoppingCart.options.switch.on')}
            unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
          />
        ) : null}
      </Form>
    </Card>
  );
};

export default memo(CustomPrice, equal);
