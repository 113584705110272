/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-empty */
import { CloseOutlined } from '@ant-design/icons';
import { Button, message as messageAntd } from 'antd';
import htmr from 'htmr';
import i18n from 'i18n';
import { uniqueId } from 'lodash';

messageAntd.config({ duration: 0 });

const Content = ({ onClose, children }) => {
  return (
    <span>
      <div className="margin-bottom-16">{children}</div>
      <div className="buttons">
        <Button type="danger" onClick={onClose}>
          {i18n.t('common.closeToastText')}
        </Button>
      </div>
    </span>
  );
};
const ContentSuccess = ({ onClose, children }) => {
  return (
    <span style={{ position: 'relative', width: '100%', height: '100%', display: 'block' }}>
      <div style={{ textAlign: 'left', paddingRight: 20 }}>{children}</div>
      <a onClick={onClose} style={{ top: -5, right: -20 }} className="ant-notification-notice-close">
        <span className="ant-notification-close-x">
          <span role="img" aria-label="close" className="anticon anticon-close ant-notification-close-icon">
            <CloseOutlined style={{ fontSize: 14, color: '#00000073' }} />
          </span>
        </span>
      </a>
    </span>
  );
};
const openNotificationWithIcon = (type, { message, subTitle, duration = 0 }) => {
  const key = uniqueId();
  const onClose = () => messageAntd.destroy(key);
  const C = duration ? ContentSuccess : Content;
  messageAntd[type]({
    duration,
    content: (
      <C onClose={onClose}>
        <div>{htmr(message)}</div>
        {subTitle ? <div className="subtitle">{subTitle}</div> : null}
      </C>
    ),
    key,
    style: { maxWidth: 'min(70vw, 800px)', margin: 'auto', wordBreak: 'break-word' },
  });
};
const toast = {
  info: (message) => openNotificationWithIcon('info', { message }),
  success: (message, { subTitle, duration } = {}) =>
    openNotificationWithIcon('success', { message, subTitle, duration: duration ?? 5 }),
  warning: (message) => openNotificationWithIcon('warning', { message }),
  error: (message) => openNotificationWithIcon('error', { message }),
  successDefault: () => toast.success(i18n.t('common.successToastText')),
};

export default toast;
