import { useFormikContext } from 'formik';
import { castArray, concat, intersectionBy, isEqual, merge, pick } from 'lodash';
import { useComparingDocumentTemplates } from 'graphql/hooks';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo } from 'react';
import { FaEye, FaPen } from 'react-icons/fa';
import { Collapse, Tooltip } from 'antd';
import DocumentLayoutBlockContent from 'pages/admin/DocumentTemplateConfiguration/DocumentLayoutBlockContent';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import DifferencesPopover from 'components/user/compareToGlobal/DifferencesPopover';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import { formatDateTime, formatDate } from 'utils/date';
import { useShoppingCartRevisionContext } from 'components/user/shoppingCart/context';
import { useParams } from 'react-router';
import { CreationMode } from 'constants/shoppingCart';
import classes from './DocumentTypesSelect.module.less';
import Buttons from '../Buttons';
import './DocumentTypesSelect.less';

const { Panel } = Collapse;

const DocumentTypeRowButtons = ({
  _id,
  initializationConfigDate,
  buttonState: { errors, ...buttonState },
  currentSubmitTypeRef,
}) => {
  const rowButtonsSchema = useMemo(
    () => [
      {
        type: 'html',
        error: errors?.all,
        buttonProps: {
          disabledProps: {
            className: classes.documentTemplateDisabledButton,
          },
          className: classes.documentTemplateButton,
          ghost: true,
          icon: <FaEye />,
        },
        submitProps: { documentTemplate: { _id, initializationConfigDate } },
      },
    ],
    [_id, initializationConfigDate, errors?.all],
  );
  return (
    <div tabIndex={0} role="button" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
      <Buttons
        currentSubmitTypeRef={currentSubmitTypeRef}
        buttonState={buttonState}
        onlyButtons
        schema={rowButtonsSchema}
      />
    </div>
  );
};
const DocumentTemplateIndicator = ({ block }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const isChanged = useMemo(() => {
    const formikBlock = values?.documentTemplateBlocks?.[block._id];
    try {
      if ('props' in formikBlock) return !isEqual(formikBlock.props, block.props);
    } catch {
      return false;
    }
    return false;
  }, [block._id, block.props, values?.documentTemplateBlocks]);
  return (
    <div>
      <Tooltip
        title={t(`user.ShoppingCart.DocumentTypes.${isChanged ? 'active' : 'inactive'}Tooltip`, {
          blockName: block.blockNumber,
        })}
      >
        <span className={cn(classes.indicator, classes.dot, isChanged ? classes.activeDot : '')} />
        <span>
          {
            // eslint-disable-next-line no-nested-ternary
            block.blockNumber
              ? isChanged
                ? t('user.ShoppingCart.DocumentTypes.customPersonalizedText', { blockName: block.blockNumber })
                : block.blockNumber
              : t(`admin.DocumentTemplateConfigurationPage.blocks.body`)
          }
        </span>
      </Tooltip>
    </div>
  );
};
const DocumentTemplateIndicatorMemo = memo(DocumentTemplateIndicator, equal);

const DocumentTemplateIndicators = ({ editingBlocks, visibleFields }) => {
  const hideInvisibleFields = (block) => {
    return {
      ...block,
      props: pick(block.props, visibleFields),
    };
  };
  return (
    <div className={cn(classes.indicators, 'info-text')}>
      {editingBlocks?.map((block) => (
        <DocumentTemplateIndicatorMemo key={block._id} block={hideInvisibleFields(block)} />
      ))}
    </div>
  );
};
const DocumentTemplateIndicatorsMemo = memo(DocumentTemplateIndicators, equal);

const DocumentLayoutBlockContentList = ({ editingBlocks, documentTemplate, visibleFields }) => {
  const { initializationConfigDate } = useShoppingCartRevisionContext();
  const { mode } = useParams();
  const { value, onChange } = useFormikField('documentTemplateBlocks');
  const onSubmit = useCallback(
    ({ blockId, values }) => {
      try {
        const clonedValue = { ...value };
        const newProps = pick(values, visibleFields);
        clonedValue[blockId] = { props: newProps };
        onChange(clonedValue);
      } catch (e) {
        console.log(e);
      }
    },
    [onChange, value, visibleFields],
  );
  return editingBlocks?.map((block) => {
    const formikBlockProps = value?.[block._id]?.props;
    if (typeof formikBlockProps === 'object' && !isEqual(pick(block.props, visibleFields), formikBlockProps)) {
      // eslint-disable-next-line no-param-reassign
      block.props = {
        ...block.props,
        ...formikBlockProps,
      };
    }
    if (mode === CreationMode.INDEPENDENT)
      block.descriptor.form.forEach((field) => {
        // eslint-disable-next-line no-param-reassign
        if (field.type === 'multi-line-text') field.variables = { initializationConfigDate };
      });

    return (
      <DocumentLayoutBlockContent
        block={block}
        allowSave={false}
        key={block._id}
        getDocumentTemplate={() => documentTemplate}
        autosaveChanges
        visibleFields={visibleFields}
        onSubmit={onSubmit}
      />
    );
  });
};
const DocumentLayoutBlockContentListMemo = memo(DocumentLayoutBlockContentList, equal);

const DocumentTemplateDifferences = memo(({ documentTemplate }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();
  const { createdAt } = useShoppingCartRevisionContext();
  if (!isCompare) return null;
  return (
    <DifferencesPopover
      changedProps={{
        title: t('user.PrintShoppingCartPage.compareTitle', { createdAt: formatDate(createdAt) }),
      }}
      className="margin-right-16"
      data={documentTemplate}
      width={400}
    />
  );
}, equal);

export const DocumentTemplateCartMemo = memo(function DocumentTemplateCart({
  documentTemplate,
  buttonState,
  currentSubmitTypeRef,
}) {
  const visibleFields = useMemo(() => ['body'], []);
  const getDocumentTemplate = useCallback(() => documentTemplate, [documentTemplate]);
  const editingBlocks =
    documentTemplate.blocks
      ?.map((block) =>
        ['Introduction', 'SpecialAgreement'].includes(block.name)
          ? {
              ...block,
              blockNumber: RichText.richEditorTextToText(block.props.title),
            }
          : null,
      )
      .filter(Boolean) || [];
  return (
    <Collapse
      expandIconPosition="left"
      ghost
      key={documentTemplate._id}
      {...(!editingBlocks?.length ? { activeKey: undefined } : null)} // disable collapse when no introductionBlocks
      // eslint-disable-next-line react/no-unstable-nested-components
      expandIcon={() => (
        <span className={classes.editDocument}>
          <FaPen size={15} />
        </span>
      )}
    >
      <Panel
        header={
          <div className={classes.headerPanel}>
            <div>
              {documentTemplate.name}{' '}
              {documentTemplate.initializationConfigDate
                ? formatDateTime(documentTemplate.initializationConfigDate)
                : null}
            </div>
            {editingBlocks?.length ? (
              <DocumentTemplateIndicatorsMemo editingBlocks={editingBlocks} visibleFields={visibleFields} />
            ) : null}
          </div>
        }
        className={cn(
          classes.documentTemplateCart,
          'document-template-panel',
          !editingBlocks?.length ? classes.onClickableHeaderPanel : '',
        )}
        key={documentTemplate._id}
        showArrow={!!editingBlocks?.length}
        extra={
          <div className="center">
            <DocumentTemplateDifferences documentTemplate={documentTemplate} />
            <DocumentTypeRowButtons
              _id={documentTemplate._id}
              initializationConfigDate={documentTemplate.initializationConfigDate}
              buttonState={buttonState}
              currentSubmitTypeRef={currentSubmitTypeRef}
              isIntroductionBlocks={editingBlocks}
            />
          </div>
        }
      >
        <DocumentLayoutBlockContentListMemo
          editingBlocks={editingBlocks}
          visibleFields={visibleFields}
          getDocumentTemplate={getDocumentTemplate}
        />
      </Panel>
    </Collapse>
  );
},
equal);

const DocumentTypesList = ({ templates, buttonState, currentSubmitTypeRef }) => {
  return (
    <div className={classes.documentTemplates}>
      {templates.map((template) => (
        <DocumentTemplateCartMemo
          key={template._id}
          buttonState={buttonState}
          currentSubmitTypeRef={currentSubmitTypeRef}
          documentTemplate={template}
        />
      ))}
    </div>
  );
};
const useDocumentTemplates = () => {
  const { _id } = useShoppingCartRevisionContext();
  const params = useParams();
  const { data: independentDocumentTemplates, global: globalDocumentTemplates } = useComparingDocumentTemplates({
    shoppingCartId: params.mode === CreationMode.INDEPENDENT ? _id : undefined,
  });
  return useMemo(() => {
    if (!independentDocumentTemplates || !globalDocumentTemplates) return null;
    return concat(independentDocumentTemplates, globalDocumentTemplates)
      .filter(Boolean)
      .map((documentTemplate) => ({
        ...documentTemplate,
        value: JSON.stringify({
          _id: documentTemplate._id,
          ...(documentTemplate.initializationConfigDate
            ? { initializationConfigDate: documentTemplate.initializationConfigDate }
            : {}),
        }),
      }));
  }, [globalDocumentTemplates, independentDocumentTemplates]);
};
const DocumentTypesContainerMemo = memo(function DocumentTypesContainer({ buttonState, currentSubmitTypeRef }) {
  const { values } = useFormikContext();
  const { documentTemplates: documentTemplatesJSON } = values || {};
  const documentTemplates = useDocumentTemplates();
  const templatesArray = castArray(documentTemplatesJSON).filter(Boolean);
  const templates = useMemo(
    () =>
      intersectionBy(documentTemplates, templatesArray, (e) => (typeof e === 'object' ? e.value : e)).map(
        (template) => ({
          ...template,
          blocks: template.blocks.map((block) => merge({}, block)),
        }),
      ),
    [documentTemplates, templatesArray],
  );

  if (!templates.length) return null;

  return (
    <DocumentTypesList buttonState={buttonState} currentSubmitTypeRef={currentSubmitTypeRef} templates={templates} />
  );
}, equal);

export default DocumentTypesContainerMemo;
