import equal from 'fast-deep-equal/es6/react';
import { Switch as SwitchFormikAntd } from 'formik-antd';
import { Switch as SwitchAntd, Tooltip } from 'antd';
import { memo } from 'react';
import cn from 'classnames';
import { useFormikField } from 'hooks/common/useFormikField';
import { InfoCircleOutlined } from '@ant-design/icons';
import FormItem from './FormItem';
import classes from './Switch.module.less';

const FormatLabel = ({ onChange, checked, label }) => (
  <span tabIndex={0} role="button" onKeyDown={() => onChange(!checked)} onClick={() => onChange(!checked)}>
    {label}{' '}
  </span>
);

const SwitchFormik = ({ name, label, checkedChildren, unCheckedChildren, disabled, ...props }) => {
  const { value, onChange } = useFormikField(name);

  return (
    <FormItem
      name={name}
      label={<FormatLabel label={label} onChange={() => disabled || onChange(!value)} />}
      {...props}
    >
      <SwitchFormikAntd
        disabled={disabled}
        name={name}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
      />
    </FormItem>
  );
};
const Switch = ({ name, label, onChange, checked, className, checkedChildren, unCheckedChildren, ...props }) => {
  const { tooltip } = props;
  if (name)
    return (
      <SwitchFormik
        name={name}
        label={label}
        className={cn(classes.switch, className, 'hide-form-item-colon row-reverse input-flex-unset')}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        {...props}
      />
    );
  return (
    <div className={cn(classes.switch, className, 'align-center')}>
      <div>
        <SwitchAntd
          className={classes.switchButton}
          onChange={onChange}
          checked={checked}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
        />
      </div>
      <div>
        <FormatLabel label={label} onChange={() => onChange(!checked)} />
        {tooltip ? (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default memo(Switch, equal);
