import apollo from 'graphql/apollo';
import { duplicateDocumentTemplateMutation } from 'graphql/mutations';
import { adminDocumentTemplateListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const duplicateDocumentTemplate = async ({ documentTemplateId, name, isLibrary }) =>
  apollo.mutate({
    mutation: duplicateDocumentTemplateMutation,
    variables: {
      documentTemplateId,
      name,
      isLibrary,
    },
    update: (cache, { data }) => {
      const newDocument = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({
        query: adminDocumentTemplateListQuery,
        variables: {
          isLibrary,
        },
      });
      const documentTemplates = grabFirstGQLDataResult(cachedData);

      apollo.writeQuery({
        query: adminDocumentTemplateListQuery,
        variables: { isLibrary },
        data: {
          documentTemplates: [newDocument, ...documentTemplates],
        },
      });
    },
  });
export default duplicateDocumentTemplate;
