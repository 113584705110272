import cn from 'classnames';
import { Link, useLocation, matchPath } from 'react-router-dom';
import kanzleiPilotLogoSrc from 'assets/images/KanzleiPilotLogo.svg';
import kanzleiPilotLogoCollapsedSrc from 'assets/images/KanzleiPilotLogoCollapse.svg';
import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import routePaths from 'router/route-paths';
import s from './AppHeader.module.less';

const Logo = memo(({ className, classNameWrapper, collapsed, onClick, to = null }) => {
  const location = useLocation();
  const isShoppingCart = useMemo(
    () =>
      !![routePaths.shoppingCart, routePaths.shoppingCartRevision, routePaths.routerShoppingCartRevision].find((e) =>
        matchPath(location?.pathname, e),
      ),
    [location?.pathname],
  );
  const img = useMemo(
    () => (
      <img
        aria-hidden="true"
        onClick={onClick}
        className={cn(s.logo, className, isShoppingCart && s.desaturate)}
        alt="logo"
        src={collapsed ? kanzleiPilotLogoCollapsedSrc : kanzleiPilotLogoSrc}
      />
    ),
    [className, collapsed, isShoppingCart, onClick],
  );
  return (
    <div className={cn(classNameWrapper, s.logoWrapper, 'header-logo')}>{to ? <Link to={to}>{img}</Link> : img}</div>
  );
}, equal);

export default memo(Logo, equal);
