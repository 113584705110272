/* eslint-disable no-nested-ternary */
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal/es6/react';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { TableMemo } from 'memo';
import Switch from 'components/common/Switch';
import { RichEditorField } from 'components/common/RichEditor';
import classnames from 'classnames';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import { find } from 'lodash';
import { ActiveComparisonTypes } from 'constants/shoppingCart';
import { useGroupComparingData } from 'graphql/hooks/compareToGlobal/utils';
import { useVariablesContext } from '../shoppingCart/context';
import DifferencesPopover from './DifferencesPopover';

const rows = ({ t }) => [
  {
    label: t('common.fields.nameRaw.label'),
    dataIndex: 'nameRaw',
    render: (nameRaw) => <RichEditorField readOnly className="rich-editor-read-only" initialValue={nameRaw} />,
  },
  {
    label: t('common.fields.valueRaw.label'),
    dataIndex: 'valueRaw',
    render: (valueRaw) => <RichEditorField readOnly className="rich-editor-read-only" initialValue={valueRaw} />,
  },
];
const getStaticItemColumns = ({ t, showPrices, isShowCompare }) =>
  [
    {
      width: showPrices ? '40%' : '100%',
      title: t('admin.staticItemsFieldModal.nameFieldLabel'),
      dataIndex: 'nameRaw',
      key: 'nameRaw',
      render: RichText.renderRichText,
    },
    showPrices && {
      width: '60%',
      title: t('admin.staticItemsFieldModal.valueFieldLabel'),
      dataIndex: 'valueRaw',
      key: 'valueRaw',
      render: RichText.renderRichText,
    },
    isShowCompare && {
      width: 25,
      key: 'action',
      className: 'ant-table-cell-background-unset',
      render: (staticItem) => <DifferencesPopover data={staticItem} rows={rows({ t })} />,
    },
  ].filter(Boolean);

export const useStaticItemPlaceholderObject = ({ showDigits }) => {
  const variables = useVariablesContext();
  return useMemo(
    () => ({
      ...variables.reduce((acc, variable) => ({ ...acc, [variable._id]: variable.value }), {}),
      showDigits,
    }),
    [showDigits, variables],
  );
};

const IntroOutroComponent = memo(({ text, changedStaticItems }) => {
  const isNeedRender = useMemo(
    () => changedStaticItems.length && !RichText.isRichTextEmpty(text),
    [changedStaticItems.length, text],
  );
  if (!isNeedRender) return null;
  return RichText.renderRichText(text);
}, equal);

const rowKeyFunc = (record) => record?._id;

const StaticItems = ({ staticItems, showPrices, introForStaticItem, outroForStaticItem, className }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();

  const [changedStaticItems, newStaticItems] = useGroupComparingData(staticItems);

  const columns = useMemo(
    () =>
      getStaticItemColumns({
        t,
        showPrices,
        isShowCompare: isCompare && find(changedStaticItems, (e) => ActiveComparisonTypes.includes(e.comparisonType)),
      }),
    [t, showPrices, isCompare, changedStaticItems],
  );
  const newColumns = useMemo(
    () => getStaticItemColumns({ t, showPrices, isShowCompare: isCompare && newStaticItems.length }),
    [t, showPrices, isCompare, newStaticItems.length],
  );
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      <div className="flex-align-right margin-bottom-16">
        <Switch checked={isShow} onChange={setIsShow} label={t('user.ShoppingCart.Category.showStaticItems')} />
      </div>
      <div className={classnames(className, { 'display-none': !isShow })}>
        <h2>{t('viewer.ShoppingCartView.BookedServices.staticItems')}</h2>
        <IntroOutroComponent changedStaticItems={changedStaticItems} text={introForStaticItem} />
        <TableMemo
          className="shopping-cart-table"
          rowKey={rowKeyFunc}
          pagination={false}
          dataSource={changedStaticItems}
          columns={columns}
        />
        {isCompare && newStaticItems.length ? (
          <>
            <h2>{t('user.CompareToGlobal.new.staticItems')}</h2>
            <TableMemo
              className="shopping-cart-table"
              rowKey={rowKeyFunc}
              pagination={false}
              dataSource={newStaticItems}
              columns={newColumns}
            />
          </>
        ) : null}
        <IntroOutroComponent changedStaticItems={changedStaticItems} text={outroForStaticItem} />
      </div>
    </>
  );
};
export default memo(StaticItems, equal);
