import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { addDocumentsToShoppingCart } from 'graphql/methods';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import inputConfirmModal from 'utils/inputConfirmModal';
import { Select } from 'formik-antd';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import roles from 'constants/roles';
import { PrintDocumentTable } from 'pages/user/ShoppingCartSuccessful/PrintShoppingCartPage';
import useAvailableDocumentTemplatesToAdd from 'graphql/hooks/useAvailableDocumentTemplatesToAdd';
import { useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';

const { Option } = Select;

const AddDocumentContent = ({ availableDocumentTemplates, loading }) => {
  const { t } = useTranslation();
  return (
    <Select
      name="documents"
      placeholder={t('viewer.ShoppingCartView.CreatedDocuments.addDocumentsModal.placeholder')}
      loading={loading}
      disabled={loading}
      showSearch
      style={{ width: '100%' }}
      mode="multiple"
    >
      {loading
        ? null
        : availableDocumentTemplates.map((option) => (
            <Option value={option._id} key={option._id}>
              {option.name}
            </Option>
          ))}
    </Select>
  );
};

const addDocument = ({ availableDocumentTemplates, loading, shoppingCartId }) =>
  inputConfirmModal({
    formContent: () => (
      <AddDocumentContent availableDocumentTemplates={availableDocumentTemplates} loadingDocumentTemplates={loading} />
    ),
    fields: [],
    onSubmit: ({ documents }) => addDocumentsToShoppingCart({ shoppingCartId, documentIds: documents }),
    value: { document: '' },
    headerText: i18n.t('viewer.ShoppingCartView.CreatedDocuments.addDocumentsModal.title'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
    width: '600px',
  });

const ValueForAll = ({ shoppingCartId, disabledAdding, availableDocuments, loading }) => {
  const { t } = useTranslation();

  const [me] = useCurrentUser();
  const isViewer = me?.role === roles.VIEWER;

  if (isViewer) return '';
  return (
    <Tooltip
      title={
        // eslint-disable-next-line no-nested-ternary
        !availableDocuments?.length
          ? t('viewer.ShoppingCartView.CreatedDocuments.noAvailableDocumentsTooltip')
          : disabledAdding
          ? t('viewer.ShoppingCartView.CreatedDocuments.disabledAddingTooltip')
          : null
      }
    >
      <Button
        disabled={!availableDocuments?.length || loading || disabledAdding}
        icon={<PlusOutlined />}
        onClick={() =>
          addDocument({
            availableDocumentTemplates: availableDocuments,
            loadingDocumentTemplates: loading,
            shoppingCartId,
          })
        }
      >
        {t('viewer.ShoppingCartView.CreatedDocuments.addDocument')}
      </Button>
    </Tooltip>
  );
};
const CreatedDocuments = ({ shoppingCartId, disabledAdding }) => {
  const { isCompare } = useCompareToGlobalContext();
  const { availableDocuments, shoppingCartDocuments } = useAvailableDocumentTemplatesToAdd({ shoppingCartId });
  return availableDocuments?.length || shoppingCartDocuments?.length ? (
    <PrintDocumentTable
      fetchPolicy="cache-and-network"
      shoppingCartId={shoppingCartId}
      isShowCompare={isCompare}
      // eslint-disable-next-line react/no-unstable-nested-components
      AllCell={() => (
        <ValueForAll
          shoppingCartId={shoppingCartId}
          disabledAdding={disabledAdding}
          availableDocuments={availableDocuments}
          shoppingCartDocuments={shoppingCartDocuments}
        />
      )}
      displayIfEmptyCard
    />
  ) : null;
};
export default memo(CreatedDocuments, equal);
