import { gql } from '@apollo/client';
import userViewShoppingCartInputFieldFragment from './userViewShoppingCartInputFieldFragment';

const userViewShoppingCartItemFragment = gql`
  fragment userViewShoppingCartItemFragment on CategoryItem {
    _id
    _apolloCacheKey
    calculationMode
    highlightBindingness
    minPrice
    name
    notesToEmployee
    paymentInterval
    pricingFormula
    scalesEnabled
    scales {
      _id
      _apolloCacheKey
      value
      pricingFormula
    }
    pricingFormulaExtended {
      _id
      _apolloCacheKey
      formatted
    }
    inputFields {
      ...userViewShoppingCartInputFieldFragment
    }
  }
  ${userViewShoppingCartInputFieldFragment}
`;

export default userViewShoppingCartItemFragment;
