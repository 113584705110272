import { gql } from '@apollo/client';

const userViewShoppingCartInputFieldFragment = gql`
  fragment userViewShoppingCartInputFieldFragment on InputField {
    _id
    _apolloCacheKey
    defaultType
    defaultValue
    name
    type
    unitName
    variable {
      _id
      _apolloCacheKey
      value
      label
    }
    options {
      _id
      _apolloCacheKey
      name
    }
  }
`;

export default userViewShoppingCartInputFieldFragment;
