import { useTranslation } from 'react-i18next';
import { FaProjectDiagram } from 'react-icons/fa';
import { Form } from 'formik-antd';
import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import InputDate, { ThisMonthButton } from 'components/common/InputDate';
import Card from 'components/common/Card';
import Input from 'components/common/Input';
import ShoppingCartFormItem from '../components/ShoppingCartFormItem';
import DocumentTypesList from './DocumentTypesList';
import { PDFTypeFormikSelect } from './ShoppingCartPdfTypesSelect';

const ProjectNameFormikInput = () => {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="name" label={t('user.ShoppingCart.inputs.name.label')}>
      <Input name="name" debounceTime={500} />
    </ShoppingCartFormItem>
  );
};
const ProjectNameFormikInputMemo = memo(ProjectNameFormikInput, equal);

const ValidityContractFormikInput = () => {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="startOfContract" label={t('user.ShoppingCart.validityContract.title')}>
      <InputDate
        name="startOfContract"
        format={t('dateFormats.dateMoment')}
        formatTo="YYYY-MM-DD"
        renderExtraFooter={(props) => <ThisMonthButton {...props} name="startOfContract" />}
      />
    </ShoppingCartFormItem>
  );
};

const ValidityContractFormikInputMemo = memo(ValidityContractFormikInput, equal);

const MeetingAtFormikInput = () => {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name="meetingAt" label={t('user.ShoppingCart.inputs.meetingAt.label')}>
      <InputDate name="meetingAt" format={t('dateFormats.dateMoment')} formatTo="YYYY-MM-DD" />
    </ShoppingCartFormItem>
  );
};

const MeetingAtFormikInputMemo = memo(MeetingAtFormikInput, equal);

const PdfTypes = ({ buttonState, isSubmitting, currentSubmitTypeRef }) => {
  const { t } = useTranslation();

  return (
    <Card bordered title={t('user.ShoppingCart.DocumentTypes.projectLabel')} icon={<FaProjectDiagram />}>
      <Form layout="vertical">
        <ProjectNameFormikInputMemo />
        <ValidityContractFormikInputMemo />
        <MeetingAtFormikInputMemo />
      </Form>
      <PDFTypeFormikSelect />
      <br />
      <DocumentTypesList
        buttonState={buttonState}
        isSubmitting={isSubmitting}
        currentSubmitTypeRef={currentSubmitTypeRef}
      />
    </Card>
  );
};
export default PdfTypes;
