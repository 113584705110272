import { useCachedQuery } from 'graphql/utils';
import { userPreferencesQuery } from 'graphql/queries';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { grabFirstGQLDataResult } from 'utils/helpers';
import routePaths from 'router/route-paths';
import { useMatch } from 'react-router-dom';

function usePreferences({ fetchPolicy = 'network-only', shoppingCartId } = {}) {
  const { data, loading, error } = useCachedQuery(userPreferencesQuery, { fetchPolicy, variables: { shoppingCartId } });
  const isRevisionMode = useMatch(routePaths.routerShoppingCartRevision);

  const preferences = useMemo(() => {
    if (!data) return null;
    const preferencesData = grabFirstGQLDataResult(data);
    const { emailPreferences } = preferencesData;
    const { shoppingCartPreferences } = preferencesData;
    return {
      ...emailPreferences,
      ...omit(shoppingCartPreferences, ['documentTemplates', 'feeType']),
      documentTemplates: shoppingCartPreferences?.documentTemplates?.map((documentTemplate) =>
        JSON.stringify({ _id: documentTemplate._id }),
      ),
      feeType: shoppingCartPreferences?.pricingMode,
      ...(!!isRevisionMode && !shoppingCartPreferences?.allowCustomPrices && { allowCustomPrices: true }),
    };
  }, [data, isRevisionMode]);
  return {
    preferences,
    loading,
    error,
  };
}

export default usePreferences;
