/* eslint-disable no-nested-ternary */
import { Select } from 'formik-antd';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { hasCategoryStartOfService } from 'pages/user/ShoppingCart/utils';
import { InputNumber } from 'components/common/InputNumber';
import InputDate, { ThisMonthButton } from 'components/common/InputDate';
import { FaAngleRight } from 'react-icons/fa';
import ShoppingCartFormItem, {
  RequiredFieldsAlertWrapper,
} from 'pages/user/ShoppingCart/components/ShoppingCartFormItem';
import cn from 'classnames';
import * as Yup from 'yup';
import i18n from 'i18n';
import useNumberFormat, { useDecimalSeparator } from 'hooks/common/useNumberFormat';
import classes from './CategoryInCart.module.less';
import { useAffectedInputFieldIdsContext, useCategoryContext } from './context';

export const CollapseArrow = ({ isActive, className }) => {
  return (
    <span className={`collapse-arrow ${isActive && 'collapse-arrow-active'} ${className}`}>
      <FaAngleRight size={20} />
    </span>
  );
};

const StartOfServiceMemoField = memo(function StartOfService({ name }) {
  const { t } = useTranslation();
  return (
    <ShoppingCartFormItem name={name} label={t('user.ShoppingCart.Category.startOfService')}>
      <InputDate
        className={classes.inputData}
        name={name}
        picker="month"
        renderExtraFooter={(props) => <ThisMonthButton {...props} name={name} />}
      />
    </ShoppingCartFormItem>
  );
});
const StartOfService = () => {
  const category = useCategoryContext();
  const display = hasCategoryStartOfService(category);
  const name = `${category._id}_startOfService`;
  return display ? <StartOfServiceMemoField name={name} /> : null;
};
export const StartOfServiceMemo = memo(StartOfService, equal);

const numberSchema = ({ name }) =>
  Yup.number()
    .typeError(i18n.t('user.ShoppingCart.Category.InputField.mustBeANumberMessage', { name }))
    .label()
    .nullable();

const InputNumberField = ({ className, label, ...props }) => {
  const [isShowError, setIsShowError] = useState(false);
  const [error, setError] = useState();
  const numberValidationSchema = useMemo(() => numberSchema({ name: label }), [label]);
  const { decimalSeparator, thousandSeparator } = useDecimalSeparator();
  const { parser } = useNumberFormat({ decimalSeparator, thousandSeparator });

  const onInput = useCallback(
    (inputStr) => {
      const input = parser(inputStr);
      numberValidationSchema
        .validate(input)
        .then(() => setError(''))
        .catch((err) => setError(err.message));
    },
    [numberValidationSchema, parser],
  );
  const onFocus = useCallback(() => {
    setIsShowError(true);
  }, [setIsShowError]);
  const onBlur = useCallback(() => {
    setIsShowError(false);
  }, [setIsShowError]);

  return (
    <>
      <InputNumber className={cn(className)} onInput={onInput} onBlur={onBlur} onFocus={onFocus} {...props} />
      {isShowError && error ? <RequiredFieldsAlertWrapper>{error}</RequiredFieldsAlertWrapper> : null}
    </>
  );
};
export const InputNumberFieldMemo = memo(InputNumberField, equal);

const InputField = ({ field, name, disabledAllInputFields }) => {
  return (
    <FormItem
      key={name}
      name={name}
      label={field.name}
      containerProps={{
        className: cn(field?.parentType === 'categoryItem' && classes.categoryItem),
      }}
    >
      {field.type === 'combo' ? (
        <Select className={classes.select} disabled={disabledAllInputFields} name={name} allowClear>
          {field.options?.map((o) => (
            <Select.Option key={o._id} value={o._id}>
              {o.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <InputNumberFieldMemo
          addonAfter={field?.unitName}
          className={classes.inputNumber}
          debounceTime={500}
          disabled={disabledAllInputFields}
          label={field?.name}
          name={name}
        />
      )}
    </FormItem>
  );
};

const InputFieldMemo = memo(InputField, equal);

const InputFields = ({ disabledAllInputFields, inputFields: inputFieldsFromProps }) => {
  const affectedInputFieldIds = useAffectedInputFieldIdsContext();
  const inputFields = inputFieldsFromProps.filter((inputField) => affectedInputFieldIds.includes(inputField._id));
  return (
    <div className={classes.inputFields}>
      {inputFields.map((field) => (
        <InputFieldMemo
          key={field._id}
          field={field}
          disabledAllInputFields={disabledAllInputFields}
          name={field._id}
        />
      ))}
    </div>
  );
};
export const InputFieldsMemo = memo(InputFields);

const RequiredFieldsAlert = ({ relatedInputs, fieldError, relItems }) => {
  const { t } = useTranslation();
  return (
    <RequiredFieldsAlertWrapper>
      <div>
        {relItems.map(({ isSelected, item, isValid }) => (
          <Fragment key={item._id}>
            {/* {JSON.stringify({ isSelected, isValid })} */}
            {isSelected
              ? isValid
                ? null
                : t(`user.ShoppingCart.Category.Item.referenceError.referenceIsSelectedButInvalid`, {
                    itemName: item.name,
                  })
              : isValid
              ? t(`user.ShoppingCart.Category.Item.referenceError.referenceNotSelectedButValid`, {
                  itemName: item.name,
                })
              : t(`user.ShoppingCart.Category.Item.referenceError.referenceNotSelectedAndInvalid`, {
                  itemName: item.name,
                })}
          </Fragment>
        ))}{' '}
        {/* This field is lorem ipsum {relatedItem.name || null}. Please select it and fix errors. */}
      </div>
      <div> {fieldError || null}</div>
      {relatedInputs?.length ? (
        <>
          {t('user.ShoppingCart.Category.Item.requiredFieldsAlertText')}
          <ul>
            {relatedInputs.map(({ _id, name }) => (
              <li key={_id}>{name}</li>
            ))}
          </ul>
        </>
      ) : null}
    </RequiredFieldsAlertWrapper>
  );
};
export const RequiredFieldsAlertMemo = memo(RequiredFieldsAlert, equal);
