import { gql } from '@apollo/client';
import adminEmailPreferencesFragment from './adminEmailPreferencesFragment';
import adminShoppingCartPreferencesFragment from './adminShoppingCartPreferencesFragment';

const adminTenantSettingsFragment = gql`
  fragment adminTenantSettingsFragment on Settings {
    _id
    hideCountryInAddresses
    shoppingCartPreferences {
      ...adminShoppingCartPreferencesFragment
    }
    emailPreferences {
      ...adminEmailPreferencesFragment
    }
  }
  ${adminShoppingCartPreferencesFragment}
  ${adminEmailPreferencesFragment}
`;

export default adminTenantSettingsFragment;
