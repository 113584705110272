/* eslint-disable no-shadow */
import { Button } from 'antd';
import { Form, Input, Radio } from 'formik-antd';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormItem';
import inputConfirmModal from 'utils/inputConfirmModal';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { pick, isEqual } from 'lodash';
import i18n from 'i18n';
import BorderedBox from 'components/common/BorderedBox';
import { feeType, VATType, CreationMode } from 'constants/shoppingCart';
import { FiMoreVertical } from 'react-icons/fi';
import Switch from 'components/common/Switch';
import cn from 'classnames';
import LockButton from 'components/user/compareToGlobal/LockButton';
import { useShoppingCartRevisionContext } from 'components/user/shoppingCart/context';
import { useParams } from 'react-router';
import classes from './ShoppingCartOptions.module.less';

export const FeeTypeFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="feeType" label={t('user.ShoppingCart.options.feeType.label')} {...props}>
      <Radio.Group name="feeType">
        <Radio.Button value={feeType.STANDARD}>{t('user.ShoppingCart.options.feeType.standardFee')}</Radio.Button>
        <Radio.Button value={feeType.FIXED_MONTHLY}>
          {t('user.ShoppingCart.options.feeType.fixedMonthlyFee')}
        </Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};

export const VATTypeFormikInput = (props) => {
  const { t } = useTranslation();
  return (
    <FormItem name="vatType" {...props}>
      <Radio.Group name="vatType">
        <Radio.Button value={VATType.COMPANY}>{t('user.ShoppingCart.options.vatType.company')}</Radio.Button>
        <Radio.Button value={VATType.PERSONAL}>{t('user.ShoppingCart.options.vatType.personal')}</Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};

export const VATFormikSwitch = ({ className, label, tooltip, sublabel, checkedChildren, unCheckedChildren }) => {
  const { t } = useTranslation();
  return (
    <Switch
      name="showVat"
      label={label ?? t('user.ShoppingCart.options.showVat.label')}
      sublabel={sublabel}
      tooltip={tooltip}
      checkedChildren={checkedChildren ?? t('user.ShoppingCart.options.switch.on')}
      unCheckedChildren={unCheckedChildren ?? t('user.ShoppingCart.options.switch.off')}
      className={cn(className, 'form-item-no-margin')}
    />
  );
};
export const VATFormikSwitchInPricingTable = ({ label }) => {
  return <VATFormikSwitch checkedChildren="" unCheckedChildren="" label={label} />;
};

const Options = ({ isActiveDiscounts }) => {
  const [showHiddenNote, setShowHiddenNote] = useState(false);
  const toggleHiddenNote = useCallback(() => setShowHiddenNote((prev) => !prev), []);
  const { t } = useTranslation();

  return (
    <div>
      <Form layout="vertical">
        <FeeTypeFormikInput tooltip={t('user.ShoppingCart.options.feeType.tooltip')} />
        <div className={classes.groupOptions}>
          <div className={classes.switchesGroup}>
            <Switch
              name="showPrices"
              label={t('user.ShoppingCart.options.showPrices.label')}
              tooltip={t('user.ShoppingCart.options.showPrices.tooltip')}
              checkedChildren={t('user.ShoppingCart.options.switch.on')}
              unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
              className="form-item-no-margin"
            />
            <Switch
              disabled={isActiveDiscounts}
              className="form-item-no-margin"
              name="showDiscounts"
              label={t('user.ShoppingCart.options.showDiscounts.label')}
              tooltip={
                isActiveDiscounts
                  ? t('user.ShoppingCart.options.showDiscounts.disabled')
                  : t('user.ShoppingCart.options.showDiscounts.tooltip')
              }
              checkedChildren={t('user.ShoppingCart.options.switch.on')}
              unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
            />
            <VATFormikSwitch
              className="form-item-no-margin ant-label-overflow-unset"
              tooltip={t('user.ShoppingCart.options.showVat.tooltip')}
              sublabel={
                <VATTypeFormikInput className={classes.VATType} label={t('user.ShoppingCart.options.vatType.label')} />
              }
            />
            <Switch
              name="debugMode"
              className="form-item-no-margin"
              label={t('user.ShoppingCart.options.debugMode.label')}
              tooltip={t('user.ShoppingCart.options.debugMode.tooltip')}
              checkedChildren={t('user.ShoppingCart.options.switch.on')}
              unCheckedChildren={t('user.ShoppingCart.options.switch.off')}
            />
          </div>
          <div className={classes.optionNote}>
            <Button onClick={toggleHiddenNote}>
              {t(`user.ShoppingCart.options.${showHiddenNote ? 'hideHiddenNote' : 'showHiddenNote'}`)}
            </Button>
            {showHiddenNote ? (
              <BorderedBox type="transparent" label={t('user.ShoppingCart.options.hiddenNote.title')}>
                <div>
                  <FormItem className="hide-form-item-colon margin-bottom-0" name="hiddenNote" label="">
                    <Input.TextArea
                      placeholder={t('user.ShoppingCart.options.hiddenNote.placeholder')}
                      name="hiddenNote"
                    />
                  </FormItem>
                </div>
              </BorderedBox>
            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
};
const F = ({ isActiveDiscounts }) => [
  { component: (props) => <Options {...props} isActiveDiscounts={isActiveDiscounts} />, label: '' },
];
const autoUpdatingFields = ['feeType', 'showPrices', 'showDiscounts', 'debugMode', 'hiddenNote', 'showVat', 'vatType'];
const editOptionsModal = ({ value, isActiveDiscounts, setFieldValueAndTouched }) =>
  inputConfirmModal({
    headerText: i18n.t('user.ShoppingCart.options.title'),
    okText: i18n.t('user.ShoppingCart.options.ok'),
    cancelText: i18n.t('user.ShoppingCart.options.cancel'),
    onSubmit: (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        setFieldValueAndTouched(key, value);
      });
    },
    onChange: (current, previous) => {
      const currentPicked = pick(current, autoUpdatingFields);
      const previousPicked = pick(previous, autoUpdatingFields);
      if (!isEqual(currentPicked, previousPicked)) {
        Object.entries(currentPicked).forEach(([key, value]) => {
          setFieldValueAndTouched(key, value);
        });
      }
    },
    fields: F({ isActiveDiscounts }),
    forceMultiField: true,
    validationSchema: () => Yup.object(),
    value,
    width: 800,
  });

const ShoppingCartOptions = ({ iconProps, categories, ...props }) => {
  const { values, setFieldValueAndTouched } = useFormikContext();
  const valuesRef = useRef();
  valuesRef.current = values;
  const params = useParams();
  const isActiveDiscounts = useMemo(() => {
    return !!categories.find((category) =>
      category.discounts.find((discount) => values[`${category._id}_discount`] === discount._id),
    );
  }, [categories, values]);
  const { initializationConfigDate } = useShoppingCartRevisionContext();
  return (
    <div className={classes.topSettings}>
      <LockButton
        stateRef={valuesRef}
        className="margin-right-16"
        initializationConfigDate={params.mode === CreationMode.GLOBAL ? null : initializationConfigDate}
      />
      <Button
        className={classes.cartOptions}
        {...props}
        onClick={() =>
          editOptionsModal({
            value: pick(values, autoUpdatingFields),
            setFieldValueAndTouched,
            isActiveDiscounts,
          })
        }
        icon={<FiMoreVertical {...iconProps} />}
      />
    </div>
  );
};

export default ShoppingCartOptions;
