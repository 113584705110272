import { gql } from '@apollo/client';
import userStatusFragment from './userStatusFragment';
import userTotalValueFragment from './userTotalValueFragment';

const userShoppingCartEntriesTableFragment = gql`
  fragment userShoppingCartEntriesTableFragment on ShoppingCart {
    _id
    name
    createdAt
    company {
      _id
      _apolloCacheKey
      name
      identifier
    }
    contacts {
      _id
      _apolloCacheKey
      firstName
      lastName
    }
    documentTemplates {
      _id
      _apolloCacheKey
      name
    }
    pdfs {
      _id
      link
      isGenerating
      name
      templateName
      documentNumber
    }
    showDigits
    oneOff {
      ...userTotalValueFragment
    }
    monthly {
      ...userTotalValueFragment
    }
    yearly {
      ...userTotalValueFragment
    }
    status {
      ...userStatusFragment
    }
  }
  ${userTotalValueFragment}
  ${userStatusFragment}
`;

export default userShoppingCartEntriesTableFragment;
