import { gql } from '@apollo/client';
import { documentTemplateBlockDescriptorFragment } from '../admin';

const userComparingDocumentTemplatesFragment = gql`
  fragment userComparingDocumentTemplatesFragment on DocumentTemplate {
    _id
    _apolloCacheKey
    name
    order
    description
    blocks {
      _id
      _apolloCacheKey
      name
      props
      descriptor {
        ...documentTemplateBlockDescriptorFragment
      }
    }
  }
  ${documentTemplateBlockDescriptorFragment}
`;

export default userComparingDocumentTemplatesFragment;
