import { Badge, Tooltip } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { formatDateTime } from 'utils/date';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import htmr from 'htmr';
import Card from 'components/common/Card';
import { useTranslation } from 'react-i18next';
import { useCachedQuery } from 'graphql/utils';
import { userHistoryItemsViewQuery } from 'graphql/queries';
import { useParams } from 'react-router';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import { PreviewEmailCard } from 'pages/user/ShoppingCart/components/SendEmail';
import { PrintDocumentTable } from 'pages/user/ShoppingCartSuccessful/PrintShoppingCartPage';
import { FaFile } from 'react-icons/fa';
import { InfoCircleOutlined } from '@ant-design/icons';
import classes from './ShoppingCartView.module.less';

const SendingError = ({ error, title }) => {
  const { t } = useTranslation();
  if (error)
    return (
      <>
        <Badge status="error" text={t('viewer.ShoppingCartView.ShoppingCartHistoricItems.sendingError')} />{' '}
        <Tooltip title={getMessageFromGraphQLError(error)} autoAdjustOverflow>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    );
  return <Badge status="success" text={title} />;
};
const historicRevisionsColumns = ({ t }) => [
  {
    title: t('viewer.ShoppingCartView.columns.createdAt'),
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => formatDateTime(new Date(createdAt)),
  },
  {
    title: t('viewer.ShoppingCartView.columns.actions'),
    key: 'action',
    render: ({ actionType, variables }) => {
      const title = t(`viewer.ShoppingCartView.ShoppingCartHistoricItems.${actionType}`, {
        ...variables,
        revision: variables.revision ? t('viewer.ShoppingCartView.ShoppingCartHistoricItems.revision') : null,
      });
      return (
        <>
          <div>
            {actionType === 'ShoppingCart.sentByEmail' ? (
              <SendingError error={variables?.error} title={title} />
            ) : (
              title
            )}
          </div>
          {actionType === 'ShoppingCart.sentByEmail' ? (
            <>
              <PreviewEmailCard
                className={classes.previewEmailCard}
                {...variables}
                recipients={variables.recipients || []}
                subject={variables.subject || ''}
                body={variables.body.split('<br/>').map((el) => (
                  <div>{htmr(el || '&#xFEFF;')}</div>
                ))}
                signature={
                  variables.signature
                    ? variables.signature.split('<br/>').map((el) => <div>{htmr(el || '&#xFEFF;')}</div>)
                    : null
                }
              />
              <PrintDocumentTable
                shoppingCartId={variables.originalId}
                icon={<FaFile />}
                title={t('user.ShoppingCart.SendEmail.attachments')}
              />
            </>
          ) : null}
        </>
      );
    },
  },
  {
    title: t('viewer.ShoppingCartView.columns.createdBy'),
    key: 'createdBy',
    dataIndex: 'createdBy',
    render: (createdBy) => createdBy?.fullName,
  },
];

const ShoppingCartHistoryItems = () => {
  const params = useParams();

  const { data, ...rest } = useCachedQuery(userHistoryItemsViewQuery, {
    variables: { _id: params.id },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  const historyItems = useMemo(() => {
    const shoppingCart = grabFirstGQLDataResult(data);
    return shoppingCart?.historyItems;
  }, [data]);

  const { t } = useTranslation();

  const _columns = useMemo(() => historicRevisionsColumns({ t }), [t]);
  return (
    <Card title={t('viewer.ShoppingCartView.ShoppingCartHistoricItems.title')}>
      <GraphQLLoadingWrapper data={data} {...rest}>
        <TableMemo
          rowKey={(record) => record?._id}
          dataSource={historyItems}
          columns={_columns}
          pagination={false}
          scroll={{ x: 450 }}
        />
      </GraphQLLoadingWrapper>
    </Card>
  );
};
export default memo(ShoppingCartHistoryItems, equal);
