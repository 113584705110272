import { Alert, Button } from 'antd';
import Card from 'components/common/Card';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { memo, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircle, IoArrowDownCircle } from 'react-icons/io5';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { createHtmlPreviewById } from 'graphql/methods';
import { documentModal } from 'components/user/document';
import { useLocation, useParams } from 'react-router-dom';
import DifferencesPopover from 'components/user/compareToGlobal/DifferencesPopover';
import { ActiveComparisonTypes } from 'constants/shoppingCart';
import { find } from 'lodash';
import { formatDate } from 'utils/date';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import classes from './PrintShoppingCartPage.module.less';
import NextActions from './NextActions';
import {
  stateLoadingPdf,
  useDocuments,
  useDownloadShoppingCartPdf,
  useDownloadZipShoppingCartPdf,
  usePrintPdf,
} from './hooks';

export const formatStateDownload = ({ stateLoading, progress, inactive, InactiveIcon = IoArrowDownCircle }) => {
  if (stateLoading === stateLoadingPdf.generate) return '0%';
  if (stateLoading === stateLoadingPdf.download) return progress;
  if (stateLoading === stateLoadingPdf.save) return '100%';

  return (
    <>
      <InactiveIcon size={20} style={{ marginRight: '8px' }} />
      {inactive || i18n.t('user.PrintShoppingCartPage.download')}
    </>
  );
};

export const DownloadAll = ({ documents, generatedFiles, addGeneratedPdf, isAllDocumentsGenerated, companyName }) => {
  const { t } = useTranslation();
  const [onClick, { loading, stateLoading, progress }] = useDownloadZipShoppingCartPdf({
    documents,
    generatedFiles,
    addGeneratedPdf,
    companyName,
  });
  if (!documents?.length) return null;
  return (
    <Button
      loading={loading || !isAllDocumentsGenerated}
      disabled={loading || !isAllDocumentsGenerated}
      onClick={onClick}
      className={classes.downloadButton}
    >
      {formatStateDownload({ stateLoading, progress, inactive: t('user.PrintShoppingCartPage.downloadAll') })}
    </Button>
  );
};

const PrintDocument = ({ document, generatedFiles, addGeneratedPdf }) => {
  const { t } = useTranslation();
  const [onPrint, { loading, progress, stateLoading }] = usePrintPdf({
    document,
    generatedFiles,
    addGeneratedPdf,
  });
  return (
    <Button
      type="primary"
      ghost
      loading={loading || document.isGenerating}
      disabled={loading || document.isGenerating}
      onClick={onPrint}
      className={classes.downloadButton}
    >
      {formatStateDownload({
        stateLoading,
        progress,
        inactive: t('user.PrintShoppingCartPage.print'),
        InactiveIcon: PrinterOutlined,
      })}
    </Button>
  );
};

const ViewDocument = ({ shoppingCartId, documentTemplate }) => {
  const { t } = useTranslation();
  return (
    <Button
      icon={<EyeOutlined />}
      onClick={() => {
        documentModal({
          value: createHtmlPreviewById({ shoppingCartId, documentTemplate }),
          type: 'html',
        });
      }}
      className={classes.downloadButton}
    >
      {t('viewer.ShoppingCartView.CreatedDocuments.viewPDF')}
    </Button>
  );
};

const DownloadOne = ({ document, generatedFiles, addGeneratedPdf }) => {
  const [onClick, { loading, stateLoading, progress }] = useDownloadShoppingCartPdf({
    document,
    generatedFiles,
    addGeneratedPdf,
    options: { isSaveAs: true },
  });

  return (
    <Button
      loading={loading || document.isGenerating}
      disabled={loading || document.isGenerating}
      onClick={onClick}
      className={classes.downloadButton}
    >
      {formatStateDownload({ stateLoading, progress })}
    </Button>
  );
};
const columns = ({
  documents,
  isShowCompare,
  shoppingCartId,
  generatedFiles,
  addGeneratedPdf,
  isAllDocumentsGenerated,
  companyName,
}) => [
  {
    title: i18n.t('user.PrintShoppingCartPage.fields.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    key: 'compare',
    render: (documentTemplate) =>
      isShowCompare ? (
        <div className="center">
          <DifferencesPopover
            data={documentTemplate}
            width={400}
            changedProps={{
              title: i18n.t('user.PrintShoppingCartPage.compareTitle', {
                createdAt: formatDate(documentTemplate.initializationConfigDate),
              }),
            }}
          />
        </div>
      ) : null,
  },
  {
    title: i18n.t('user.PrintShoppingCartPage.fields.actions'),
    render: (document) => (
      <div className={classes.actions}>
        {document._id === 'all' ? (
          <DownloadAll
            documents={documents}
            generatedFiles={generatedFiles}
            addGeneratedPdf={addGeneratedPdf}
            isAllDocumentsGenerated={isAllDocumentsGenerated}
            companyName={companyName}
          />
        ) : (
          <>
            {document.isGenerating ? (
              <ViewDocument
                documentTemplate={{
                  _id: document.documentTemplateId,
                  initializationConfigDate: document.initializationConfigDate,
                }}
                shoppingCartId={shoppingCartId}
              />
            ) : null}
            <PrintDocument document={document} generatedFiles={generatedFiles} addGeneratedPdf={addGeneratedPdf} />
            {!document.isGenerating ? (
              <DownloadOne document={document} generatedFiles={generatedFiles} addGeneratedPdf={addGeneratedPdf} />
            ) : null}
          </>
        )}
      </div>
    ),
    key: 'actions',
  },
];
const DocumentTemplateTable = ({
  shoppingCartId,
  documents,
  isShowCompare,
  isAllDocumentsGenerated,
  AllCell,
  displayIfEmptyCard,
  companyName,
}) => {
  const [generatedFiles, setGeneratedPdfs] = useState({});
  const addGeneratedPdf = useCallback((key, pdf) => setGeneratedPdfs((state) => ({ ...state, [key]: pdf })), []);
  const { t } = useTranslation();

  return (
    <TableMemo
      columns={columns({
        documents,
        shoppingCartId,
        generatedFiles,
        addGeneratedPdf,
        isAllDocumentsGenerated,
        companyName,
        isShowCompare: isShowCompare && find(documents, (e) => ActiveComparisonTypes.includes(e.comparisonType)),
      })}
      dataSource={
        (documents || displayIfEmptyCard) && [
          ...documents,
          { name: AllCell ? <AllCell /> : t('user.PrintShoppingCartPage.downloadAll'), _id: 'all' },
        ]
      }
      scroll={{
        x: 390,
      }}
      pagination={false}
    />
  );
};

export const PrintDocumentTable = memo(
  ({ title, icon, isShowCompare, shoppingCartId, displayIfEmptyCard, AllCell = null, fetchPolicy = 'cache-first' }) => {
    const { t } = useTranslation();
    const {
      documents,
      isAllDocumentsGenerated,
      companyName,
      loading,
      error: documentsError,
    } = useDocuments({
      fetchPolicy,
      shoppingCartId,
    });
    const error = useMemo(
      () => (documentsError && !documentsError?.networkError ? documentsError : undefined),
      [documentsError],
    );
    if (!AllCell && documents && !documents.length) return null;
    return (
      <Card icon={icon} title={title ?? t('user.PrintShoppingCartPage.downloadDocuments')}>
        <GraphQLLoadingWrapper
          data={documents}
          loading={loading}
          error={error}
          isAlwaysDisplay={displayIfEmptyCard || documents?.length}
        >
          <DocumentTemplateTable
            AllCell={AllCell}
            isShowCompare={isShowCompare}
            shoppingCartId={shoppingCartId}
            documents={documents}
            isAllDocumentsGenerated={isAllDocumentsGenerated}
            displayIfEmptyCard={displayIfEmptyCard}
            companyName={companyName}
          />
        </GraphQLLoadingWrapper>
      </Card>
    );
  },

  equal,
);

function PrintShoppingCartPage() {
  const state = useLocation();
  const { id: shoppingCartId } = useParams();
  const { t } = useTranslation();
  return (
    <PageContainer>
      <div className={`xs-mt-20 ${classes.container}`}>
        {state && (
          <Alert
            message={t('user.PrintShoppingCartPage.successfulMessage')}
            type="success"
            showIcon
            icon={<IoCheckmarkCircle type="success" size={40} />}
            closable
          />
        )}
        <PrintDocumentTable fetchPolicy="cache-first" shoppingCartId={shoppingCartId} />
        <NextActions />
      </div>
    </PageContainer>
  );
}
export default memo(PrintShoppingCartPage, equal);
