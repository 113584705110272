/* eslint-disable react/no-unescaped-entities */
import BorderedBox from 'components/common/BorderedBox';
import { useCategoriesContext } from 'components/user/shoppingCart/context';
import { OverallTotalTable } from 'components/user/shoppingCart/TotalTable';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
/* import { Collapse } from 'antd';
import { FaCalculator } from 'react-icons/fa';
import { CollapseArrow } from 'components/user/shoppingCart/components';
import Card, { formatCartTitle } from 'components/common/Card';
import cn from 'classnames'; */
import Card from 'components/common/Card';
import { memo } from 'react';
import equal from 'fast-deep-equal';
import s from './ShoppingCart.module.less';
import classes from './TotalPricing.module.less';

// const { Panel } = Collapse;

const Discounts = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const categories = useCategoriesContext();
  const activeDiscounts = categories
    .map((category) => [
      category,
      category.discounts.find((discount) => values[`${category._id}_discount`] === discount._id),
    ])
    .filter(([, discount]) => discount);
  if (!activeDiscounts.length) return null;
  return (
    <div className="xs-mb-20">
      <BorderedBox className={s.discounts} type="success" label={t('user.ShoppingCart.Discounts.activeDiscountsLabel')}>
        <div>{t('user.ShoppingCart.Discounts.activeDiscountsListLabel')}</div>
        <ul>
          {activeDiscounts.map(([category, discount]) => (
            <li key={discount._id}>
              {t('user.ShoppingCart.Discounts.activeForCategory', {
                discountName: discount.name,
                categoryName: category.name,
                discountValue: discount.value,
                discountUnitName: discount.type === 'percent' ? '%' : '€',
              })}
            </li>
          ))}
        </ul>
      </BorderedBox>
    </div>
  );
};

/*
    <Card
      className={classes.totalPricing}
      title={
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={[0]}
          className={cn(classes.collapse, 'ant-collapse-without-horizontal-padding')}
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon={({ className, ...props }) => {
            return <CollapseArrow {...props} className={cn(className, classes.collapseExpandIcon)} />;
          }}
          ghost
        >
          <Panel
            className={classes.panelCollapse}
            forceRender
            header={formatCartTitle({ icon: <FaCalculator />, title: t('user.ShoppingCart.TotalPricing.label') })}
          >
            <div className={classes.totalPricingContent}>{children}</div>
          </Panel>
        </Collapse>
      }
    />
*/

export const TotalPricingCard = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Card title={t('user.ShoppingCart.TotalPricing.label')}>
      <div className={classes.totalPricingContent}>{children}</div>
    </Card>
  );
};
const TotalPricingCardMemo = memo(TotalPricingCard, equal);

const TotalPricing = () => {
  const { values } = useFormikContext();
  if (!values.showPrices) return null;

  return (
    <TotalPricingCardMemo>
      <Discounts />
      <OverallTotalTable />
    </TotalPricingCardMemo>
  );
};

export default TotalPricing;
