import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { CalculationMode, HighlightBindingness } from 'constants/item';
import i18n from 'i18n';

export const totalValueToString = ({ paymentIntervalValues, showDigits }) => {
  return Calc.formatCurrency(paymentIntervalValues?.discountedValue, { showDigits });
};

export const formatPaymentIntervalAndHighlightBindingness = ({
  highlightBindingness,
  paymentInterval,
  calculationMode,
}) =>
  [
    i18n.t(`common.Item.paymentIntervalValue.${paymentInterval}`),
    calculationMode === CalculationMode.ON_ACTUAL_COST || highlightBindingness === HighlightBindingness.NO_HIGHLIGHT
      ? null
      : i18n.t(`common.Item.highlightBindingness.${highlightBindingness}`),
  ]
    .filter(Boolean)
    .join(', ');

export const formatPricingFormula = ({ calculationMode, formula }) =>
  calculationMode === CalculationMode.ON_ACTUAL_COST
    ? i18n.t(`common.Item.calculationMode.${calculationMode}`)
    : formula;
