import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLibraryContext } from 'contexts/LibraryContext';
import { DeleteOutlined, EditOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TableMemo } from 'memo';
import { createEmailTemplate, deleteEmailTemplatesWithConfirmation, updateEmailTemplate } from 'graphql/methods';
import i18n from 'i18n';
import { Input, Checkbox } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import inputConfirmModal from 'utils/inputConfirmModal';
import { useCachedQuery } from 'graphql/utils';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { RichEditorField } from 'components/common/RichEditor';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { filter } from 'lodash';
import { withSingleLine } from 'components/common/RichEditor/plugins/withSingleLine';
import { withNoMarks } from 'components/common/RichEditor/plugins/withNoMarks';
import classes from './EmailTemplates.module.less';
import { EmailTemplateValidationSchema } from './schema';
import ImportEmailTemplates from './ImportEmailTemplates';

export const EmailFormikRichEditor = ({ disableModifiers = false, ...props }) => {
  return (
    <RichEditorField
      allowedModifiers={
        disableModifiers
          ? []
          : ['bold', 'italic', 'underline', 'bulleted-list', 'numbered-list', 'indent', 'outdent', 'reset-formatting']
      }
      modifyPlaceholderSchema={(option) => filter(option, (e) => e.name !== 'document')}
      rootElement="div"
      {...props}
    />
  );
};

const withPlugins = (e) => withSingleLine(withNoMarks(e));

export const SubjectFormikInput = () => {
  const { t } = useTranslation();
  return (
    <EmailFormikRichEditor
      withPlugins={withPlugins}
      disableModifiers
      label={t('admin.EmailTemplates.fields.subject.label')}
      name="subject"
    />
  );
};

export const BodyFormikInput = () => {
  const { t } = useTranslation();
  return <EmailFormikRichEditor label={t('admin.EmailTemplates.fields.body.label')} name="body" />;
};

const EmailTemplateForm = () => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  return (
    <>
      <FormItem name="name" label={t('admin.EmailTemplates.fields.name.label')}>
        <Input name="name" placeholder={t('admin.EmailTemplates.fields.name.placeholder')} />
      </FormItem>
      <SubjectFormikInput />
      <BodyFormikInput />
      {isLibrary ? (
        <FormItem name="isDefault" label={t('admin.EmailTemplates.fields.isDefault.label')}>
          <Checkbox name="isDefault">{t('admin.EmailTemplates.fields.isDefault.label')}</Checkbox>
        </FormItem>
      ) : null}
    </>
  );
};

const addEmailTemplate = ({ isLibrary }) =>
  inputConfirmModal({
    formContent: () => <EmailTemplateForm />,
    fields: [],
    onSubmit: ({ name, subject, body, isDefault }) =>
      createEmailTemplate({ name, subject, body, isLibrary, isDefault }),
    value: {
      name: '',
      subject: RichText.getDefaultRichEditorValue(),
      body: RichText.getDefaultRichEditorValue(),
      isDefault: false,
    },
    headerText: i18n.t('admin.EmailTemplates.modal.addTitle'),
    errorResolver: { Duplicated: ['name', i18n.t('admin.EmailTemplates.modal.error.duplicatedErrorMessage')] },
    okText: i18n.t('common.ok'),
    validationSchema: EmailTemplateValidationSchema,
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
  });

const editEmailTemplate = ({ emailTemplate, isLibrary }) =>
  inputConfirmModal({
    formContent: () => <EmailTemplateForm />,
    fields: [],
    onSubmit: ({ name, subject, body, isDefault }) => {
      updateEmailTemplate({ _id: emailTemplate._id, name, subject, body, isLibrary, isDefault });
    },
    value: {
      name: emailTemplate.name ?? '',
      subject: emailTemplate.subject ?? RichText.getDefaultRichEditorValue(),
      body: emailTemplate.body ?? RichText.getDefaultRichEditorValue(),
      isDefault: emailTemplate.isDefault ?? false,
    },
    headerText: i18n.t('admin.EmailTemplates.modal.editTitle'),
    errorResolver: { Duplicated: ['name', i18n.t('admin.EmailTemplates.modal.error.duplicatedErrorMessage')] },
    okText: i18n.t('common.ok'),
    validationSchema: EmailTemplateValidationSchema,
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
  });

const columns = ({ t, onEdit, onDelete, isLibrary }) => [
  {
    title: t('admin.EmailTemplates.columns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('admin.EmailTemplates.columns.subject'),
    dataIndex: 'subject',
    render: (subject) => {
      if (RichText.isRichTextString(subject)) {
        return <RichEditorField key={subject} readOnly className="rich-editor-read-only" initialValue={subject} />;
      }
      return subject;
    },
    key: 'subject',
  },
  {
    title: t('admin.EmailTemplates.columns.body'),
    dataIndex: 'body',
    key: 'body',
    render: (body) => (
      <RichEditorField
        key={body}
        className="rich-editor-no-paragraphs rich-editor-read-only"
        readOnly
        initialValue={body}
      />
    ),
  },
  ...(isLibrary
    ? [
        {
          title: t('admin.EmailTemplates.columns.isDefault'),
          dataIndex: 'isDefault',
          key: 'isDefault',
          render: (isDefault) => t(`admin.EmailTemplates.fields.isDefault.${isDefault ? 'yes' : 'no'}`),
        },
      ]
    : []),
  {
    key: 'actions',
    width: 102,
    render: (emailTemplate) => {
      return (
        <>
          <Button
            className="ant-btn-default"
            ghost
            icon={<EditOutlined />}
            type="primary"
            onClick={() => {
              onEdit(emailTemplate);
            }}
          />{' '}
          <Button
            className="ant-btn-default"
            ghost
            icon={<DeleteOutlined />}
            type="danger"
            onClick={() => {
              onDelete(emailTemplate._id);
            }}
          />
        </>
      );
    },
  },
];

function EmailTemplates() {
  const { isLibrary } = useLibraryContext();
  const { t } = useTranslation();
  const { data } = useCachedQuery(adminEmailTemplatesQuery, {
    variables: { isLibrary },
    fetchPolicy: 'cache-and-network',
  });
  const emailTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);

  const onEdit = useCallback(
    (emailTemplate) => {
      editEmailTemplate({ emailTemplate, isLibrary });
    },
    [isLibrary],
  );
  const onDelete = useCallback(
    (_id) => {
      deleteEmailTemplatesWithConfirmation({ _id, isLibrary });
    },
    [isLibrary],
  );

  return (
    <TableMemo
      className={classes.emailTemplatesList}
      rowKey={({ _id }) => _id}
      dataSource={emailTemplates}
      columns={columns({ t, onEdit, onDelete, isLibrary })}
      pagination={false}
    />
  );
}

function EmailTemplatesLayout() {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onClickImportButton = () => setIsModalVisible(true);

  return (
    <PageContainer
      title={t(isLibrary ? 'NavBar.commonLibrary.emailCommonLibrary.title' : 'admin.EmailTemplates.title')}
      rightProps={{ style: { flex: 0.5 } }}
      right={
        <div className="ant-modal-confirm-btns">
          {isLibrary ? null : (
            <Button icon={<ImportOutlined />} onClick={onClickImportButton}>
              {t('admin.EmailTemplates.import')}
            </Button>
          )}
          <Button icon={<PlusOutlined />} onClick={() => addEmailTemplate({ isLibrary })} type="primary">
            {t('admin.EmailTemplates.add')}
          </Button>
        </div>
      }
    >
      <EmailTemplates />
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          <ImportEmailTemplates visible={isModalVisible} handleClose={setIsModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
}

const EmailTemplatesLayoutMemo = memo(EmailTemplatesLayout, equal);

export default EmailTemplatesLayoutMemo;
