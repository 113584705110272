import { forwardRef, useMemo } from 'react';
import { Tabs } from 'antd';
import { Form, Input } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormItem';
import SaveChangesButton from 'components/common/SaveChangesButton';
import I18nFormik from 'components/common/I18nFormik';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import i18n from 'i18n';
import { RichEditorField } from 'components/common/RichEditor';
import { useTranslation } from 'react-i18next';
import { find, groupBy, forEach } from 'lodash';
import { useFormikContext } from 'formik';
import Switch from 'components/common/Switch';
import { uploadFile } from 'graphql/methods';
import FormikImageField from 'components/common/FormikImage';
import BlockController from './BlockController';
import { PersonAllowedToSignFormikMultiSelect } from './Inputs';

const { TabPane } = Tabs;

const FieldRenderer = ({ setPreviewBlockDescriptor, fieldDescriptor }) => {
  const label = fieldDescriptor.displayName || fieldDescriptor.name;
  if (fieldDescriptor.type === 'multi-line-text') {
    return <RichEditorField {...fieldDescriptor} label={label} />;
  }
  if (fieldDescriptor.type === 'signatures-select') {
    return (
      <FormItem name={fieldDescriptor.name} label={label}>
        <PersonAllowedToSignFormikMultiSelect name={fieldDescriptor.name} />
      </FormItem>
    );
  }
  if (fieldDescriptor.type === 'boolean') {
    return (
      <Switch
        name={fieldDescriptor.name}
        label={label}
        checkedChildren={i18n.t('common.on')}
        unCheckedChildren={i18n.t('common.off')}
        tooltip={fieldDescriptor.tooltip}
      />
    );
  }
  if (fieldDescriptor.type === 'photo') {
    const { uploadButtonLabel } = fieldDescriptor;
    return (
      <FormikImageField
        name={fieldDescriptor.name}
        label={label}
        uploadLabel={uploadButtonLabel}
        tooltip={fieldDescriptor.tooltip}
        allowSVG
        maxWidthImage={2480}
        maxHeightImage={3508}
      />
    );
  }
  if (fieldDescriptor.type === 'text') {
    return (
      <FormItem name={fieldDescriptor.name} label={label} tooltip={fieldDescriptor.tooltip}>
        <Input name={fieldDescriptor.name} />
      </FormItem>
    );
  }
  if (fieldDescriptor.type === 'tab') {
    return (
      <Tabs
        defaultActiveKey="1"
        destroyInactiveTabPane
        onChange={(key) => {
          const tab = fieldDescriptor.tabs.find((d) => d.name === key);
          setPreviewBlockDescriptor(tab);
        }}
      >
        {fieldDescriptor.tabs.map((tab) => (
          <TabPane tab={tab.displayName} key={tab.name}>
            {tab.props.map((fd) => (
              <FieldRenderer key={fd.name} fieldDescriptor={fd} setPreviewBlockDescriptor={setPreviewBlockDescriptor} />
            ))}
          </TabPane>
        ))}
      </Tabs>
    );
  }
  return null;
};

function hideFields({ nodes, values }) {
  return nodes
    .map((node) => {
      if (node.type === 'tab')
        return {
          ...node,
          tabs: hideFields({ nodes: node.tabs, values }),
        };
      const schema = node.showIf;
      if (schema) {
        return Object.entries(schema).filter(([key, operations]) => {
          if (key in values) {
            if ('equal' in operations && values[key] === operations.equal) return false;
          }
          return true;
        }).length
          ? undefined
          : node;
      }
      return node;
    })
    .filter(Boolean);
}
const FormItems = ({ setPreviewBlockDescriptor, form: _form, visibleFields, blockNumber }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const form = useMemo(() => {
    const filterForm = values ? hideFields({ nodes: _form, values }) : _form;
    let filteredForm = filterForm;
    if (visibleFields) {
      filteredForm = filterForm
        .map((formItem) =>
          visibleFields.includes(formItem.name)
            ? {
                ...formItem,
                displayName: blockNumber || t(`admin.DocumentTemplateConfigurationPage.blocks.${formItem.name}`),
              }
            : null,
        )
        .filter(Boolean);
    }
    return groupBy(filteredForm, (e) => e?.subtitle || '');
  }, [values, _form, visibleFields, t, blockNumber]);

  return Object.entries(form).map(([subtitle, fields]) => {
    return (
      <>
        {subtitle ? (
          <div className="margin-bottom-16">
            {t(`admin.DocumentTemplateConfigurationPage.blockSubtitles.${subtitle}`)}
          </div>
        ) : null}
        {fields.map((fieldDescriptor) => (
          <FieldRenderer
            key={fieldDescriptor.name}
            fieldDescriptor={fieldDescriptor}
            setPreviewBlockDescriptor={setPreviewBlockDescriptor}
          />
        ))}
      </>
    );
  });
};
function DocumentLayoutBlockForm(
  {
    form,
    initialValues,
    getDocumentTemplate,
    blockId,
    visibleFields,
    blockNumber,
    setPreviewBlockDescriptor,
    autosaveChanges,
    onSubmit,
  },
  ref,
) {
  const mergedInitialValues = useMemo(() => ({ ...initialValues }), [initialValues]);
  return (
    <I18nFormik
      ref={ref}
      initialValues={mergedInitialValues}
      onSubmit={async (_values) => {
        const values = {};
        const promises = [];
        forEach(_values, (value, key) => {
          const type = find(form, { name: key })?.type;
          if (type === 'photo') {
            promises.push(
              uploadFile({
                name: `name_${new Date().toISOString()}`,
                parentId: blockId,
                parentType: 'blocks',
                size: value.size,
                file: value,
              }),
            );
          } else {
            values[key] = value;
          }
        });
        await Promise.all(promises);
        if (typeof onSubmit === 'function') onSubmit({ blockId, values });
        else {
          const blocks = BlockController.updateBlockForm({
            blockId,
            blocks: getDocumentTemplate().blocks,
            formValues: values,
          });
          BlockController.persistBlocks({ _id: getDocumentTemplate()._id, blocks })
            .then(autosaveChanges || toast.successDefault)
            .catch(toastGraphQLError);
        }
      }}
      // TODO: do we need validation?
      // validationSchema={generateValidationSchema(form)}
      enableReinitialize
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItems
          visibleFields={visibleFields}
          blockNumber={blockNumber}
          form={form}
          setPreviewBlockDescriptor={setPreviewBlockDescriptor}
        />
        <SaveChangesButton autosaveEnabled={autosaveChanges} initialValues={mergedInitialValues} />
      </Form>
    </I18nFormik>
  );
}

export default forwardRef(DocumentLayoutBlockForm);
