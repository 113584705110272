import { gql } from '@apollo/client';

const createShoppingCartMutation = gql`
  mutation createShoppingCartMutation($shoppingCart: ShoppingCartInput!) {
    createShoppingCart(shoppingCart: $shoppingCart) {
      _id
    }
  }
`;

export default createShoppingCartMutation;
