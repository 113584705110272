import { Button } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import EditShoppingCartModal from 'components/user/shoppingCart/modals/EditShoppingCartModal';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userEditShoppingCartQuery } from 'graphql/queries';

const EditShoppingCartButton = ({ _id, className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const openEditShoppingCartRevisionModal = () => {
    setIsShowEditModal(true);
  };

  const hideEditModal = () => {
    setIsShowEditModal(false);
  };

  const { data, loading } = useCachedQuery(userEditShoppingCartQuery, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
  });
  const { isLatest, migrated } = useMemo(() => grabFirstGQLDataResult(data) ?? {}, [data]);

  if (data && !isLatest) return null;
  return (
    <>
      <Button
        icon={<EditFilled />}
        disabled={!data && loading}
        loading={!data && loading}
        onClick={openEditShoppingCartRevisionModal}
        className={className}
      >
        {t('viewer.ShoppingCartView.editShoppingCart')}
      </Button>
      <EditShoppingCartModal
        _id={_id}
        navigate={navigate}
        isShowEditModal={isShowEditModal}
        hideModal={hideEditModal}
        migrated={migrated}
      />
    </>
  );
};

export default EditShoppingCartButton;
